import { FunctionComponent, Suspense, lazy, memo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ChecklistProps, TChecklistForm } from './types'

const ChecklistMobile = lazy(() => import('./Checklist.mobile'))

const ChecklistDesktop = lazy(() => import('./Checklist.desktop'))

const formSchema = yup.object().shape({
  newItem: yup.string().nullable().max(70, 'A ação pode ter no máximo 70 caracteres')
})

const Checklist: FunctionComponent<ChecklistProps> = memo(
  ({ list = [], onAddNewItem, onChangeItemStatus, onRemoveItem, isEdit = false }) => {
    const isMobile = useBreakpointValue({ base: true, lg: false })

    const itemsChecked = list.filter((item) => item.checked)

    const { control, reset, handleSubmit } = useForm<TChecklistForm>({
      mode: 'onSubmit',
      resolver: yupResolver(formSchema)
    })

    const onSubmit = handleSubmit(({ newItem }: { newItem: string }) => {
      if (newItem) onAddNewItem(newItem)

      reset({ newItem: '' })
    })

    const Component = isMobile ? ChecklistMobile : ChecklistDesktop

    return (
      <Suspense fallback={<LoadingTurbo />}>
        <Component
          {...{ control, itemsChecked, list, onSubmit, onChangeItemStatus, onRemoveItem, isEdit }}
        />
      </Suspense>
    )
  }
)

export default Checklist
