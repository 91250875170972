import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { HomeFilterScreenComponent } from './HomeFilterScreen.types'
import { useHomeFilterScreen } from './useHomeFilterScreen'

const HomeFilterScreenDesktop = lazy(() => import('./HomeFilterScreen.desktop'))

const HomeFilterScreenMobile = lazy(() => import('./HomeFilterScreen.mobile'))

export const HomeFilterScreen: HomeFilterScreenComponent = ({ navigation, route }) => {
  const homeFilterScreen = useHomeFilterScreen({ navigation, route })

  const Screen = homeFilterScreen.isMobile ? HomeFilterScreenMobile : HomeFilterScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...homeFilterScreen} />
    </Suspense>
  )
}
