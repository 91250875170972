import { forwardRef } from 'react'

import { CustomBottomSheet, BottomSheetModal } from 'molecules'
import { VStack, Heading, Pressable, Text } from 'native-base'
import { ScrollView } from 'react-native-gesture-handler'
import { theme } from 'src/theme'

export type FilterTitles = 'Micro Região' | 'Segmento' | 'Subsegmentos'

type MicroRegionOptions = { name: string }[]

type SegmentsOptions = { code: number; name: string }[]

type SubSegmentsOptions = { code: number; name: string }[]

type BottomSheetFilterProps = {
  onSelect(): void
  handleSelectFilter(value: string | number): void
  value: string | undefined | number
  options: MicroRegionOptions | SegmentsOptions | SubSegmentsOptions
  title: FilterTitles
}

export const BottomSheetFilter = forwardRef<BottomSheetModal, BottomSheetFilterProps>(
  ({ handleSelectFilter, onSelect, value, options, title }, ref) => {
    return (
      <CustomBottomSheet
        ref={ref}
        snapPoints={[450]}
        handleIndicatorStyle={{ backgroundColor: theme.colors.gray[400] }}>
        <VStack p={4} space={6} w="full" h="full" pt={6}>
          <Heading fontSize="18px" lineHeight="24px" textAlign="center">
            Selecione {title === 'Micro Região' ? `a ${title}` : `o ${title}`}
          </Heading>

          <ScrollView showsVerticalScrollIndicator={false}>
            {options.map((item, index) => {
              return (
                <Pressable
                  // eslint-disable-next-line react/jsx-curly-brace-presence
                  key={`${index}`}
                  onPress={() => {
                    //@ts-ignore
                    handleSelectFilter(title === 'Micro Região' ? item.name : item)

                    onSelect()
                  }}
                  mb={2}
                  h="40px"
                  justifyContent="center"
                  _pressed={{
                    bg: 'gray.50',
                    borderRadius: 'full'
                  }}>
                  <Text fontSize="16px" lineHeight="24px" color="gray.700" textAlign="center">
                    {item.name}
                  </Text>
                </Pressable>
              )
            })}
          </ScrollView>
        </VStack>
      </CustomBottomSheet>
    )
  }
)
