import { forwardRef } from 'react'

import Icons from 'atoms/Icons'
import { IconButton, IInputProps, Input, useDisclose } from 'native-base'
import { TextInput } from 'react-native'

export const InputPassword = forwardRef<TextInput, IInputProps>((props, ref) => {
  const { isOpen, onToggle } = useDisclose(false)

  return (
    <Input
      // @ts-ignore
      ref={ref}
      autoCapitalize="none"
      placeholder="Senha"
      InputRightElement={
        <IconButton
          borderRadius="full"
          color="gray.700"
          icon={<Icons.Eye closed={!isOpen} color="gray.700" size={5} mr={1} />}
          onPress={onToggle}
          variant="ghost"
          _pressed={{
            bg: 'transparent'
          }}
          _hover={{
            bg: 'transparent'
          }}
        />
      }
      InputLeftElement={<Icons.PadLock color="gray.700" size={5} mr="9px" />}
      type={isOpen ? 'text' : 'password'}
      {...props}
    />
  )
})
