import { AttachmentCard } from './AttachmentCard'
import { CategoryContentCard as CategoryContent } from './CategoryContentCard'
import { ContentCard as Content } from './ContentCard'
import { HighlightedContentCard as HighlightedContent } from './HighlightedContentCard'
import { NewsCard } from './NewsCard'
import { NotificationCard as Notification } from './NotificationCard'
import { OpportunityCard as Opportunity } from './OpportunityCard'
import { OpportunityOfCard } from './OpportunityOfCard'
import { OrganizationCard } from './OrganizationCard'
import { SummaryCard as Summary } from './SummaryCard'
import { SummaryDetailCard } from './SummaryDetailCard'
import { WalletCard } from './WalletCard'

export default {
  Content,
  HighlightedContent,
  Opportunity,
  OpportunityOfCard,
  NewsCard,
  Notification,
  CategoryContent,
  OrganizationCard,
  Summary,
  WalletCard,
  AttachmentCard,
  SummaryDetailCard
}
