import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { format } from 'date-fns'
import { UnconnectedMessage } from 'molecules'
import {
  Box,
  Center,
  Heading,
  HStack,
  Pressable,
  Skeleton,
  Text,
  useBreakpointValue,
  VStack
} from 'native-base'
import { HomeChartsTypes, homeResumedChartsProps } from 'src/screens/HomeScreen/constants'

type ChartProps = {
  type: HomeChartsTypes
  chart?: React.ReactElement
  handleGoToFullChartScreen(type: HomeChartsTypes): void
  labels?: string[]
  colors?: string[]
  isLoading: boolean
  updated_at?: string
  noLabels?: boolean
  hasData?: boolean
}

export const ChartInfos: FunctionComponent<ChartProps> = ({
  type,
  chart,
  handleGoToFullChartScreen,
  isLoading,
  updated_at,
  labels,
  colors,
  noLabels,
  hasData
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <VStack space={4}>
      <HStack justifyContent="space-between" zIndex={1} mb={{ base: 2, lg: 8 }}>
        <Heading
          color="gray.700"
          fontSize={{ base: '16px', lg: '18px' }}
          fontWeight={{ base: 700, lg: 400 }}>
          {homeResumedChartsProps[type].title}
        </Heading>
        {isMobile && (
          <>
            {isLoading ? (
              <Skeleton borderRadius="full" h={6} w={60} />
            ) : (
              <Pressable isDisabled={!hasData} onPress={() => handleGoToFullChartScreen(type)}>
                <HStack alignItems="center" opacity={!hasData ? 0.6 : undefined}>
                  <Text
                    color={noLabels ? 'gray.200' : 'gray.700'}
                    fontSize="12px"
                    fontWeight="semibold"
                    mr={2}>
                    Ver mais
                  </Text>
                  <Icons.Arrow
                    direction="right"
                    size={6}
                    color={noLabels ? 'gray.200' : 'gray.700'}
                  />
                </HStack>
              </Pressable>
            )}
          </>
        )}
      </HStack>

      {hasData ? (
        <Box
          {...(!noLabels && {
            h: isMobile ? '130px' : 'auto',
            position: 'relative'
          })}>
          {chart}
        </Box>
      ) : (
        <UnconnectedMessage />
      )}

      {noLabels || !hasData ? (
        <></>
      ) : (
        <>
          <HStack flexWrap="wrap">
            {labels?.length
              ? labels.map((label, index) => (
                  <HStack alignItems="center" mb={2} mr={{ base: 3, lg: 8 }} key={label}>
                    <Text color="gray.700" fontSize="12px" fontWeight={{ base: 700, lg: 400 }}>
                      {label}
                    </Text>
                  </HStack>
                ))
              : homeResumedChartsProps[type].subtitles &&
                homeResumedChartsProps[type].subtitles.length > 0 &&
                homeResumedChartsProps[type].subtitles.map((subtitle, index, size) => (
                  <HStack
                    alignItems="center"
                    mb={{ base: 2, lg: 6 }}
                    mr={{ base: 3, lg: 8 }}
                    key={subtitle.title}
                    flex={size.length !== 3 ? undefined : isMobile && 1}>
                    <Center
                      bg={subtitle.color}
                      borderRadius="full"
                      size={{ base: 3, lg: 4 }}
                      mr="6px"
                    />
                    <Text color="gray.700" fontSize="12px" bold>
                      {subtitle.title}
                    </Text>
                  </HStack>
                ))}
          </HStack>
          {isLoading ? (
            <Skeleton borderRadius="full" h={4} w={60} />
          ) : (
            !!updated_at && (
              <Text
                textAlign={{ base: 'left', lg: 'end' }}
                color="gray.200"
                fontSize={{ base: '10px', lg: '14px' }}
                fontWeight="semibold"
                mt={{ base: -2, lg: -6 }}>
                Data de Ref. {updated_at && format(new Date(updated_at), 'dd/MM/yyyy')}
              </Text>
            )
          )}
        </>
      )}
    </VStack>
  )
}
