import { FunctionComponent, memo } from 'react'

import Icons from 'atoms/Icons'
import { Store } from 'integration/resources/store'
import { VStack, Heading, Text, Box, HStack, Circle } from 'native-base'
import { formatDateStringToMask } from 'src/utils'
import { currencyFormat } from 'src/utils/currencyFormat'

type OpportunityOfCardProps = {
  is_finished: boolean
  earning_potential: number
  title: string
  due_date: string
  store: Store
}

type pportunitiesListOfCardProps = {
  data: OpportunityOfCardProps
}

export const OpportunityOfCard: FunctionComponent<pportunitiesListOfCardProps> = memo(
  ({ data }) => {
    return (
      <VStack w="full" p={4} bg="white" borderRadius={20}>
        <Box>
          <HStack space={4} alignItems="center">
            <Circle
              size="48px"
              bg={data.is_finished ? 'primary.50' : 'white'}
              borderColor={data.is_finished ? 'primary.50' : 'background.base'}
              borderWidth="1px">
              <Icons.Dollar color={data.is_finished ? 'secondary.500' : 'gray.700'} />
            </Circle>
            <VStack>
              <Heading
                fontSize="14px"
                lineHeight="18px"
                fontWeight="bold"
                color="gray.700"
                minW="3/5"
                w="240px"
                numberOfLines={1}>
                {data.title}
              </Heading>
              <Text
                fontSize="12px"
                lineHeight="16px"
                fontWeight="normal"
                color="gray.400"
                mt={1}
                numberOfLines={1}>
                TAB {data.store?.tab_number} •{' '}
                {formatDateStringToMask(data.due_date, "dd'/'MM'/'yyyy")}
              </Text>
              <Text
                fontSize="12px"
                lineHeight="16px"
                fontWeight="normal"
                color="gray.400"
                mt="6px"
                numberOfLines={1}>
                R$ {currencyFormat(data.earning_potential.toString())}
              </Text>
            </VStack>
          </HStack>
        </Box>
      </VStack>
    )
  }
)
