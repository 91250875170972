export const Checkbox = {
  defaultProps: {
    size: 'md',
    borderRadius: 'full',
    borderWidth: '1px',
    _checked: {
      bg: 'primary.800'
    }
  }
}
