import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { Platform } from 'react-native'
import { AuthData } from 'src/integration/resources/auth'
import { Menu } from 'src/integration/resources/menu'
import { UserProfile } from 'src/integration/resources/user'
import { Storage } from 'src/utils'

export type AuthdataExtend = AuthData & { waiting_onboarding?: boolean } & {
  menus?: Menu[]
  profile?: UserProfile
}

export const AuthKey = 'consultormaisturbo_auth'

const authAtom = atomWithStorage<AuthdataExtend | null | undefined>(AuthKey, undefined, {
  removeItem: Storage.removeItem,
  getItem: async (key) => {
    const item = await Storage.getItem(key)

    const itemParsed = item ? (JSON.parse(item) as AuthdataExtend) : null

    return itemParsed
  },
  setItem: (key, value) => {
    if (value) {
      const newValue = Platform.OS === 'web' ? value : JSON.stringify(value)

      return Storage.setItem(key, newValue as string)
    }

    return Storage.removeItem(key)
  }
})

export const useAuthAtom = () => useAtom(authAtom)

export const useAuthAtomValue = () => useAtomValue(authAtom)

export const useSetAuthAtom = () => useSetAtom(authAtom)
