import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  useGetSimulationOptionsQuery,
  useSendSimulationMutation
} from 'integration/resources/simulator'
import { BottomSheetModal } from 'molecules'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { currencyFormat } from 'src/utils/currencyFormat'
import { LogEvent } from 'src/utils/logEvents'

import { UseSimulatorScreen } from './SimulatorScreen.types'

export const useSimulatorScreen: UseSimulatorScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [moves, setMoves] = useState(0)

  const toast = useToast()

  const { mutateAsync, isLoading: sendSimulationIsLoading } = useSendSimulationMutation()

  const { data: simulationOptions, isLoading, isFetching } = useGetSimulationOptionsQuery()

  const includeSheetRef = useRef<BottomSheetModal>(null)

  const openIncludeSheet = useCallback(() => {
    includeSheetRef.current?.present()
  }, [])

  const closeIncludeSheet = useCallback(() => {
    LogEvent('simulador_recusou_oportunidades_simulada', {
      item_id: ''
    })

    includeSheetRef.current?.close()

    includeSheetRef.current?.dismiss()
  }, [])

  const warningSheetRef = useRef<BottomSheetModal>(null)

  const openWarningSheet = useCallback(() => {
    warningSheetRef.current?.present()
  }, [])

  const closeWarningSheet = useCallback(() => {
    warningSheetRef.current?.close()

    warningSheetRef.current?.dismiss()
  }, [])

  const maxSheetRef = useRef<BottomSheetModal>(null)

  const openMaxSheet = useCallback(() => {
    maxSheetRef.current?.present()
  }, [])

  const closeMaxSheet = useCallback(() => {
    maxSheetRef.current?.close()

    maxSheetRef.current?.dismiss()
  }, [])

  const simulatorData = simulationOptions?.data.data

  const simulationActive = simulatorData?.active_simulation_item?.value
    ? simulatorData.active_simulation_item
    : {
        value: 0,
        opportunities: '0',
        points: 100
      }

  useEffect(() => {
    if (isFetching || simulationActive?.points !== simulatorData?.maxValue) {
      closeMaxSheet()
    } else {
      openMaxSheet()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationActive?.points, simulatorData?.maxValue, isFetching])

  const initialValue = simulationActive?.value?.toFixed(2)

  const initialOpportunities = simulationActive?.opportunities

  const sliderItem = {
    sliderValue: simulationActive?.points ?? 100,
    minSliderValue: simulatorData?.minValue ?? 100,
    maxSliderValue: simulatorData?.maxValue ?? 100,
    trackMarks: simulatorData?.trackMarks ?? [],
    step: 10
  }

  const [sliderValue, setSliderValue] = useState(sliderItem.sliderValue)

  const [simulationValue, setSimulationValue] = useState(currencyFormat('000'))

  const [opportunities, setOpportunities] = useState('0')

  const changeOpportunity = (sliderValue: number) => {
    const opportunities =
      simulatorData?.active_simulation === sliderValue
        ? '0'
        : simulatorData?.simulations.find((simulation) => simulation.points === sliderValue)
            ?.opportunities

    setOpportunities(opportunities ?? '0')
  }

  const handleUpdateSliderValue = (value: number) => {
    setSliderValue(value)

    changeOpportunity(value)
  }

  const handleSlideComplete = (value: number) => {
    return value !== simulationActive?.points && simulatorData
      ? setSimulationValue(
          currencyFormat(
            simulatorData.simulations
              .filter((simulation, index) => simulation.points === value)[0]
              .value.toFixed(2)
          )
        )
      : setSimulationValue(currencyFormat('000'))
  }

  const handleAccept = (value: number) => {
    mutateAsync(
      { simulation: value },
      {
        onError: () => {
          closeIncludeSheet()

          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação" />
          })

          handleGoBack()
        },
        onSuccess: (response) => {
          closeIncludeSheet()

          LogEvent('simulador_confirmou_oporturnidades_simulada', {
            item_id: response?.data?.data?.id || ''
          })

          toast.show({
            render: () => <Toast type="success" text="Simulação realizada com sucesso" />
          })

          setTimeout(() => {
            handleGoHome()
          }, 1000)
        }
      }
    )
  }

  const handleGoBack = () => {
    closeMaxSheet()

    navigation.goBack()
  }

  const handleGoHome = useCallback(async () => {
    LogEvent('simulador_saiu_da_tela_simulador', {
      item_id: ''
    })

    navigation.navigate('Tab', { screen: 'Home', params: { refetch: true } })
  }, [navigation])

  const isDisabledButton = useMemo(
    () => simulationActive.points === sliderValue,
    [simulationActive.points, sliderValue]
  )

  return {
    isMobile,
    simulationOptionsIsLoading: isLoading || isFetching,
    sendSimulationIsLoading,
    simulationValue,
    sliderItem,
    sliderValue,
    includeSheetRef,
    closeIncludeSheet,
    openIncludeSheet,
    warningSheetRef,
    maxSheetRef,
    handleUpdateSliderValue,
    handleSlideComplete,
    handleAccept,
    handleGoHome,
    setMoves: (value) => [setMoves(value), value === 1 ? openWarningSheet() : closeWarningSheet()],
    moves,
    initialValue,
    initialOpportunities,
    opportunities,
    isDisabledButton
  }
}
