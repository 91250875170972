import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const ViewAllIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G clip-path="url(#clip0_5325_47312)">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.72147 1.70996L8.72002 1.71063L1.7383 4.9747L8.73877 8.19057C8.82351 8.22948 8.91568 8.24956 9.00893 8.24941C9.10193 8.24927 9.1938 8.22902 9.27824 8.19004V8.19004L16.2783 4.9296L9.26015 1.71063C9.17547 1.67159 9.08333 1.65137 8.99009 1.65137C8.89737 1.65137 8.80575 1.67136 8.72147 1.70996ZM8.11271 9.55367L1.00094 6.28667L0.990315 6.28169C0.743816 6.16374 0.53571 5.97848 0.390035 5.74728C0.24436 5.51609 0.167057 5.2484 0.167057 4.97514C0.167057 4.70188 0.244359 4.43419 0.390035 4.203C0.535711 3.9718 0.743818 3.78653 0.990315 3.66859L0.996371 3.6657L8.09195 0.34844C8.37357 0.218597 8.67998 0.151367 8.99009 0.151367C9.29987 0.151367 9.60597 0.218468 9.88734 0.348048L9.88822 0.34845L17.0103 3.6151C17.2604 3.73181 17.472 3.91747 17.6202 4.15025C17.7685 4.38303 17.8472 4.65327 17.8472 4.92924C17.8472 5.20521 17.7685 5.47545 17.6202 5.70823C17.472 5.94101 17.2604 6.12666 17.0103 6.24337L9.91065 9.55021L9.90906 9.55095C9.62765 9.68122 9.32134 9.74893 9.01124 9.74941C8.70114 9.74989 8.39452 9.68308 8.11271 9.55367Z"
        fill="currentColor"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7709 9.12451C17.9457 9.50005 17.7829 9.94616 17.4074 10.1209L10.0814 13.5301L10.0796 13.531C9.74428 13.6859 9.37929 13.7662 9.00989 13.7662C8.64082 13.7662 8.27616 13.6861 7.94108 13.5314L7.94017 13.531L0.58722 10.1568C0.210753 9.98404 0.0456119 9.53881 0.218367 9.16234C0.391121 8.78587 0.836353 8.62073 1.21282 8.79348L8.56941 12.1693C8.70749 12.2331 8.85778 12.2662 9.00989 12.2662C9.1617 12.2662 9.3117 12.2333 9.44956 12.1697L9.45036 12.1693L16.7745 8.76096C17.1501 8.5862 17.5962 8.74897 17.7709 9.12451Z"
        fill="currentColor"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7705 13.2043C17.9458 13.5796 17.7835 14.0259 17.4082 14.2011L10.0796 17.6224C9.74428 17.7773 9.37929 17.8576 9.00989 17.8576C8.64061 17.8576 8.27574 17.7774 7.94051 17.6225V17.6225L0.586394 14.237C0.210137 14.0638 0.0455354 13.6184 0.218746 13.2421C0.391957 12.8659 0.837389 12.7013 1.21365 12.8745L8.56941 16.2607C8.70749 16.3245 8.85778 16.3576 9.00989 16.3576C9.16157 16.3576 9.31145 16.3247 9.44921 16.2613L9.45036 16.2607L16.7737 12.8419C17.149 12.6667 17.5953 12.829 17.7705 13.2043Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5325_47312">
        <Rect width="17.9999" height="18" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
