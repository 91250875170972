import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const TabIcon = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <>
      <Path
        d="M3.59987 7.94998C3.59987 7.53577 3.93566 7.19998 4.34987 7.19998H7.94989C8.3641 7.19998 8.69989 7.53577 8.69989 7.94998C8.69989 8.3642 8.3641 8.69998 7.94989 8.69998H4.34987C3.93566 8.69998 3.59987 8.3642 3.59987 7.94998Z"
        fill="currentColor"
      />
      <Path
        d="M4.34987 10.8C3.93566 10.8 3.59987 11.1358 3.59987 11.55C3.59987 11.9642 3.93566 12.3 4.34987 12.3H7.94989C8.3641 12.3 8.69989 11.9642 8.69989 11.55C8.69989 11.1358 8.3641 10.8 7.94989 10.8H4.34987Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.63017 0.173834C6.35203 -0.0579447 5.94802 -0.0579447 5.66989 0.173834L0.269863 4.67383C0.0988677 4.81633 0 5.02742 0 5.25V16.95C0 17.3642 0.335788 17.7 0.750003 17.7H11.55L11.5554 17.7H16.95C17.3642 17.7 17.7 17.3642 17.7 16.95V7.94998C17.7 7.53577 17.3642 7.19998 16.95 7.19998H12.3001V5.25C12.3001 5.02742 12.2012 4.81633 12.0302 4.67383L6.63017 0.173834ZM12.3001 16.2V8.69998H16.2V16.2H12.3001ZM10.8 7.93936V5.60128L6.15003 1.72628L1.50001 5.60128V16.2H10.8V7.94998L10.8 7.93936Z"
        fill="currentColor"
      />
    </>
  ]
})
