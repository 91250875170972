import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'

import { TabCommonProps } from './types'

const RelatedMobile = lazy(() => import('./mobile'))

const RelatedDesktop = lazy(() => import('./desktop'))

export const Related: FunctionComponent<TabCommonProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? RelatedMobile : RelatedDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...props} />
    </Suspense>
  )
}
