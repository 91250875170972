import { FunctionComponent, useMemo, useState } from 'react'

import { FunnelDataTypeMonths } from 'integration/resources/walletResume'
import { Box, Pressable, useTheme } from 'native-base'
import { Dimensions, Platform } from 'react-native'
import { HomeChartsTypes } from 'src/screens/HomeScreen/constants'
import { calculateMonthValueStack, findMaxSumByYearMonth } from 'src/utils'
import { currencyFormatChart } from 'src/utils/currencyFormat'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
  VictoryTheme
} from 'victory-native'

import { StackBarChartTooltip } from '../PortraitCharts/StackBarChart/StackBarChartTooltip'

export type ChartData = {
  value: number
  year_month: string
}[]

export type StackBarsChartProps = {
  colors: string[]
  chartsData: ChartData[]
  chartType: HomeChartsTypes
  tooltips: {
    values: FunnelDataTypeMonths[]
    titles: string[]
    showTooltip?: boolean
  }
  type?: string
}

const verticalPosition = (height: number, isIos: boolean, chartType: HomeChartsTypes) => {
  if (height > 55) return chartType === HomeChartsTypes.WALLET_SITUATION ? `${height + 2}%` : '65%'

  if (height > 50) return `${height + 2}%`
  else return `${height + (isIos ? 0 : 15)}%`
}

const horizontalPosition = (index: number, isIos: boolean, widthPercentage: number) => {
  let value = 0

  switch (index) {
    case 0:
      value = isIos ? 8.5 : 7.5

      break

    case 1:
      value = isIos ? 7.5 : 7

      break

    case 2:
      value = 6.5

      break

    case 3:
      value = 5.5

      break

    case 4:
      value = 4.5

      break

    case 5:
      value = 3.8

      break

    case 6:
      value = 2.9

      break

    case 7:
      value = 2.1

      break

    case 8:
      value = 1.5

      break

    case 9:
      value = 0.8

      break

    case 10:
      value = 0

      break

    case 11:
      value = -0.9

      break

    case 12:
      value = isIos ? -2.2 : -1.5

      break
  }

  return `${widthPercentage * index + value}%`
}

export const StackBarsChart: FunctionComponent<StackBarsChartProps> = ({
  chartsData,
  colors,
  chartType,
  tooltips,
  type
}) => {
  const higherValue = findMaxSumByYearMonth(chartsData)

  const tickValues = useMemo(
    () =>
      chartsData
        .map((chartData) => chartData.map((item) => item.year_month))
        .flat()
        .filter((item, index, total) => item !== total[index - 1]),
    [chartsData]
  )

  const maxIndex = chartsData[0].length - 1

  const theme = useTheme()

  // we use these constants to remedy the limitations of the charts library in relation to responsiveness

  const phoneHeight = Dimensions.get('window').height

  const phoneWidth = Dimensions.get('window').width

  const isIos = Platform.OS === 'ios'

  const [openTooltip, setOpenTooltip] = useState(0)

  const tickValuesData = calculateMonthValueStack(chartsData)

  return (
    <>
      <Box mt={-11} ml={-4} zIndex={1}>
        <VictoryChart
          theme={VictoryTheme.material}
          width={isIos ? phoneWidth * 0.95 : phoneWidth * 1.02}
          height={phoneHeight * 0.77}
          domainPadding={{ x: 50 }}
          domain={{ x: [1, 13] }}>
          <VictoryAxis
            tickValues={tickValues}
            tickFormat={(t) => String(t).split('-')[0]}
            orientation="bottom"
            style={{
              tickLabels: {
                fontSize: 14,
                padding: 10,
                fill: theme.colors.gray[400]
              },
              ticks: { size: 0 },
              axis: { stroke: 0 },
              grid: { stroke: 0 }
            }}
            standalone={false}
          />

          <VictoryAxis
            dependentAxis
            tickValues={[0, Math.round(higherValue / 2), higherValue]}
            tickFormat={(t) =>
              t > 0 && t < 100
                ? `${t.toFixed(2)}%`
                : type === 'currency'
                ? currencyFormatChart(String(t), true, false, 2)
                : type === 'percentage'
                ? `${t.toFixed(2)}%`
                : `${t}`
            }
            style={{
              tickLabels: {
                fontSize: 10,
                fill: theme.colors.gray[400],
                fontWeight: 600
              },
              ticks: { size: 0 },
              axis: { stroke: 0 },
              grid: {
                stroke: theme.colors.gray[100],
                strokeWidth: 1
              }
            }}
            standalone={false}
          />
          <VictoryStack
            colorScale={colors}
            labels={tickValuesData.map((d) => {
              return type === 'currency'
                ? currencyFormatChart(String(d), true, false, 1)
                : type === 'percentage'
                ? `${d.toFixed(2)}%`
                : `${d}`
            })}
            labelComponent={<VictoryLabel dy={-10} />}>
            {chartsData?.length > 0 &&
              chartsData.map((chart, index, size) => {
                return (
                  <VictoryBar
                    key={String(Math.random())}
                    data={chart}
                    x="year_month"
                    y="value"
                    name={colors[index]}
                    barWidth={28}
                    cornerRadius={{
                      top: ({ datum }) => {
                        return datum._stack === size.length ? 6 : 0
                      },
                      bottom: ({ datum }) => {
                        return datum._stack === 1 ? 6 : 0
                      }
                    }}
                    style={{
                      data: { fill: colors[index] },
                      labels: {
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: 16,
                        //@ts-ignore
                        fill: theme.colors.gray[700]
                      }
                    }}
                    labelComponent={<VictoryLabel dy={18} />}
                  />
                )
              })}
          </VictoryStack>
        </VictoryChart>
      </Box>

      {tooltips.showTooltip ? (
        new Array(13).fill(0).map((item, index) => {
          const fakeIndex = index > maxIndex ? 0 : index

          const widthPercentage = 100 / 13

          const ranges = Object.keys(tooltips.values?.[fakeIndex] ?? {}).map(
            (key) => tooltips.values[fakeIndex][key as keyof FunnelDataTypeMonths]
          )

          if (index <= maxIndex) {
            return (
              <Pressable
                position="absolute"
                top={0}
                left={horizontalPosition(index, isIos, widthPercentage)}
                width={`${widthPercentage - 2}%`}
                height="87%"
                onPress={() => setOpenTooltip(index + 1)}
                zIndex={1}>
                <Box flex={1} position="relative">
                  <Box
                    position="absolute"
                    height={1}
                    bottom={verticalPosition(90, isIos, chartType)}
                    left="15%">
                    <StackBarChartTooltip
                      isVisible={openTooltip === index + 1}
                      handleOnClose={() => setOpenTooltip(0)}
                      values={ranges}
                      titles={tooltips.titles}
                      type={chartType}
                    />
                  </Box>
                </Box>
              </Pressable>
            )
          }

          return <></>
        })
      ) : (
        <></>
      )}
    </>
  )
}
