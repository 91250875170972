import { AxiosError, AxiosResponse } from 'axios'
import { ResponseError, ResponseSuccess } from 'integration/types'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { TNotification, TNotReadResponse } from './types'

export const KEY_NOT_READ_NOTIFICATIONS_QUERY = 'getNotReadNotifications'

export const KEY_NOTIFICATIONS_QUERY = 'notifications'

export const useGetNotReadNotificationsQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<TNotReadResponse>>, AxiosError<ResponseError>>(
    KEY_NOT_READ_NOTIFICATIONS_QUERY,
    requests.getNotReadNotifications
  )

export const useGetAllNotificationsInfinityQuery = ({ enabled = true, ...params }) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<TNotification[]>>, AxiosError<ResponseError>>(
    [KEY_NOTIFICATIONS_QUERY, params],
    ({ pageParam }) =>
      requests.getAllNotifications({
        ...params,
        currentPage: pageParam
      }),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        if (
          lastPage.data.metadata.pagination.current_page <
          lastPage.data.metadata.pagination.last_page
        ) {
          return lastPage.data.metadata.pagination.current_page + 1
        }

        if (
          lastPage.data.metadata.pagination.current_page ===
          lastPage.data.metadata.pagination.last_page
        ) {
          return false
        }
      }
    }
  )

export const useGetDetailNotificationQuery = ({ id }: Pick<TNotification, 'id'>) => {
  const queryClient = useQueryClient()

  return useQuery<AxiosResponse<ResponseSuccess<TNotification>>, AxiosError<ResponseError>>(
    ['getDetailNotification', id],
    () => requests.getDetailNotification({ id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_NOTIFICATIONS_QUERY)

        queryClient.invalidateQueries(KEY_NOT_READ_NOTIFICATIONS_QUERY)
      }
    }
  )
}

export const usePathMarkUnreadNotificationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TNotification>>,
    AxiosError<ResponseError>,
    { id: string }
  >(requests.pathMarkToUnreadNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(KEY_NOTIFICATIONS_QUERY)

      queryClient.invalidateQueries(KEY_NOT_READ_NOTIFICATIONS_QUERY)
    }
  })
}

export const usePathMarkReadNotificationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TNotification>>,
    AxiosError<ResponseError>,
    { id: string }
  >(requests.pathMarkToReadNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(KEY_NOTIFICATIONS_QUERY)

      queryClient.invalidateQueries(KEY_NOT_READ_NOTIFICATIONS_QUERY)
    }
  })
}
