import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react'

import { useNavigation } from '@react-navigation/native'
import { CustomStatusBar, FadeInView, Icons } from 'atoms'
import Constants from 'expo-constants'
import { Platform } from 'expo-modules-core'
import { ButtonZendesk } from 'molecules'
import { Heading, Box, VStack, Text, HStack, IconButton, Pressable } from 'native-base'
import { Dimensions, ImageBackground, Keyboard, StyleSheet } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

type CreateAccountLayoutProps = {
  title: string
  subTitle: string
}

const POSITION_TOP_FULL = '0%'

const INITIAL_POSITION = '100%'

const POSITION_TOP_NORMAL_SMALL = '10%'

const POSITION_TOP_NORMAL = '30%'

const POSITION_TOP_NORMAL_BIG = '37%'

const normalizePosition = () => {
  const screenHeight = Dimensions.get('screen').height

  if (screenHeight <= 700) {
    return POSITION_TOP_NORMAL_SMALL
  } else if (screenHeight > 800) {
    return POSITION_TOP_NORMAL_BIG
  } else {
    return POSITION_TOP_NORMAL
  }
}

export const CreateAccountLayout: FunctionComponent<
  PropsWithChildren<CreateAccountLayoutProps>
> = ({ children, title, subTitle }) => {
  const navigation = useNavigation()

  const animatedViewHeight = useSharedValue(INITIAL_POSITION)

  const animatedViewBorder = useSharedValue(40)

  const isIOS = Platform.OS === 'ios'

  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardVisible(true)

      animatedViewHeight.value = POSITION_TOP_FULL

      animatedViewBorder.value = 0
    })

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardVisible(false)

      animatedViewHeight.value = normalizePosition()

      animatedViewBorder.value = 40
    })

    return () => {
      keyboardDidHideListener.remove()

      keyboardDidShowListener.remove()
    }
  }, [animatedViewHeight, animatedViewBorder])

  const animatedTop = useAnimatedStyle(() => {
    return {
      top: withTiming(animatedViewHeight.value, {
        duration: 600
      })
    }
  })

  const animatedBorder = useAnimatedStyle(() => {
    const border = withTiming(animatedViewBorder.value, {
      duration: 200
    })

    const paddingTop = withTiming(animatedViewBorder.value === 0 ? 32 : 18, {
      duration: 400
    })

    return {
      paddingTop,
      borderTopLeftRadius: border,
      borderTopRightRadius: border
    }
  })

  useEffect(() => {
    animatedViewHeight.value = normalizePosition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const screenHeight = Dimensions.get('screen').height

  const screenWidth = Dimensions.get('screen').width

  const statusBarHeight = Constants.statusBarHeight

  return (
    <Pressable flex={1} onPress={Keyboard.dismiss}>
      <Box position="relative" flex={1} bg="gray.800">
        <CustomStatusBar barStyle={isKeyboardVisible ? 'dark-content' : 'light-content'} />

        <Box flex={1}>
          <ImageBackground
            source={require('assets/santander-cpf.png')}
            resizeMode="contain"
            style={{
              width: screenWidth,
              height: screenHeight,
              marginTop: isIOS ? (statusBarHeight > 44 ? -120 : -122) : -145
            }}>
            <VStack justifyContent="flex-start" ml={4} mt="50%">
              <FadeInView delay={600}>
                <Icons.FlameSantander color="white" size={9} />
              </FadeInView>
            </VStack>
          </ImageBackground>
        </Box>

        <Animated.View style={[styles.container, animatedTop]}>
          <Animated.View style={[styles.content, animatedBorder]}>
            <KeyboardAwareScrollView showsVerticalScrollIndicator={false}>
              {navigation.canGoBack() && (
                <HStack
                  {...(isKeyboardVisible ? { safeAreaTop: true } : {})}
                  mb={!isKeyboardVisible ? '16px' : '46px'}>
                  <IconButton
                    bgColor="background.base"
                    borderRadius="full"
                    mt={2}
                    padding={1}
                    icon={<Icons.Chevron direction="left" color="gray.700" size={9} />}
                    onPress={navigation.goBack}
                  />
                </HStack>
              )}
              <Heading color="gray.700" mt={2} mb={2}>
                {title}
              </Heading>
              <Text fontSize="16px" color="gray.750" lineHeight="24px" mb={5}>
                {subTitle}
              </Text>
              {children}
            </KeyboardAwareScrollView>
          </Animated.View>
        </Animated.View>
      </Box>

      <ButtonZendesk />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
    left: 0,
    right: 0
  },
  content: {
    backgroundColor: 'white',
    flex: 1,
    paddingHorizontal: 16
  }
})
