import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { Avatar, Box, Heading, Pressable, Text, VStack } from 'native-base'

type TUserMessage = {
  avatarUri: string
  id: number
  name: string
  description: string
  amountToRead: number
  lastMessage: string
}

export const ActionCardUserMessage: FunctionComponent<TUserMessage> = ({
  avatarUri,
  name,
  description,
  amountToRead,
  lastMessage
}) => (
  <Pressable
    _pressed={{
      opacity: 0.7,
      bg: 'background.base'
    }}
    flexDir="row"
    onPress={() => {}}
    borderRadius="20px"
    bg="white"
    px={4}
    py={6}
    mb={2}>
    <Avatar
      mr={2}
      source={{
        uri: avatarUri
      }}
      bg="blue.200"
      w={12}
      h={12}
    />
    <VStack space={1} flex="1">
      <Heading fontSize="14px" lineHeight="18px" color="gray.700">
        {name}
      </Heading>
      <Text fontSize="12px" lineHeight="16px" color="gray.400">
        {description}
      </Text>
    </VStack>
    <VStack alignItems="flex-end">
      {amountToRead > 0 ? (
        <Box
          bg="primary.500"
          borderRadius="full"
          justifyContent="center"
          alignItems="center"
          w="16px"
          h="16px"
          mb={3}>
          <Text color="white" fontSize="10px" letterSpacing={0.3} fontWeight="600">
            {amountToRead}
          </Text>
        </Box>
      ) : (
        <Icons.Read color="gray.700" size={4} />
      )}
      <Text fontSize="12px" lineHeight="16px" color="gray.400">
        {lastMessage}
      </Text>
    </VStack>
  </Pressable>
)
