import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import * as requests from './requests'
import {
  AuthData,
  AuthPayload,
  LogoutPayload,
  PreAuthData,
  PreAuthPayload,
  PreAuthResponse,
  PreAuthSendCode
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const usePreAuthMutation = () =>
  useMutation<
    AxiosResponse<ResponseSuccess<PreAuthResponse>>,
    AxiosError<ResponseError>,
    PreAuthPayload
  >(requests.preAuth)

export const useAuthMutation = () =>
  useMutation<AxiosResponse<ResponseSuccess<AuthData>>, AxiosError<ResponseError>, AuthPayload>(
    requests.auth
  )

export const useLogoutMutation = () =>
  useMutation<AxiosResponse<ResponseSuccess<any>>, AxiosError<ResponseError>, LogoutPayload>(
    requests.logout
  )

export const useAcceptTermsMutation = () =>
  useMutation<AxiosResponse<ResponseSuccess<null>>[], AxiosError<ResponseError>>(
    requests.acceptTerms
  )

export const useAuthSendCodeMutation = () =>
  useMutation<
    AxiosResponse<ResponseSuccess<PreAuthData>>,
    AxiosError<ResponseError>,
    PreAuthSendCode
  >(requests.authSendCode)
