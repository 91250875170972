import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import {
  Apnef,
  ContractsAmountDataSerialized,
  CreditInsuranceDataSerialized,
  Fpd10DataSerialized,
  GetChartDataParams,
  GetChartDataParamsSequencial,
  MarketShareDataSerialized,
  OpenedAccountQuantityDataSerialized,
  Over30M3DataSerialized,
  OverDataSerialized,
  PotentialDataSerialized,
  ProductionDataSerialized,
  ProposalFunnelSerialized,
  SantanderAutoInsuranceDataSerialized,
  SantanderAutoTypeDataSerialized,
  SpreadPercentageDataSerialized,
  WebmotorsDataSerialized
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_PROPOSAL_FUNNEL_QUERY = 'getProposalFunnel'

export const KEY_APNEF_QUERY = 'getApnef'

export const KEY_CONTRACTS_AMOUNT_QUERY = 'getContractsAmount'

export const KEY_SPREAD_PERCENTAGE_QUERY = 'getSpreadPercentage'

export const KEY_CREDIT_INSURANCE_QUERY = 'getCreditInsurance'

export const KEY_SANTANDER_AUTO_TYPE_QUERY = 'getSantanderAutoType'

export const KEY_PRODUCTION_QUERY = 'getProduction'

export const KEY_POTENTIAL_QUERY = 'getPotential'

export const KEY_MARKET_SHARE_QUERY = 'getMarketShare'

export const KEY_SANTANDER_AUTO_INSURANCE_QUERY = 'getSantanderAutoInsurance'

export const KEY_OPENED_ACCOUNT_QUANTITY_QUERY = 'getOpenedAccountQuantity'

export const KEY_WEBMOTORS_QUERY = 'getWebmotors'

export const KEY_FPD10_QUERY = 'getFpd10'

export const KEY_OVER30_M3_QUERY = 'getOver30M3'

export const KEY_OVER30_QUERY = 'getOver30'

export const KEY_OVER90_QUERY = 'getOver90'

export const useGetApnefQuery = (tab_number: string) =>
  useQuery<AxiosResponse<ResponseSuccess<Apnef>>, AxiosError<ResponseError>>(
    [`${KEY_APNEF_QUERY}.${tab_number}`],
    () => requests.getApnef(tab_number)
  )

export const useGetProposalFunnelQuery = (tab_number: string) =>
  useQuery<AxiosResponse<ResponseSuccess<ProposalFunnelSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_PROPOSAL_FUNNEL_QUERY}.${tab_number}`],
    () => requests.getProposalFunnel(tab_number)
  )

export const useGetProductionQuery = (params: GetChartDataParams) =>
  useQuery<AxiosResponse<ResponseSuccess<ProductionDataSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_PRODUCTION_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getProduction({ ...params })
  )

export const useGetPotentialQuery = (params: GetChartDataParamsSequencial) =>
  useQuery<AxiosResponse<ResponseSuccess<PotentialDataSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_POTENTIAL_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getPotential({ ...params }),
    { enabled: params.enabled }
  )

export const useGetMarketShareQuery = (params: GetChartDataParamsSequencial) =>
  useQuery<AxiosResponse<ResponseSuccess<MarketShareDataSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_MARKET_SHARE_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getMarketShare({ ...params }),
    { enabled: params.enabled }
  )

export const useGetSantanderAutoInsuranceQuery = (params: GetChartDataParamsSequencial) =>
  useQuery<
    AxiosResponse<ResponseSuccess<SantanderAutoInsuranceDataSerialized>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_SANTANDER_AUTO_INSURANCE_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getSantanderAutoInsurance({ ...params }),
    { enabled: params.enabled }
  )

export const useGetOpenedAccountQuantityQuery = (params: GetChartDataParamsSequencial) =>
  useQuery<
    AxiosResponse<ResponseSuccess<OpenedAccountQuantityDataSerialized>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_OPENED_ACCOUNT_QUANTITY_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getOpenedAccountQuantity({ ...params }),
    { enabled: params.enabled }
  )

export const useGetWebmotorsQuery = (params: GetChartDataParamsSequencial) =>
  useQuery<AxiosResponse<ResponseSuccess<WebmotorsDataSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_WEBMOTORS_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getWebmotors({ ...params }),
    { enabled: params.enabled }
  )

export const useGetFpd10Query = (params: GetChartDataParamsSequencial) =>
  useQuery<AxiosResponse<ResponseSuccess<Fpd10DataSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_FPD10_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getFpd10({ ...params }),
    { enabled: params.enabled }
  )

export const useGetOver30M3Query = (params: GetChartDataParamsSequencial) =>
  useQuery<AxiosResponse<ResponseSuccess<Over30M3DataSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_OVER30_M3_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getOver30M3({ ...params }),
    { enabled: params.enabled }
  )

export const useGetOverQuery = (params: GetChartDataParamsSequencial) =>
  useQuery<AxiosResponse<ResponseSuccess<OverDataSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_OVER30_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getOver({ ...params }),
    { enabled: params.enabled }
  )

export const useGetContractsAmountQuery = (params: GetChartDataParams) =>
  useQuery<
    AxiosResponse<ResponseSuccess<ContractsAmountDataSerialized>>,
    AxiosError<ResponseError>
  >([`${KEY_CONTRACTS_AMOUNT_QUERY}.${params.tab_number}.${params.year_month_qty}`], () =>
    requests.getContractsAmount({ ...params })
  )

export const useGetSpreadPercentageQuery = (params: GetChartDataParamsSequencial) =>
  useQuery<
    AxiosResponse<ResponseSuccess<SpreadPercentageDataSerialized>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_SPREAD_PERCENTAGE_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getSpreadPercentage({ ...params }),
    { enabled: params.enabled }
  )

export const useGetCreditInsuranceQuery = (params: GetChartDataParamsSequencial) =>
  useQuery<
    AxiosResponse<ResponseSuccess<CreditInsuranceDataSerialized>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_CREDIT_INSURANCE_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getCreditInsurance({ ...params }),
    { enabled: params.enabled }
  )

export const useGetSantanderAutoTypeQuery = (params: GetChartDataParamsSequencial) =>
  useQuery<
    AxiosResponse<ResponseSuccess<SantanderAutoTypeDataSerialized>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_SANTANDER_AUTO_TYPE_QUERY}.${params.tab_number}.${params.year_month_qty}`],
    () => requests.getSantanderAutoType({ ...params }),
    { enabled: params.enabled }
  )
