import { FunctionComponent } from 'react'

import { LoadingTurbo } from 'atoms'
import { Center, useTheme, VStack, useBreakpointValue } from 'native-base'
import Chart from 'organisms/Chart'
import Layouts from 'organisms/Layouts'
import { Platform } from 'react-native'

import { useHomeFunnel } from './hook'
import { HomeChartsTypes, homeResumedChartsProps } from '../../constants'

type HomeFunnelProps = {
  handleGoToFullChartScreen: (type: HomeChartsTypes) => void
  isGoodsAndServices: boolean
}

export const HomeFunnel: FunctionComponent<HomeFunnelProps> = ({ handleGoToFullChartScreen }) => {
  const { simulationsRatingData, contractsRatingData, funnelDataIsLoading } = useHomeFunnel()

  const theme = useTheme()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <VStack
      mt={isDesktop ? 8 : Platform.OS === 'ios' ? 1 : 5}
      mr={4}
      mb={2}
      space={4}
      maxW={isDesktop ? '1122px' : 'auto'}>
      {funnelDataIsLoading ? (
        <Center h={360} w="full" position="absolute" zIndex={9}>
          <LoadingTurbo size={100} />
        </Center>
      ) : (
        <>
          <Layouts.MultipleResumedChart
            charts={[
              {
                isLoading: funnelDataIsLoading,
                hasData: simulationsRatingData.hasData,
                type: HomeChartsTypes.SIMULATIONS_RATING,
                updated_at: simulationsRatingData.processed_at ?? '',
                handleGoToFullChartScreen: () =>
                  handleGoToFullChartScreen(HomeChartsTypes.SIMULATIONS_RATING),
                noLabels: false,
                chart: (
                  <Chart.PortraitStackBar
                    horizontalLabels={isDesktop}
                    colors={[
                      theme.colors.primary[800],
                      theme.colors.primary[500],
                      theme.colors.primary[100]
                    ]}
                    chartType={HomeChartsTypes.SIMULATIONS_RATING}
                    chartsData={[
                      simulationsRatingData?.range9or10 ?? [],
                      simulationsRatingData?.range7or8 ?? [],
                      simulationsRatingData?.range5or6 ?? []
                    ]}
                    tooltips={{
                      titles:
                        homeResumedChartsProps[HomeChartsTypes.SIMULATIONS_RATING].tooltipsTitles,
                      values: simulationsRatingData.tooltipsValues ?? []
                    }}
                    showTooltip
                    type="percentage"
                  />
                )
              },
              {
                isLoading: funnelDataIsLoading,
                hasData: contractsRatingData.hasData,
                type: HomeChartsTypes.CONTRACTS_RATING,
                updated_at: contractsRatingData.processed_at ?? '',
                handleGoToFullChartScreen: () =>
                  handleGoToFullChartScreen(HomeChartsTypes.CONTRACTS_RATING),
                noLabels: false,
                chart: (
                  <Chart.PortraitStackBar
                    horizontalLabels={isDesktop}
                    colors={[
                      theme.colors.primary[500],
                      theme.colors.primary[300],
                      theme.colors.primary[100]
                    ]}
                    chartType={HomeChartsTypes.CONTRACTS_RATING}
                    chartsData={[
                      contractsRatingData?.range9or10 ?? [],
                      contractsRatingData?.range7or8 ?? [],
                      contractsRatingData?.range5or6 ?? []
                    ]}
                    type="percentage"
                    tooltips={{
                      titles:
                        homeResumedChartsProps[HomeChartsTypes.CONTRACTS_RATING].tooltipsTitles,
                      values: contractsRatingData.tooltipsValues ?? []
                    }}
                    showTooltip
                  />
                )
              }
            ]}
          />
        </>
      )}
    </VStack>
  )
}
