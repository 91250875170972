import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { HomeChartScreenComponent } from './HomeChartScreen.types'
import { useHomeChartScreen } from './useHomeChartScreen'

const HomeChartScreenDesktop = lazy(() => import('./HomeChartScreen.desktop'))

const HomeChartScreenMobile = lazy(() => import('./HomeChartScreen.mobile'))

export const HomeChartScreen: HomeChartScreenComponent = ({ navigation, route }) => {
  const homeChartScreen = useHomeChartScreen({ navigation, route })

  const Screen = homeChartScreen.isMobile ? HomeChartScreenMobile : HomeChartScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...homeChartScreen} />
    </Suspense>
  )
}
