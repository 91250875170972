import { FunctionComponent, useCallback, useState } from 'react'

import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import Icons from 'atoms/Icons'
import { Box, Button, Heading, HStack, IconButton, Popover, Text } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { Platform, Pressable } from 'react-native'

type NotificationCardCardProps = {
  id: string
  title: string
  description: string
  receivedAt: string
  notificationTypeTitle?: string
  read?: boolean
  isMobile?: boolean
  recent?: boolean
  icon: JSX.Element
  messageRead?: boolean
  navigation?: NativeStackNavigationProp<RootStackParamList, 'NotificationDetail', undefined>
  handleMarkToUnread(id: string): void
  handleNotificationAction(): void
}

export const NotificationCard: FunctionComponent<NotificationCardCardProps> = ({
  title,
  id,
  receivedAt,
  description,
  read,
  recent,
  messageRead,
  isMobile,
  handleMarkToUnread,
  handleNotificationAction,
  notificationTypeTitle,
  icon: Icon,
  ...props
}) => {
  const [details, showDetails] = useState(false)

  const handleNotificationPressed = useCallback(() => {
    props.navigation?.navigate('NotificationDetail', { id })

    showDetails(false)
  }, [id, props.navigation])

  return (
    <HStack
      p={{ base: 4, lg: 6 }}
      space={{ base: 5, lg: 4 }}
      mb={{ base: 1 }}
      justifyContent={{ base: 'space-between', lg: undefined }}
      alignItems={{ base: 'center', lg: 'flex-start' }}
      borderRadius={{ lg: '20px' }}
      backgroundColor={messageRead ? (isMobile ? 'white' : 'gray.50') : 'tertiary.50'}>
      <IconButton
        onPress={isMobile ? handleNotificationPressed : () => {}}
        isDisabled={!isMobile}
        borderRadius="full"
        bg={{ base: 'background.base', lg: 'gray.30' }}
        icon={Icon}
        p={{ lg: '10px' }}
        w={{ base: 12, lg: 10 }}
        h={{ base: 12, lg: 10 }}
        _disabled={{ opacity: isMobile ? 0.6 : 1 }}
        _pressed={{
          bg: 'transparent'
        }}
      />
      <Box
        marginRight={{ base: 'auto', lg: undefined }}
        flexDirection={{ lg: 'column' }}
        minWidth={{ lg: '586px' }}
        maxWidth={{ lg: '586px' }}>
        <Heading
          fontSize={{ base: '14px', lg: '16px' }}
          lineHeight={{ base: '18px', lg: '24px' }}
          fontWeight={{ base: 'bold', lg: 700 }}
          color="gray.700"
          w={{ base: '230px', lg: undefined }}
          mb={{ lg: 4 }}
          numberOfLines={1}>
          {title}
        </Heading>

        <HStack justifyContent="space-between">
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            lineHeight={{ base: '16px', lg: '22px' }}
            fontWeight={{ base: 'normal', lg: 400 }}
            color="gray.400"
            w={{ base: '230px', lg: undefined }}
            mb={{ lg: 6 }}
            numberOfLines={isMobile ? 1 : 2}>
            {description}
          </Text>

          {!isMobile && !messageRead && <Icons.Awaiting color="white" size={5} />}
        </HStack>

        {isMobile ? (
          <Text
            fontSize="12px"
            lineHeight="16px"
            fontWeight="normal"
            color="gray.400"
            textTransform="capitalize">
            {receivedAt}
          </Text>
        ) : (
          <HStack justifyContent="space-between">
            <HStack space={4}>
              <Button
                _text={{ fontSize: 12, fontWeight: 700, color: 'gray.700' }}
                style={{
                  paddingHorizontal: '16px',
                  paddingVertical: '8px',
                  height: '32px'
                }}
                variant="outline"
                colorScheme="gray"
                onPress={() => handleMarkToUnread(id)}>
                {messageRead ? 'Marcar como não lido' : 'Marcar como lido'}
              </Button>

              <Button
                _text={{ fontSize: 12, fontWeight: 700 }}
                style={{
                  paddingHorizontal: '16px',
                  paddingVertical: '8px',
                  height: '32px'
                }}
                colorScheme="gray"
                onPress={() => handleNotificationAction()}>
                {notificationTypeTitle ?? ''}
              </Button>
            </HStack>

            <Text
              fontSize="14px"
              fontWeight={600}
              lineHeight="20px"
              color="gray.400"
              textTransform="capitalize">
              {receivedAt}
            </Text>
          </HStack>
        )}
      </Box>

      {isMobile && (
        <Popover
          isOpen={details}
          onClose={() => showDetails(false)}
          onOpen={() => showDetails(true)}
          trigger={(triggerProps) => {
            return !recent && !messageRead ? (
              <IconButton
                onPress={() => showDetails(!details)}
                _pressed={{
                  bg: 'transparent'
                }}>
                <Icons.Awaiting color="white" size={5} />
              </IconButton>
            ) : (
              <Button
                {...triggerProps}
                bg="transparent"
                _pressed={{
                  bg: 'transparent'
                }}>
                <Icons.More {...triggerProps} color="gray.700" size={5} vertical />
              </Button>
            )
          }}>
          <Popover.Content
            borderWidth={0}
            top={Platform.OS === 'ios' ? '-18px' : '-19px'}
            left="-20px"
            p={2}>
            <Popover.Body
              bg="white"
              p={4}
              shadow="3"
              style={{
                shadowOpacity: 0.1
              }}
              borderRadius="20px"
              alignItems="center"
              justifyContent="center">
              <Pressable
                onPress={() => {
                  handleMarkToUnread(id)

                  showDetails(false)
                }}>
                <Text
                  fontSize="12px"
                  lineHeight="20px"
                  fontWeight="600"
                  color="gray.700"
                  textAlign="center">
                  Marcar como não lido
                </Text>
              </Pressable>
            </Popover.Body>
          </Popover.Content>
        </Popover>
      )}
    </HStack>
  )
}
