import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'integration/types'
import { getShortMonthAndYear } from 'src/utils'

import { ProductionHistorySerialized } from './types'

export const getProductionHistory: (
  response: AxiosResponse<ResponseSuccess<ProductionHistorySerialized>>
) => AxiosResponse<ResponseSuccess<ProductionHistorySerialized>> = (response) => {
  let maxValue = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        historySerialized: response.data.data.history.map((item, index) => {
          if (Number(item.value) > maxValue) maxValue = Number(item.value)

          return {
            value: Number(response.data.data.history[index].value),
            year_month: getShortMonthAndYear(String(response.data.data.history[index].year_month))
          }
        }),
        maxValue
      })
    }
  }
}

export const getProductionHistoryMonths: (
  response: AxiosResponse<ResponseSuccess<ProductionHistorySerialized>>
) => AxiosResponse<{ value: number; label: string }[]> = (response) => {
  return {
    ...response,
    data: response.data?.data?.history.flatMap((item) => ({
      value: item.year_month,
      label: getShortMonthAndYear(String(item.year_month), false)
    }))
  }
}
