import { useCallback, useMemo, useState, useEffect } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { isAfter, isBefore, parseISO, subDays } from 'date-fns'
import {
  Category,
  ContentSerialized,
  useGetCategoriesQuery,
  useGetKnowledgeBasesInfiniteQuery,
  useKnowledgeBaseByCategory,
  KEY_KNOWLEGDE_BASES_QUERY
} from 'integration/resources/knowledgeBase'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { LogEvent } from 'src/utils/logEvents'

import { UseContentByCategoryScreen } from './ContentByCategory.types'

const mergeDataInfiniteQuery = (data: ReturnType<typeof useKnowledgeBaseByCategory>['data']) =>
  data?.pages.reduce<ContentSerialized[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useContentByCategoryScreen: UseContentByCategoryScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const [search, onSearch] = useState(route.params.searchTerm ?? '')

  const [isSearch, setIsSearch] = useState(false)

  const [refreshPage, setRefreshPage] = useState(false)

  const [searchParam, setSearchParam] = useState(route.params.searchTerm)

  const [categoryID, setCategoryID] = useState(route.params.category_id)

  const { data: categoryList, isLoading: isLoadingCategory } = useGetCategoriesQuery()

  const queryClient = useQueryClient()

  const {
    data: allContentData,
    isLoading,
    isFetchingNextPage: allContentIsFetchingNextPage,
    hasNextPage: recentHasNextPage,
    fetchNextPage: recentFetchNextPage,
    refetch: refreshAllContent
  } = useGetKnowledgeBasesInfiniteQuery({
    per_page: 12,
    page: 1,
    category_id: route.params.category_id === 'todas' ? undefined : route.params.category_id,
    order_by: 'updated_at',
    title: route.params.searchTerm
  })

  const onScrollEndDragAllContentList = useCallback(() => {
    if (!allContentIsFetchingNextPage && recentHasNextPage) {
      recentFetchNextPage()
    }
  }, [recentFetchNextPage, recentHasNextPage, allContentIsFetchingNextPage])

  const onScrollEndDrag = useCallback(
    () => onScrollEndDragAllContentList(),
    [onScrollEndDragAllContentList]
  )

  const categoryListWithAll = [
    {
      id: 'todas',
      name: 'Todas',
      code: 999,
      total: allContentData?.pages.map((page) => page.data.metadata.pagination.total)
    },
    ...(categoryList?.data?.data ?? [])
  ]

  const list = mergeDataInfiniteQuery(allContentData)

  const recentData = useMemo(
    () =>
      list.filter((item) => {
        const lastUpdated = parseISO(item.last_update)

        return isAfter(lastUpdated, subDays(new Date(), 7))
      }),
    [list]
  )

  useEffect(() => {
    refreshAllContent()
  }, [refreshAllContent])

  useEffect(() => {
    return () => {
      queryClient.resetQueries(KEY_KNOWLEGDE_BASES_QUERY)
    }
  }, [queryClient])

  const onRefresh = async () => {
    setRefreshPage(true)

    onScrollEndDragAllContentList()

    setRefreshPage(false)
  }

  const allData = useMemo(
    () =>
      list.filter((item) => {
        const lastUpdated = parseISO(item.last_update)

        return isBefore(lastUpdated, subDays(new Date(), 7))
      }),
    [list]
  )

  const category = list.length ? list[0].category : undefined

  const handleGoToContentDetailsScreen = useCallback(
    (id: string) => {
      LogEvent(`conteudo`, {
        item_id: id
      })

      linkTo(`/content/${id}`)
    },
    [linkTo]
  )

  const allContent = mergeDataInfiniteQuery(allContentData)

  const handleGoToContentScreen = useCallback(
    (content: ContentSerialized) => {
      LogEvent(`conteudo`, {
        item_id: content.id
      })

      linkTo(`/conteudos/${content.id}/detalhes`)
    },
    [linkTo]
  )

  const handleSelectCategory = (category: Category) => {
    setCategoryID(category.id)

    navigation.navigate('ContentByCategory', {
      category_id: category.id,
      searchTerm: search
    })
  }

  useEffect(() => {
    if (route.params.category_id) {
      setCategoryID(route.params.category_id)
    }

    if (route.params.searchTerm === '') {
      setIsSearch(false)
    } else {
      setIsSearch(true)

      setSearchParam(route.params.searchTerm)
    }
  }, [route.params.category_id, route.params.searchTerm, search])

  const handleSelectCategorySearch = () => {
    if (search === '') {
      setIsSearch(false)
    } else {
      setIsSearch(true)
    }

    setSearchParam(route.params.searchTerm)

    navigation.navigate('ContentByCategory', {
      searchTerm: search,
      category_id: 'todas'
    })
  }

  const handleSearchClear = () => {
    setSearchParam('')

    setCategoryID('todas')

    setIsSearch(false)

    onSearch('')

    navigation.navigate('ContentByCategory', { category_id: 'todas' })
  }

  return {
    isMobile,
    allData,
    recentData,
    handleSearchClear,
    isLoading,
    category,
    handleGoToContentDetailsScreen,
    isLoadingCategory,
    allContent,
    handleGoToContentScreen,
    categoryList: categoryListWithAll,
    pageIsLoading: isLoadingCategory || isLoading || refreshPage,
    onRefresh,
    onScrollEndDrag,
    onSearch,
    handleSelectCategory,
    handleSelectCategorySearch,
    allContentIsFetchingNextPage,
    categoryID,
    search,
    isSearch,
    searchParam
  }
}
