import { memo, useCallback, useRef } from 'react'

import { Icons } from 'atoms'
import { BottomSheetModal } from 'molecules'
import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Input,
  Text,
  TextArea,
  VStack,
  ScrollView
} from 'native-base'
import { BottomSheetNoteTypes, SelectType } from 'organisms'
import { Controller } from 'react-hook-form'
import { Platform } from 'react-native'

import { NoteFormTemplateComponent } from './NoteFormTemplate.types'

const NOTE_TYPES = ['INOVAÇÃO', 'NEGOCIAÇÃO', 'LEMBRETE', 'PLANO DE AÇÃO', 'NOTA']

export const NoteFormTemplate: NoteFormTemplateComponent = memo(
  ({ control, submit, isLoading, isEdit = false, handleAddedNoteType, isDisabled = false }) => {
    const noteTypeSheetRef = useRef<BottomSheetModal>(null)

    const isMobile = Platform.OS !== 'web'

    const openNoteTypeSheet = useCallback(() => {
      noteTypeSheetRef.current?.present()
    }, [])

    const closeNoteTypeSheet = useCallback(() => {
      noteTypeSheetRef.current?.close()

      noteTypeSheetRef.current?.dismiss()
    }, [])

    return (
      <ScrollView flex={1} showsVerticalScrollIndicator={false}>
        <VStack justifyContent="flex-start" p={0.5}>
          <Box pt={2}>
            <Controller
              control={control}
              render={({
                field: { onChange: onChangeText, onBlur, value },
                formState: { errors }
              }) => (
                <FormControl isInvalid={!!errors.title} isRequired>
                  <Text color="gray.400" fontSize="16px">
                    Título
                  </Text>
                  <Input
                    {...{ onChangeText, onBlur, value }}
                    autoCorrect={false}
                    enablesReturnKeyAutomatically
                    mt={2}
                    pl={0}
                    selectionColor="gray.200"
                    color="gray.700"
                    pr="6px"
                    fontSize="16px"
                    lineHeight="24px"
                    bg={isMobile ? 'white' : 'gray.30'}
                    placeholder="Título"
                    borderColor="white"
                    InputLeftElement={<Icons.EditText size="18px" mr={2} color="gray.700" />}
                  />
                  {errors.title && (
                    <FormControl.ErrorMessage>{errors.title.message}</FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
              name="title"
            />
          </Box>
          <Box pt={6}>
            <Controller
              control={control}
              render={({
                field: { onChange: onChangeText, onBlur, value },
                formState: { errors }
              }) => (
                <FormControl isInvalid={!!errors} isRequired>
                  <Text color="gray.400" fontSize="16px">
                    Detalhe
                  </Text>
                  <TextArea
                    {...{ onChangeText, onBlur, value }}
                    autoCompleteType={null}
                    pb={3}
                    pt={3}
                    pr={4}
                    pl={10}
                    mt={2}
                    h="202px"
                    fontSize="16px"
                    color="gray.700"
                    selectionColor="gray.200"
                    borderRadius="20px"
                    lineHeight="24px"
                    bg={isMobile ? 'white' : 'gray.30'}
                    borderColor="white"
                    _focus={{
                      borderColor: 'gray.400',
                      bg: 'white'
                    }}
                    InputLeftElement={
                      <Icons.EditText
                        size="18px"
                        style={{ position: 'absolute', zIndex: 1, top: 16 }}
                        ml={4}
                        mr={2}
                        color="gray.700"
                      />
                    }
                    placeholder="Anote algo"
                  />
                  {errors.detail && (
                    <FormControl.ErrorMessage>{errors.detail.message}</FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
              name="detail"
            />
          </Box>

          <Box pt={6}>
            {isMobile ? (
              <Controller
                name="type"
                control={control}
                render={({ field: { value }, formState: { errors } }) => (
                  <FormControl isInvalid={!!errors.type} isRequired>
                    <Text color="gray.400" fontSize="16px">
                      Selecione o tipo
                    </Text>

                    <Button
                      borderColor={errors.type ? 'error.500' : 'transparent'}
                      borderWidth={0.5}
                      h="50px"
                      bg={isMobile ? 'white' : 'gray.30'}
                      onPress={openNoteTypeSheet}
                      mt={2}
                      _pressed={{
                        bg: 'white'
                      }}
                      justifyContent="flex-start">
                      <HStack alignItems="center" space={4}>
                        <Text color="gray.500" fontSize={16}>
                          Tipo
                        </Text>
                        {value && (
                          <Center
                            h={7}
                            py={1}
                            px={4}
                            bg="tertiary.800"
                            borderRadius="full"
                            mr="auto">
                            <Text fontSize="12px" color="white" textTransform="uppercase" bold>
                              {value}
                            </Text>
                          </Center>
                        )}
                      </HStack>
                    </Button>

                    <Icons.Dropdown
                      color="gray.700"
                      mt="16px"
                      mr={4}
                      style={{ position: 'absolute', right: 0, bottom: 11 }}
                    />
                  </FormControl>
                )}
              />
            ) : (
              <Controller
                name="type"
                control={control}
                render={({ field: { value, onChange }, formState: { errors } }) => (
                  <FormControl isInvalid={!!errors.type} isRequired>
                    <Text color="gray.400" fontSize="16px" mb={2}>
                      Selecione o tipo
                    </Text>

                    <SelectType
                      onSelect={onChange}
                      list={NOTE_TYPES}
                      //@ts-ignore
                      value={value}
                    />
                  </FormControl>
                )}
              />
            )}
          </Box>

          <BottomSheetNoteTypes
            handleAddedNoteType={(type) => [
              type && handleAddedNoteType(type),
              closeNoteTypeSheet()
            ]}
            initialValue={control._formValues.type}
            ref={noteTypeSheetRef}
          />
        </VStack>

        <Box flexGrow={1} />

        <VStack mt="164">
          <Button
            onPress={submit}
            isDisabled={isDisabled}
            isLoading={isLoading}
            colorScheme="gray"
            mb={10}>
            {isEdit ? 'Salvar alterações' : 'Criar nota'}
          </Button>
        </VStack>
      </ScrollView>
    )
  }
)
