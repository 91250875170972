import { useLinkTo, useRoute } from '@react-navigation/native'
import { Icons } from 'atoms'
import { Box, HStack, VStack, useDisclose, Button } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { LogEvent } from 'src/utils/logEvents'

type TMenu = {
  active?: boolean
  title: string
  path: string
  id: number
  testID?: string
}

type TItemMenu = {
  active?: boolean
  title: string
  testID?: string
  icon: keyof typeof Icons
  path?: string
  submenus?: TMenu[]
  onPress?(): void
}

export const ItemMenu = ({ testID, title, icon, submenus = [], path, onPress }: TItemMenu) => {
  const linkTo = useLinkTo()

  const IconComponent = (icon ? Icons[icon] : null) as React.ElementType

  const route = useRoute()

  const currentRoute = route.path ?? window.location.pathname.split('/')[0].replace('/', '')

  const active = onPress ? false : path === currentRoute

  const stillInsideWallet = window.location.pathname.split('/').includes('carteira')

  const stillInsideAnalytics = window.location.pathname.split('/').includes('analitico')

  const hasMenuActive = submenus.filter((item) => {
    if (item.path.includes('carteira') && stillInsideWallet) return true

    if (item.path.includes('analitico') && stillInsideAnalytics) return true

    return route.path && item.path.includes(route.path)
  })

  const { isOpen, onToggle } = useDisclose(!!hasMenuActive.length)

  const handleItem = onPress
    ? onPress
    : submenus.length > 0
    ? onToggle
    : () => {
        linkTo(path as keyof RootStackParamList)
      }

  return (
    <VStack w="full">
      <Button
        testID={testID}
        variant="unstyled"
        onPress={handleItem}
        {...((active || !!hasMenuActive.length) && { bg: 'gray.800' })}
        w="full"
        borderRightRadius={0}
        h={10}
        _web={{
          _hover: {
            _text: {
              color: active || !!hasMenuActive.length ? 'gray.300' : 'white'
            }
          }
        }}
        _text={{
          pl: 4,
          fontSize: '16px',
          fontFamily: 'body',
          lineHeight: '22px',
          fontWeight: 400,
          color: active || !!hasMenuActive.length ? 'white' : 'gray.300'
        }}
        justifyContent="flex-start"
        leftIcon={
          <IconComponent color={active || !!hasMenuActive.length ? 'white' : 'gray.300'} />
        }>
        {title}
      </Button>
      {isOpen && submenus.length > 0 && (
        <HStack pl={6} mt={4}>
          <Box h="full" bg="primary.500" w="1px" mr="28px" borderRadius="10px" />
          <VStack space={4}>
            {submenus.map((menu) => {
              const subMenuActive = menu.path?.includes(route.path ?? '')

              return (
                <Button
                  testID={menu.testID}
                  key={menu.id}
                  variant="unstyled"
                  borderRadius={0}
                  justifyContent="flex-start"
                  p={0}
                  h="20px"
                  _text={{
                    fontFamily: 'body',
                    underline: subMenuActive,
                    color: subMenuActive ? 'white' : 'gray.300'
                  }}
                  onPress={() => {
                    LogEvent('menu', {
                      item_id: menu.title
                    })

                    linkTo(menu.path)
                  }}>
                  {menu.title}
                </Button>
              )
            })}
          </VStack>
        </HStack>
      )}
    </VStack>
  )
}
