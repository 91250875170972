import { useMemo } from 'react'

import { useGetFunnelQuery } from 'integration/resources/walletResume'
import { useTheme } from 'native-base'

import { UseHomeProposalFunnel } from './types'

export const useHomeProposalFunnel: UseHomeProposalFunnel = () => {
  const theme = useTheme()

  const { data, isLoading: funnelDataIsLoading } = useGetFunnelQuery({})

  const proposalFunnel = useMemo(() => data?.data.data, [data])

  const higherValue = [
    proposalFunnel?.screen_data?.proposal_funnel?.simulation_quantity ?? 0,
    proposalFunnel?.screen_data?.proposal_funnel?.proposal_quantity ?? 0,
    proposalFunnel?.screen_data?.proposal_funnel?.approved_quantity ?? 0,
    proposalFunnel?.screen_data?.proposal_funnel?.effective_quantity ?? 0
  ].sort((a, b) => b - a)[0]

  const proposalFunnelData = useMemo(() => {
    return {
      dataSerialized: [
        {
          x: 4,
          y: proposalFunnel?.screen_data?.proposal_funnel?.simulation_quantity ?? 0
        },
        {
          x: 3,
          y: proposalFunnel?.screen_data?.proposal_funnel?.proposal_quantity ?? 0
        },
        {
          x: 2,
          y: proposalFunnel?.screen_data?.proposal_funnel?.approved_quantity ?? 0
        },
        {
          x: 1,
          y: proposalFunnel?.screen_data?.proposal_funnel?.effective_quantity ?? 0
        }
      ],
      higherValue,
      processed_at: proposalFunnel?.screen_data.proposal_funnel.processed_at,
      is_short_format: false
    }
  }, [
    higherValue,
    proposalFunnel?.screen_data.proposal_funnel?.approved_quantity,
    proposalFunnel?.screen_data.proposal_funnel?.effective_quantity,
    proposalFunnel?.screen_data.proposal_funnel.processed_at,
    proposalFunnel?.screen_data.proposal_funnel?.proposal_quantity,
    proposalFunnel?.screen_data.proposal_funnel?.simulation_quantity
  ])

  const changeColor = (type?: number) => {
    switch (type) {
      case 4:
        return theme.colors.secondary[800]

      case 3:
        return theme.colors.secondary[500]

      case 2:
        return theme.colors.secondary[300]

      case 1:
        return theme.colors.secondary[100]

      default:
        return theme.colors.secondary[50]
    }
  }

  return {
    proposalFunnelData,
    funnelDataIsLoading,
    changeColor
  }
}
