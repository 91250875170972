import { forwardRef, useImperativeHandle } from 'react'

import { LoadingTurbo } from 'atoms'
import { Box, HStack, Heading, Text, Pressable, Button, VStack, Center } from 'native-base'
import { CurrentViewTypesRef } from 'src/components/templates/ContactScreenTemplate/ContactScreenTemplate'

import { useTabComponent } from './hook'
import { TabCommonProps } from './types'

export const NotesMobile = forwardRef<CurrentViewTypesRef, TabCommonProps>(
  ({ tabNumber, handleGoToNoteCreateDetails, handleGoToNoteDetails }, ref) => {
    const { allNotes, onScrollEndDragAllNotesList, isLoading } = useTabComponent({
      tabNumber: tabNumber ?? ''
    })

    useImperativeHandle(ref, () => ({
      onScrollEndDragContent: onScrollEndDragAllNotesList
    }))

    return (
      <VStack flex={1} mt={8} safeAreaBottom mb={10}>
        {isLoading ? (
          <Center h={360} w="full" position="absolute" zIndex={9}>
            <LoadingTurbo size={100} />
          </Center>
        ) : (
          <>
            <Heading fontSize="18px" color="gray.600" mb={6}>
              Caderninho
            </Heading>

            {!!allNotes?.length &&
              allNotes?.map((note) => (
                <Pressable mb={2} onPress={() => handleGoToNoteDetails?.(note.id)} key={note.id}>
                  <Box bg="white" borderRadius="20px" w="full" p={4}>
                    <Heading fontSize="14px" color="gray.700" mb={1}>
                      {note.title}
                    </Heading>
                    <Text fontSize="12px" color="gray.400" isTruncated mb={1}>
                      {note.detail}
                    </Text>
                    <HStack alignItems="center">
                      <Text fontSize="12px" color="gray.400" mb={1}>
                        {note.created_at}
                      </Text>
                      {note?.type && (
                        <>
                          <Box bg="gray.400" borderRadius="full" size={1} mx={1} />
                          <Text fontSize="12px" color="gray.400" mb={1} textTransform="capitalize">
                            {note.type}
                          </Text>
                        </>
                      )}
                    </HStack>
                  </Box>
                </Pressable>
              ))}

            {allNotes?.length === 0 && (
              <Text flexGrow={1} textAlign="center" fontSize="16px" color="gray.500" mb={6}>
                Nenhuma nota ainda
              </Text>
            )}

            <Button
              mt={allNotes?.length ? 6 : 0}
              mb={10}
              colorScheme="gray"
              onPress={() => tabNumber && handleGoToNoteCreateDetails?.(tabNumber)}>
              Nova Nota
            </Button>
          </>
        )}
      </VStack>
    )
  }
)
