import { FunctionComponent, memo, useEffect, useState } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Avatar, Icons } from 'atoms'
import { Activity } from 'integration/resources/activities'
import { Button, Center, HStack, Heading, Text, VStack, useTheme } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { TouchableOpacity, ActivityIndicator, View } from 'react-native'
import { formatDateStringToMask } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

type CardBaseProps = Activity & {
  title: string
  id: string
  onCheckPress?: (fn: (a: boolean) => void, a: boolean) => void
  active?: boolean
  icon: 'Dollar' | 'Check'
}

/**
 * TODO
 * anexos e checklist faltantes na API
 * estao mocados nesse componente
 **/

export const ActivityCard: FunctionComponent<CardBaseProps> = memo(
  ({
    id,
    title,
    icon,
    store,
    schedule_date,

    is_finished,
    active,
    count_activity_attachments,
    activity_plan_action,
    onCheckPress,
    ...rest
  }) => {
    const theme = useTheme()

    const [checked, setChecked] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

    const IconComponent = Icons[icon]

    const iconColor = icon === 'Check' ? 'primary.500' : 'secondary.500'

    useEffect(() => {
      active !== undefined && setChecked(active)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active])

    return (
      <VStack space={4} key={id}>
        <VStack bg="white" borderRadius="20px" p={4}>
          <HStack space={2} alignItems="center">
            <TouchableOpacity
              onPress={() => {
                if (!onCheckPress) {
                  return
                }

                setIsLoading(true)

                onCheckPress?.((value: boolean) => {
                  setChecked(value)

                  setIsLoading(false)
                }, active ?? false)
              }}>
              <Center h={12} w={12}>
                <View
                  style={[
                    {
                      backgroundColor: checked ? theme.colors.primary[50] : theme.colors.white,
                      borderColor: checked
                        ? theme.colors.primary[50]
                        : theme.colors.background.base,
                      borderWidth: 1,
                      borderRadius: 30,
                      position: 'relative',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 48,
                      width: 48,
                      zIndex: 1,
                      top: 0,
                      left: 0,
                      opacity: 1
                    }
                  ]}>
                  {isLoading ? (
                    <ActivityIndicator />
                  ) : (
                    <IconComponent color={checked ? iconColor : 'gray.700'} />
                  )}
                </View>
              </Center>
            </TouchableOpacity>

            <TouchableOpacity
              style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}
              onPress={() => {
                LogEvent('organizacao_atividade', {
                  item_id: id
                })

                navigation.navigate('OrganizationActivity', {
                  id: String(id)
                })
              }}>
              <VStack flex={1}>
                <Text fontSize="18px" lineHeight="24px" fontWeight="normal" color="gray.400">
                  {formatDateStringToMask(schedule_date, 'dd/MM/yyyy hh:mm')}
                </Text>
              </VStack>

              <HStack flex={1} space={4} alignItems="center">
                <Heading
                  fontSize="16px"
                  lineHeight="21px"
                  fontWeight="normal"
                  color="gray.700"
                  mb={1}>
                  {title}
                </Heading>
              </HStack>

              <HStack
                flex={1}
                space={4}
                alignItems="center"
                display={{
                  xl: 'flex',
                  lg: 'none',
                  md: 'none'
                }}>
                <Center bg="white" borderRadius="full" h={10} w={10}>
                  <Avatar uri={undefined} title={title} type="light" h={10} w={10} />
                </Center>
                <VStack>
                  <Text
                    fontSize="16px"
                    lineHeight="21px"
                    fontWeight="normal"
                    color="gray.400"
                    maxWidth={200}>
                    {store.name_fantasy}
                  </Text>
                  <Text fontSize="14px" lineHeight="21px" fontWeight="semibold" color="gray.400">
                    TAB {store?.tab_number}
                  </Text>
                </VStack>
              </HStack>

              <HStack flex={1} space={4} ml={2} justifyContent="center">
                <Button
                  h="24px"
                  p={0}
                  px={4}
                  bg="tertiary.800"
                  _hover={{ bg: 'tertiary.800' }}
                  _text={{
                    color: 'white',
                    fontSize: '12px',
                    lineHeight: '24px',
                    textTransform: 'uppercase'
                  }}>
                  oportunidade
                </Button>
              </HStack>

              <HStack space={6}>
                <HStack space={2} alignItems="center">
                  <Icons.Attachment color="gray.400" />

                  <Text fontSize="14px" lineHeight="20px" fontWeight="semibold" color="gray.400">
                    {count_activity_attachments}
                  </Text>
                </HStack>

                <HStack space={2} alignItems="center">
                  <Icons.CheckConcluded color="gray.400" />

                  <Text fontSize="14px" lineHeight="20px" fontWeight="semibold" color="gray.400">
                    {activity_plan_action.is_finished}/{activity_plan_action.total}
                  </Text>
                </HStack>
              </HStack>
            </TouchableOpacity>
          </HStack>
        </VStack>
      </VStack>
    )
  }
)
