import { useMemo } from 'react'

import { useGetSummaryByTabNumberQuery } from 'integration/resources/wallet'
import { useAuthAtomValue } from 'src/store/auth'

import { UseTabComponent } from './types'

export const useTabComponent: UseTabComponent = ({ tabNumber }) => {
  const { data: summaryData, isLoading } = useGetSummaryByTabNumberQuery(tabNumber)

  const authAtom = useAuthAtomValue()

  const isLeves = useMemo(() => authAtom?.user.business_code === 4, [authAtom?.user.business_code])

  return { summaryData, isLoading, isLeves }
}
