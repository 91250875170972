import { useCallback, useEffect, useMemo } from 'react'

import {
  ActivitiesResponse,
  useGetActivityListInfiniteQuery,
  useUnFinishedActivityMutation,
  useFinishedActivityMutation,
  KEY_ACTIVITY_ITEM_QUERY
} from 'integration/resources/activities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import { LogEvent } from 'src/utils/logEvents'

import { UseActivityContainerList } from './types'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0
}

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetActivityListInfiniteQuery>['data']
) =>
  data?.pages.reduce<ActivitiesResponse>(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...currentValue.data.data,
      items: [...previousValue.items, ...currentValue.data.data.items]
    }),
    defaultReturn
  )

export const useActivityContainerList: UseActivityContainerList = ({ store_tab_numbers }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: apiRefetch
  } = useGetActivityListInfiniteQuery({
    store_tab_numbers,
    group_by: 2,
    expand_details: isDesktop && Platform.OS === 'web'
  })

  const onScrollEndDragActivityList = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const activities = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  const toast = useToast()

  const finishedActivityMutation = useFinishedActivityMutation()

  const unFinishedActivityMutation = useUnFinishedActivityMutation()

  const queryClient = useQueryClient()

  const handleComplete = (callback: (a: boolean) => void, currentCheck: boolean, id: string) =>
    (currentCheck ? unFinishedActivityMutation : finishedActivityMutation).mutate(
      {
        id
      },
      {
        onError() {
          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação" />,
            placement: 'bottom',
            duration: 800
          })

          queryClient
            .invalidateQueries([`${KEY_ACTIVITY_ITEM_QUERY}.${id}`])
            .then(() => toast.closeAll())

          callback(currentCheck)
        },
        onSuccess: async (response) => {
          if (!currentCheck) {
            toast.show({
              render: () => <Toast type="success" text="Atividade marcada como concluída 🎉" />,
              duration: 800
            })

            LogEvent('atividade_concluida', {
              item_id: response.data.data.id
            })
          }

          queryClient
            .invalidateQueries([`${KEY_ACTIVITY_ITEM_QUERY}.${id}`])
            .then(() => toast.closeAll())

          callback(!currentCheck)
        }
      }
    )

  useEffect(() => {
    apiRefetch()
  }, [apiRefetch])

  return {
    activities,
    isLoading,
    handleComplete,
    onScrollEndDragActivityList
  }
}
