import { FunctionComponent, PropsWithChildren, useState } from 'react'

import { Pressable, IPressableProps, useTheme } from 'native-base'
import { StyleSheet } from 'react-native'
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated'

type TEffectPulseProps = {
  bgEffectPulse?: string
}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

export const PulseButton: FunctionComponent<
  PropsWithChildren<TEffectPulseProps & IPressableProps>
> = ({ bgEffectPulse, children, ...props }) => {
  const theme = useTheme()

  const animation = useSharedValue(0)

  const [width, setWidth] = useState(100)

  const [height, setHeight] = useState(100)

  const animatedStyles = useAnimatedStyle(() => {
    const opacity = interpolate(animation.value, [0, 1], [0.6, 0], Extrapolate.CLAMP)

    return {
      opacity,
      transform: [{ scale: animation.value }]
    }
  })

  return (
    <AnimatedPressable
      position="relative"
      onPressIn={() => {
        animation.value = withTiming(1, {
          duration: 200,
          easing: Easing.linear
        })
      }}
      onPressOut={() => {
        animation.value = 0
      }}
      _pressed={{
        bg: 'transparent'
      }}
      onLayout={(e) => {
        setWidth(e.nativeEvent.layout.width)

        setHeight(e.nativeEvent.layout.height)
      }}
      {...props}>
      {children}
      <Animated.View
        style={[
          styles.container,
          {
            backgroundColor: bgEffectPulse ?? theme.colors.primary[200],
            borderRadius: width / 2,
            height: height + 20
          },
          animatedStyles
        ]}
      />
    </AnimatedPressable>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: -10,
    justifyContent: 'center',
    zIndex: 1
  }
})
