import * as serializers from './serializers'
import {
  EvaluationItem,
  GetListParams,
  OpportunitiesByBusinessResponse,
  OpportunitiesResponse,
  OpportunitiesSummary,
  Opportunity
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getOpportunityList = async (params: GetListParams) =>
  serializers.getOpportunityList(
    await client.get<ResponseSuccess<OpportunitiesResponse>>('v1/opportunities', {
      params: {
        ...params,
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1,
        order_by_direction: params.order_by_direction ?? 'desc'
      }
    })
  )

export const getOpportunityListByBusiness = async (params: GetListParams) =>
  serializers.getOpportunityListByBusiness(
    await client.get<ResponseSuccess<OpportunitiesByBusinessResponse>>('v1/opportunities', {
      params: {
        ...params,
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1,
        order_by_direction: params.order_by_direction ?? 'desc'
      }
    })
  )

export const getOpportunityItem = async (id?: string) =>
  serializers.getOpportunityItem(
    await client.get<ResponseSuccess<Opportunity>>(`v1/opportunities/${id}`)
  )

export const getOpportunitiesSummary = async () =>
  await client.get<ResponseSuccess<OpportunitiesSummary[]>>('v1/opportunities/summary')

export const setOpportunityHighlighted = async ({ id }: Pick<Opportunity, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<Opportunity, 'id'>>>(`v1/opportunities/${id}/highlighted`)

export const setOpportunityUnHighlighted = async ({ id }: Pick<Opportunity, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<Opportunity, 'id'>>>(
    `v1/opportunities/${id}/unhighlighted`
  )

export const setOpportunityRates = async (data: EvaluationItem) =>
  await client.post<ResponseSuccess<Pick<Opportunity, 'opportunity_id'>>>('v1/opportunity-rates', {
    ...data
  })

export const finishedOpportunity = async ({ id }: Pick<Opportunity, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<Opportunity, 'id'>>>(`v1/opportunities/${id}/finished`)

export const unFinishedOpportunity = async ({ id }: Pick<Opportunity, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<Opportunity, 'id'>>>(`v1/opportunities/${id}/unfinished`)
