import { useEffect, useState } from 'react'

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  ColumnFiltersState,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
  getFacetedRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState
} from '@tanstack/react-table'
import { Icons } from 'atoms'
import { UnconnectedMessage } from 'molecules'
import { Box, Button, Divider, HStack, Skeleton, Text, VStack } from 'native-base'
import { Skeleton as Skeletons } from 'organisms'
import { StyleSheet, Dimensions } from 'react-native'

import FilterTable from './FilterTable'
import { TTableProps } from './types'

const screenHeight = Dimensions.get('window').height

export function DataTable<TData>({
  columns,
  data,
  setFilteredData,
  isServerPagination = false,
  setPagination,
  paginationState,
  isLoading,
  pageCount
}: TTableProps<TData>) {
  const isDataEmpty = data && data.length === 0

  const [sorting, setSorting] = useState<SortingState>([])

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const serverPaginationConfig = isServerPagination
    ? {
        manualPagination: true,
        pageCount,
        state: {
          pagination: paginationState
        },
        onPaginationChange: setPagination
      }
    : { getPaginationRowModel: getPaginationRowModel() }

  const table = useReactTable<TData>({
    columns,
    data,
    state: {
      columnFilters,
      sorting,
      ...serverPaginationConfig.state
    },
    ...serverPaginationConfig,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  })

  const renderArrowIcon = (isSorted: boolean | string, direction: string) => {
    const rotationStyle = direction === 'up' ? { transform: 'rotate(180deg)' } : {}

    const marginStyle = direction === 'up' ? { mb: '-2' } : { ml: '0.5px' }

    const color = isSorted ? 'primary.800' : 'gray.500'

    return (
      <Icons.ArrowDropDown size={4} color={color} style={{ ...rotationStyle }} {...marginStyle} />
    )
  }

  useEffect(() => {
    const filteredData = table.getFilteredRowModel().rows.map((row) => row.original)

    setFilteredData(filteredData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, columnFilters, setFilteredData])

  if (isDataEmpty && isLoading) {
    return (
      <Box borderRadius="20px" bg="white" borderColor="gray.100" borderWidth={1} p={4}>
        <Skeletons.Table />
      </Box>
    )
  }

  if (isDataEmpty && !isLoading)
    return (
      <Box
        borderRadius="20px"
        bg="white"
        borderColor="gray.100"
        borderWidth={1}
        py={screenHeight > 900 ? 12 : 2}>
        <UnconnectedMessage />
      </Box>
    )

  return (
    <Box borderRadius="20px" borderWidth={1} bg="white" borderColor="gray.100">
      <VStack
        borderColor="gray.100"
        overflowX="auto"
        borderTopRightRadius="20px"
        borderTopLeftRadius="20px"
        maxH={screenHeight > 900 ? 'calc(100vh - 458px)' : 'calc(100vh - 249px)'}>
        <table style={{ borderCollapse: 'collapse' }}>
          <thead style={styles.stickyHeader}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} style={{ outline: '1px solid #DBDBDB' }}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} style={styles.columns}>
                      {isLoading ? (
                        <Skeleton h={10} w="140px" />
                      ) : (
                        <>
                          {header.isPlaceholder ? null : (
                            <VStack alignItems="center">
                              <Button
                                p={0}
                                size="sm"
                                variant="link"
                                rightIcon={
                                  header.column.getCanSort() && !isServerPagination ? (
                                    <VStack mb={screenHeight > 900 ? 4 : 2}>
                                      {renderArrowIcon(
                                        header.column.getIsSorted() === 'desc',
                                        'up'
                                      )}
                                      {renderArrowIcon(
                                        header.column.getIsSorted() === 'asc',
                                        'down'
                                      )}
                                    </VStack>
                                  ) : undefined
                                }
                                onPress={() => header.column.toggleSorting()}
                                disabled={!header.column.getCanSort()}>
                                <Text
                                  isTruncated
                                  color="gray.700"
                                  fontSize="14px"
                                  fontWeight="bold"
                                  mb={isServerPagination ? 0 : screenHeight > 900 ? 4 : 2}>
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                </Text>
                              </Button>
                              {header.column.getCanFilter() && !isServerPagination ? (
                                <>
                                  <Divider
                                    mb={screenHeight > 900 ? 6 : 2}
                                    w={
                                      screenHeight > 900 ? 'calc(100% + 48px)' : 'calc(100% + 16px)'
                                    }
                                  />

                                  <HStack h="32px">
                                    <FilterTable
                                      column={header.column}
                                      showUniqueValues={!isServerPagination}
                                    />
                                  </HStack>
                                </>
                              ) : null}
                            </VStack>
                          )}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            <>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} style={styles.body}>
                          {isLoading ? (
                            <Skeleton h={10} w="full" startColor="gray.100" />
                          ) : (
                            <Text color="gray.700" fontSize="14px" lineHeight="1rem">
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Text>
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </>
          </tbody>
        </table>
      </VStack>

      <HStack
        borderBottomRightRadius="20px"
        borderBottomLeftRadius="20px"
        space={2}
        alignItems="center"
        justifyContent="center"
        py={screenHeight > 900 ? 2 : 0}
        borderColor="gray.100"
        borderTopWidth={1}>
        <Button
          colorScheme="unstyled"
          onPress={() => table.setPageIndex(0)}
          isDisabled={!table.getCanPreviousPage() || isLoading}
          _disabled={{ opacity: '0.5' }}
          _text={{ color: 'gray.500', fontSize: 'md' }}>
          {'<<'}
        </Button>
        <Button
          colorScheme="unstyled"
          onPress={() => table.previousPage()}
          isDisabled={!table.getCanPreviousPage() || isLoading}
          _disabled={{ opacity: '0.5' }}
          _text={{ color: 'gray.500', fontSize: 'md' }}>
          {'<'}
        </Button>
        <HStack space={1} alignItems="center" p={2}>
          <Text color="gray.700" fontWeight={600}>
            Página {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
          </Text>
        </HStack>
        <Button
          colorScheme="unstyled"
          onPress={() => table.nextPage()}
          isDisabled={!table.getCanNextPage() || isLoading}
          _disabled={{ opacity: 0.5 }}
          _text={{ color: 'gray.500', fontSize: 'md' }}>
          {'>'}
        </Button>
        <Button
          colorScheme="unstyled"
          onPress={() => table.setPageIndex(table.getPageCount() - 1)}
          isDisabled={!table.getCanNextPage() || isLoading}
          _disabled={{ opacity: '0.5' }}
          _text={{ color: 'gray.500', fontSize: 'md' }}>
          {'>>'}
        </Button>

        <HStack space={2} alignItems="center">
          <Text color="gray.700" fontWeight={600}>
            Resultados por página
          </Text>
          <select
            disabled={isLoading}
            value={table.getState().pagination.pageSize}
            onChange={(e: any) => {
              table.setPageSize(Number(e.target.value))
            }}
            style={styles.numberPagesInput}>
            {[10, 20, 30, 40, 50, 100, 500].map((pageSize, index) => (
              <option key={`${index}-${pageSize}`} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </HStack>
      </HStack>
    </Box>
  )
}

export default DataTable

const styles = StyleSheet.create({
  stickyHeader: {
    position: 'sticky' as any,
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1
  },
  body: {
    border: '1px solid #DBDBDB',
    borderTopWidth: 0,
    padding: 16,
    paddingTop: screenHeight > 900 ? 8 : 0,
    paddingBottom: screenHeight > 900 ? 8 : 0
  },
  columns: {
    border: '0px solid #DBDBDB',
    borderRightWidth: 1,
    paddingTop: screenHeight > 900 ? 16 : 8,
    paddingRight: screenHeight > 900 ? 24 : 8,
    paddingLeft: screenHeight > 900 ? 24 : 8,
    paddingBottom: screenHeight > 900 ? 24 : 8
  },
  numberPagesInput: {
    width: 80,
    height: 32,
    border: '1px solid #DBDBDB',
    borderRadius: 14,
    paddingLeft: 17
  }
})
