import { useEffect, useMemo, useState } from 'react'

import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import {
  TBodyItem,
  useGetAnalyticsQuery,
  useGetAvailableReportsQuery
} from 'integration/resources/analytics'
import { Dimensions } from 'react-native'
import { useAuthAtomValue } from 'src/store/auth'
import { lastTwelveMonthsFromCurrentDate, sanitazeColumnValues } from 'src/utils'
import { LogEvent, LogEventScreen } from 'src/utils/logEvents'

import { UseAnalyticsScreen } from './AnalyticsScreen.types'

export const useAnalyticsScreen: UseAnalyticsScreen = ({ navigation, route }) => {
  const authAtom = useAuthAtomValue()

  const isAdmin = authAtom?.user.access_level === 4

  const screenHeight = Dimensions.get('window').height

  const defaultYearMonth = format(new Date(), 'yyyyMM')

  const [selectedReportId, setSelectedReportId] = useState<number | undefined>(undefined)

  const [searchStdCode, setSearchStdCode] = useState<string | undefined>(undefined)

  const [currentYearMonth, setCurrentYearMonth] = useState(defaultYearMonth)

  const {
    data: reports,
    isSuccess: isAvailableReportsSuccess,
    isLoading: isLoadingAvailableReports
  } = useGetAvailableReportsQuery()

  const availableReports = reports?.data.data

  const {
    data: AnalyticsData,
    isLoading: isLoadingAnalytics,
    isRefetching: isRefetchingAnalytics,
    refetch: refetchAnalytics
  } = useGetAnalyticsQuery({
    year_month: currentYearMonth,
    id: selectedReportId,
    std_code: isAdmin && searchStdCode ? searchStdCode : undefined
  })

  const analyticsList = AnalyticsData?.data?.data

  const columns = useMemo(() => {
    if (!analyticsList?.body || !analyticsList.body.length) return []

    const columnHelper = createColumnHelper<TBodyItem>()

    const bodyKeys = Object.keys(analyticsList.body[0])

    return bodyKeys.map((key) => {
      const header = analyticsList.header.find((h) => h.name === key)

      return columnHelper.accessor((row: TBodyItem) => sanitazeColumnValues(row[key], key), {
        header: header?.slug ?? key,
        filterFn: 'includesString'
      })
    })
  }, [analyticsList?.header, analyticsList?.body])

  const monthsList = lastTwelveMonthsFromCurrentDate()

  useEffect(() => {
    const isValidMonth = monthsList.some((month) => month.value === route?.params?.year_month)

    if (isValidMonth) {
      setCurrentYearMonth(route?.params?.year_month)

      navigation.navigate('Analytics', {
        year_month: route?.params?.year_month
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.params?.year_month])

  const handleReportChange = (reportId: number | undefined) => {
    if (reportId !== selectedReportId) {
      setSelectedReportId(reportId)

      if (reportId) {
        const reportName = availableReports?.find((report) => report.id === reportId)?.name

        LogEvent('analitico_clicou_relatorio', {
          item_id: `${reportName ?? ''} - id:${reportId?.toString()}`
        })
      }

      navigation.navigate('Analytics', {
        year_month: currentYearMonth
      })
    }
  }

  const handleSelectYearMonth = (yearMonth: string) => {
    LogEvent(`analitico_clicou_ano_mes`, {
      item_id: yearMonth.slice(4, 6) + '/' + yearMonth.slice(0, 4)
    })

    setCurrentYearMonth(yearMonth)

    navigation.navigate('Analytics', {
      year_month: yearMonth
    })
  }

  const handleSearchStdCode = (stdCode: string | undefined) => {
    setSearchStdCode(stdCode)

    navigation.navigate('Analytics', {
      year_month: currentYearMonth
    })
  }

  useEffect(() => {
    if (selectedReportId && currentYearMonth && (!isAdmin || (isAdmin && searchStdCode))) {
      refetchAnalytics()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportId, currentYearMonth, searchStdCode, isAdmin])

  useEffect(() => {
    if (isAvailableReportsSuccess && availableReports?.length) {
      setSelectedReportId(availableReports[0].id)

      navigation.navigate('Analytics', {
        year_month: currentYearMonth
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailableReportsSuccess, availableReports?.length])

  useEffect(() => {
    const date = new Date()

    const formated = format(date, 'dd-MM-yyyy HH:mm')

    const screenInfos = `Analítico_Relatorio_${
      availableReports?.find((r) => r.id === selectedReportId)?.name
    }_${currentYearMonth.slice(4, 6) + '/' + currentYearMonth.slice(0, 4)}`

    LogEventScreen({
      screen_name: screenInfos,
      screen_class: screenInfos,
      date: formated,
      hora: format(date, 'HH'),
      minutos: format(date, 'mm')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportId, currentYearMonth])

  return {
    handleReportChange,
    columns,
    screenHeight,
    availableReports,
    selectedReportId,
    analyticsList,
    isLoadingAnalytics,
    isLoadingAvailableReports,
    currentYearMonth,
    handleSelectYearMonth,
    monthsList,
    isAdmin,
    isRefetchingAnalytics,
    totalResults: AnalyticsData?.data.metadata.total ?? 0,
    handleSearchStdCode,
    searchStdCode
  }
}
