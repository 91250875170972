import axios, { AxiosResponse } from 'axios'

import { Address } from './types'

const client = axios.create({
  baseURL: 'https://brasilapi.com.br/api/',
  headers: {
    'Content-Type': 'application/json'
  }
})

export const getAddressByZipCode = async (zipCode: string) =>
  await client.get<AxiosResponse<Address>>(`cep/v2/${zipCode}`)
