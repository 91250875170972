import {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import {
  Assistant_400Regular,
  Assistant_600SemiBold,
  Assistant_700Bold
} from '@expo-google-fonts/assistant'
import {
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_500Medium,
  OpenSans_500Medium_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic
} from '@expo-google-fonts/open-sans'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { CustomStatusBar } from 'atoms'
import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import LottieView from 'lottie-react-native'
import { NativeBaseProvider, View } from 'native-base'
import { Platform, StyleSheet } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { QueryClient, QueryClientProvider } from 'react-query'
import Zendesk from 'react-zendesk'
import { theme } from 'src/theme'

import { BottomSheetProvider } from './contexts/BottomSheetContext'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 100
    }
  }
})

const settings = {
  color: {
    theme: '#5AB8CA'
  }
}

export const AppProviders: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [appIsReady, setAppIsReady] = useState(false)

  const [finishSplash, setFinishSplash] = useState(false)

  const animation = useRef(null)

  useEffect(() => {
    async function prepare() {
      try {
        // Keep the splash screen visible while we fetch resources

        // @ts-ignore
        animation.current?.play()

        // Pre-load fonts, make any API calls you need to do here
        await Font.loadAsync({
          Assistant_400Regular,
          Assistant_600SemiBold,
          Assistant_700Bold,
          OpenSans_300Light,
          OpenSans_400Regular,
          OpenSans_500Medium,
          OpenSans_600SemiBold,
          OpenSans_700Bold,
          OpenSans_800ExtraBold,
          OpenSans_300Light_Italic,
          OpenSans_400Regular_Italic,
          OpenSans_500Medium_Italic,
          OpenSans_600SemiBold_Italic,
          OpenSans_700Bold_Italic,
          OpenSans_800ExtraBold_Italic
        })
      } catch (error) {
        console.warn(error)
      } finally {
        setAppIsReady(true)
      }
    }

    prepare()
  }, [])

  const onLayout = useCallback(() => {
    if (appIsReady) {
      SplashScreen.hideAsync()
    }
  }, [appIsReady])

  const handleFinishSplash = () => {
    setFinishSplash(true)
  }

  // fix react reanimated on web doesn't remove
  // @ts-ignore
  if (window._frameTimestamp === undefined) {
    // @ts-ignore
    window._frameTimestamp = null
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }} onLayout={onLayout}>
      <QueryClientProvider client={queryClient}>
        <NativeBaseProvider
          initialWindowMetrics={{
            frame: { x: 0, y: 0, width: 0, height: 0 },
            insets: { top: 0, left: 0, right: 0, bottom: 0 }
          }}
          config={{ suppressColorAccessibilityWarning: true }}
          theme={theme}>
          {!finishSplash ? (
            <View style={styles.animationContainer}>
              <CustomStatusBar barStyle="light-content" />

              {Platform.OS !== 'web' ? (
                <LottieView
                  ref={animation}
                  loop={false}
                  style={{
                    flex: 1,
                    height: '100%'
                  }}
                  onAnimationFinish={handleFinishSplash}
                  source={require('assets/splash_turbo.json')}
                />
              ) : (
                handleFinishSplash()
              )}
            </View>
          ) : (
            <BottomSheetModalProvider>
              <BottomSheetProvider>
                <CustomStatusBar barStyle="light-content" />
                {children}
              </BottomSheetProvider>
            </BottomSheetModalProvider>
          )}

          {Platform.OS === 'web' && (
            <Zendesk defer zendeskKey="9817832b-cace-4ad2-a091-0829f93b4300" {...settings} />
          )}
        </NativeBaseProvider>
      </QueryClientProvider>
    </GestureHandlerRootView>
  )
}

const styles = StyleSheet.create({
  animationContainer: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: '100%'
  }
})
