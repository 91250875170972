import { useBreakpointValue } from 'native-base'

import { UseAboutScreen } from './AboutScreen.types'

export const useAboutScreen: UseAboutScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const currentDate = new Date()

  const currentYear = currentDate.getFullYear()

  return {
    isMobile,
    currentYear
  }
}
