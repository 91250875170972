// @ts-nocheck
import { forwardRef, useCallback, useImperativeHandle, useEffect, useState } from 'react'

import { Cards, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { LinearGradient } from 'expo-linear-gradient'
import { Business } from 'integration/resources/opportunities'
import { AnimatedSlideDot, UnconnectedMessage } from 'molecules'
import {
  Box,
  VStack,
  Heading,
  Center,
  View,
  useTheme,
  ZStack,
  HStack,
  Text,
  useBreakpointValue
} from 'native-base'
import { BottomSheetActionIllustration, BottomSheetEvaluationOpportunity } from 'organisms'
import { useWindowDimensions, Platform } from 'react-native'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'
import { LogEvent } from 'src/utils/logEvents'

import { useListOpportunities } from './hook'
import { OPPORTUNITY_BLOCK_TYPE_ENUM } from '../../../../integration/resources/opportunities/types'
import { ListTypesRef, ListTypesView } from '../../OrganizationScreen.types'

export const ListOpportunitiesByBusiness = forwardRef<ListTypesRef, ListTypesView>(
  ({ navigation, isLoading }, ref) => {
    const [search, setSearch] = useState('')

    const isMobile = useBreakpointValue({ base: true, lg: false })

    const {
      list,
      isLoading: localIsLoading,
      refetch,
      refreshing,
      isLoadingEvaluantion,
      isOpenEvaluation,
      setIsOpenEvaluantion,
      handleEvaluate,
      evaluationSuccessSheetRef,
      closeEvaluationSuccessSheet,
      setOpportunityEvaluation
    } = useListOpportunities({ search })

    const skeletonArray = new Array(6).fill(0)

    const items = list?.items.map((item) => ({
      ...item,
      data: item.business_block_items
    })) as Business[]

    const scrollX = useSharedValue(1)

    const scrollHandler = useAnimatedScrollHandler((event) => {
      scrollX.value = event.contentOffset.x
    })

    const { width, height } = useWindowDimensions()

    useEffect(() => {
      navigation.addListener('focus', () => {
        refetch()
      })
    }, [navigation, refetch])

    const renderItem = useCallback(
      ({ item }: { item: Business }) => {
        return (
          <View
            style={[
              {
                ...(Platform.OS === 'web' && { flex: 1 }),
                paddingHorizontal: 16,
                width: Platform.OS !== 'web' ? width : 'auto'
              }
            ]}>
            <View
              style={{
                flex: 1,
                backgroundColor: '#fff',
                padding: 16,
                borderRadius: 32,
                width: Platform.OS !== `web` ? width - 32 : 'auto'
              }}>
              <Box key={`opportunity-section-${item.id}`}>
                <Box mx={2} py={4} bgColor="white" rounded={16}>
                  <Box pb={4}>
                    <Heading
                      mb={2}
                      fontSize="24px"
                      lineHeight="26px"
                      fontWeight="bold"
                      color="gray.700"
                      textTransform="capitalize">
                      {OPPORTUNITY_BLOCK_TYPE_ENUM[item.name]}
                    </Heading>

                    <View flex={1}>
                      <Box
                        justifyContent={{ base: 'center', lg: undefined }}
                        flexDir="row"
                        bgColor={{ base: 'primary.800', lg: undefined }}
                        px={3}
                        py={1}
                        borderRadius={20}
                        maxWidth={{ base: '134px', lg: undefined }}>
                        {isMobile ? (
                          <Heading
                            color="white"
                            fontSize={12}
                            fontWeight="bold"
                            fontFamily="heading">
                            {item?.business_block_items?.length} Oportunidades
                          </Heading>
                        ) : (
                          <Text color="gray.400" fontSize="16px" fontWeight="normal">
                            {item?.business_block_items?.length} Oportunidades
                          </Text>
                        )}
                      </Box>
                    </View>
                  </Box>

                  <VStack space={2} w="full">
                    {item.business_block_items?.map((opportunity, index) => (
                      <Box key={`opportunity-${opportunity.id}-${opportunity.dueDate}-${index}`}>
                        <Cards.OrganizationCard
                          topText={opportunity.title}
                          midText={
                            opportunity.store?.name_fantasy
                              ? `TAB ${opportunity.store?.tab_number} • ${opportunity.dueDate}`
                              : undefined
                          }
                          bottomText={`R$ ${opportunity.earningPotential}`}
                          userName={opportunity.user_name}
                          active={opportunity.is_finished}
                          icon="Dollar"
                          bgColor="gray.50"
                          onPress={() => {
                            LogEvent('oportunidade', {
                              item_id: opportunity?.id
                            })

                            navigation.navigate('OrganizationOpportunity', {
                              id: String(opportunity.id)
                            })
                          }}
                          onCheckPress={
                            !opportunity.isFinished
                              ? (callback, currentCheck) => {
                                  setOpportunityEvaluation(opportunity)

                                  setIsOpenEvaluantion(true)

                                  callback(currentCheck)
                                }
                              : undefined
                          }
                        />
                      </Box>
                    ))}
                  </VStack>
                </Box>
              </Box>
            </View>
          </View>
        )
      },
      [navigation, setIsOpenEvaluantion, setOpportunityEvaluation, width, isMobile]
    )

    const keyExtractor = useCallback((item: Business) => item.id, [])

    useImperativeHandle(ref, () => ({
      refetch,
      refreshing,
      search: (value: string) => setSearch(value),
      clearSearch: () => setSearch('')
    }))

    const theme = useTheme()

    return (
      <Box mt={6} position="relative">
        {((localIsLoading && !list?.items.length) || isLoading) && (
          <VStack py={4} w={width - 32}>
            {skeletonArray.map((_, i) => (
              <SkeletonCard key={`key-skeleton-${i}`} />
            ))}
          </VStack>
        )}

        {!list?.items.length && !localIsLoading && <UnconnectedMessage />}

        {Platform.OS !== 'web' && (
          <HStack space={1} justifyContent="flex-end" px={4} pb={4}>
            {items?.map((_, index) => (
              <AnimatedSlideDot
                index={index}
                scrollX={scrollX}
                slideWidth={width}
                key={Math.random()}
                color={theme.colors.primary[300]}
              />
            ))}
          </HStack>
        )}
        <View flex={1}>
          {Platform.OS === 'web' ? (
            <Animated.FlatList
              data={items}
              keyExtractor={keyExtractor}
              showsHorizontalScrollIndicator={false}
              onScroll={scrollHandler}
              pagingEnabled
              numColumns={3}
              decelerationRate="normal"
              scrollEventThrottle={16}
              renderItem={renderItem}
            />
          ) : (
            <Animated.FlatList
              data={items}
              keyExtractor={keyExtractor}
              showsHorizontalScrollIndicator={false}
              onScroll={scrollHandler}
              pagingEnabled
              horizontal
              decelerationRate="normal"
              scrollEventThrottle={16}
              renderItem={renderItem}
            />
          )}
        </View>

        {refreshing && !isLoading && (
          <>
            <LinearGradient
              style={{
                position: 'absolute',
                height: 800,
                zIndex: 4,
                flex: 1,
                width
              }}
              colors={['#ffffff05', theme.colors.background.light, '#ffffff05']}
            />
            <ZStack
              position="absolute"
              w={width}
              h={0}
              alignItems="center"
              justifyContent="center"
              top={height / 5}
              zIndex={8}>
              <Center>
                <LoadingTurbo size={48} />
              </Center>
            </ZStack>
          </>
        )}

        <BottomSheetEvaluationOpportunity
          isLoading={isLoadingEvaluantion}
          isOpen={isOpenEvaluation}
          handleClose={() => setIsOpenEvaluantion(false)}
          onSubmit={handleEvaluate}
        />

        <BottomSheetActionIllustration
          illustration="EvaluationSuccess"
          ref={evaluationSuccessSheetRef}
          title="Obrigado por avaliar esta oportunidade"
          description="Desta forma nós podemos separar as oportunidades que melhor combinam com o seu perfil."
          handleConfirm={() => closeEvaluationSuccessSheet()}
          buttonTextConfirm={Platform.OS === 'web' ? 'Confirmar' : 'Ok'}
        />
      </Box>
    )
  }
)
