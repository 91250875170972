import { FunctionComponent, useEffect } from 'react'

import { Box, Heading, HStack } from 'native-base'
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated'

import { Striped } from './striped'
import { StackedBarChartProps } from './types'

const AnimatedBox = Animated.createAnimatedComponent(Box)

const StackedBarChart: FunctionComponent<StackedBarChartProps> = ({
  expectedValue,
  expectedIsStriped,
  currentValue,
  currentIsStriped,
  maxValue,
  style
}) => {
  const points = [0, 100, 130, 160, 200]

  const calculatedExpectedValue = useSharedValue('0%')

  const calculatedCurrentValue = useSharedValue('0%')

  const config = {
    duration: 1200,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
    damping: 20,
    stiffness: 90
  }

  const expectedStyles = useAnimatedStyle(() => ({
    width: withTiming(calculatedExpectedValue.value, config)
  }))

  const currentStyles = useAnimatedStyle(() => ({
    width: withTiming(calculatedCurrentValue.value, config)
  }))

  const currentIsFull = currentValue >= maxValue / 2

  const expectedIsFull = expectedValue >= maxValue / 2

  useEffect(() => {
    calculatedExpectedValue.value = `${(expectedValue / maxValue) * 100 + 1}%` // +1 visualy only

    calculatedCurrentValue.value = `${(currentValue / maxValue) * 100}%`
  }, [expectedValue, currentValue, maxValue, calculatedExpectedValue, calculatedCurrentValue])

  return (
    <Box style={style}>
      <Box
        w="100%"
        h={6}
        bgColor="gray.100"
        position="relative"
        borderRadius={20}
        overflow="hidden">
        <AnimatedBox
          bgColor={currentIsFull ? 'secondary.500' : 'error.500'}
          style={[
            {
              height: '100%',
              position: 'absolute',
              overflow: 'hidden',
              borderRadius: 20,
              zIndex: 2,
              top: 0,
              left: 0
            },
            currentStyles
          ]}>
          {currentIsStriped && (
            <Box position="absolute" right={0} top={-8}>
              <Striped color={currentIsFull ? '#4FAE73' : '#B40929'} />
            </Box>
          )}
        </AnimatedBox>

        <AnimatedBox
          bgColor={expectedIsFull ? 'secondary.300' : 'error.500'}
          style={[
            {
              height: '100%',
              overflow: 'hidden',
              position: 'absolute',
              borderRadius: 20,
              zIndex: 1,
              top: 0,
              left: 0
            },
            expectedStyles
          ]}>
          {expectedIsStriped && (
            <Box position="absolute" right={0} top={-8}>
              <Striped color={expectedIsFull ? '#4FAE73' : '#B40929'} />
            </Box>
          )}
        </AnimatedBox>

        {/* lines */}
        <Box w={0.5} h={6} bgColor="white" position="absolute" zIndex={3} top={0} right="20%" />
        <Box w={0.5} h={6} bgColor="white" position="absolute" zIndex={3} top={0} right="35%" />
        <Box w={0.5} h={6} bgColor="white" position="absolute" zIndex={3} top={0} right="50%" />
      </Box>

      <Box w="100%" h={6} mt={2} position="relative">
        <Box position="absolute" zIndex={3} top={0} right={0}>
          <HStack alignItems="center">
            <Heading fontSize={16} fontWeight="bold" color="gray.400">
              {points[4]}
            </Heading>
            <Heading fontSize={12} fontWeight="bold" color="gray.400" mt="2px">
              %
            </Heading>
          </HStack>
        </Box>

        <Box position="absolute" zIndex={3} top={0} right="17%">
          <HStack alignItems="center">
            <Heading fontSize={16} fontWeight="bold" color="gray.400">
              {points[3]}
            </Heading>
            <Heading fontSize={12} fontWeight="bold" color="gray.400" mt="2px">
              %
            </Heading>
          </HStack>
        </Box>

        <Box position="absolute" zIndex={3} top={0} right="32%">
          <HStack alignItems="center">
            <Heading fontSize={16} fontWeight="bold" color="gray.400">
              {points[2]}
            </Heading>
            <Heading fontSize={12} fontWeight="bold" color="gray.400" mt="2px">
              %
            </Heading>
          </HStack>
        </Box>

        <Box position="absolute" zIndex={3} top={0} right="47%">
          <HStack alignItems="center">
            <Heading fontSize={16} fontWeight="bold" color="gray.400">
              {points[1]}
            </Heading>
            <Heading fontSize={12} fontWeight="bold" color="gray.400" mt="2px">
              %
            </Heading>
          </HStack>
        </Box>

        <Box position="absolute" zIndex={3} top={0} left={0}>
          <HStack alignItems="center">
            <Heading fontSize={16} fontWeight="bold" color="gray.400">
              {points[0]}
            </Heading>
            <Heading fontSize={12} fontWeight="bold" color="gray.400" mt="2px">
              %
            </Heading>
          </HStack>
        </Box>
      </Box>
    </Box>
  )
}

export default StackedBarChart
