import { CountryCode } from 'react-native-country-picker-modal'
import { MaskService } from 'react-native-masked-text'
import { isValidNumber } from 'react-native-phone-number-input'

export const validateAuthCode = (value: string) => value.length === 6 || 'Código inválido'

export const validateCPF = (value: string) =>
  MaskService.isValid('cpf', value) && value !== '00000000000'

export const validatePassword = (value: string) => ({
  'Mínimo 8 caracteres': value ? value.length >= 8 : null,
  'Pelo menos 1 letra maiúscula': value ? !!value.match(new RegExp('^(?=.*[A-Z]).*$')) : null,
  'Pelo menos 1 letra minúscula': value ? !!value.match(new RegExp('^(?=.*[a-z]).*$')) : null,
  'Pelo menos 1 símbolo (@#!$%^+=)': value
    ? !!value.match(new RegExp('^(?=.*[@#!$%^+=]).*$'))
    : null,
  'Pelo menos 1 número': value ? !!value.match(new RegExp('^(?=.*[0-9]).*$')) : null
})

export const validatePhone = (value: string, countryCode: CountryCode) => {
  return isValidNumber(value, countryCode)
}

export const verifyHasData = (key: string, data: any[]) => {
  const INITIAL_VALUE_REDUCE = 0

  const values = data.map((item) => item[key])

  const sum = values.reduce((acc, value) => acc + value, INITIAL_VALUE_REDUCE)

  return sum > 0
}

export const verifyHasDataOnArrays = (arr: any) => {
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      if (arr[i][j].value !== 0) {
        return true
      }
    }
  }

  return false
}

export const verifyHasValue = (value: any) => {
  if (value === null || value === undefined) {
    return false
  }

  if (isObjectVazio(value) || isArrayVazio(value)) {
    return false
  }

  return true
}

export const isObjectVazio = (objeto: unknown): objeto is Record<string, never> => {
  return typeof objeto === 'object' && objeto !== null && Object.keys(objeto).length === 0
}

export const isArrayVazio = (array: unknown): array is never[] => {
  return Array.isArray(array) && array.length === 0
}

export const isBase64File = (value: string) => {
  const base64Regex = /^data:[\w/]+;base64,[\w+/=]+$/

  if (!base64Regex.test(value)) return false

  const byteCharacters = atob(value.split(',')[1])

  const byteArrays = []

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i))
  }

  const byteArray = new Uint8Array(byteArrays)

  const blob = new Blob([byteArray])

  return blob.size > 0
}
