import React, { useCallback, useEffect, useRef, useState } from 'react'

import { HeadPage } from 'atoms/web/HeadPage'
import { HStack, VStack, View, Button } from 'native-base'
import Layouts from 'organisms/Layouts'
import { FlatList, RefreshControl } from 'react-native'
import useDebounce from 'src/hooks/useDebounce'

import { ListOpportunities } from './components/ListOpportunities'
import { ListOpportunitiesByBusiness } from './components/ListOpportunitiesByBusiness'
import { ListOpportunitiesConcluded } from './components/ListOpportunitiesConcluded'
import { ListOpportunitiesHighlighted } from './components/ListOpportunitiesHighlighted'
import { ListOpportunitiesNotConcluded } from './components/ListOpportunitiesNotConcluded'
import {
  ListTypesRef,
  ListTypesView,
  OrganizationScreenOpportunityComponent
} from './OrganizationScreen.types'
import { useOrganizationScreen } from './useOrganizationScreen'

const OrganizationScreenOpportunityDesktop: OrganizationScreenOpportunityComponent = ({
  navigation,
  route
}) => {
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)

  const {
    handleToggleActivitiesTypeView,
    currentSelectedDate,
    isLoading,
    ascOrDesc,
    viewTypes,
    viewType
    //@ts-ignore
  } = useOrganizationScreen({ navigation, route })

  const currentViewRef = useRef<ListTypesRef>(null)

  const OpportunitiesViews: any = {
    10: ListOpportunitiesNotConcluded,
    9: ListOpportunities,
    8: ListOpportunitiesHighlighted,
    7: ListOpportunitiesConcluded,
    6: ListOpportunitiesByBusiness
  }

  const selectedOpportunityView = viewType.group as 6 | 7 | 8 | 9

  const CurrentOpportunityView = OpportunitiesViews[selectedOpportunityView]

  const CurrentView = CurrentOpportunityView

  const keyExtractor = useCallback(
    (item: { component: React.ElementType; key: string }) => item.key,
    []
  )

  const data = [
    {
      component: CurrentView,
      key: '1'
    }
  ]

  const renderItem = useCallback(
    (
      { item }: { item: { component: React.FunctionComponent<ListTypesView>; key: string } },
      index: number
    ) => {
      const Component = item.component as
        | React.FunctionComponent<ListTypesView>
        | React.ForwardRefExoticComponent<ListTypesView & React.RefAttributes<ListTypesRef>>

      return (
        <View bg="background.base" flex={1} minH={600} pb={30} key={`${index}-${item.key}`}>
          <Component
            ref={currentViewRef}
            isLoading={isLoading}
            ascOrDesc={ascOrDesc}
            //@ts-ignore
            navigation={navigation}
            handleSelect={handleToggleActivitiesTypeView}
            data={{
              date: currentSelectedDate
            }}
          />
        </View>
      )
    },
    [ascOrDesc, currentSelectedDate, handleToggleActivitiesTypeView, isLoading, navigation]
  )

  const monthNames = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Maio',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ]

  useEffect(() => {
    if (currentViewRef.current) {
      currentViewRef.current.search(debouncedSearch)
    }
  }, [debouncedSearch, currentViewRef])

  return (
    <Layouts.InternalDesktop onSearch={setSearch} search={search}>
      <View h="full" flex={1} px={8}>
        <FlatList
          data={data}
          keyExtractor={keyExtractor}
          ListHeaderComponent={
            <View>
              <HeadPage
                title="Oportunidades"
                badge={monthNames[new Date().getMonth()]}
                pages={[
                  {
                    id: 1,
                    title: 'Início',
                    link: 'Home',
                    params: null
                  },
                  {
                    id: 2,
                    title: 'Minha organização',
                    link: 'minha-organizacao/oportunidades',
                    params: null
                  },
                  {
                    id: 3,
                    title: 'Oportunidades',
                    link: 'minha-organizacao/oportunidades',
                    params: null
                  }
                ]}
              />

              <VStack bg="background.base" borderTopRadius={32} mt={8} pb={2}>
                <>
                  <HStack bgColor="background.base" justifyContent="space-between" mt={2}>
                    <View
                      flexDir="row"
                      alignItems="center"
                      flexWrap="wrap"
                      w="100%"
                      //  @ts-ignore
                      style={{ gap: '8px' }}>
                      {viewTypes.map((vision) => (
                        <Button
                          key={vision.title}
                          //@ts-ignore
                          onPress={() => handleToggleActivitiesTypeView(vision)}
                          colorScheme={viewType.title === vision.title ? 'primary-light' : 'white'}
                          borderRadius="full"
                          px={6}
                          size="sm"
                          _text={{
                            fontSize: '16px'
                          }}
                          h={10}>
                          {vision.title}
                        </Button>
                      ))}
                    </View>
                  </HStack>
                </>
              </VStack>
            </View>
          }
          showsHorizontalScrollIndicator
          decelerationRate="normal"
          scrollEventThrottle={16}
          onEndReached={() => {
            if (currentViewRef.current) {
              currentViewRef.current?.fetchMore?.()
            }
          }}
          flexShrink={1}
          showsVerticalScrollIndicator
          refreshControl={
            <RefreshControl
              refreshing={currentViewRef.current?.refreshing ?? false}
              onRefresh={() => {
                currentViewRef.current?.refetch?.()
              }}
              tintColor="white"
            />
          }
          // @ts-ignore
          renderItem={renderItem}
        />
      </View>
    </Layouts.InternalDesktop>
  )
}

export default OrganizationScreenOpportunityDesktop
