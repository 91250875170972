import { Store } from '../store'

export type GetListParams = {
  page?: number
  per_page?: number
  filter_by?: 1 | 2 | 3 | 5
  get_highlighted_view?: boolean
  store_tab_numbers?: [number]
  group_by?: 1
  business_block_id?: number
  title?: string
  order_by?: string
  order_by_direction?: string
  expand_details?: boolean
  search?: string
}

export type Opportunity = {
  id: string
  opportunity_id?: string
  external_id?: number
  title: string
  is_finished: boolean
  due_date: string
  earning_potential: number
  is_highlighted: boolean
  highlighted_by_user_std_code?: string
  ranking: string
  description: string
  finished_at: string

  store: Store
  tab_number: string
  tab: string
  created_at: string
  user_std_code: number
  user_name: string
  creator_user?: {
    name: string
    user_std_code: number
  }
  rv_simulation_goal_points: number
  opportunity_business_block_id: keyof typeof OPPORTUNITY_BLOCK_TYPE_ENUM
  related_opportunities: {
    title: string
    earning_potential: number
    id: string
    due_date: string
    is_finished: boolean
  }[]
  rate: {
    insight: keyof typeof OPPORTUNITY_INSIGHT_ENUM
    comment: string
    score: number
    created_at: string
  }
  knowledge_base: {
    id: string
    title: string
    category: string
    cover_image: string
    published_at: string
  }
  activities: {
    title: string
    schedule_date: string
    id: string
    is_finished: boolean
  }[]
}

export type OpportunitySerialized = Opportunity & {
  isFinished: boolean
  dueDate: string
  earningPotential: string
  isHighlighted: boolean
  highlightedByUserStdCode?: string
}

export type Highlighteds = {
  items?: OpportunitySerialized[]
  total_opportunities?: number
}

export type OpportunitiesResponse = {
  items: OpportunitySerialized[]
  highlights: Highlighteds
  total_activities: number
  total_opportunities: number
}

export type Business = {
  business_block_items: OpportunitySerialized[]
  id: string
  name: keyof typeof OPPORTUNITY_BLOCK_TYPE_ENUM | string
  identifier: number
  total: number
}

export type OpportunitiesByBusinessResponse = {
  items: Business[]
  total_activities: number
  total_opportunities: number
}

export const OPPORTUNITY_BLOCK_TYPE_ENUM = {
  PRODUCTION: 'Produção',
  AUTO_INSURANCE: 'Seguro Auto',
  SPF: 'Seguro Prestamista',
  INADEQUACY: 'Inadimplência',
  ACTIVATION: 'Ativação',
  CONTRACTS: 'Contratos da TAB',
  1: 'Produção',
  2: 'Seguro Auto',
  3: 'Seguro Prestamista',
  4: 'Inadimplência',
  5: 'Ativação'
}

export const OPPORTUNITY_INSIGHT_ENUM = {
  1: 'Potencial baixo',
  2: 'Crédito restrito',
  3: 'Potencial irreal',
  4: 'Falta de dados'
}

export type OpportunitiesSummary = {
  name: string
  id: number
  total: number
}

export interface EvaluationItem {
  opportunity_id?: string
  score: string
  insight: 1 | 2 | 3 | 4
  comment: string
}
