import { forwardRef, useImperativeHandle } from 'react'

import { Actionsheet, Button, Heading, HStack, Text, useDisclose, VStack } from 'native-base'

type TMessageActionSheet = {
  title: string
  description: string
  titleButtonOk?: string
  titleButtonBack?: string
  handleButtonOk(): void
  backButtonOutline?: boolean
  hiddenBackButton?: boolean
}

export type TMessageActionSheetHandleRef = {
  onToggle(): void
}

export const MessageActionSheet = forwardRef<TMessageActionSheetHandleRef, TMessageActionSheet>(
  (
    {
      title,
      description,
      titleButtonOk = 'OK',
      titleButtonBack = 'Voltar',
      handleButtonOk,
      backButtonOutline = false,
      hiddenBackButton = false
    },
    ref
  ) => {
    const { isOpen, onToggle, onClose } = useDisclose(false)

    useImperativeHandle(ref, () => ({
      onToggle
    }))

    return (
      <Actionsheet isOpen={isOpen} hideDragIndicator onClose={onClose}>
        <Actionsheet.Content p={4}>
          <VStack space={4} alignItems="center">
            <Heading
              textAlign="center"
              color="gray.700"
              fontSize="24px"
              maxW="95%"
              lineHeight="28px">
              {title}
            </Heading>
            <Text color="gray.500" fontSize="12px" lineHeight="16px">
              {description}
            </Text>
          </VStack>

          <HStack maxW="full" justifyContent="space-between" my={6}>
            <Button
              h={10}
              flex={1}
              mr="8px"
              onPress={() => {
                onToggle()

                handleButtonOk()
              }}
              bg="primary.500"
              color="white"
              _pressed={{
                opacity: 0.8
              }}
              _text={{
                fontSize: '12px',
                lineHeight: '16px'
              }}>
              {titleButtonOk}
            </Button>
            {!hiddenBackButton ? (
              <>
                {backButtonOutline ? (
                  <Button
                    h={10}
                    flex={1}
                    ml="8px"
                    onPress={onClose}
                    variant="outline"
                    borderColor="gray.700"
                    bg="gray.50"
                    _pressed={{
                      bg: 'transparent'
                    }}
                    _text={{
                      color: 'gray.400',
                      fontSize: '12px',
                      lineHeight: '16px'
                    }}>
                    {titleButtonBack}
                  </Button>
                ) : (
                  <Button
                    h={10}
                    flex={1}
                    ml="8px"
                    onPress={onClose}
                    bg="gray.50"
                    _pressed={{
                      bg: 'gray.100',
                      opacity: 0.8
                    }}
                    _text={{
                      color: 'gray.400',
                      fontSize: '12px',
                      lineHeight: '16px'
                    }}>
                    {titleButtonBack}
                  </Button>
                )}
              </>
            ) : (
              <></>
            )}
          </HStack>
        </Actionsheet.Content>
      </Actionsheet>
    )
  }
)
