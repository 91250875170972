import { useCallback, useEffect, useState } from 'react'

import { format } from 'date-fns'
import * as ScreenOrientation from 'expo-screen-orientation'
import {
  GetChartDataParams,
  useGetContractsAmountQuery,
  useGetCreditInsuranceQuery,
  useGetFpd10Query,
  useGetMarketShareQuery,
  useGetOpenedAccountQuantityQuery,
  useGetOver30M3Query,
  useGetOverQuery,
  useGetPotentialQuery,
  useGetProductionQuery,
  useGetSantanderAutoInsuranceQuery,
  useGetSantanderAutoTypeQuery,
  useGetSpreadPercentageQuery,
  useGetWebmotorsQuery
} from 'integration/resources/panels'
import { useBreakpointValue, useTheme } from 'native-base'
import { PanelsTypes, panelsFullChartsProps } from 'organisms/TabPanels/constants'
import { getShortMonthAndYear } from 'src/utils'
import { LogEventScreen } from 'src/utils/logEvents'

import { UseChartScreen } from './ChartScreen.types'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

export const useChartScreen: UseChartScreen = ({ navigation, route }) => {
  useEffect(() => {
    changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT).then(() => {
      const rotateTimeout = setTimeout(() => {}, 1500)

      clearTimeout(rotateTimeout)
    })
  }, [])

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const theme = useTheme()

  const tabNumber = route.params.tabNumber

  const type = route.params.type

  const [showModal, setShowModal] = useState(true)

  const timeoutModal = setTimeout(() => {
    setShowModal(false)
  }, 2500)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutModal)
    }
  }, [timeoutModal])

  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    if (!showSpinner) return

    const timeoutSpinner = setTimeout(() => {
      setShowSpinner(!showSpinner)
    }, 3000)

    return () => {
      clearTimeout(timeoutSpinner)
    }
  }, [showSpinner])

  const handleGoBack = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  useEffect(() => {
    const date = new Date()

    const formated = format(date, 'dd-MM-yyyy HH:mm')

    LogEventScreen({
      screen_name: `Grafico_${panelsFullChartsProps[type].title}`,
      screen_class: `Grafico_${panelsFullChartsProps[type].title}`,
      date: formated,
      hora: format(date, 'HH'),
      minutos: format(date, 'mm')
    })
  }, [type])

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (e.data.action.type === 'GO_BACK' && isMobile) {
          // Prevent default behavior of leaving the screen
          e.preventDefault()

          setShowSpinner(true)

          changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP)

          setTimeout(() => {
            navigation.dispatch(e.data.action)
          }, 3000)
        }
      }),
    [navigation, isMobile]
  )

  const switchHook = (type: PanelsTypes) => {
    switch (type) {
      case `${PanelsTypes.CONTRACTS_AMOUNT}GoodsAndServices`:
        return useGetContractsAmountQuery

      case PanelsTypes.CONTRACTS_AMOUNT:
        return useGetContractsAmountQuery

      case `${PanelsTypes.CONTRACTS_AMOUNT}Pesados`:
        return useGetContractsAmountQuery

      case PanelsTypes.PRODUCTION:
        return useGetProductionQuery

      case PanelsTypes.SPREAD:
        return useGetSpreadPercentageQuery

      case PanelsTypes.POTENTIAL:
        return useGetPotentialQuery

      case PanelsTypes.MARKETING_SHARE:
        return useGetMarketShareQuery

      case PanelsTypes.MARKET_VISUALIZATION:
        return useGetMarketShareQuery

      case PanelsTypes.CREDIT_LIFE_INSURANCE:
        return useGetCreditInsuranceQuery

      case PanelsTypes.SANTANDER_AUTO_INSURANCE:
        return useGetSantanderAutoInsuranceQuery

      case PanelsTypes.TYPES_SANTANDER_AUTO:
        return useGetSantanderAutoTypeQuery

      case PanelsTypes.ACCOUNT_AMOUNT:
        return useGetOpenedAccountQuantityQuery

      case PanelsTypes.WEBMOTORS:
        return useGetWebmotorsQuery

      case PanelsTypes.FPD10:
        return useGetFpd10Query

      case PanelsTypes.OVER30_M3:
        return useGetOver30M3Query

      case PanelsTypes.OVER30:
        return useGetOverQuery

      case PanelsTypes.OVER60:
        return useGetOverQuery

      case PanelsTypes.OVER90:
        return useGetOverQuery

      default:
        return []
    }
  }

  const hook = switchHook(type)

  //@ts-ignore
  const data = hook({
    year_month_qty: 13,
    tab_number: tabNumber
  } as GetChartDataParams)

  const switchProcessedAt = (type: PanelsTypes) => {
    switch (type) {
      case PanelsTypes.OVER30:
        return data?.data?.data?.data?.over30?.processed_at

      case PanelsTypes.OVER60:
        return data?.data?.data?.data?.over60?.processed_at

      case PanelsTypes.OVER90:
        return data?.data?.data?.data?.over90?.processed_at

      default:
        return data?.data?.data?.data?.processed_at
    }
  }

  const processedAt = switchProcessedAt(type)

  const isShortFormat = data?.data?.data?.data?.is_short_format ?? false

  const switchChartData = (type: PanelsTypes) => {
    switch (type) {
      case `${PanelsTypes.CONTRACTS_AMOUNT}GoodsAndServices`:
        return data?.data && data.data.data && data.data.data.data
          ? [
              data.data.data.data.contracts_quantity,
              data.data.data.data.insurance_contracts_quantity
            ]
          : []

      case PanelsTypes.CONTRACTS_AMOUNT:
        return data?.data && data.data.data && data.data.data.data
          ? [data.data.data.data.contracts_quantity]
          : []

      case `${PanelsTypes.CONTRACTS_AMOUNT}Pesados`:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.contracts_quantity
          : []

      case PanelsTypes.PRODUCTION:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.production
          : []

      case PanelsTypes.SPREAD:
        return data?.data && data.data.data && data.data.data.data
          ? [data.data.data.data.net_spread, data.data.data.data.gross_spread]
          : []

      case PanelsTypes.POTENTIAL:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.potential
          : []

      case PanelsTypes.MARKETING_SHARE:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.actual_share
          : []

      case PanelsTypes.MARKET_VISUALIZATION:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.market_visualization
          : []

      case PanelsTypes.CREDIT_LIFE_INSURANCE:
        return data?.data && data.data.data && data.data.data.data
          ? [data.data.data.data.ip_insurance_spf_qty, data.data.data.data.ip_insurance_spf_volume]
          : []

      case PanelsTypes.SANTANDER_AUTO_INSURANCE:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.ip_auto_insurance_qty
          : []

      case PanelsTypes.TYPES_SANTANDER_AUTO:
        return data?.data && data.data.data && data.data.data.data
          ? [
              data.data.data.data.complete_insurance,
              data.data.data.data.fire_theft_insurance,
              data.data.data.data.third_party_insurance,
              data.data.data.data.loose_insurance
            ]
          : []

      case PanelsTypes.ACCOUNT_AMOUNT:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.accounts_opened_quantity
          : []

      case PanelsTypes.WEBMOTORS:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.web_realized_value
          : []

      case PanelsTypes.FPD10:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.fpd10_value
          : []

      case PanelsTypes.OVER30_M3:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.over30_m3_calculated
          : []

      case PanelsTypes.OVER30:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.over30_.over_calculated
          : []

      case PanelsTypes.OVER60:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.over60_.over_calculated
          : []

      case PanelsTypes.OVER90:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.over90_.over_calculated
          : []

      default:
        return []
    }
  }

  const switchChartXValues = (type: PanelsTypes) => {
    switch (type) {
      case PanelsTypes.OVER30:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.over30.months
          : []

      case PanelsTypes.OVER60:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.over60.months
          : []

      case PanelsTypes.OVER90:
        return data?.data && data.data.data && data.data.data.data
          ? data.data.data.data.over90.months
          : []

      default:
        return data?.data && data.data.data && data.data.data.data ? data.data.data.data.months : []
    }
  }

  const chartsData = switchChartData(type)

  const chartXValues = switchChartXValues(type).map((item: { year_month: number }) =>
    getShortMonthAndYear(String(item.year_month))
  )

  const switchChartDataColors = (type: PanelsTypes) => {
    switch (type) {
      case `${PanelsTypes.CONTRACTS_AMOUNT}GoodsAndServices`:
        return [theme.colors.primary[800], theme.colors.primary[500]]

      case PanelsTypes.CONTRACTS_AMOUNT:
        return [theme.colors.primary[800], theme.colors.primary[500], theme.colors.primary[300]]

      case `${PanelsTypes.CONTRACTS_AMOUNT}Pesados`:
        return [theme.colors.primary[300]]

      case PanelsTypes.SPREAD:
        return [theme.colors.primary[500], theme.colors.primary[300]]

      case PanelsTypes.CREDIT_LIFE_INSURANCE:
        return [theme.colors.primary[500], theme.colors.primary[300]]

      case PanelsTypes.TYPES_SANTANDER_AUTO:
        return [
          theme.colors.primary[800],
          theme.colors.primary[500],
          theme.colors.primary[300],
          theme.colors.gray[200]
        ]

      default:
        return []
    }
  }

  return {
    isMobile,
    handleGoBack,
    showModal,
    setShowModal,
    showSpinner,
    setShowSpinner,
    type,
    chartsData,
    chartsDataIsLoading: data.isLoading,
    chartsDataColors: switchChartDataColors(type),
    chartXValues,
    tabNumber,
    processedAt,
    isShortFormat
  }
}
