import { useCallback, useState } from 'react'

import {
  GetListsParams,
  KEY_CONTACT_LIST_QUERY,
  Note,
  useDeleteNoteContact,
  useFavoriteContactMutation,
  useGetContactItemlQuery,
  useUnfavoriteContactMutation
} from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { FAVORITE_TYPE } from 'src/screens/ContentScreen/ContentScreen.types'

import { Documents } from './components/Documents'
import { Informations } from './components/Informations'
import { Notes } from './components/Notes'
import { UseContactScreen } from './ContactScreen.types'

const sanitizeFilters = (
  parameters: GetListsParams = {}
): { params: GetListsParams; size: number } => {
  let size = 0

  const params: GetListsParams = {}

  if (parameters?.is_favorite) {
    params.is_favorite = 1

    size++
  } else {
    delete params.is_favorite
  }

  if ('store_tab_number' in parameters && parameters.store_tab_number) {
    params.store_tab_number = parameters.store_tab_number

    size++
  } else if ('search' in parameters && parameters.search) {
    params.search = parameters.search

    size++
  }

  if ('job_title' in parameters && parameters.job_title) {
    params.job_title = parameters.job_title

    size++
  }

  if ('status' in parameters && parameters.status) {
    params.status = parameters.status

    size++
  }

  if ('subsegment_code' in parameters && parameters.subsegment_code) {
    params.subsegment_code = parameters.subsegment_code

    params.subSegment_name = parameters.subSegment_name

    size++
  }

  return {
    params,
    size
  }
}

export const useContactScreen: UseContactScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { contactId } = route?.params

  const { params } = sanitizeFilters(route?.params as GetListsParams)

  const { data, isLoading } = useGetContactItemlQuery(contactId)

  const contact = data?.data.data

  const favoriteContactMutation = useFavoriteContactMutation(contactId)

  const unfavoriteContactMutation = useUnfavoriteContactMutation(contactId)

  const queryClient = useQueryClient()

  const onRefresh = () => queryClient.invalidateQueries([KEY_CONTACT_LIST_QUERY, params])

  const handleToggleFavorite = (type: FAVORITE_TYPE) => {
    const mutation =
      type === FAVORITE_TYPE.FAVORITE ? favoriteContactMutation : unfavoriteContactMutation

    return mutation.mutateAsync(
      { id: contactId },
      {
        onSuccess: () => {
          onRefresh()
        }
      }
    )
  }

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const handleGoToContactDetails = useCallback(() => {
    if (contactId) navigation.navigate('ContactDetails', { contactId })
  }, [navigation, contactId])

  const handleGoToContactBirthDate = useCallback(() => {
    if (contact) navigation.navigate('ContactBirthday', { contactId })
  }, [contact, contactId, navigation])

  const handleGoToContactEdit = useCallback(() => {
    if (contact)
      navigation.navigate('ContactEdit', { contact: { ...contact, id: contactId }, contactId })
  }, [contact, contactId, navigation])

  const handleGoToNoteCreateDetails = useCallback(
    () =>
      navigation.navigate(isMobile ? 'CreateNoteDetails' : 'ContactCreateNoteDetails', {
        contactId
      }),
    [contactId, isMobile, navigation]
  )

  const handleGoToNoteDetails = useCallback(
    (id: string) => navigation.navigate('NoteDetails', { id, key: 'contacts', contactId }),
    [navigation, contactId]
  )

  const [currentTab, setCurrentTab] = useState<number>(1)

  const handleGoToEditNote = useCallback(
    (note: Note) => {
      navigation.navigate(isMobile ? 'EditNoteDetails' : 'ContactEditNoteDetails', {
        note,
        key: 'contacts',
        contactId
      })
    },
    [contactId, isMobile, navigation]
  )

  const [isConfirmDeleteNoteOpen, setIsConfirmDeleteNoteOpen] = useState(false)

  const { mutate: deleteNoteMutation, isLoading: isLoadingNoteDelete } = useDeleteNoteContact()

  const handleConfirmDelete = (note: Note) => {
    deleteNoteMutation(
      { id: note!.id },
      {
        onSuccess: () => {
          setIsConfirmDeleteNoteOpen(false)
        },
        onError: () => {
          setIsConfirmDeleteNoteOpen(false)
        }
      }
    )
  }

  const mainContent = {
    1: {
      component: Informations,
      name: 'Dados de contato',
      show: true
    },
    2: {
      component: Notes,
      name: 'Notas',
      show: true
    },
    3: {
      component: Documents,
      name: 'Documentos',
      show: true
    }
  }

  const mainContentCommonProps = {
    contact,
    contactId,
    handleGoToNoteDetails,
    handleOpenEditContact: handleGoToContactEdit,
    handleGoToContactDetails,
    handleGoToContactBirthDate,
    handleGoToNoteCreateDetails,
    handleGoToEditNote,
    handleToggleFavorite,
    isConfirmDeleteNoteOpen,
    isLoadingNoteDelete,
    setIsConfirmDeleteNoteOpen,
    handleConfirmDelete
  }

  return {
    contact,
    isMobile,
    currentTab,
    mainContent,
    contactIsFavorite: contact?.is_favorite ?? false,
    mainContentCommonProps,
    contactIsLoading: isLoading,
    favoriteIsLoading: favoriteContactMutation.isLoading || unfavoriteContactMutation.isLoading,
    handleGoBack,
    setCurrentTab,
    handleGoToContactEdit,
    handleToggleFavorite
  }
}
