import { memo } from 'react'

import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg'

export const TurboIllustration = () => (
  <Svg width={203} height={179} viewBox="0 0 203 179" fill="none">
    <Path
      d="M72.4606 47.741L75.0899 42.7105C74.078 44.3413 73.2015 46.0226 72.4606 47.741Z"
      fill="#553D41"
    />
    <Path
      d="M98.4949 5C90.9333 17.1827 92.146 31.8059 99.7063 43.9887L123.383 83.2439C128.151 90.3058 128.532 97.0091 128.65 100.39M72.4606 47.741L75.0899 42.7105C67.5556 54.8522 67.5322 69.8012 75.0146 81.9622L75.0133 81.9634L98.8127 120.464C103.675 127.91 104.162 132.384 104.569 134.906M53.3578 75.6383C20.6615 83.5858 1.24991 103.466 1.24991 123.007C1.24991 150.191 49.5834 178.172 103.454 178.172"
      stroke="#595959"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.01 78.8732C147.727 72.8257 145.908 66.8264 142.54 61.4036L112.84 13.5665C110.575 9.91766 109.01 6.00931 108.14 2L106.901 3.99937C99.5478 15.8395 99.5478 30.4268 106.899 42.2669L130.66 80.5368C138.012 92.378 138.012 106.967 130.66 118.807L129.419 120.806C128.55 116.797 126.985 112.888 124.72 109.241L89.0789 51.8352C86.8152 48.1875 85.2497 44.278 84.3811 40.2699L83.14 42.2692C75.8136 54.0694 75.7909 68.598 83.0668 80.417L83.0655 80.4182L106.901 118.807C114.251 130.647 114.251 145.235 106.901 157.076L105.658 159.076C104.79 155.067 103.225 151.158 100.961 147.509L71.2597 99.6723C67.2765 93.2562 65.4559 86.0336 65.7892 78.879C33.9951 86.603 12.0487 103.005 12.0487 121.996C12.0487 148.416 54.5162 169.832 106.901 169.832C159.284 169.832 201.75 148.416 201.75 121.996C201.75 103.004 179.806 86.5983 148.01 78.8732Z"
      fill="#553D41"
      stroke="url(#paint0_linear_9515_168995)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_9515_168995"
        x1={82.8706}
        y1={-17.0155}
        x2={119.886}
        y2={109.138}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#F3123C" />
        <Stop offset={1} stopColor="#CC0000" />
        <Stop offset={1} stopColor="#FF393C" />
      </LinearGradient>
    </Defs>
  </Svg>
)

export const Turbo = memo(TurboIllustration)
