import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const HourglassIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 19 19', ...props }}>
    <G clip-path="url(#clip0_5589_45743)">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.74998 0.923828C3.74998 0.509615 4.08576 0.173828 4.49997 0.173828H13.4999C13.9141 0.173828 14.2499 0.509615 14.2499 0.923828V4.52383C14.2499 5.91621 13.6968 7.25157 12.7123 8.23614C11.7277 9.2207 10.3923 9.77383 8.99996 9.77383C7.60757 9.77383 6.27222 9.2207 5.28766 8.23614C4.3031 7.25157 3.74998 5.91621 3.74998 4.52383V0.923828ZM5.24997 1.67383V4.52383C5.24997 5.51839 5.64506 6.47222 6.34832 7.17548C7.05157 7.87874 8.0054 8.27383 8.99996 8.27383C9.99451 8.27383 10.9483 7.87874 11.6516 7.17548C12.3549 6.47222 12.7499 5.51839 12.7499 4.52383V1.67383H5.24997Z"
        fill="currentColor"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.28766 9.81152C6.27222 8.82695 7.60757 8.27383 8.99996 8.27383C10.3923 8.27383 11.7277 8.82695 12.7123 9.81152C13.6968 10.7961 14.2499 12.1314 14.2499 13.5238V17.1238C14.2499 17.538 13.9141 17.8738 13.4999 17.8738H4.49997C4.08576 17.8738 3.74998 17.538 3.74998 17.1238V13.5238C3.74998 12.1314 4.3031 10.7961 5.28766 9.81152ZM8.99996 9.77383C8.0054 9.77383 7.05157 10.1689 6.34832 10.8722C5.64506 11.5754 5.24997 12.5293 5.24997 13.5238V16.3738H12.7499V13.5238C12.7499 12.5293 12.3549 11.5754 11.6516 10.8722C10.9483 10.1689 9.99451 9.77383 8.99996 9.77383Z"
        fill="currentColor"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.04999 0.923828C1.04999 0.509615 1.38577 0.173828 1.79998 0.173828H16.1999C16.6141 0.173828 16.9499 0.509615 16.9499 0.923828C16.9499 1.33804 16.6141 1.67383 16.1999 1.67383H1.79998C1.38577 1.67383 1.04999 1.33804 1.04999 0.923828Z"
        fill="currentColor"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.04999 17.1238C1.04999 16.7096 1.38577 16.3738 1.79998 16.3738H16.1999C16.6141 16.3738 16.9499 16.7096 16.9499 17.1238C16.9499 17.538 16.6141 17.8738 16.1999 17.8738H1.79998C1.38577 17.8738 1.04999 17.538 1.04999 17.1238Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5589_45743">
        <Rect width="17.9999" height="18" fill="white" transform="translate(0 0.0234375)" />
      </ClipPath>
    </Defs>
  </Icon>
)
