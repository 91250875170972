import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import {
  Category,
  ContentSerialized,
  KEY_KNOWLEGDE_BASES_QUERY,
  useGetCategoriesQuery,
  useGetKnowledgeBasesHighlightInfiniteQuery,
  useGetKnowledgeBasesInfiniteQuery
} from 'integration/resources/knowledgeBase'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { getRecentKnowledgeData } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { UseHomeContentScreen } from './HomeContentScreen.types'

const mergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetKnowledgeBasesInfiniteQuery>['data']
) =>
  data?.pages.reduce<ContentSerialized[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useHomeContentScreen: UseHomeContentScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [searchContentVisible, setSearchContentVisible] = useState<boolean>(false)

  const linkTo = useLinkTo()

  const [search, onSearch] = useState('')

  const {
    data: allContentData,
    isLoading: allContentIsLoading,
    isFetchingNextPage: allContentIsFetchingNextPage,
    hasNextPage: recentHasNextPage,
    fetchNextPage: recentFetchNextPage,
    refetch: refreshAllContent
  } = useGetKnowledgeBasesInfiniteQuery({})

  const {
    data: highlightedData,
    isLoading: highlightedIsLoading,
    isFetchingNextPage: highlightedIsFetchingNextPage,
    hasNextPage: highlightedHasNextPage,
    fetchNextPage: highlightedFetchNextPage,
    refetch: refreshHighlight
  } = useGetKnowledgeBasesHighlightInfiniteQuery({})

  useEffect(() => {
    refreshAllContent()

    refreshHighlight()
  }, [refreshAllContent, refreshHighlight])

  const { data: recentContent } = useGetKnowledgeBasesInfiniteQuery({
    is_read: 0,
    period_starting_at: getRecentKnowledgeData(),
    per_page: 1
  })

  const amountNewContents = recentContent?.pages[0]?.data?.metadata?.pagination?.total ?? 0

  const {
    data: categoryList,
    isLoading: isLoadingCategory,
    refetch: refreshCategoryList
  } = useGetCategoriesQuery()

  const highlightedList = useMemo(() => mergeDataInfiniteQuery(highlightedData), [highlightedData])

  const allContent = mergeDataInfiniteQuery(allContentData)

  const categoryListWithAll = [
    {
      id: 'todas',
      name: 'Todas',
      code: 999,
      total: allContentData?.pages.map((page) => page.data.metadata.pagination.total)
    },
    ...(categoryList?.data?.data ?? [])
  ]

  const handleGoToContentScreen = useCallback(
    (content: ContentSerialized) => {
      LogEvent(`conteudo`, {
        item_id: content.id
      })

      linkTo(`/conteudos/${content.id}/detalhes`)
    },
    [linkTo]
  )

  const onPressCategoryCard = useCallback(
    (category: Category) => {
      LogEvent(`conteudo_categoria`, {
        item_id: category.id
      })

      navigation.navigate('ContentByCategory', { category_id: category.id })
    },
    [navigation]
  )

  const onPressSearchContent = useCallback(
    (searchTerm: string, category: Category) => {
      navigation.navigate('ContentByCategory', {
        searchTerm: search,
        category_id: 'todas'
      })
    },
    [navigation, search]
  )

  const onEndReachedHighlightedList = useCallback(() => {
    if (!highlightedIsFetchingNextPage && highlightedHasNextPage) {
      highlightedFetchNextPage()
    }
  }, [highlightedFetchNextPage, highlightedHasNextPage, highlightedIsFetchingNextPage])

  const onScrollEndDragAllContentList = useCallback(() => {
    if (!allContentIsFetchingNextPage && recentHasNextPage) {
      recentFetchNextPage()
    }
  }, [recentFetchNextPage, recentHasNextPage, allContentIsFetchingNextPage])

  const onScrollEndDrag = useCallback(
    () => onScrollEndDragAllContentList(),
    [onScrollEndDragAllContentList]
  )

  const queryClient = useQueryClient()

  const [refreshPage, setRefreshPage] = useState(false)

  const onRefresh = async () => {
    setRefreshPage(true)

    await refreshHighlight()

    await refreshCategoryList()

    await refreshAllContent()

    setRefreshPage(false)
  }

  useEffect(() => {
    return () => {
      queryClient.resetQueries(KEY_KNOWLEGDE_BASES_QUERY)
    }
  }, [queryClient])

  const handleSearchClear = () => {
    onSearch('')
  }

  return {
    searchContentVisible,
    search,
    setSearchContentVisible,
    isMobile,
    handleGoToContentScreen,
    highlightedList,
    highlightedIsLoading,
    highlightedIsFetchingNextPage,
    onEndReachedHighlightedList,
    categoryList: categoryListWithAll,
    onPressCategoryCard,
    onPressSearchContent,
    allContentIsLoading,
    allContent,
    onScrollEndDrag,
    allContentIsFetchingNextPage,
    amountNewContents,
    onRefresh,
    isLoadingCategory,
    handleSearchClear,
    pageIsLoading: isLoadingCategory || highlightedIsLoading || allContentIsLoading || refreshPage,
    onSearch
  }
}
