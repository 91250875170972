import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const KeyIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5354 5.74507L8.11426 9.16621C8.64636 9.91666 8.9376 10.8188 8.9376 11.751C8.9376 12.9362 8.46678 14.0728 7.62872 14.9109C6.79066 15.7489 5.654 16.2198 4.4688 16.2198C3.2836 16.2198 2.14694 15.7489 1.30888 14.9109C0.470819 14.0728 0 12.9362 0 11.751C0 10.5658 0.470819 9.42911 1.30888 8.59105C2.14694 7.75299 3.2836 7.28217 4.4688 7.28217C5.40099 7.28217 6.30314 7.57342 7.05361 8.10554L13.1969 1.96226C13.4539 1.70625 13.8018 1.5625 14.1645 1.5625C14.5273 1.5625 14.8752 1.70625 15.1322 1.96226L17.1132 3.94324C17.4061 4.23614 17.4061 4.71101 17.1132 5.00391C16.8203 5.2968 16.3454 5.2968 16.0525 5.00391L14.1645 3.11593L12.5961 4.68441L14.0372 6.12689C14.33 6.41991 14.3298 6.89479 14.0367 7.18755C13.7437 7.4803 13.2688 7.48009 12.9761 7.18706L11.5354 5.74507ZM4.4688 8.78217C3.68143 8.78217 2.9263 9.09495 2.36954 9.65171C1.81278 10.2085 1.5 10.9636 1.5 11.751C1.5 12.5383 1.81278 13.2935 2.36954 13.8502C2.9263 14.407 3.68142 14.7198 4.4688 14.7198C5.25618 14.7198 6.0113 14.407 6.56806 13.8502C7.12482 13.2935 7.4376 12.5383 7.4376 11.751C7.4376 10.9636 7.12482 10.2085 6.56806 9.65171C6.0113 9.09495 5.25617 8.78217 4.4688 8.78217Z"
      fill="currentColor"
    />
  </Icon>
)
