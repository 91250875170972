import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const PlusIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 25', ...props }}>
    <G clipPath="url(#clip0_5502_8938)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.82344C2 3.72476 3.70132 2.02344 5.8 2.02344H17.8C19.8987 2.02344 21.6 3.72476 21.6 5.82344V17.8234C21.6 19.9221 19.8987 21.6234 17.8 21.6234H5.8C3.70132 21.6234 2 19.9221 2 17.8234V5.82344ZM5.8 0.0234375C2.59675 0.0234375 0 2.62019 0 5.82344V17.8234C0 21.0267 2.59675 23.6234 5.8 23.6234H17.8C21.0033 23.6234 23.6 21.0267 23.6 17.8234V5.82344C23.6 2.62019 21.0033 0.0234375 17.8 0.0234375H5.8ZM11.7998 6.02344C12.3521 6.02344 12.7998 6.47115 12.7998 7.02344V10.8234H16.6C17.1523 10.8234 17.6 11.2711 17.6 11.8234C17.6 12.3757 17.1523 12.8234 16.6 12.8234H12.7998V16.6234C12.7998 17.1757 12.3521 17.6234 11.7998 17.6234C11.2475 17.6234 10.7998 17.1757 10.7998 16.6234V12.8234H7C6.44772 12.8234 6 12.3757 6 11.8234C6 11.2711 6.44772 10.8234 7 10.8234H10.7998V7.02344C10.7998 6.47115 11.2475 6.02344 11.7998 6.02344Z"
        fill="#404040"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5502_8938">
        <Rect width="24" height="24" fill="white" transform="translate(0 0.0234375)" />
      </ClipPath>
    </Defs>
  </Icon>
)
