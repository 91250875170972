import { forwardRef, useState } from 'react'

import { CustomBottomSheet, BottomSheetModal } from 'molecules'
import { VStack, Pressable, Text } from 'native-base'
import { ScrollView } from 'react-native-gesture-handler'

type TBottomSheetJob = {
  value: string | undefined
  onSelect(): void
  onChange(value: string): void
  segment?: boolean
}

export const BottomSheetSelectWalletHome = forwardRef<BottomSheetModal, TBottomSheetJob>(
  ({ onSelect, value, onChange, segment }, ref) => {
    const [walletSelected, setWalletSelected] = useState(value)

    const WALLETS = [
      {
        label: 'Resultado',
        show: true
      },
      {
        label: 'Funil',
        show: true
      },
      {
        label: 'Concentração',
        show: true
      },
      {
        label: 'Comissão',
        show: segment
      },
      {
        label: 'Inadimplência',
        show: segment
      }
    ]

    return (
      <CustomBottomSheet ref={ref} snapPoints={[350]}>
        <VStack space={6} w="full" h="full" pt={6}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {WALLETS.filter((wallet) => wallet.show).map((_wallet, index) => {
              return (
                <Pressable
                  key={`${index}-${_wallet.label}`}
                  onPress={() => {
                    setWalletSelected(_wallet.label)

                    onChange(_wallet.label)

                    onSelect()
                  }}
                  mb={2}
                  h="40px"
                  justifyContent="center"
                  _pressed={{
                    bg: 'gray.200'
                  }}
                  bg={walletSelected !== _wallet.label ? '' : 'gray.50'}>
                  <Text fontSize="16px" lineHeight="24px" color="gray.700" textAlign="center">
                    {_wallet.label}
                  </Text>
                </Pressable>
              )
            })}
          </ScrollView>
        </VStack>
      </CustomBottomSheet>
    )
  }
)
