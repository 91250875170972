import { Center, HStack, Skeleton, VStack } from 'native-base'

export default function SkeletonCard() {
  return (
    <Center w="full" mb={3}>
      <HStack w="full" space={2} overflow="hidden" rounded={20} bgColor="background.light" py={4}>
        <Skeleton h={12} w={12} rounded="full" startColor="gray.100" />

        <VStack flex="5" space="2">
          <Skeleton h="3" flex="1" rounded="full" />
          <Skeleton h="3" flex="1" rounded="full" />

          <HStack space="2" flex="1" alignItems="center">
            <Skeleton size="3" rounded="full" />
            <Skeleton h="3" flex="2" rounded="full" />
          </HStack>
        </VStack>
      </HStack>
    </Center>
  )
}
