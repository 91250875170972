import { FunctionComponent, PropsWithChildren } from 'react'

import { useNavigation } from '@react-navigation/native'
import { CustomStatusBar, Icons } from 'atoms'
import { Box, Center, Heading, HStack, IconButton, Menu, useTheme, View, VStack } from 'native-base'
import { Dimensions } from 'react-native'

export type TOption = {
  type: string
  title: string
  color?: string
  onPress(): void
}

type InternalLayoutProps = {
  title?: string
  capitalize?: boolean
  goToEdit?(): void
  goBack?: () => void
  statusBarColor?: 'dark-content' | 'light-content'
  buttonMode?: 'close' | 'closeSimple' | 'back' | 'edit' | 'moreDetails'
  titleTextAlign?: 'left' | 'center' | 'right'
  px?: number
  moreOptions?: TOption[]
}

export const InternalLayout: FunctionComponent<PropsWithChildren<InternalLayoutProps>> = ({
  children,
  title,
  goToEdit,
  statusBarColor = 'dark-content',
  buttonMode = 'back',
  titleTextAlign = 'center',
  goBack,
  px = 4,
  capitalize = true,
  moreOptions,
  ...rest
}) => {
  const navigation = useNavigation()

  const theme = useTheme()

  const screenHeight = Dimensions.get('screen').height

  return (
    <View flex={1} bg={theme.colors.muted[100]} {...rest}>
      <CustomStatusBar barStyle={statusBarColor} />
      <Box mt={screenHeight > 760 ? 16 : 10} />
      {navigation.canGoBack() && (
        <Center mx={4} mt={6} alignItems="center" h={10}>
          <IconButton
            size={10}
            bg={buttonMode === 'closeSimple' ? theme.colors.muted[100] : 'white'}
            _pressed={{
              bg: 'white',
              opacity: 0.7
            }}
            borderRadius="full"
            icon={
              buttonMode === 'close' || buttonMode === 'closeSimple' ? (
                <Icons.Close color="gray.700" />
              ) : (
                <Icons.Chevron direction="left" color="gray.700" size={9} />
              )
            }
            onPress={goBack ?? navigation.goBack}
            left={0}
            position="absolute"
          />
          {title && (
            <Heading
              flexShrink={1}
              flexWrap="wrap"
              color="gray.900"
              fontSize="18px"
              mx="12%"
              w="74%"
              textAlign={titleTextAlign}
              zIndex={-1}
              textTransform={capitalize ? 'capitalize' : undefined}>
              {title}
            </Heading>
          )}
          <HStack flexShrink={1} position="absolute" right={0} pt={2}>
            {goToEdit && (
              <IconButton
                bg="white"
                rounded="full"
                onPress={goToEdit}
                icon={<Icons.Edit color="gray.700" />}
              />
            )}
            {!!moreOptions && moreOptions.length > 0 && (
              <Menu
                borderRadius={20}
                minW={155}
                right={5}
                top={1}
                shadow={0.9}
                trigger={(triggerProps) => (
                  <IconButton
                    bg="white"
                    _pressed={{
                      bg: 'gray.50',
                      opacity: 0.9
                    }}
                    accessibilityLabel="Show menu"
                    rounded="full"
                    {...triggerProps}
                    icon={<Icons.More color="gray.700" />}
                    ml={1}
                    style={{
                      transform: [
                        {
                          rotate: '90deg'
                        }
                      ]
                    }}
                  />
                )}>
                {moreOptions.map((option) => (
                  <Menu.Item
                    key={option.type}
                    _text={{
                      color: option?.color ?? 'gray.700',
                      fontSize: 12,
                      fontWeight: 600,
                      lineHeight: 20
                    }}
                    _pressed={{
                      background: 'gray.50',
                      opacity: 0.9
                    }}
                    onPress={option.onPress}>
                    {option.title}
                  </Menu.Item>
                ))}
              </Menu>
            )}
          </HStack>
        </Center>
      )}
      <VStack safeAreaBottom flex={1} justifyContent="center" px={px} pt={14}>
        {children}
      </VStack>
    </View>
  )
}
