import * as serializers from './serializers'
import {
  ChargeDataSerialized,
  CommissionDataSerialized,
  ConcentrationDataSerialized,
  FunnelDataSerialized,
  GetWalletResumeParams,
  ResultDataSerialized
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getCharge = async (params: GetWalletResumeParams) => {
  const response = await client.get<ResponseSuccess<ChargeDataSerialized>>(
    `v1/wallets/summary/charge`,
    {
      params
    }
  )

  return serializers.getCharge(response)
}

export const getCommission = async (params: GetWalletResumeParams) => {
  const response = await client.get<ResponseSuccess<CommissionDataSerialized>>(
    `v1/wallets/summary/commission`,
    {
      params
    }
  )

  return serializers.getCommission(response)
}

export const getConcentration = async (params: GetWalletResumeParams) => {
  const response = await client.get<ResponseSuccess<ConcentrationDataSerialized>>(
    `v1/wallets/summary/concentration`,
    {
      params
    }
  )

  return serializers.getConcentration(response)
}

export const getFunnel = async (params: GetWalletResumeParams) => {
  const response = await client.get<ResponseSuccess<FunnelDataSerialized>>(
    `v1/wallets/summary/funnel`,
    {
      params
    }
  )

  return serializers.getFunnel(response)
}

export const getResult = async (params: GetWalletResumeParams) => {
  const response = await client.get<ResponseSuccess<ResultDataSerialized>>(
    `v1/wallets/summary/result`,
    {
      params
    }
  )

  return serializers.getResult(response)
}
