import { FunctionComponent } from 'react'

import { IIconProps } from 'native-base'
import { Path, Svg, ClipPath, Defs, G, Rect } from 'react-native-svg'

export const AcademyIcon: FunctionComponent<IIconProps> = ({ ...props }) => {
  return (
    <Svg width={20} height={20} fill="none">
      <Path
        d="M17.5972 7.88498L9.70212 5.01404C9.67023 5.00245 9.63528 5.00245 9.60339 5.01404L2.08162 7.74923C1.95484 7.79533 1.95484 7.97463 2.08162 8.02073L9.60339 10.7559C9.63528 10.7675 9.67023 10.7675 9.70212 10.7559L17.5972 7.88498V12.2183V7.88498Z"
        fill="#F3FAFB"
      />
      <Path
        d="M5.31946 9.32812V13.5067C5.31946 14.1224 5.776 14.7128 6.58866 15.1482C7.40132 15.5835 8.50352 15.8281 9.65279 15.8281C10.8021 15.8281 11.9043 15.5835 12.7169 15.1482C13.5296 14.7128 13.9861 14.1224 13.9861 13.5067V9.32812"
        fill="#F3FAFB"
      />
      <G clipPath="url(#clip0_7770_23510)">
        <Path
          d="M20.0416 7.49349L10.875 4.16016L1.70831 7.49349L10.875 10.8268L20.0416 7.49349ZM20.0416 7.49349V12.4935"
          stroke="#5AB8CA"
          strokeWidth={1.66667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M5.875 9.16016V13.9816C5.875 14.692 6.40178 15.3733 7.33947 15.8756C8.27715 16.378 9.54892 16.6602 10.875 16.6602C12.2011 16.6602 13.4729 16.378 14.4105 15.8756C15.3482 15.3733 15.875 14.692 15.875 13.9816V9.16016"
          stroke="#5AB8CA"
          strokeWidth={1.66667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_7770_23510">
          <Rect width={20} height={20} fill="white" transform="translate(0.875 0.828125)" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
