import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { RvStatement } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_GET_RV_STATEMENT_QUERY = 'useGetRVStatementQuery'

export const useGetRVStatementQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<RvStatement>>, AxiosError<ResponseError>>(
    [KEY_GET_RV_STATEMENT_QUERY],
    () => requests.getRvStatement()
  )
