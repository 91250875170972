import { FunctionComponent, memo } from 'react'

import { Illustrations } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { ActivityCard } from 'atoms/web/Card/ActivityCard'
import { Box, Center, ScrollView, Text } from 'native-base'

import { useActivityContainerList } from './useActivityContainerList'

type ActivityContainerListProps = {
  tabNumber: string
}

export const ActivityContainerListDesktop: FunctionComponent<ActivityContainerListProps> = memo(
  ({ tabNumber }) => {
    const { isLoading, activities, onScrollEndDragActivityList, handleComplete } =
      useActivityContainerList({
        store_tab_numbers: [tabNumber]
      })

    const activitySkeleton = isLoading && !activities?.items.length

    const noResults = !activities?.items.length && !isLoading

    const contentPreview = new Array<number>(5).fill(0)

    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        onScrollEndDrag={onScrollEndDragActivityList}>
        {activitySkeleton &&
          contentPreview.map((_, i) => <SkeletonCard key={`key-skeleton-${i}`} />)}

        {activities?.items.map((props) => (
          <Box mt="16px" key={props.id}>
            <ActivityCard
              {...props}
              active={props.is_finished}
              icon="Check"
              onCheckPress={(callback, currentCheck) =>
                handleComplete(callback, currentCheck, props.id)
              }
            />
          </Box>
        ))}

        {noResults && (
          <Center mb={8}>
            <Illustrations.ActivitiesEmpty />

            <Text fontSize="16px" lineHeight="24px" color="gray.500" textAlign="center" mt={6}>
              Ainda não há atividades relacionadas para este TAB.
            </Text>
          </Center>
        )}
      </ScrollView>
    )
  }
)
