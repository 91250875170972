import { FunctionComponent, useCallback } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Cards } from 'atoms'
import { OpportunitiesSummary } from 'integration/resources/opportunities'
import { AnimatedSlideDot } from 'molecules/AnimatedSlideDot/AnimatedSlideDot'
import { Box, Button, HStack, Skeleton, useTheme, VStack } from 'native-base'
import { TouchableOpacity, useWindowDimensions } from 'react-native'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'

type TProgress = {
  current: number | string
  pointsAmount: string | number
  currencyAmount: string | number
}

type OpportunitiesListProps = {
  data: OpportunitiesSummary[]
  simulatedPoints: TProgress
  handleGoToOpportunitiesList(): void
  isGoodsAndServices: boolean
}

export const OpportunitiesList: FunctionComponent<OpportunitiesListProps> = ({
  data = [],
  simulatedPoints,
  handleGoToOpportunitiesList,
  isGoodsAndServices
}) => {
  const dataWithValues = data.filter((item) => item.total > 0)

  const linkTo = useLinkTo()

  const theme = useTheme()

  const scrollX = useSharedValue(1)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  const { width: SCREEN_WIDTH } = useWindowDimensions()

  const cutarray = Math.round(dataWithValues.length / 2)

  const sliderDots = dataWithValues.slice(0, cutarray)

  const renderItem = useCallback(
    ({ item }: { item: OpportunitiesSummary }) => {
      return (
        <TouchableOpacity
          onPress={() =>
            linkTo(
              `/oportunidade/${item.name}/${simulatedPoints.pointsAmount}/${item.total}/${item.id}`
            )
          }
          key={Math.random()}>
          <Box mr={4} ml={item.id === dataWithValues[0].id ? 4 : 0}>
            <Cards.Opportunity
              title={item.total}
              subtitle={item.name}
              description={simulatedPoints.pointsAmount}
              key={item.id}
              isGoodsAndServices={isGoodsAndServices}
            />
          </Box>
        </TouchableOpacity>
      )
    },
    [dataWithValues, linkTo, simulatedPoints.pointsAmount, isGoodsAndServices]
  )

  const keyExtractor = useCallback((item: OpportunitiesSummary) => String(item.id), [])

  return (
    <VStack space={8}>
      <Box position="relative">
        <Animated.FlatList
          data={dataWithValues}
          keyExtractor={keyExtractor}
          showsHorizontalScrollIndicator={false}
          onScroll={scrollHandler}
          horizontal
          decelerationRate="normal"
          scrollEventThrottle={16}
          renderItem={renderItem}
        />

        {!dataWithValues.length && (
          <HStack>
            {[0, 1, 2, 3].map((item) => (
              <Skeleton key={item} h="117px" w="170px" borderRadius={20} ml={4} />
            ))}
          </HStack>
        )}
      </Box>

      <Box px={4} mt={-2}>
        <HStack alignItems="center" justifyContent="space-between">
          <HStack space={1}>
            {sliderDots.map((_, index) => (
              <AnimatedSlideDot
                index={index}
                key={Math.random()}
                scrollX={scrollX}
                slideWidth={SCREEN_WIDTH}
                color={theme.colors.primary[800]}
              />
            ))}
          </HStack>

          <Button
            bg="primary.100"
            size="md"
            p={0}
            _stack={{ flex: 1, justifyContent: 'center' }}
            onPress={handleGoToOpportunitiesList}
            w={20}
            _text={{
              color: 'primary.800',
              textTransform: 'uppercase'
            }}
            _pressed={{
              backgroundColor: 'primary.100'
            }}>
            ver tudo
          </Button>
        </HStack>
      </Box>
    </VStack>
  )
}
