import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import {
  EvaluationItem,
  KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY,
  OpportunitiesByBusinessResponse,
  OpportunitySerialized,
  useGetOpportunityListByBusinessInfiniteQuery,
  useSetOpportunityRatesMutation
} from 'integration/resources/opportunities'
import { useToast } from 'native-base'
import { Toast } from 'organisms'
import { useQueryClient } from 'react-query'

import { UseListOpportunities } from './types'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0,
  highlights: {
    total_opportunities: 0,
    items: []
  }
}

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetOpportunityListByBusinessInfiniteQuery>['data']
) =>
  data?.pages.reduce<OpportunitiesByBusinessResponse>(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...currentValue.data.data,
      items: [...previousValue.items, ...currentValue.data.data.items]
    }),
    defaultReturn
  )

export const useListOpportunities: UseListOpportunities = ({ search }) => {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchHook
  } = useGetOpportunityListByBusinessInfiniteQuery({
    per_page: 1000,
    group_by: 1,
    order_by: 'earning_potential',
    order_by_direction: 'desc',
    search
  })

  const onScrollEndDrag = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const list = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  if (list?.items.length) {
    list.items = list?.items.sort((opportunityPrevious, opportunityNext) => {
      return parseInt(opportunityPrevious.id, 10) - parseInt(opportunityNext.id, 10)
    })
  }

  const toast = useToast()

  const [refetchLoading, setRefetchLoading] = useState(false)

  const queryClient = useQueryClient()

  const refetch = useCallback(() => {
    setRefetchLoading(true)

    setTimeout(() => {
      setRefetchLoading(false)
    }, 400)

    queryClient.invalidateQueries(KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY)
  }, [queryClient])

  const evaluationSuccessSheetRef = useRef<BottomSheetModal>(null)

  const openEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.present()
  }, [])

  const closeEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.close()

    evaluationSuccessSheetRef.current?.dismiss()
  }, [])

  const [isOpenEvaluation, setIsOpenEvaluantion] = useState(false)

  const [opportunityEvaluation, setOpportunityEvaluation] = useState<OpportunitySerialized>()

  const setOpportunityRatesMutation = useSetOpportunityRatesMutation()

  const handleEvaluate = useCallback(
    (evaluantion: EvaluationItem) => {
      opportunityEvaluation?.id &&
        setOpportunityRatesMutation
          .mutateAsync(
            {
              ...evaluantion,
              opportunity_id: opportunityEvaluation.id
            },
            {
              onError: () => {
                toast.show({
                  render: () => (
                    <Toast type="error" text="Não foi possível concluir oportunidade" />
                  ),
                  duration: 3000
                })
              },
              onSuccess: () => {
                openEvaluationSuccessSheet()

                refetch()
              }
            }
          )
          .finally(() => setIsOpenEvaluantion(false))
    },
    [
      openEvaluationSuccessSheet,
      opportunityEvaluation?.id,
      refetch,
      setOpportunityRatesMutation,
      toast
    ]
  )

  useEffect(() => {
    refetchHook()
  }, [refetchHook, search])

  return {
    list,
    refetch,
    isLoading,
    refreshing: refetchLoading,
    isFetchingNextPage,
    onScrollEndDrag,
    closeEvaluationSuccessSheet,
    openEvaluationSuccessSheet,
    isOpenEvaluation,
    isLoadingEvaluantion: setOpportunityRatesMutation.isLoading,
    setIsOpenEvaluantion,
    evaluationSuccessSheetRef,
    handleEvaluate,
    setOpportunityEvaluation
  }
}
