import { FunctionComponent, memo, useEffect, useState } from 'react'

import { Avatar, Icons } from 'atoms'
import {
  Center,
  Heading,
  HStack,
  Text,
  useTheme,
  VStack,
  useBreakpointValue,
  Box,
  Button
} from 'native-base'
import { TouchableOpacity, ActivityIndicator, View } from 'react-native'

type OrganizationCardProps = {
  topText: string
  midText?: string
  bottomText?: string
  userName?: string
  nameFantasy?: string
  tabNumber?: string
  favorite?: boolean
  icon: 'Dollar' | 'Check'
  active?: boolean
  onPress?: () => void
  onCheckPress?: (fn: (a: boolean) => void, a: boolean) => void
  bgColor?: string
  organizationType?: string
  isDesktop?: boolean
  expandedDetail?: boolean
  cover?: string
  handleFavorite?: () => void
  disableHandleFavorite?: boolean
  isLoadingFavorite?: boolean
  points?: number
  attachments?: string
  planActions?: {
    is_finished: string
    total: string
  }
  hour?: string
}

export const OpportunityCard: FunctionComponent<OrganizationCardProps> = memo(
  ({
    topText,
    midText,
    bottomText,
    userName,
    nameFantasy,
    tabNumber,
    favorite,
    icon,
    hour,
    expandedDetail = false,
    active,
    cover,
    onPress,
    bgColor = 'white',
    onCheckPress,
    handleFavorite,
    isDesktop,
    isLoadingFavorite,
    disableHandleFavorite,
    points,
    organizationType,
    attachments,
    planActions
  }) => {
    const [checked, setChecked] = useState(false)

    const theme = useTheme()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
      active !== undefined && setChecked(active)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active])

    const IconComponent = Icons[icon]

    const iconColor = icon === 'Check' ? 'tertiary.500' : 'secondary.500'

    const isMobile = useBreakpointValue({ base: true, lg: false })

    return (
      <>
        {isMobile || !expandedDetail ? (
          <VStack bgColor={bgColor} rounded={20} overflow="hidden" p={4} mt="16px">
            <HStack alignItems="center" w="full">
              <TouchableOpacity
                onPress={() => {
                  if (!onCheckPress) {
                    return
                  }

                  setIsLoading(true)

                  onCheckPress?.((value: boolean) => {
                    setChecked(value)

                    setIsLoading(false)
                  }, active ?? false)
                }}>
                <Center h={12} w={12}>
                  <View
                    style={[
                      {
                        backgroundColor: checked ? theme.colors.primary[50] : theme.colors.white,
                        borderColor: checked
                          ? theme.colors.primary[50]
                          : theme.colors.background.base,
                        borderWidth: 1,
                        borderRadius: 30,
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 48,
                        width: 48,
                        zIndex: 1,
                        top: 0,
                        left: 0,
                        opacity: 1
                      }
                    ]}>
                    {isLoading ? (
                      <ActivityIndicator />
                    ) : (
                      <IconComponent color={checked ? iconColor : 'gray.700'} />
                    )}
                  </View>
                </Center>
              </TouchableOpacity>

              <VStack flex={1}>
                <TouchableOpacity onPress={onPress}>
                  <Heading
                    fontSize="14px"
                    lineHeight="18px"
                    fontWeight="bold"
                    color="gray.700"
                    noOfLines={1}
                    mb={{ base: 1, lg: 2 }}>
                    {topText}
                  </Heading>

                  {icon === 'Check' && !isDesktop ? (
                    <>
                      {(!!midText || !!bottomText) && (
                        <HStack alignItems="center" mb={{ base: 1, lg: 2 }}>
                          <Text
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight="normal"
                            color="gray.400"
                            noOfLines={1}>
                            {midText} •{' '}
                          </Text>

                          <Text
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight="normal"
                            color="gray.400"
                            noOfLines={1}>
                            {bottomText}
                          </Text>
                        </HStack>
                      )}

                      {!!userName && (
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color="gray.400"
                          mb={{ base: 1, lg: 2 }}
                          noOfLines={1}
                          textTransform="capitalize"
                          isTruncated
                          maxW="60%">
                          {userName}
                        </Text>
                      )}
                    </>
                  ) : (
                    <>
                      {(!!midText || !!bottomText) && (
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color="gray.400"
                          mb={{ base: 1, lg: 2 }}
                          noOfLines={1}>
                          {midText}
                        </Text>
                      )}

                      <HStack alignItems="center" mb={{ base: 1, lg: 2 }}>
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color="gray.400"
                          noOfLines={1}>
                          {bottomText}
                        </Text>

                        {!!userName && (
                          <>
                            <Text
                              fontSize="12px"
                              lineHeight="16px"
                              fontWeight="normal"
                              color="gray.400"
                              noOfLines={1}
                              textTransform="capitalize"
                              isTruncated
                              maxW="60%">
                              {' '}
                              • {userName}
                            </Text>
                          </>
                        )}
                      </HStack>
                    </>
                  )}
                </TouchableOpacity>
              </VStack>
            </HStack>
          </VStack>
        ) : (
          <VStack bgColor={bgColor} rounded={20} overflow="hidden" p={4} mt="16px">
            <HStack space={4} alignItems="center" w="full">
              <TouchableOpacity
                onPress={() => {
                  if (!onCheckPress) {
                    return
                  }

                  setIsLoading(true)

                  onCheckPress?.((value: boolean) => {
                    setChecked(value)

                    setIsLoading(false)
                  }, active ?? false)
                }}>
                <Center h={12} w={12}>
                  <View
                    style={[
                      {
                        backgroundColor: checked ? theme.colors.primary[50] : theme.colors.white,
                        borderColor: checked
                          ? theme.colors.primary[50]
                          : theme.colors.background.base,
                        borderWidth: 1,
                        borderRadius: 30,
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 48,
                        width: 48,
                        zIndex: 1,
                        top: 0,
                        left: 0,
                        opacity: 1
                      }
                    ]}>
                    {isLoading ? (
                      <ActivityIndicator />
                    ) : (
                      <IconComponent color={checked ? iconColor : 'gray.700'} />
                    )}
                  </View>
                </Center>
              </TouchableOpacity>

              <VStack flex={1}>
                <TouchableOpacity onPress={onPress}>
                  <Box flexDir="row" justifyContent="space-between" alignItems="center">
                    {/* This section is temporarily disabled
                    {!!hour && (
                      <Box>
                        <Text
                          fontSize="18px"
                          lineHeight="24px"
                          mr={6}
                          fontWeight="normal"
                          color="gray.400">
                          {hour} h
                        </Text>
                      </Box>
                    )} */}

                    <Heading
                      fontSize="16px"
                      maxW={{
                        base: 130,
                        xxl: 340
                      }}
                      minW={{
                        base: 130,
                        xxl: 340
                      }}
                      numberOfLines={2}
                      lineHeight="22px"
                      fontWeight={400}
                      mr="20px"
                      color="gray.700">
                      {topText}
                    </Heading>

                    <>
                      <Box flex={1} flexDir="row" alignItems="center" mr="20px">
                        <Box
                          w="40px"
                          h="40px"
                          mr="16px"
                          flexDir="row"
                          alignItems="center"
                          justifyContent="center"
                          borderWidth={1}
                          borderColor="#ECEDF2"
                          borderRadius={100}
                          overflow="hidden"
                          display={{
                            base: 'none',
                            xl: 'flex'
                          }}>
                          <Avatar
                            uri={cover}
                            size="sm"
                            title={nameFantasy ?? 'minha atividade'}
                            type="light"
                            variant="white"
                          />
                        </Box>
                        <Box>
                          <Text
                            fontSize={{
                              base: '13px',
                              xl: '16px'
                            }}
                            lineHeight="22px"
                            fontWeight={400}
                            color="gray.400"
                            textTransform="capitalize">
                            {nameFantasy ?? 'minha atividade'}
                          </Text>
                          {!!tabNumber && (
                            <Text
                              fontSize="14px"
                              lineHeight="20px"
                              fontWeight={600}
                              color="gray.400">
                              TAB {tabNumber}
                            </Text>
                          )}
                        </Box>
                      </Box>
                      {!!bottomText && !organizationType && (
                        <Box flex={1}>
                          <Text fontSize="16px" fontWeight={400} lineHeight="22px" color="gray.400">
                            Potencial
                          </Text>
                          <Text
                            fontSize="14px"
                            lineHeight="20px"
                            fontWeight="normal"
                            color="gray.400">
                            {bottomText}
                          </Text>
                        </Box>
                      )}
                      <Box flexDir="row" alignItems="center" flex={1} justifyContent="flex-end">
                        <Center
                          h={6}
                          py={1}
                          px={4}
                          mr="16px"
                          borderWidth={1}
                          borderColor="primary.800"
                          backgroundColor="primary.800"
                          display={{
                            base: 'none',
                            xl: 'flex'
                          }}
                          borderRadius="full">
                          <Heading fontSize="12px" color="white" textTransform="uppercase">
                            {organizationType ?? `Oportunidade`}
                          </Heading>
                        </Center>
                        {!!points && (
                          <Center
                            h={6}
                            py={1}
                            px={4}
                            borderWidth={1}
                            mr="16px"
                            borderColor="gray.700"
                            borderRadius="full">
                            <Heading fontSize="12px" color="gray.700" textTransform="uppercase">
                              {points} PTS
                            </Heading>
                          </Center>
                        )}

                        {!!handleFavorite && (
                          <Button
                            isDisabled={disableHandleFavorite}
                            onPress={handleFavorite}
                            h={10}
                            w={10}
                            borderRadius="full"
                            isLoading={isLoadingFavorite}
                            colorScheme="dark"
                            _spinner={{
                              color: 'gray.700'
                            }}
                            _loading={{
                              bgColor: 'gray.70'
                            }}
                            bgColor="gray.30">
                            <Center>
                              <Icons.Star
                                size={4}
                                filled={favorite}
                                color={favorite ? 'tabs-yellow.50' : 'gray.200'}
                              />
                            </Center>
                          </Button>
                        )}
                      </Box>
                    </>
                  </Box>
                </TouchableOpacity>
              </VStack>
            </HStack>
          </VStack>
        )}
      </>
    )
  }
)
