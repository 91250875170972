import { FunctionComponent } from 'react'

import { TabPanels } from 'organisms'

import { TabCommonProps } from './types'

export const Panels: FunctionComponent<TabCommonProps> = ({
  tabNumber,
  handleGoToFullChartScreen
}) => {
  if (!tabNumber || !handleGoToFullChartScreen) return null

  return <TabPanels tabNumber={tabNumber} handleGoToFullChartScreen={handleGoToFullChartScreen} />
}
