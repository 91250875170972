import { useMemo } from 'react'

import { useGetChargeQuery } from 'integration/resources/walletResume'
import {
  getShortMonthAndYear,
  isObjectVazio,
  verifyHasData,
  verifyHasDataOnArrays
} from 'src/utils'

import { UseHomeCharge } from './types'

export const useHomeCharge: UseHomeCharge = () => {
  const { data, isLoading: chargeDataIsLoading } = useGetChargeQuery({})

  const chargeData = useMemo(() => data?.data.data, [data])

  const walletTooltipsValues = chargeData?.screen_data.wallet_situation.months.map((item) => ({
    inDay: item.in_day,
    overdue: item.overdue
  }))

  const overdueData = useMemo(() => {
    const tooltipsValues: { [key: string]: number }[] = []

    chargeData?.screen_data.overdue.months.map((item) => {
      const objt: { [key: string]: number } = {}

      item.ranges.length &&
        item.ranges.forEach((subitem) => {
          objt[subitem.label] = subitem.value
        })

      if (!isObjectVazio(objt)) tooltipsValues.push(objt)
    })

    return {
      chartData: chargeData?.screen_data.overdue_serialized,
      titles: chargeData?.screen_data.range_names,
      tickValues: chargeData?.screen_data.overdue.months.map((item: { year_month: number }) =>
        getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: chargeData?.screen_data.overdue.processed_at,
      hasData: verifyHasDataOnArrays(chargeData?.screen_data.overdue_serialized ?? [[]]),
      tooltipsValues
    }
  }, [chargeData])

  const walletData = useMemo(() => {
    const in_day = chargeData?.screen_data.wallet_situation_serialized.in_day.length
      ? chargeData?.screen_data.wallet_situation_serialized.in_day.length > 6
        ? chargeData?.screen_data.wallet_situation_serialized.in_day.slice(-6)
        : chargeData?.screen_data.wallet_situation_serialized.in_day
      : []

    const overdue = chargeData?.screen_data.wallet_situation_serialized.overdue.length
      ? chargeData?.screen_data.wallet_situation_serialized.overdue.length > 6
        ? chargeData?.screen_data.wallet_situation_serialized.overdue.slice(-6)
        : chargeData?.screen_data.wallet_situation_serialized.overdue
      : []

    return {
      in_day,
      overdue,
      tickValues: chargeData?.screen_data.wallet_situation.months.map(
        (item: { year_month: number }) => getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: chargeData?.screen_data.wallet_situation.processed_at,
      hasData: verifyHasData('value', [...(in_day ?? []), ...(overdue ?? [])]),
      tooltipsValues: walletTooltipsValues
    }
  }, [chargeData, walletTooltipsValues])

  return {
    overdueData,
    walletData,
    chargeDataIsLoading
  }
}
