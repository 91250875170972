import { FunctionComponent, useMemo } from 'react'

import { Box, useBreakpointValue, useTheme } from 'native-base'
import { Dimensions, Platform } from 'react-native'
import { getNumberYearAndMonth, percentageCalculate } from 'src/utils'
import { currencyFormatChart } from 'src/utils/currencyFormat'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryTheme } from 'victory-native'

export type ChartData = {
  value: number
  year_month: string
}[]

export type SingleBarChartProps = {
  chartData: ChartData
  color?: string
  onClickChart?({ monthYear, total }: { monthYear: string; total: string }): void
  type?: string
}

export const SingleBarChart: FunctionComponent<SingleBarChartProps> = ({
  chartData,
  color,
  onClickChart,
  type
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const higherValue = useMemo(() => {
    return chartData.reduce((a, b) => (a.value > b.value ? a : b)).value
  }, [chartData])

  const lowerValue = useMemo(() => {
    return chartData.reduce((a, b) => (a.value < b.value ? a : b)).value
  }, [chartData])

  const theme = useTheme()

  // we use these constants to remedy the limitations of the charts library in relation to responsiveness
  const isIos = Platform.OS === 'ios'

  const phoneHeight = Dimensions.get('window').height

  const phoneWidth = Dimensions.get('window').width

  const lowWidthPhone = phoneWidth < 700

  const needsLabel = {
    yes: true,
    no: false
  }

  const tickValues = useMemo(
    () =>
      chartData
        .map((item) => item.year_month)
        .flat()
        .filter((item, index, total) => item !== total[index - 1]),
    [chartData]
  )

  const tickValuesAxis =
    type === 'percentage'
      ? [0, 25, 50, 75, 100]
      : type === 'currency'
      ? lowerValue < 0
        ? [lowerValue, lowerValue * 0.5, 0, higherValue * 0.5, higherValue]
        : [0, higherValue * 0.25, higherValue * 0.5, higherValue * 0.75, higherValue]
      : [0, higherValue]

  return (
    <Box mt={isMobile ? -4 : 0} zIndex={1} ml={isMobile ? -4 : 0}>
      <VictoryChart
        theme={VictoryTheme.material}
        width={isIos ? (lowWidthPhone ? phoneWidth * 1.03 : phoneWidth * 0.95) : phoneWidth * 1.02}
        height={
          isIos ? (lowWidthPhone ? phoneHeight * 0.84 : phoneHeight * 0.77) : phoneHeight * 0.77
        }
        domainPadding={{ x: isMobile ? 25 : 50, y: 25 }}
        domain={{ x: [1, 13] }}>
        <VictoryAxis
          tickValues={tickValues}
          tickFormat={(t) => String(t).split('-')[0]}
          orientation="bottom"
          style={{
            tickLabels: {
              fontSize: isMobile ? 14 : 18,
              padding: isMobile
                ? higherValue <= 0
                  ? -250
                  : lowerValue >= 0
                  ? 10
                  : (higherValue / lowerValue) * -1 > 1
                  ? -240
                  : -120
                : 10,
              fill: theme.colors.gray[500]
            },
            ticks: { size: 0 },
            axis: { stroke: 0 },
            grid: { stroke: 0 }
          }}
          standalone={false}
        />

        <VictoryAxis
          dependentAxis
          tickValues={tickValuesAxis}
          tickFormat={(t) =>
            t && type === 'currency'
              ? t > 0
                ? currencyFormatChart(String(t), needsLabel.yes, needsLabel.no, 0)
                : `-${currencyFormatChart(String(t * -1), needsLabel.yes, needsLabel.no, 0)}`
              : type === 'percentage'
              ? `${parseInt(t, 10)}%`
              : parseInt(t, 10)
          }
          style={{
            tickLabels: {
              fontSize: isMobile ? 10 : 14,
              fill: theme.colors.gray[400],
              fontWeight: 600
            },
            ticks: { size: 0 },
            axis: { stroke: 0 },
            grid: {
              stroke: theme.colors.gray[100],
              strokeWidth: 1
            }
          }}
          standalone={false}
        />
        <VictoryBar
          data={chartData}
          x="year_month"
          y="value"
          barWidth={isMobile ? 28 : 48}
          labelComponent={
            <VictoryLabel dy={({ datum: { value } }) => (value > 0 ? -5 : value < 0 ? 5 : -10)} />
          }
          cornerRadius={{
            top: !isMobile
              ? 20
              : ({ datum }) => {
                  const percentage = percentageCalculate(higherValue ?? 0, datum.value ?? 0)

                  if (percentage > 15) return 14

                  if (percentage > 13) return 12

                  if (percentage > 8) return 8

                  if (percentage > 5) return 6

                  if (percentage > 3) return 4

                  return 2
                },
            bottom: !isMobile
              ? 20
              : ({ datum }) => {
                  const percentage = percentageCalculate(higherValue ?? 0, datum.value ?? 0)

                  if (percentage > 15) return 14

                  if (percentage > 13) return 12

                  if (percentage > 8) return 8

                  if (percentage > 5) return 6

                  if (percentage > 3) return 4

                  return 2
                }
          }}
          style={{
            data: { fill: color ? color : theme.colors.primary[500] },
            //@ts-ignore

            labels: {
              fontSize: isMobile ? 14 : 18,
              fontWeight: 700,
              //@ts-ignore
              fill: theme.colors.gray[600]
            }
          }}
          labels={({ datum: { value } }) =>
            value && type === 'currency'
              ? value > 0
                ? currencyFormatChart(String(value), needsLabel.yes, needsLabel.no, 2)
                : `-${currencyFormatChart(String(value * -1), needsLabel.yes, needsLabel.no, 2)}`
              : type === 'percentage'
              ? `${value.toFixed(2)}%`
              : value
          }
          events={[
            {
              target: 'data',
              eventHandlers: {
                onPressOut: () => [
                  {
                    target: 'data',

                    mutation: (props) =>
                      onClickChart?.({
                        monthYear: getNumberYearAndMonth(props.datum.year_month).monthNumber,
                        total: props.datum.value
                      })
                  }
                ],
                onClick: () => [
                  {
                    target: 'data',

                    mutation: (props) =>
                      onClickChart?.({
                        monthYear: getNumberYearAndMonth(props.datum.year_month).monthNumber,
                        total: props.datum.value
                      })
                  }
                ]
              }
            }
          ]}
        />
      </VictoryChart>
    </Box>
  )
}
