import Svg, { Path, SvgProps } from 'react-native-svg'

export const LogoTabBar = (props: SvgProps) => {
  return (
    <Svg width={109} height={43} viewBox="0 0 109 43" fill="none">
      <Path
        d="M38.5728 16.8288C38.5728 17.6043 38.7942 18.1536 39.237 18.4767C39.6798 18.7998 40.3018 18.9614 41.103 18.9614C41.4614 18.9614 41.8409 18.9075 42.2415 18.7998C42.6421 18.6921 42.99 18.5629 43.2852 18.4121C43.4328 18.5844 43.5593 18.7783 43.6647 18.9937C43.7913 19.2091 43.8545 19.4676 43.8545 19.7692C43.8545 20.3292 43.5699 20.7924 43.0006 21.1586C42.4524 21.5248 41.609 21.7079 40.4704 21.7079C38.8891 21.7079 37.624 21.3524 36.6752 20.6416C35.7475 19.9092 35.2836 18.7136 35.2836 17.055V1.96532C35.4312 1.92224 35.6421 1.86839 35.9162 1.80376C36.1903 1.73914 36.4855 1.70683 36.8017 1.70683C37.4132 1.70683 37.8559 1.8253 38.13 2.06226C38.4252 2.29921 38.5728 2.69773 38.5728 3.2578V6.45667H43.475C43.5593 6.58592 43.6331 6.76902 43.6964 7.00597C43.7807 7.24293 43.8229 7.49065 43.8229 7.74915C43.8229 8.67542 43.4328 9.13856 42.6527 9.13856H38.5728V16.8288Z"
        fill="#F3123C"
      />
      <Path
        d="M47.1121 6.16587C47.2597 6.12278 47.4705 6.0797 47.7446 6.03662C48.0187 5.97199 48.3139 5.93968 48.6302 5.93968C49.2416 5.93968 49.6844 6.05816 49.9585 6.29511C50.2537 6.53207 50.4013 6.94135 50.4013 7.52296V15.504C50.4013 16.7534 50.7175 17.6474 51.3501 18.1859C52.0037 18.7029 52.8998 18.9614 54.0384 18.9614C54.7763 18.9614 55.3983 18.886 55.9043 18.7352C56.4104 18.5844 56.811 18.4228 57.1062 18.2505V6.16587C57.2748 6.12278 57.4857 6.0797 57.7387 6.03662C58.0128 5.97199 58.308 5.93968 58.6243 5.93968C59.2357 5.93968 59.689 6.05816 59.9842 6.29511C60.2794 6.53207 60.427 6.94135 60.427 7.52296V18.4121C60.427 18.886 60.3532 19.2737 60.2056 19.5753C60.058 19.8769 59.7628 20.1677 59.32 20.4477C58.7718 20.7708 58.0444 21.0616 57.1378 21.3201C56.2522 21.5786 55.2086 21.7079 54.0067 21.7079C51.835 21.7079 50.1377 21.2232 48.9148 20.2538C47.713 19.2629 47.1121 17.7012 47.1121 15.5686V6.16587Z"
        fill="#F3123C"
      />
      <Path
        d="M68.0678 21.1909C67.9202 21.2555 67.7094 21.3094 67.4353 21.3524C67.1823 21.4171 66.8976 21.4494 66.5814 21.4494C65.9699 21.4494 65.5166 21.3309 65.2214 21.094C64.9262 20.857 64.7786 20.4585 64.7786 19.8984V9.30012C64.7786 8.76159 64.8841 8.33076 65.0949 8.00764C65.3058 7.66298 65.6431 7.33986 66.107 7.03829C66.7184 6.65054 67.4986 6.32743 68.4474 6.06893C69.3962 5.81044 70.4399 5.68119 71.5784 5.68119C73.4339 5.68119 74.3616 6.21972 74.3616 7.29678C74.3616 7.55528 74.3194 7.803 74.2351 8.03996C74.1507 8.25537 74.0559 8.44924 73.9504 8.62157C73.7396 8.57849 73.476 8.5354 73.1598 8.49232C72.8435 8.44924 72.5061 8.4277 72.1477 8.4277C71.3043 8.4277 70.5347 8.52463 69.8389 8.7185C69.1432 8.89083 68.5528 9.11702 68.0678 9.39705V21.1909Z"
        fill="#F3123C"
      />
      <Path
        d="M84.1548 5.68119C85.1458 5.68119 86.0735 5.85352 86.938 6.19818C87.8024 6.5213 88.5509 7.01675 89.1835 7.68452C89.816 8.33076 90.3115 9.1601 90.6699 10.1725C91.0284 11.1634 91.2076 12.3267 91.2076 13.6622C91.2076 15.0193 91.0178 16.2041 90.6383 17.2165C90.2799 18.229 89.7633 19.0691 89.0886 19.7369C88.4139 20.3831 87.6021 20.8785 86.6533 21.2232C85.7256 21.5463 84.6925 21.7079 83.5539 21.7079C82.4153 21.7079 81.4033 21.5679 80.5177 21.2878C79.6532 21.0078 78.9469 20.6847 78.3987 20.3185C77.9559 20.0169 77.6397 19.7153 77.4499 19.4137C77.2601 19.1122 77.1653 18.7244 77.1653 18.2505V0.285105C77.3128 0.242023 77.5237 0.18817 77.7978 0.123546C78.0719 0.0589228 78.3671 0.0266113 78.6833 0.0266113C79.2948 0.0266113 79.7376 0.145088 80.0117 0.382041C80.3069 0.618994 80.4545 1.01751 80.4545 1.57758V6.68286C80.9183 6.42436 81.456 6.19818 82.0674 6.00431C82.6789 5.78889 83.3747 5.68119 84.1548 5.68119ZM83.6171 8.39538C82.9424 8.39538 82.3415 8.51386 81.8144 8.75082C81.2873 8.96623 80.834 9.22472 80.4545 9.5263V18.1213C80.7496 18.3367 81.1608 18.5305 81.6879 18.7029C82.215 18.8752 82.8265 18.9614 83.5223 18.9614C84.8084 18.9614 85.8521 18.5305 86.6533 17.6689C87.4545 16.8072 87.8551 15.4717 87.8551 13.6622C87.8551 11.8312 87.4545 10.4957 86.6533 9.65555C85.8732 8.81544 84.8611 8.39538 83.6171 8.39538Z"
        fill="#F3123C"
      />
      <Path
        d="M109 13.6945C109 14.9224 108.821 16.0318 108.462 17.0227C108.104 18.0136 107.598 18.8537 106.944 19.543C106.312 20.2323 105.532 20.7708 104.604 21.1586C103.676 21.5248 102.643 21.7079 101.504 21.7079C100.366 21.7079 99.3326 21.5248 98.4049 21.1586C97.4772 20.7708 96.6865 20.2323 96.0329 19.543C95.4004 18.8537 94.9049 18.0136 94.5464 17.0227C94.188 16.0318 94.0088 14.9224 94.0088 13.6945C94.0088 12.4667 94.188 11.3573 94.5464 10.3664C94.9049 9.37551 95.4109 8.5354 96.0645 7.84608C96.7182 7.15676 97.5088 6.629 98.4365 6.2628C99.3643 5.87506 100.387 5.68119 101.504 5.68119C102.622 5.68119 103.644 5.87506 104.572 6.2628C105.5 6.629 106.291 7.16753 106.944 7.87839C107.598 8.56771 108.104 9.40782 108.462 10.3987C108.821 11.3681 109 12.4667 109 13.6945ZM101.504 8.39538C100.239 8.39538 99.2378 8.85852 98.4998 9.7848C97.7618 10.7111 97.3929 12.0143 97.3929 13.6945C97.3929 15.3748 97.7513 16.678 98.4682 17.6043C99.185 18.509 100.197 18.9614 101.504 18.9614C102.812 18.9614 103.824 18.509 104.541 17.6043C105.257 16.678 105.616 15.3748 105.616 13.6945C105.616 12.0359 105.247 10.7434 104.509 9.81711C103.792 8.86929 102.791 8.39538 101.504 8.39538Z"
        fill="#F3123C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8835 10.7022C18.8442 9.86239 18.5915 9.02925 18.1239 8.27616L13.9987 1.63289C13.6842 1.12616 13.4669 0.583396 13.3461 0.0266113L13.1739 0.30427C12.1527 1.94854 12.1527 3.97432 13.1737 5.61859L16.4738 10.9332C17.4949 12.5777 17.4949 14.6036 16.4738 16.2479L16.3014 16.5255C16.1808 15.9688 15.9634 15.426 15.6488 14.9194L10.6987 6.94738C10.3842 6.44081 10.1668 5.89788 10.0462 5.34126L9.8738 5.61892C8.85625 7.25764 8.85309 9.27527 9.86363 10.9166L9.86346 10.9168L13.1739 16.2479C14.1948 17.8922 14.1948 19.9181 13.1739 21.5625L13.0014 21.8402C12.8807 21.2834 12.6635 20.7406 12.3489 20.2339L8.22376 13.5906C7.67053 12.6996 7.41767 11.6966 7.46396 10.703C3.04812 11.7757 0 14.0535 0 16.6909C0 20.3598 5.89826 23.334 13.1739 23.334C20.4494 23.334 26.3474 20.3598 26.3474 16.6909C26.3474 14.0534 23.2997 11.775 18.8835 10.7022Z"
        fill="#F3123C"
      />
      <Path
        d="M3.22295 36.2499C2.8974 36.2499 2.60596 36.3062 2.34862 36.4188C2.09438 36.5315 1.87889 36.6944 1.70216 36.9078C1.52544 37.1182 1.39057 37.3745 1.29755 37.6768C1.20454 37.9761 1.15803 38.314 1.15803 38.6903C1.15803 39.1941 1.23244 39.6282 1.38126 39.9927C1.53009 40.3543 1.75642 40.6328 2.06027 40.8284C2.36412 41.021 2.74858 41.1174 3.21365 41.1174C3.502 41.1174 3.78259 41.0907 4.05543 41.0373C4.32827 40.984 4.61042 40.9114 4.90186 40.8195V41.7263C4.62592 41.8301 4.34688 41.9056 4.06473 41.953C3.78259 42.0005 3.45704 42.0242 3.08808 42.0242C2.39357 42.0242 1.81688 41.8864 1.35801 41.6108C0.90224 41.3352 0.561187 40.947 0.334852 40.4461C0.111617 39.9453 0 39.3586 0 38.6859C0 38.1939 0.0713111 37.7435 0.213933 37.3345C0.356555 36.9256 0.564288 36.5729 0.83713 36.2766C1.10997 35.9773 1.44638 35.7476 1.84634 35.5876C2.2494 35.4246 2.70982 35.3431 3.2276 35.3431C3.56866 35.3431 3.90351 35.3802 4.23216 35.4542C4.56391 35.5254 4.87086 35.6276 5.153 35.761L4.76234 36.6411C4.5267 36.5344 4.28022 36.4426 4.02288 36.3655C3.76554 36.2885 3.49889 36.2499 3.22295 36.2499Z"
        fill="#2E2E2E"
      />
      <Path
        d="M17.1612 38.677C17.1612 39.1778 17.0945 39.6342 16.9612 40.0461C16.831 40.455 16.6341 40.8077 16.3705 41.104C16.107 41.3974 15.7768 41.6241 15.3799 41.7841C14.9831 41.9442 14.518 42.0242 13.9847 42.0242C13.4421 42.0242 12.9709 41.9442 12.5709 41.7841C12.174 41.6241 11.8438 41.3959 11.5803 41.0996C11.3199 40.8032 11.1245 40.4491 10.9943 40.0372C10.8641 39.6253 10.799 39.1689 10.799 38.6681C10.799 37.9984 10.9137 37.4146 11.1431 36.9167C11.3757 36.4159 11.7276 36.0277 12.1988 35.7521C12.6732 35.4735 13.2716 35.3342 13.994 35.3342C14.7009 35.3342 15.2885 35.472 15.7567 35.7476C16.2248 36.0232 16.5752 36.4114 16.8077 36.9123C17.0434 37.4101 17.1612 37.9984 17.1612 38.677ZM11.971 38.677C11.971 39.1778 12.0423 39.6119 12.1849 39.9794C12.3275 40.3439 12.5461 40.6254 12.8406 40.824C13.1383 41.0196 13.5197 41.1174 13.9847 41.1174C14.4529 41.1174 14.8343 41.0196 15.1288 40.824C15.4234 40.6254 15.6404 40.3439 15.7799 39.9794C15.9194 39.6119 15.9892 39.1778 15.9892 38.677C15.9892 37.9154 15.8295 37.3197 15.5102 36.89C15.1939 36.4574 14.6885 36.241 13.994 36.241C13.529 36.241 13.146 36.3388 12.8453 36.5344C12.5477 36.73 12.3275 37.01 12.1849 37.3745C12.0423 37.7361 11.971 38.1702 11.971 38.677Z"
        fill="#2E2E2E"
      />
      <Path
        d="M29.0577 41.9353H27.6858L24.3558 36.7478H24.314C24.3264 36.9226 24.3372 37.1078 24.3465 37.3034C24.3589 37.499 24.3682 37.7005 24.3744 37.908C24.3837 38.1154 24.3915 38.3258 24.3977 38.5392V41.9353H23.3838V35.4365H24.7465L28.0718 40.5884H28.1043C28.0981 40.4373 28.0904 40.2639 28.0811 40.0683C28.0718 39.8727 28.0625 39.6712 28.0532 39.4638C28.047 39.2534 28.0423 39.0504 28.0392 38.8548V35.4365H29.0577V41.9353Z"
        fill="#2E2E2E"
      />
      <Path
        d="M39.5312 40.1661C39.5312 40.5513 39.4319 40.8833 39.2335 41.1618C39.0382 41.4404 38.756 41.6537 38.3871 41.8019C38.0212 41.9501 37.584 42.0242 37.0756 42.0242C36.8275 42.0242 36.5903 42.0108 36.364 41.9842C36.1377 41.9575 35.9237 41.919 35.7222 41.8686C35.5238 41.8152 35.3424 41.7515 35.1781 41.6774V40.6728C35.454 40.7884 35.7625 40.8936 36.1036 40.9885C36.4446 41.0803 36.7919 41.1263 37.1453 41.1263C37.4337 41.1263 37.6724 41.0907 37.8615 41.0196C38.0538 40.9455 38.1964 40.8418 38.2894 40.7084C38.3824 40.5721 38.4289 40.4135 38.4289 40.2328C38.4289 40.0402 38.3747 39.8772 38.2662 39.7438C38.1576 39.6105 37.9933 39.489 37.7732 39.3793C37.5561 39.2667 37.2833 39.1467 36.9546 39.0193C36.7314 38.9333 36.5175 38.8355 36.3129 38.7259C36.1113 38.6162 35.9315 38.4873 35.7734 38.3391C35.6152 38.191 35.4897 38.0161 35.3967 37.8146C35.3067 37.6101 35.2618 37.3701 35.2618 37.0945C35.2618 36.727 35.3533 36.4129 35.5362 36.1521C35.7222 35.8914 35.9826 35.6913 36.3175 35.552C36.6555 35.4128 37.0492 35.3431 37.4988 35.3431C37.8553 35.3431 38.1902 35.3787 38.5033 35.4498C38.8196 35.5209 39.1296 35.6217 39.4335 35.7521L39.08 36.61C38.801 36.5003 38.5297 36.4129 38.2662 36.3477C38.0057 36.2825 37.7391 36.2499 37.4662 36.2499C37.2306 36.2499 37.0306 36.284 36.8663 36.3522C36.702 36.4203 36.5764 36.5166 36.4896 36.6411C36.4059 36.7626 36.364 36.9078 36.364 37.0767C36.364 37.2664 36.4121 37.4264 36.5082 37.5568C36.6074 37.6842 36.7593 37.8013 36.964 37.908C37.1717 38.0147 37.4383 38.1332 37.7639 38.2636C38.139 38.4118 38.4568 38.5673 38.7173 38.7303C38.9808 38.8933 39.1823 39.0889 39.3219 39.3171C39.4614 39.5423 39.5312 39.8253 39.5312 40.1661Z"
        fill="#2E2E2E"
      />
      <Path
        d="M50.944 35.4365V39.6416C50.944 40.095 50.8433 40.501 50.6417 40.8595C50.4433 41.2181 50.1426 41.5026 49.7395 41.713C49.3364 41.9205 48.8295 42.0242 48.2187 42.0242C47.3475 42.0242 46.684 41.8064 46.2282 41.3707C45.7755 40.9321 45.5492 40.3498 45.5492 39.6238V35.4365H46.6654V39.5304C46.6654 40.0728 46.7987 40.4728 47.0653 40.7306C47.332 40.9885 47.7304 41.1174 48.2606 41.1174C48.6264 41.1174 48.9241 41.0566 49.1535 40.9351C49.386 40.8106 49.5566 40.6299 49.6651 40.3928C49.7767 40.1528 49.8325 39.8638 49.8325 39.526V35.4365H50.944Z"
        fill="#2E2E2E"
      />
      <Path d="M57.4457 41.9353V35.4365H58.5619V41.0285H61.45V41.9353H57.4457Z" fill="#2E2E2E" />
      <Path
        d="M69.7469 41.9353H68.6261V36.3433H66.6356V35.4365H71.7328V36.3433H69.7469V41.9353Z"
        fill="#2E2E2E"
      />
      <Path
        d="M83.5735 38.677C83.5735 39.1778 83.5068 39.6342 83.3735 40.0461C83.2433 40.455 83.0464 40.8077 82.7829 41.104C82.5193 41.3974 82.1891 41.6241 81.7923 41.7841C81.3954 41.9442 80.9303 42.0242 80.3971 42.0242C79.8545 42.0242 79.3832 41.9442 78.9832 41.7841C78.5864 41.6241 78.2562 41.3959 77.9926 41.0996C77.7322 40.8032 77.5369 40.4491 77.4066 40.0372C77.2764 39.6253 77.2113 39.1689 77.2113 38.6681C77.2113 37.9984 77.326 37.4146 77.5555 36.9167C77.788 36.4159 78.1399 36.0277 78.6112 35.7521C79.0856 35.4735 79.684 35.3342 80.4064 35.3342C81.1133 35.3342 81.7008 35.472 82.169 35.7476C82.6372 36.0232 82.9875 36.4114 83.22 36.9123C83.4557 37.4101 83.5735 37.9984 83.5735 38.677ZM78.3833 38.677C78.3833 39.1778 78.4546 39.6119 78.5972 39.9794C78.7399 40.3439 78.9584 40.6254 79.253 40.824C79.5506 41.0196 79.932 41.1174 80.3971 41.1174C80.8652 41.1174 81.2466 41.0196 81.5411 40.824C81.8357 40.6254 82.0527 40.3439 82.1922 39.9794C82.3318 39.6119 82.4015 39.1778 82.4015 38.677C82.4015 37.9154 82.2418 37.3197 81.9225 36.89C81.6062 36.4574 81.1009 36.241 80.4064 36.241C79.9413 36.241 79.5584 36.3388 79.2576 36.5344C78.96 36.73 78.7399 37.01 78.5972 37.3745C78.4546 37.7361 78.3833 38.1702 78.3833 38.677Z"
        fill="#2E2E2E"
      />
      <Path
        d="M91.7076 35.4365C92.2936 35.4365 92.7773 35.5046 93.1586 35.6409C93.5431 35.7773 93.8284 35.9847 94.0144 36.2633C94.2035 36.5418 94.2981 36.896 94.2981 37.3257C94.2981 37.6457 94.2361 37.9183 94.112 38.1436C93.988 38.3688 93.8252 38.5555 93.6237 38.7036C93.4222 38.8518 93.2067 38.9704 92.9773 39.0593L94.9073 41.9353H93.647L92.0053 39.3304H90.9124V41.9353H89.7962V35.4365H91.7076ZM91.6332 36.3255H90.9124V38.4503H91.6844C92.2021 38.4503 92.5773 38.3584 92.8098 38.1747C93.0455 37.9909 93.1633 37.7198 93.1633 37.3612C93.1633 36.9849 93.0377 36.7182 92.7866 36.5611C92.5386 36.404 92.1541 36.3255 91.6332 36.3255Z"
        fill="#2E2E2E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.681 35.9949C105.681 35.6299 105.371 35.334 104.989 35.334C104.607 35.334 104.298 35.6299 104.298 35.9949V38.5061H101.671C101.289 38.5061 100.979 38.802 100.979 39.167C100.979 39.532 101.289 39.8279 101.671 39.8279H104.298V42.3393C104.298 42.7043 104.607 43.0002 104.989 43.0002C105.371 43.0002 105.681 42.7043 105.681 42.3393V39.8279H108.309C108.69 39.8279 109 39.532 109 39.167C109 38.802 108.69 38.5061 108.309 38.5061H105.681V35.9949Z"
        fill="#5AB8CA"
      />
    </Svg>
  )
}
