import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'
import { Dimensions } from 'react-native'

import { UseEntryScreen } from './EntryScreen.types'

export const useEntryScreen: UseEntryScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  /**
   * @TODO change navigation navigate to useLinkTo
   * define custom names cause this name used in url in version web
   */
  const goToCreateAccountCPFScreen = useCallback(
    () => navigation.navigate('CreateAccountCPF'),
    [navigation]
  )

  const screenHeight = Dimensions.get('screen').height

  const goToLoginScreen = useCallback(() => navigation.navigate('Login'), [navigation])

  return {
    goToCreateAccountCPFScreen,
    goToLoginScreen,
    screenHeight,
    isMobile
  }
}
