import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const DownloadingIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 19 19', ...props }}>
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3478 18.2458C11.4108 18.2688 10.4809 18.0772 9.62923 17.6858C8.77755 17.2944 8.02659 16.7135 7.43374 15.9875C7.03724 15.502 6.71847 14.9601 6.48702 14.3814C5.881 14.6021 5.22957 14.6867 4.57968 14.6238C3.60995 14.53 2.69977 14.1134 1.99501 13.4407C1.29025 12.7681 0.8317 11.8783 0.692849 10.914C0.553999 9.94966 0.742885 8.96665 1.22924 8.12247C1.43602 7.76356 1.89459 7.64023 2.2535 7.84701C2.61241 8.05379 2.73574 8.51237 2.52896 8.87128C2.21032 9.42436 2.08657 10.0684 2.17754 10.7002C2.26851 11.332 2.56894 11.9149 3.03068 12.3557C3.49242 12.7964 4.08874 13.0693 4.72408 13.1308C5.35942 13.1922 5.997 13.0386 6.53464 12.6946C6.73971 12.5633 6.99571 12.5398 7.22126 12.6315C7.44681 12.7231 7.61384 12.9186 7.66923 13.1556C7.83048 13.8457 8.14733 14.4899 8.59558 15.0388C9.04383 15.5877 9.61163 16.0269 10.2556 16.3229C10.8995 16.6188 11.6026 16.7636 12.3111 16.7463C13.0196 16.7289 13.7147 16.5499 14.3434 16.2228C14.9721 15.8957 15.5177 15.4292 15.9386 14.859C16.3594 14.2888 16.6444 13.6299 16.7717 12.9328C16.8989 12.2356 16.8652 11.5186 16.673 10.8364C16.4807 10.1543 16.1351 9.52512 15.6626 8.99698C15.3864 8.6883 15.4127 8.21416 15.7214 7.93795C16.0301 7.66175 16.5042 7.68808 16.7804 7.99677C17.4054 8.69527 17.8625 9.5274 18.1167 10.4296C18.371 11.3317 18.4156 12.2801 18.2473 13.2022C18.0789 14.1242 17.7021 14.9956 17.1455 15.7498C16.5889 16.5039 15.8672 17.1208 15.0357 17.5534C14.2042 17.986 13.2848 18.2229 12.3478 18.2458Z"
      fill="currentColor"
    />

    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.4967 9.24688C9.08249 9.24688 8.7467 8.91109 8.7467 8.49688L8.7467 1.29687C8.7467 0.882661 9.08249 0.546875 9.4967 0.546875C9.91092 0.546875 10.2467 0.882661 10.2467 1.29687L10.2467 8.49688C10.2467 8.91109 9.91092 9.24688 9.4967 9.24688Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.027 9.02721C9.73414 9.3201 9.25927 9.3201 8.96637 9.02721L6.26637 6.32721C5.97348 6.03431 5.97348 5.55944 6.26637 5.26655C6.55927 4.97365 7.03414 4.97365 7.32703 5.26655L9.4967 7.43622L11.6664 5.26655C11.9593 4.97365 12.4341 4.97365 12.727 5.26655C13.0199 5.55944 13.0199 6.03431 12.727 6.32721L10.027 9.02721Z"
      fill="currentColor"
    />
  </Icon>
)
