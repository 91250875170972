import { useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { format, parseISO } from 'date-fns'
import { useCreateActivityMutation } from 'integration/resources/activities'
import {
  useGetOpportunityItemQuery,
  useGetOpportunityQuery
} from 'integration/resources/opportunities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { CheckItem } from 'organisms/Checklist/types'
import { useForm } from 'react-hook-form'
import { Platform } from 'react-native'
import {
  ActivityForm,
  activityFormSchema
} from 'src/components/templates/ActivityFormTemplate/types'
import { useAuthAtomValue } from 'src/store/auth'
import { LogEvent } from 'src/utils/logEvents'

import { UseCreateActivity } from './types'
import { serializerActivity } from '../serializers'

export const useCreateActivity: UseCreateActivity = ({ route, navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const authAtom = useAuthAtomValue()

  const isPesados = useMemo(
    () => authAtom?.user.business_code === 5,
    [authAtom?.user.business_code]
  )

  const toast = useToast()

  const createActivityMutation = useCreateActivityMutation()

  const { refetch } = useGetOpportunityQuery(route?.params?.opportunity_id)

  const { refetch: refreshOpportunity } = useGetOpportunityItemQuery(
    route?.params?.opportunity_id ?? ''
  )

  const onHide = () => navigation.goBack()

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit: onSubmit
  } = useForm<ActivityForm>({
    mode: 'onSubmit',
    resolver: yupResolver(activityFormSchema),
    defaultValues: {
      activity_plan_action_items: [],
      activity_attachments: [],
      is_all_day_schedule: false,
      schedule_date: format(new Date(), 'yyyy-MM-dd')
    }
  })

  const handleSubmit = (formData: ActivityForm) => {
    const std_code = authAtom?.user.std_code ?? formData.created_by_user_std_code

    createActivityMutation.mutate(serializerActivity(formData, std_code), {
      onError: () => {
        toast.show({
          render: () => <Toast type="error" text="Não foi possível completar a ação" />,
          duration: 3000
        })
      },
      onSuccess: async (response) => {
        reset({})

        refreshOpportunity()

        if (route.params?.id) {
          LogEvent('atividade_criada_a_partir_oportunidade', {
            item_id: route.params?.id
          })
        } else {
          LogEvent('atividade_criada', {
            item_id: response.data.data.id
          })
        }

        navigation.goBack()

        toast.show({
          render: () => <Toast type="success" text="Atividade criada com sucesso 🎉" />,
          duration: 3000
        })
      }
    })
  }

  const handleAddItemActionPlan = (value: string) => {
    setValue('activity_plan_action_items', [
      ...(getValues('activity_plan_action_items') ? getValues('activity_plan_action_items') : []),
      {
        value,
        id: value,
        checked: false
      }
    ])
  }

  const handleUpdateItemActionPlan = (item: CheckItem, checked: boolean) => {
    setValue(
      'activity_plan_action_items',
      getValues('activity_plan_action_items').map((obj) => {
        if (obj.id === item.id) {
          return { ...obj, checked }
        }

        return obj
      })
    )
  }

  const handleRemoveItemActionPlan = (item: CheckItem) => {
    setValue(
      'activity_plan_action_items',
      getValues('activity_plan_action_items').filter((obj) => obj.id !== item.id)
    )
  }

  useEffect(() => {
    if (route.params?.opportunity_id) {
      refetch().then(({ data: opportunityData }) => {
        const opportunityDetails = opportunityData?.data.data

        reset({
          title: opportunityDetails?.title,
          store: opportunityDetails?.store,
          opportunity: opportunityDetails,
          schedule_date: opportunityDetails?.due_date
        })
      })
    }
  }, [route.params?.opportunity_id, reset, refetch])

  useEffect(() => {
    if (!route.params?.opportunity_id) {
      const data: any = {}

      if (route.params?.title) {
        data.title = route.params.title
      }

      if (route.params?.date) {
        data.schedule_date =
          Platform.OS === 'android' ? route.params.date : parseISO(route.params.date)
      }

      reset(data)
    }
  }, [route.params, reset])

  return {
    watch,
    setValue,
    control,
    isMobile,
    onSubmit,
    buttonIsLoading: createActivityMutation.isLoading,
    handleSubmit,
    handleAddItemActionPlan,
    handleUpdateItemActionPlan,
    handleRemoveItemActionPlan,
    onHide,
    isPesados
  }
}
