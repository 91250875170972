import { MutableRefObject, forwardRef, useImperativeHandle } from 'react'

import Icons from 'atoms/Icons'
import { Button, Menu, useDisclose, IButtonProps } from 'native-base'

type DropdownProps = {
  label: string
  renderItems: JSX.Element
  active: boolean
  maxH?: number
  w?: number
  buttonH?: number
  ref?: MutableRefObject<any>
  buttonProps?: IButtonProps
}

export type DropdownRefProps = {
  open: () => void
  close: () => void
}

export const Dropdown = forwardRef<DropdownRefProps, DropdownProps>(
  ({ label, active, maxH = 400, w, buttonH = 10, renderItems, buttonProps, ...props }, ref) => {
    const { isOpen, onClose, onOpen } = useDisclose(false)

    useImperativeHandle(
      ref,
      () => {
        return {
          open: onOpen,
          close: onClose
        }
      },
      [onClose, onOpen]
    )

    return (
      <>
        <Menu
          isOpen={isOpen}
          placement={undefined}
          onClose={onClose}
          minH={100}
          w={w}
          flex={0}
          mt={4}
          py={4}
          bgColor="white"
          borderRadius={20}
          {...props}
          trigger={(triggerProps) => {
            delete triggerProps.onPress

            return (
              <Button
                onPress={onOpen}
                px={6}
                _text={{
                  fontSize: '16px'
                }}
                h={buttonH}
                _hover={{
                  bgColor: 'primary.50',
                  _text: {
                    color: 'primary.500'
                  }
                }}
                colorScheme={active ? 'primary-light' : 'white'}
                rightIcon={
                  <Icons.ArrowDropDown color={active ? 'primary.500' : 'gray.500'} size={5} />
                }
                size="sm"
                {...triggerProps}
                {...buttonProps}>
                {label}
              </Button>
            )
          }}>
          {renderItems}
        </Menu>
      </>
    )
  }
)
