import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { format } from 'date-fns'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { BottomSheetModal } from 'molecules'
import { useBreakpointValue } from 'native-base'
import { useAuthAtomValue } from 'src/store/auth'
import { LogEvent, LogEventScreen } from 'src/utils/logEvents'

import { UseOrganizationScreen, ViewType } from './OrganizationScreen.types'

const organization = atom({
  total_activities: -1,
  total_opportunities: -1
})

export const useOrganization = () => useAtom(organization)

export const useOrganizationValue = () => useAtomValue(organization)

export const useSetOrganizationAtom = () => useSetAtom(organization)

export const activityViewTypes: (Omit<ViewType, 'group'> & { group: 1 | 2 | 3 | 4 })[] = [
  {
    group: 1,
    title: 'Visão da semana',
    icon: 'CalendarWeek'
  },
  {
    group: 3,
    title: 'Visão por data',
    icon: 'CalendarPlus'
  },

  {
    group: 4,
    title: 'Atividades Concluídas',
    icon: 'CheckConcluded'
  },

  {
    group: 2,
    title: 'Todas as atividades',
    icon: 'ViewAll'
  }
]

export const opportunityViewTypes: ViewType[] = [
  {
    group: 9,
    title: 'Todas as Oportunidades',
    icon: 'Cash',
    combo: false
  },
  {
    group: 8,
    title: 'Oportunidades Destacadas',
    icon: 'Star',
    combo: false
  },
  {
    group: 7,
    title: 'Oportunidades Concluídas',
    icon: 'Concluded',
    combo: false
  },
  {
    group: 6,
    title: 'Oportunidades por Negócio',
    icon: 'Kanban',
    combo: true
  }
]

export const useOrganizationScreen: UseOrganizationScreen = ({ navigation, route }) => {
  const viewTypeSheetRef = useRef<BottomSheetModal>(null)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [isLoading, setIsLoading] = useState(false)

  const [currentTab, setCurrentTab] = useState<1 | 2>(
    route?.params?.tabId ?? route.path ? (!route.path?.includes('atividades') ? 2 : 1) : 1
  )

  const [currentSelectedDate, setCurrentSelectedDate] = useState<string>()

  const [currentSelectedPeriod, setCurrentSelectedPeriod] = useState<{
    startingDay: string | undefined
    endingDay: string | undefined
  }>()

  const [viewType, setViewType] = useState<ViewType>(
    Number(currentTab) === 1 ? activityViewTypes[0] : opportunityViewTypes[0]
  )

  const openViewTypeSheet = useCallback(async () => {
    LogEvent(
      `organizacao_abriu_filtros_${Number(currentTab) === 1 ? 'atividades' : 'oportunidades'}`,
      {
        item_id: ''
      }
    )

    viewTypeSheetRef.current?.present()
  }, [currentTab])

  const closeViewTypeSheet = useCallback(async () => {
    LogEvent(
      `organizacao_fechou_filtros_${Number(currentTab) === 1 ? 'atividades' : 'oportunidades'}`,
      {
        item_id: ''
      }
    )

    viewTypeSheetRef.current?.close()
  }, [currentTab, viewTypeSheetRef])

  useEffect(() => {
    if (!route?.params?.tabId) return

    setCurrentTab(route?.params?.tabId ?? 1)

    setViewType(
      (Number(route?.params?.tabId) ?? 1) === 1 ? activityViewTypes[0] : opportunityViewTypes[0]
    )
  }, [route?.params])

  const viewTypes = Number(currentTab) === 1 ? activityViewTypes : opportunityViewTypes

  const [ascOrDesc, setAscOrDesc] = useState<'asc' | 'desc'>('asc')

  const handleToggleAscOrDesc = () => {
    setAscOrDesc(ascOrDesc === 'asc' ? 'desc' : 'asc')
  }

  const handleToggleActivitiesOrOpportunities = (id: 1 | 2 | number) => {
    setIsLoading(true)

    setAscOrDesc('asc')

    setCurrentTab(id === 1 ? 1 : 2)

    setViewType(id === 1 ? activityViewTypes[0] : opportunityViewTypes[0])

    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }

  const handleToggleActivitiesTypeView = async (
    typeView: ViewType,
    date: string,
    period?: {
      startingDay: string | undefined
      endingDay: string | undefined
    }
  ) => {
    LogEvent(
      `organizacao_aplicou_filtro_${Number(currentTab) === 1 ? 'atividades' : 'oportunidades'}`,
      {
        item_id: ''
      }
    )

    LogEvent(`organizacao_filtro_${typeView.title.replace(' ', '_')}`, {
      item_id: ''
    })

    setAscOrDesc('asc')

    setViewType(typeView)

    if (period) {
      setCurrentSelectedPeriod(period)
    } else {
      setCurrentSelectedDate(date)
    }

    closeViewTypeSheet()
  }

  const authAtom = useAuthAtomValue()

  const isPesados = useMemo(
    () => authAtom?.user.business_code === 5,
    [authAtom?.user.business_code]
  )

  const tabs = [
    {
      id: 1,
      title: 'Atividades',
      show: true
    },
    {
      id: 2,
      title: 'Oportunidades',
      show: !isPesados
    }
  ]

  const organizationValue = useOrganizationValue()

  useEffect(() => {
    const logAnalitcs = async () => {
      const date = new Date()

      const formated = format(date, 'dd-MM-yyyy HH:mm')

      LogEventScreen({
        screen_class:
          Number(currentTab) === 1 ? 'OrganizationListActivities' : 'OrganizationListOpportunities',
        screen_name:
          Number(currentTab) === 1
            ? 'Organização - Lista Atividades'
            : 'Organização - Lista Oportunidades',
        date: formated,
        hora: format(date, 'HH'),
        minutos: format(date, 'mm')
      })
    }

    logAnalitcs()
  }, [currentTab])

  return {
    tabs,
    isMobile,
    viewType,
    viewTypes,
    ascOrDesc,
    isLoading,
    currentTab,
    navigation,
    setCurrentTab,
    viewTypeSheetRef,
    openViewTypeSheet,
    organizationValue,
    currentSelectedDate,
    currentSelectedPeriod,
    handleToggleAscOrDesc,
    handleToggleActivitiesTypeView,
    handleToggleActivitiesOrOpportunities,
    isPesados
  }
}
