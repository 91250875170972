import { FunctionComponent, memo } from 'react'

import Icons from 'atoms/Icons'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { LinearGradient } from 'expo-linear-gradient'
import { Badge, HStack, Heading, Text, VStack, useBreakpointValue } from 'native-base'
import { ImageBackground, Pressable, StyleSheet } from 'react-native'
import { ContentSerialized } from 'src/integration/resources/knowledgeBase'

type ContentCardProps = ContentSerialized & {
  onPress(): void
}

export const HighlightedContentCard: FunctionComponent<ContentCardProps> = memo(
  ({ category, onPress, title, cover_image_url, estimated_reading_time, last_update }) => {
    const isMobile = useBreakpointValue({ base: true, lg: false })

    return (
      <Pressable onPress={onPress} style={{ height: isMobile ? 'auto' : '100%' }}>
        <ImageBackground
          source={
            cover_image_url !== undefined
              ? { uri: cover_image_url }
              : require('assets/default-banner-content.jpeg')
          }
          style={styles.bg}
          imageStyle={{ borderRadius: 20 }}>
          <LinearGradient
            colors={['#0d0d0d0d', '#00000028', '#00000096']}
            style={{ flex: 1, justifyContent: 'center', borderRadius: 20 }}>
            <VStack
              h={{ base: '190px', lg: 'full' }}
              justifyContent="space-between"
              p={{ base: '11px', lg: 6 }}
              pb={6}>
              <HStack w="full">
                <Badge
                  bg="primary.800"
                  borderRadius="full"
                  color="white"
                  padding={0}
                  borderWidth={0}
                  _text={{
                    color: 'white',
                    fontFamily: 'heading',
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    paddingX: 4,
                    paddingY: 1
                  }}>
                  {category.name}
                </Badge>
              </HStack>
              <VStack w="full">
                <Heading
                  fontSize={{ base: '16px', lg: '18px' }}
                  lineHeight={{ base: '18px', lg: '24px' }}
                  fontWeight="bold"
                  color="white"
                  noOfLines={2}>
                  {title}
                </Heading>

                <HStack display={{ base: 'none', lg: 'flex' }} alignItems="center">
                  {last_update && (
                    <Text color="gray.100" fontSize="16px" lineHeight="22px">
                      {format(parseISO(last_update), "dd 'de' MMM 'de' yyyy", {
                        locale: ptBR
                      })}
                    </Text>
                  )}

                  {estimated_reading_time && (
                    <HStack ml={4} space={1} alignItems="center">
                      <Icons.CalendarHour color="gray.100" />
                      <Text color="gray.100" fontSize="16px" lineHeight="22px">
                        {estimated_reading_time} min
                      </Text>
                    </HStack>
                  )}

                  <HStack ml={4} space={1} alignItems="center">
                    <Icons.Share color="gray.100" />
                    <Text color="gray.100" fontSize="16px" lineHeight="22px">
                      1
                    </Text>
                  </HStack>
                </HStack>
              </VStack>
            </VStack>
          </LinearGradient>
        </ImageBackground>
      </Pressable>
    )
  }
)

const styles = StyleSheet.create({
  bg: {
    flex: 1
  }
})
