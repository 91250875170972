import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'integration/types'
import { getShortMonthAndYear } from 'src/utils'

import {
  ChargeDataSerialized,
  CommissionDataSerialized,
  ConcentrationDataSerialized,
  FunnelDataSerialized,
  GeneralDataTypeSerialized,
  ResultDataSerialized
} from './types'

const getNewAttributes = (data: any, keys: string[], needsKeys?: boolean) => {
  const result: any = {}

  keys.forEach((key) => {
    result[key] = data.months.map((monthItem: any) => {
      return {
        value: monthItem[key] ?? 0,
        year_month: getShortMonthAndYear(String(monthItem.year_month ?? '')),
        order: monthItem.year_month
      }
    })
  })

  return needsKeys ? result : result?.['value']
}

export const getCommission = (
  response: AxiosResponse<ResponseSuccess<CommissionDataSerialized>>
): AxiosResponse<ResponseSuccess<CommissionDataSerialized>> => {
  const { data } = response.data

  const commission_production_serialized = getNewAttributes(data.chart_data.commission_production, [
    'value'
  ])

  const commission_campaign_serialized = getNewAttributes(data.chart_data.commission_campaign, [
    'value'
  ])

  const commission_results_serialized = getNewAttributes(data.chart_data.commission_results, [
    'value'
  ])

  const commission_to_receive_serialized = getNewAttributes(data.chart_data.commission_to_receive, [
    'value'
  ])

  return {
    ...response,
    data: {
      ...response.data,
      data: {
        ...data,
        chart_data: {
          ...data.chart_data,
          commission_production_serialized,
          commission_campaign_serialized,
          commission_results_serialized,
          commission_to_receive_serialized
        }
      }
    }
  }
}

export const getResult = (
  response: AxiosResponse<ResponseSuccess<ResultDataSerialized>>
): AxiosResponse<ResponseSuccess<ResultDataSerialized>> => {
  const { data } = response.data

  const production_accumulated_serialized = getNewAttributes(
    data.chart_data.production_accumulated,
    ['value']
  )

  const production_projected_serialized = getNewAttributes(data.chart_data.production_projected, [
    'value'
  ])

  const insurances_serialized = getNewAttributes(data.chart_data.insurances, ['value'])

  const mbb_serialized = getNewAttributes(data.chart_data.mbb, ['value'])

  const frauds_serialized = getNewAttributes(data.chart_data.frauds, ['value'])

  const over90_serialized = getNewAttributes(data.chart_data.over90, ['value'])

  return {
    ...response,
    data: {
      ...response.data,
      data: {
        ...data,
        chart_data: {
          ...data.chart_data,
          production_accumulated_serialized,
          production_projected_serialized,
          insurances_serialized,
          mbb_serialized,
          frauds_serialized,
          over90_serialized
        }
      }
    }
  }
}

export const getFunnel = (
  response: AxiosResponse<ResponseSuccess<FunnelDataSerialized>>
): AxiosResponse<ResponseSuccess<FunnelDataSerialized>> => {
  const { data } = response.data

  const simulations_serialized = getNewAttributes(
    data.screen_data.simulations,
    ['range5or6', 'range7or8', 'range9or10'],
    true
  )

  const contracts_serialized = getNewAttributes(
    data.screen_data.contracts,
    ['range5or6', 'range7or8', 'range9or10'],
    true
  )

  const simulations_chart_serialized = getNewAttributes(
    data.chart_data.simulations,
    ['range5or6', 'range7or8', 'range9or10'],
    true
  )

  const contracts_chart_serialized = getNewAttributes(
    data.chart_data.contracts,
    ['range5or6', 'range7or8', 'range9or10'],
    true
  )

  const simulations_quantity_serialized = getNewAttributes(data.chart_data.simulations_quantity, [
    'value'
  ])

  const contracts_quantity_serialized = getNewAttributes(data.chart_data.contracts_quantity, [
    'value'
  ])

  const apnef_quantity_serialized = getNewAttributes(data.chart_data.apnef_quantity, ['value'])

  const apnef_value_serialized = getNewAttributes(data.chart_data.apnef_value, ['value'])

  return {
    ...response,
    data: {
      ...response.data,
      data: {
        screen_data: {
          ...data.screen_data,
          simulations_serialized,
          contracts_serialized
        },
        chart_data: {
          ...data.chart_data,
          simulations_serialized: simulations_chart_serialized,
          contracts_serialized: contracts_chart_serialized,
          simulations_quantity_serialized,
          contracts_quantity_serialized,
          apnef_quantity_serialized,
          apnef_value_serialized
        }
      }
    }
  }
}

export const getConcentration: (
  response: AxiosResponse<ResponseSuccess<ConcentrationDataSerialized>>
) => AxiosResponse<ResponseSuccess<ConcentrationDataSerialized>> = (response) => {
  const { data } = response.data

  const production_serialized: GeneralDataTypeSerialized[] = []

  const subsegment_names: string[] = []

  data.screen_data.productions.months.forEach((monthItem) => {
    monthItem.subsegments.reduce((accumulator, subsegment) => {
      const subsegmentName = subsegment.name

      const value = subsegment.production ?? 0

      const year_month = getShortMonthAndYear(String(monthItem.year_month))

      // Check if subsegment already exists in subsegment_names, if not, add it
      if (subsegment_names !== null && !subsegment_names.includes(subsegmentName)) {
        subsegment_names.push(subsegmentName)
      }

      // Get index of subsegment in subsegment_names
      const index = subsegment_names.indexOf(subsegmentName)

      // If the subsegment doesn't exist in production_serialized yet, add it
      if (!accumulator[index]) {
        accumulator[index] = []
      }

      // Add the production to the corresponding subsegment in production_serialized
      accumulator[index].push({ value, year_month })

      return accumulator
    }, production_serialized)
  })

  const production_chart_serialized: GeneralDataTypeSerialized[] = []

  const subsegment_chart_names: string[] = []

  data.chart_data.productions.months.forEach((monthItem) => {
    monthItem.subsegments.reduce((accumulator, subsegment) => {
      const subsegmentName = subsegment.name

      const value = subsegment.production ?? 0

      const year_month = getShortMonthAndYear(String(monthItem.year_month))

      // Check if subsegment already exists in subsegment_chart_names, if not, add it
      if (!subsegment_chart_names.includes(subsegmentName)) {
        subsegment_chart_names.push(subsegmentName)
      }

      // Get index of subsegment in subsegment_chart_names
      const index = subsegment_chart_names.indexOf(subsegmentName)

      // If the subsegment doesn't exist in production_chart_serialized yet, add it
      if (!accumulator[index]) {
        accumulator[index] = []
      }

      // Add the production to the corresponding subsegment in production_chart_serialized
      accumulator[index].push({ value, year_month })

      return accumulator
    }, production_chart_serialized)
  })

  const total_stores_serialized = getNewAttributes(data.chart_data.total_stores, ['value'])

  const inactive_stores_serialized = getNewAttributes(data.chart_data.inactive_stores, ['value'])

  const production_50_serialized = getNewAttributes(data.chart_data.production_50, ['value'])

  const simulate_serialized = getNewAttributes(data.chart_data.simulate, ['value'])

  const produce_serialized = getNewAttributes(data.chart_data.produce, ['value'])

  const recurrent_serialized = getNewAttributes(data.chart_data.recurrent, ['value'])

  const active_stores_serialized = getNewAttributes(data.chart_data.active_stores, ['value'])

  return {
    ...response,
    data: {
      ...response.data,
      data: {
        ...data,
        screen_data: {
          ...data.screen_data,
          production_serialized,
          subsegment_names
        },
        chart_data: {
          ...data.chart_data,
          total_stores_serialized,
          inactive_stores_serialized,
          production_50_serialized,
          simulate_serialized,
          production_serialized: production_chart_serialized,
          subsegment_names: subsegment_chart_names,
          produce_serialized,
          recurrent_serialized,
          active_stores_serialized
        }
      }
    }
  }
}

export const getCharge: (
  response: AxiosResponse<ResponseSuccess<ChargeDataSerialized>>
) => AxiosResponse<ResponseSuccess<ChargeDataSerialized>> = (response) => {
  const { data } = response.data

  const overdue_serialized: GeneralDataTypeSerialized[] = []

  const overdue_chart_serialized: GeneralDataTypeSerialized[] = []

  const range_names: string[] = []

  const range_chart_names: string[] = []

  data.screen_data.overdue.months.forEach((monthItem) => {
    monthItem.ranges.reduce((accumulator, range) => {
      if (range.show_in_graph) {
        const rangeName = range.label

        const value = Number(range.value) ?? 0

        const year_month = getShortMonthAndYear(String(monthItem.year_month))

        // Check if range already exists in range_names, if not, add it
        if (!range_names.includes(rangeName)) {
          range_names.push(rangeName)
        }

        // Get index of range in range_names
        const index = range_names.indexOf(rangeName)

        // If the range doesn't exist in overdue_serialized yet, add it
        if (!accumulator[index]) {
          accumulator[index] = []
        }

        // Add the overdue to the corresponding range in overdue_serialized
        accumulator[index].push({ value, year_month })
      }

      return accumulator
    }, overdue_serialized)
  })

  data.chart_data.overdue.months.forEach((monthItem) => {
    monthItem.ranges.reduce((accumulator, range) => {
      if (range.show_in_graph) {
        const rangeName = range.label

        const value = Number(range.value) ?? 0

        const year_month = getShortMonthAndYear(String(monthItem.year_month))

        // Check if range already exists in range_chart_names, if not, add it
        if (!range_chart_names.includes(rangeName)) {
          range_chart_names.push(rangeName)
        }

        // Get index of range in range_chart_names
        const index = range_chart_names.indexOf(rangeName)

        // If the range doesn't exist in overdue_serialized yet, add it
        if (!accumulator[index]) {
          accumulator[index] = []
        }

        // Add the overdue to the corresponding range in overdue_serialized
        accumulator[index].push({ value, year_month })
      }

      return accumulator
    }, overdue_chart_serialized)
  })

  const wallet_situation_serialized = getNewAttributes(
    data.screen_data.wallet_situation,
    ['in_day', 'overdue'],
    true
  )

  const wallet_situation_chart_serialized = getNewAttributes(
    data.chart_data.wallet_situation,
    ['in_day', 'overdue'],
    true
  )

  const fpd10_serialized = getNewAttributes(data.chart_data.fpd10, ['value'])

  const over30m3_serialized = getNewAttributes(data.chart_data.over30m3, ['value'])

  const saldo_safra_serialized = getNewAttributes(data.chart_data.saldo_safra, ['value'])

  const over30_serialized = getNewAttributes(data.chart_data.over30, ['value'])

  const over60_serialized = getNewAttributes(data.chart_data.over60, ['value'])

  const over90_serialized = getNewAttributes(data.chart_data.over90, ['value'])

  const wallet_balance_serialized = getNewAttributes(data.chart_data.wallet_balance, ['value'])

  const fraud_serialized = getNewAttributes(data.chart_data.fraud, ['value'])

  const contract_delinquency_qty_serialized = getNewAttributes(
    data.chart_data.contract_delinquency_qty,
    ['value']
  )

  return {
    ...response,
    data: {
      ...response.data,
      data: {
        ...data,
        screen_data: {
          ...data.screen_data,
          overdue_serialized,
          range_names,
          wallet_situation_serialized
        },
        chart_data: {
          ...data.chart_data,
          overdue_serialized: overdue_chart_serialized,
          fpd10_serialized,
          over30m3_serialized,
          saldo_safra_serialized,
          over30_serialized,
          over60_serialized,
          over90_serialized,
          wallet_balance_serialized,
          fraud_serialized,
          contract_delinquency_qty_serialized,
          wallet_situation_serialized: wallet_situation_chart_serialized
        }
      }
    }
  }
}
