import { useMemo } from 'react'

import { useGetFunnelQuery } from 'integration/resources/walletResume'
import { getShortMonthAndYear, verifyHasData } from 'src/utils'

import { UseHomeFunnel } from './types'

export const useHomeFunnel: UseHomeFunnel = () => {
  const { data, isLoading: funnelDataIsLoading } = useGetFunnelQuery({})

  const funnelData = useMemo(() => data?.data.data, [data])

  const simulationsRatingData = useMemo(() => {
    const range5or6 = funnelData?.screen_data.simulations_serialized.range5or6.length
      ? funnelData?.screen_data.simulations_serialized.range5or6.length > 6
        ? funnelData?.screen_data.simulations_serialized.range5or6.slice(-6)
        : funnelData?.screen_data.simulations_serialized.range5or6
      : []

    const range7or8 = funnelData?.screen_data.simulations_serialized.range7or8.length
      ? funnelData?.screen_data.simulations_serialized.range7or8.length > 6
        ? funnelData?.screen_data.simulations_serialized.range7or8.slice(-6)
        : funnelData?.screen_data.simulations_serialized.range7or8
      : []

    const range9or10 = funnelData?.screen_data.simulations_serialized.range9or10.length
      ? funnelData?.screen_data.simulations_serialized.range9or10.length > 6
        ? funnelData?.screen_data.simulations_serialized.range9or10.slice(-6)
        : funnelData?.screen_data.simulations_serialized.range9or10
      : []

    const tooltipsValues =
      funnelData?.screen_data.simulations.months.map((item) => ({
        rangeNt: item.rangeNt,
        range1or2: item.range1or2,
        range3or4: item.range3or4,
        range5or6: item.range5or6,
        range7or8: item.range7or8,
        range9or10: item.range9or10
      })) ?? []

    return {
      range5or6,
      range7or8,
      range9or10,
      tickValues: funnelData?.chart_data.simulations.months.map((item) =>
        getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: funnelData?.chart_data.simulations.processed_at,
      hasData: verifyHasData('value', [
        ...(range5or6 ?? []),
        ...(range7or8 ?? []),
        ...(range9or10 ?? [])
      ]),
      tooltipsValues
    }
  }, [funnelData])

  const contractsRatingData = useMemo(() => {
    const range5or6 = funnelData?.screen_data.contracts_serialized.range5or6.length
      ? funnelData?.screen_data.contracts_serialized.range5or6.length > 6
        ? funnelData?.screen_data.contracts_serialized.range5or6.slice(-6)
        : funnelData?.screen_data.contracts_serialized.range5or6
      : []

    const range7or8 = funnelData?.screen_data.contracts_serialized.range7or8.length
      ? funnelData?.screen_data.contracts_serialized.range7or8.length > 6
        ? funnelData?.screen_data.contracts_serialized.range7or8.slice(-6)
        : funnelData?.screen_data.contracts_serialized.range7or8
      : []

    const range9or10 = funnelData?.screen_data.contracts_serialized.range9or10.length
      ? funnelData?.screen_data.contracts_serialized.range9or10.length > 6
        ? funnelData?.screen_data.contracts_serialized.range9or10.slice(-6)
        : funnelData?.screen_data.contracts_serialized.range9or10
      : []

    const tooltipsValues =
      funnelData?.screen_data.contracts.months.map((item) => ({
        rangeNt: item.rangeNt,
        range1or2: item.range1or2,
        range3or4: item.range3or4,
        range5or6: item.range5or6,
        range7or8: item.range7or8,
        range9or10: item.range9or10
      })) ?? []

    return {
      range5or6,
      range7or8,
      range9or10,
      tickValues: funnelData?.chart_data.contracts.months.map((item) =>
        getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: funnelData?.chart_data.contracts.processed_at,
      hasData: verifyHasData('value', [
        ...(range5or6 ?? []),
        ...(range7or8 ?? []),
        ...(range9or10 ?? [])
      ]),
      tooltipsValues
    }
  }, [funnelData])

  return {
    simulationsRatingData,
    contractsRatingData,
    funnelDataIsLoading
  }
}
