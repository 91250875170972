/* eslint-disable padding-line-between-statements */
import { FunctionComponent, memo } from 'react'

import { Avatar, Icons } from 'atoms'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ContentSerialized } from 'integration/resources/knowledgeBase'
import { Badge, Box, Center, HStack, Heading, Text, VStack, useBreakpointValue } from 'native-base'
import { ImageBackground, TouchableOpacity } from 'react-native'
import { formatDateStringToMask } from 'src/utils'

type ContentCardProps = ContentSerialized & {
  onPress(): void
  save?: boolean
}

export const ContentCard: FunctionComponent<ContentCardProps> = memo(
  ({
    title,
    category,
    last_update,
    cover_image_url,
    onPress,
    save = false,
    estimated_reading_time
  }) => {
    const isMobile = useBreakpointValue({ base: true, lg: false })

    return (
      <TouchableOpacity onPress={onPress} testID="profile-saved-content">
        {save ? (
          <VStack
            bg="white"
            {...(isMobile ? { maxWidth: '100%' } : { width: '352px', height: '187px' })}
            justifyContent="space-between"
            borderRadius="20px"
            mb={{ base: 1, lg: 0 }}
            mr={{ base: 0, lg: 4 }}
            p={6}>
            <HStack space={2} alignItems="flex-start">
              <Center bg={cover_image_url ? 'white' : 'gray.50'} borderRadius="full" h={12} w={12}>
                <Avatar uri={cover_image_url} title={title} type="dark" h={12} w={12} />
              </Center>
              <VStack flex={1}>
                <Heading
                  testID="profile-saved-content-name"
                  fontSize={{ base: '14px', lg: '16px' }}
                  lineHeight={{ base: '18px', lg: '22px' }}
                  fontWeight={isMobile ? 700 : 400}
                  color="gray.700"
                  noOfLines={2}
                  mb={{ base: 0, lg: 2 }}>
                  {title}
                </Heading>
                <HStack flexDir="column">
                  <Text
                    testID="profile-saved-content-category"
                    fontSize={{ base: '12px', lg: '15px' }}
                    lineHeight={{ base: '16px', lg: '22px' }}
                    color="gray.400"
                    mr={4}
                    mb={{ base: 0, lg: 1 }}>
                    {category.name}
                  </Text>
                  <Text
                    fontSize={{ base: '12px', lg: '15px' }}
                    lineHeight={{ base: '16px', lg: '22px' }}
                    color="gray.400">
                    {formatDateStringToMask(last_update, "dd 'de' MMM 'de' yyyy")}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
            {!isMobile && (
              <HStack justifyContent="flex-end" mt={2}>
                <Icons.Bookmark
                  size="23px"
                  color="gray.700"
                  filled
                  testID="profile-favorit-content"
                />
              </HStack>
            )}
          </VStack>
        ) : (
          <>
            <VStack
              bg="white"
              borderRadius="20px"
              h="92px"
              w={{ lg: '352px' }}
              p={4}
              display={{ base: 'flex', lg: 'none' }}>
              <HStack space={2} alignItems="center">
                <Center
                  bg={cover_image_url ? 'white' : 'gray.50'}
                  borderRadius="full"
                  h={12}
                  w={12}>
                  <Avatar uri={cover_image_url} title={title} type="dark" h={12} w={12} />
                </Center>
                <VStack flex={1}>
                  <Heading
                    fontSize="14px"
                    lineHeight="18px"
                    fontWeight="bold"
                    color="gray.700"
                    noOfLines={1}
                    mb={1}>
                    {title}
                  </Heading>
                  <Text
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="normal"
                    color="gray.400"
                    mb={1}>
                    {category.name}
                  </Text>
                  <Text fontSize="12px" lineHeight="16px" fontWeight="normal" color="gray.400">
                    {formatDateStringToMask(last_update, "dd 'de' MMM 'de' yyyy")}
                  </Text>
                </VStack>
              </HStack>
            </VStack>

            <VStack display={{ base: 'none', lg: 'flex' }} flex={1}>
              <Box height="256px">
                <ImageBackground
                  source={
                    cover_image_url !== undefined
                      ? { uri: cover_image_url }
                      : require('assets/default-banner-content.jpeg')
                  }
                  style={{ flex: 1, height: '100%' }}
                  imageStyle={{
                    borderRadius: 20,
                    resizeMode: 'cover'
                  }}>
                  <VStack justifyContent="space-between" p={{ base: '11px', lg: 6 }} pb={6}>
                    <HStack w="full">
                      <Badge
                        bg="primary.800"
                        borderRadius="full"
                        color="white"
                        padding={0}
                        borderWidth={0}
                        _text={{
                          color: 'white',
                          fontFamily: 'heading',
                          fontSize: '12px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          paddingX: 4,
                          paddingY: 1
                        }}>
                        {category.name}
                      </Badge>
                    </HStack>
                  </VStack>
                </ImageBackground>
              </Box>

              <Heading
                fontSize={{ base: '16px', lg: '18px' }}
                lineHeight={{ base: '18px', lg: '24px' }}
                fontWeight="bold"
                color="gray.700"
                noOfLines={2}
                mt={6}
                mb={2}>
                {title}
              </Heading>

              <HStack display={{ base: 'none', lg: 'flex' }} alignItems="center">
                {last_update && (
                  <Text color="gray.500" fontSize="16px" lineHeight="22px">
                    {format(parseISO(last_update), "dd 'de' MMM 'de' yyyy", {
                      locale: ptBR
                    })}
                  </Text>
                )}

                {estimated_reading_time && (
                  <HStack ml={4} space={1} alignItems="center">
                    <Icons.CalendarHour color="gray.500" />
                    <Text color="gray.500" fontSize="16px" lineHeight="22px">
                      {estimated_reading_time} min
                    </Text>
                  </HStack>
                )}

                <HStack ml={4} space={1} alignItems="center">
                  <Icons.Share color="gray.500" />
                  <Text color="gray.500" fontSize="16px" lineHeight="22px">
                    1
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          </>
        )}
      </TouchableOpacity>
    )
  }
)
