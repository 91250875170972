import { useCallback, useEffect, useMemo, useState } from 'react'

import * as ScreenOrientation from 'expo-screen-orientation'
import {
  KEY_PRODUCTION_STORES_LIST_QUERY,
  useGetProductionStoresListInfiniteQuery
} from 'integration/resources/production'
import { Store } from 'integration/resources/store'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { HomeChartsTypes, homeResumedChartsProps } from 'src/screens/HomeScreen/constants'
import { useAuthAtomValue } from 'src/store/auth'
import { formatToReal, getMonthName } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { UseProductionScreen } from './ProductionScreen.types'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetProductionStoresListInfiniteQuery>['data']
) =>
  data?.pages.reduce<Store[]>((previousValue, currentValue) => {
    return [...previousValue, ...currentValue.data.data.items] as Store[]
  }, [])

const CONTRACT_LIST_NAVIGATION = [
  HomeChartsTypes.AMOUNT_STORES,
  HomeChartsTypes.AMOUNT_INACTIVES,
  HomeChartsTypes.AMOUNT_HALF_PRODUCTION,
  HomeChartsTypes.AMOUNT_ACTIVE_STORES,
  HomeChartsTypes.PRODUCTION,
  HomeChartsTypes.RESULT,
  HomeChartsTypes.RECEIVABLE,
  HomeChartsTypes.CAMPAIGNS,
  HomeChartsTypes.PROJECTED_PRODUCTION
]

export const useProductionScreen: UseProductionScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [showSpinner, setShowSpinner] = useState(true)

  const authAtom = useAuthAtomValue()

  const isGoodsAndServices = useMemo(
    () => authAtom?.user.business_code === 3,
    [authAtom?.user.business_code]
  )

  const timeoutSpinner = setTimeout(() => {
    setShowSpinner(false)
  }, 1250)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutSpinner)
    }
  }, [timeoutSpinner])

  const handleGoBack = useCallback(() => {
    setShowSpinner(true)

    if (isMobile) {
      changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT).then(() => {})
    } else {
      navigation.replace('ProductionChart')
    }
  }, [navigation, isMobile])

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (e.data.action.type === 'GO_BACK' && isMobile) {
          // Prevent default behavior of leaving the screen
          e.preventDefault()

          setShowSpinner(true)

          changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT).then(() => {
            setTimeout(() => {
              navigation.replace('ProductionChart')
            }, 1250)
          })
        }
      }),
    [navigation, isMobile]
  )

  useEffect(() => {
    if (isMobile) {
      const listener = ScreenOrientation.addOrientationChangeListener((update) => {
        if (update.orientationInfo.orientation === 4) {
          navigation.goBack()
        }
      })

      return () => {
        ScreenOrientation.removeOrientationChangeListener(listener)
      }
    }
  }, [navigation, isMobile])

  const queryClient = useQueryClient()

  const [orderByDirectionAsc, setOrderByDirectionAsc] = useState<boolean>(false)

  const { yearMonth, type, total } = route?.params

  const activeMock = useMemo(() => type === HomeChartsTypes.AMOUNT_ACTIVE_STORES, [type])

  const pageTitle = homeResumedChartsProps[type].title

  const pageType = homeResumedChartsProps[type].type

  const productionMonthYear = `${getMonthName(Number(yearMonth.slice(4)))} ${yearMonth.slice(
    0,
    4
  )} ${pageType === 'currency' ? '(R$)' : ''}`

  const { data, isLoading, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetProductionStoresListInfiniteQuery({
      year_month: yearMonth,
      order_by: 'production',
      order_by_direction: orderByDirectionAsc ? 'asc' : 'desc',
      per_page: 6,
      type
    })

  const totalProduction = data?.pages[0]?.data?.data?.total_value ?? Number(total)

  const totalStores = data?.pages[0].data.metadata.pagination.total ?? 0

  const onRefresh = () =>
    queryClient.invalidateQueries([`${KEY_PRODUCTION_STORES_LIST_QUERY}.${yearMonth}`])

  const handleOrderBy = () => {
    LogEvent(`producao_tab_ordenacao_${orderByDirectionAsc ? 'asc' : 'desc'}`, {
      item_id: ''
    })

    setOrderByDirectionAsc(!orderByDirectionAsc)

    queryClient.invalidateQueries(KEY_PRODUCTION_STORES_LIST_QUERY)
  }

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const storesPagination = data?.pages[0].data.metadata.pagination

  const storesList = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  const navigateToContractListScreen = (tabNumber: string) => {
    navigation.navigate('ContractList', {
      yearMonth,
      tabNumber,
      //@ts-ignore
      title: homeResumedChartsProps[type].subLevelTitle,
      total,
      type
    })
  }

  const handleGoToContractListScreen = useCallback(
    (tabNumber: string, total: string) => {
      if (!CONTRACT_LIST_NAVIGATION.includes(type) && tabNumber) {
        setShowSpinner(true)

        if (isMobile) {
          changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP).then(() =>
            navigateToContractListScreen(tabNumber)
          )
        } else {
          navigateToContractListScreen(tabNumber)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [yearMonth, type, navigation]
  )

  const handleSelectYearMonth = (yearMonth: string) => {
    LogEvent(`producao_tab`, {
      item_id: productionMonthYear.replace(' ', '_')
    })

    navigation.navigate('Production', {
      yearMonth,
      type,
      total
    })
  }

  const types = Object.assign(homeResumedChartsProps)

  const typeFormat = (currentType: HomeChartsTypes) =>
    types[currentType] ? types[currentType]?.type : 'currency'

  const getTotal = (type: HomeChartsTypes, total: number) => {
    switch (typeFormat(type)) {
      case 'currency':
        return formatToReal(Number(total))

      case 'percentage':
        return `${total}%`

      default:
        return total
    }
  }

  return {
    isMobile,
    showSpinner,
    handleGoToContractListScreen,
    handleOrderBy,
    handleGoBack,
    orderByDirectionAsc,
    storesList,
    isLoading,
    isFetching,
    storesPagination,
    onRefresh,
    onEndReached,
    totalProduction: getTotal(type as HomeChartsTypes, totalProduction),
    productionMonthYear,
    isGoodsAndServices,
    totalStores,
    pageTitle: pageTitle?.replace('(R$)', ''),
    activeMock,
    typeFormat: typeFormat(type),
    currentYearMonth: yearMonth,
    handleSelectYearMonth
  }
}
