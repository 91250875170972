export enum USER_STATUS {
  AWAITING_CONFIRM_MOBILE = 1, // O cadastro do usuário realizado via aplicativo esta pendente de confirmação do numero do celular informado
  AWAITING_CONFIRM_EMAIL = 2, // O cadastro do usuário realizado via aplicativo esta pendente de confirmação de e-mail
  AWAITING_APPROVAL = 3, // Os dados do cadastro do usuário realizado via aplicativo/ETL esta pendente de ser avaliado pelo back-office
  FIRST_PASSWORD = 4, // O usuário esta pendente de definir a sua senha
  REFUSED = 5, // O backoffice apos avaliar os dados do usuário cadastrado via aplicativo, recusa o cadastro encerrando o fluxo
  AWAITING_ACCEPT_TERM = 6, // O usuário possui termo(s) pendente(s) de ser(em) aprovado(s)
  ACTIVE = 7, // O usuário possui acesso as features da aplicação
  INACTIVE = 8, // O usuário esta com o acesso suspenso
  DELETED = 9, // O usuário foi deletado logicamente da base, o mesmo não deve ser exibido nas listagens
  WAITING_ONBOARDING = 10
}

export enum PROFILE_ACCESS_LEVEL_TYPE_ENUM {
  OWNER_DATA = 1,
  PARTNR_DATA = 2,
  HIERARCHY_DATA = 3,
  ALL_DATA = 4
}

export enum BUSINESS_CODE {
  DEFAULT = 1,
  VEHICLES = 2,
  GOODS_AND_SERVICES = 3,
  LEVES = 4,
  PESADOS = 5
}

export type PreAuthData = {
  id: string
  phone_number_cell: string
  status: USER_STATUS
  is_authorized: boolean
}

export type PreAuthPayload = {
  email: string
  password: string
  device_code?: string
  device_description?: string
  device_push_notification_token?: string
}

export type PreAuthSendCode = {
  user_id: string
  slt: string
  type: 'sms' | 'email'
}

export type AuthData = {
  is_authorized?: boolean
  auth: {
    expires_at: string
    token: string
    type: string
  }
  user: {
    waiting_onboarding?: boolean
    accepted_commitment_term_at?: string
    accepted_responsibility_term_at?: string
    access_level: PROFILE_ACCESS_LEVEL_TYPE_ENUM
    business_code: BUSINESS_CODE
    name: string
    email: string
    profile_image_url?: string
    regional_office?: string
    roles: [18, 20, 30, 47, 48, 106, 600, 705, 706, 707, 708, 709, 33, 65, 67]
    std_code: 8585
    status: USER_STATUS
    occupation?: string
  }
}

export type AuthPayload = PreAuthPayload & {
  code: string
  slt: string
}

export type LogoutPayload = {
  device_push_notification_token?: string
}

export type PreAuthResponse = AuthData | PreAuthData
