export enum ProposalFunnelProps {
  SIMULATION_QUANTITY = 'simulation_quantity',
  PROPOSAL_QUANTITY = 'proposal_quantity',
  APPROVED_QUANTITY = 'approved_quantity',
  EFFECTIVE_QUANTITY = 'effective_quantity',
  CONTRACTS_QUANTITY = 'contracts_quantity'
}

export enum PanelsTypes {
  MARKETING_SHARE = 'mktShare',
  MARKET_VISUALIZATION = 'mktVisualization',
  SANTANDER_AUTO_INSURANCE = 'santanderAutoInsurance',
  ACCOUNT_AMOUNT = 'accountAmount',
  WEBMOTORS = 'webmotors',
  PRODUCTION = 'production',
  POTENTIAL = 'potential',
  FPD10 = 'fpd10',
  OVER30_M3 = 'over30m3',
  OVER30 = 'over30',
  OVER60 = 'over60',
  OVER90 = 'over90',
  CONTRACTS_AMOUNT = 'contractsAmount',
  SPREAD = 'spread',
  TYPES_SANTANDER_AUTO = 'typesSantanderAuto',
  CREDIT_LIFE_INSURANCE = 'creditLifeInsurance'
}

export const panelsResumedChartsProps = {
  contractsAmount: {
    title: 'Contratos (QTD)',
    slug: 'contratos_qtd',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Quantidade de Contratos',
        color: 'primary.800'
      },
      {
        title: 'Seguro Prestamista',
        color: 'primary.500'
      },
      {
        title: 'Seguro Auto',
        color: 'primary.300'
      }
    ],
    type: 'qtd'
  },
  contractsAmountGoodsAndServices: {
    title: 'Quantidade de Contratos',
    slug: 'qtd_de_contratos',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Quantidade de Contratos',
        color: 'primary.800'
      },
      {
        title: 'Seguro Prestamista',
        color: 'primary.500'
      }
    ],
    type: 'qtd'
  },
  contractsAmountPesados: {
    title: 'Quantidade de Contratos',
    slug: 'qtd_de_contratos',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Quantidade de Contratos',
        color: 'primary.800'
      }
    ],
    type: 'qtd'
  },
  production: {
    title: 'Produção (R$)',
    slug: 'producao',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Produção (R$)',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  spread: {
    title: 'Spread (%)',
    slug: 'spread',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Spread Bruto',
        color: 'primary.500'
      },
      {
        title: 'Spread Líquido',
        color: 'primary.300'
      }
    ],
    type: 'percentage'
  },
  potential: {
    title: 'Potencial (R$)',
    slug: 'potencial',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Potencial (R$)',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  mktShare: {
    title: 'Market Share (%)',
    slug: 'market_share',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Market Share %',
        color: 'primary.500'
      }
    ],
    type: 'percentage'
  },
  mktVisualization: {
    title: 'Vis. de mercado (%)',
    slug: 'visao_de_mercado',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Visualização de mercado %',
        color: 'primary.500'
      }
    ],
    type: 'percentage'
  },
  creditLifeInsurance: {
    title: 'Seguro Prestamista',
    slug: 'seguro_prestamista',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: '% IP Seguro Prestamista (QTD)',
        color: 'primary.500'
      },
      {
        title: '% IP Seguro Prestamista (Volume)',
        color: 'primary.300'
      }
    ],
    type: 'percentage'
  },
  santanderAutoInsurance: {
    title: 'Seguro Santander Auto',
    slug: 'seguro_santander_auto',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: '% IP Seguro Auto (Qtd)',
        color: 'primary.500'
      }
    ],
    type: 'percentage'
  },
  typesSantanderAuto: {
    title: 'Santander Auto Tipos (Qtd)',
    slug: 'sanander_auto_tipos',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Seguro Completo',
        color: 'primary.800'
      },
      {
        title: 'Seguro Incêndio Roubo e Furto',
        color: 'primary.500'
      },
      {
        title: 'Seguros Terceiros',
        color: 'gray.200'
      },
      {
        title: 'Seguros Avulsa',
        color: 'primary.300'
      }
    ],
    type: 'qtd'
  },
  accountAmount: {
    title: 'Quantidade de Contas',
    slug: 'qtd_de_contas',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Contas Abertas',
        color: 'primary.500'
      }
    ],
    type: 'qtd'
  },
  webmotors: {
    title: 'Webmotors (R$)',
    slug: 'webmotors',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Faturamento Webmotors',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  fpd10: {
    title: 'FPD10 (R$)',
    slug: 'fpd10',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'FPD10',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  over30m3: {
    title: 'OVER30M3 (R$)',
    slug: 'over30_m3',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'OVER30M3',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  over30: {
    title: 'OVER30 (R$)',
    slug: 'over30',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'OVER30',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  over60: {
    title: 'OVER60 (R$)',
    slug: 'over60',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'OVER60',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  over90: {
    title: 'OVER90 (R$)',
    slug: 'over90',

    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'OVER90',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  }
}

export const panelsFullChartsProps = {
  contractsAmount: {
    title: 'Quantidade de Contratos',
    slug: 'qtd_contratos',
    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Qtd de Contratos',
        color: 'primary.800'
      },
      {
        title: 'Qtd Seguro Prestamista',
        color: 'primary.500'
      },
      {
        title: 'Qtd Seguro Auto',
        color: 'primary.300'
      }
    ],
    type: 'qtd'
  },
  contractsAmountGoodsAndServices: {
    title: 'Quantidade de Contratos',
    slug: 'qtd_contratos',
    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Qtd de Contratos',
        color: 'primary.800'
      },
      {
        title: 'Seguro Prestramista',
        color: 'primary.500'
      }
    ],
    type: 'qtd'
  },
  contractsAmountPesados: {
    title: 'Quantidade de Contratos',
    slug: 'qtd_contratos',
    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Qtd de Contratos',
        color: 'primary.400'
      }
    ],
    type: 'qtd'
  },
  production: {
    title: 'Produção (R$)',
    slug: 'producao',
    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Produção em R$',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  spread: {
    title: '% de Spread',
    slug: 'spread',
    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Spread Bruto',
        color: 'primary.500'
      },
      {
        title: 'Spread Líquido',
        color: 'primary.300'
      }
    ],
    type: 'small_percentage'
  },
  potential: {
    title: 'Potencial (R$)',
    subLevelTitle: 'Contrato',
    slug: 'potencial',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Potencial em R$',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  mktShare: {
    title: 'Market Share %',
    slug: 'market_share',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Market Share %',
        color: 'primary.500'
      }
    ],
    type: 'percentage'
  },
  mktVisualization: {
    title: 'Visualização de mercado %',
    slug: 'visao_de_mercado',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Visualização de mercado %',
        color: 'primary.500'
      }
    ],
    type: 'percentage'
  },
  creditLifeInsurance: {
    title: 'Seguro Prestamista',
    slug: 'seguro_prestamista',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: '% IP Seguro Prestramista (QTD)',
        color: 'primary.500'
      },
      {
        title: '% IP Seguro Prestramista (Volume)',
        color: 'primary.300'
      }
    ],
    type: 'percentage'
  },
  santanderAutoInsurance: {
    title: 'Seguro Santander Auto',
    slug: 'seguro_santander_auto',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: '% IP Seguro Auto (QTD)',
        color: 'primary.500'
      }
    ],
    type: 'percentage'
  },
  typesSantanderAuto: {
    title: 'Santander Auto Tipos',
    slug: 'santander_auto_tipos',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Seguro Completo',
        color: 'primary.800'
      },
      {
        title: 'Seguro Incêndio Roubo e Furto',
        color: 'primary.500'
      },
      {
        title: 'Seguros Terceiros',
        color: 'gray.200'
      },
      {
        title: 'Seguros Avulsa',
        color: 'primary.300'
      }
    ],
    type: 'qtd'
  },
  accountAmount: {
    title: 'Quantidade de Contas',
    slug: 'qtd_de_contas',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Contas Abertas',
        color: 'primary.500'
      }
    ],
    type: 'qtd'
  },
  webmotors: {
    title: 'Webmotors (R$)',
    slug: 'webmotors',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Produção (R$ 0 - 25MM)',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  fpd10: {
    title: 'FPD10 (R$)',
    slug: 'fpd10',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'FPD10',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  over30m3: {
    title: 'OVER30M3 (R$)',
    slug: 'over30_m3',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'OVER30M3',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  over30: {
    title: 'OVER30 (R$)',
    slug: 'over30',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'OVER30',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  over60: {
    title: 'OVER60 (R$)',
    slug: 'over60',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'OVER60',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  },
  over90: {
    title: 'OVER90 (R$)',
    slug: 'over90',

    subLevelTitle: 'Contrato',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'OVER90',
        color: 'primary.500'
      }
    ],
    type: 'currency'
  }
}
