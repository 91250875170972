import { useBreakpointValue } from 'native-base'

import { UseTabRelatedScreen } from './TabRelatedScreen.types'

export const useTabRelatedScreen: UseTabRelatedScreen = ({ route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { type, tabNumber } = route.params

  const title = `${type === 'activities' ? 'Atividades' : 'Oportunidades'} relacionadas`

  return {
    isMobile,
    type,
    title,
    tabNumber
  }
}
