import { MultipleBarsChart as LandscapeMultipleBars } from './LandscapeCharts/MultipleBarsChart'
import { SingleBarChart as LandscapeSingleBar } from './LandscapeCharts/SingleBarChart'
import { StackBarsChart as LandscapeStackBars } from './LandscapeCharts/StackBarChart'
import { MultipleBarsChart as PortraitMultipleBars } from './PortraitCharts/MultipleBarsChart'
import { SingleBarChart as PortraitSingleBar } from './PortraitCharts/SingleBarChart'
import { StackBarChart as PortraitStackBar } from './PortraitCharts/StackBarChart/StackBarChart'

export default {
  LandscapeSingleBar,
  LandscapeMultipleBars,
  LandscapeStackBars,
  PortraitMultipleBars,
  PortraitSingleBar,
  PortraitStackBar
}
