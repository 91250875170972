const swithColorSchemeSolid = (colorScheme: string) => {
  switch (colorScheme) {
    case 'primary':
      return {
        borderColor: 'primary.500',
        background: 'primary.500',
        _disabled: {
          borderColor: 'primary.200',
          background: 'primary.200',
          _text: {
            color: 'white'
          }
        },
        _pressed: {
          background: 'primary.600',
          _text: {
            color: 'white'
          }
        },
        _hover: {
          background: 'primary.300',
          _text: {
            color: 'white'
          }
        }
      }

    case 'primary-light':
      return {
        borderColor: 'primary.100',
        background: 'primary.100',
        _text: {
          color: 'primary.800'
        },
        _disabled: {
          borderColor: 'primary.200',
          background: 'primary.200',
          _text: {
            color: 'white'
          }
        },
        _hover: {
          style: {
            shadowOffset: { width: 0, height: 3 },
            shadowRadius: 16,
            shadowColor: 'rgba(0, 0, 0, 0.08)'
          }
        }
      }

    case 'gray':
      return {
        borderColor: 'gray.700',
        background: 'gray.700',
        _text: {
          color: 'white',
          fontSize: '14px',
          fontWeight: 'bold',
          lineHeight: '20px'
        },
        _disabled: {
          borderColor: 'gray.200',
          background: 'gray.200',
          opacity: 0.7
        },
        _pressed: {
          background: 'gray.800'
        },
        _hover: {
          background: 'gray.800'
        }
      }

    case 'background':
      return {
        borderColor: 'background.base',
        background: 'background.base',
        _text: {
          color: 'gray.500',
          fontSize: '12px',
          lineHeight: '16px'
        },
        _disabled: {
          borderColor: 'gray.200',
          background: 'gray.200',
          opacity: 1
        },
        _pressed: {
          background: 'gray.100'
        },
        _hover: {
          background: 'gray.100'
        }
      }

    case 'white':
      return {
        borderColor: 'white',
        background: 'white',
        _text: {
          color: 'gray.600',
          fontSize: '14px',
          lineHeight: '16px'
        },
        _disabled: {
          borderColor: 'gray.200',
          background: 'gray.200',
          opacity: 1
        },
        _hover: {
          style: {
            shadowOffset: { width: 0, height: 3 },
            shadowRadius: 16,
            shadowColor: 'rgba(0, 0, 0, 0.08)'
          }
        }
      }

    default:
      return {}
  }
}

const swithColorSchemeOutline = (colorScheme: string) => {
  switch (colorScheme) {
    case 'gray':
      return {
        borderColor: 'gray.700',
        background: 'gray.30',
        _text: {
          color: 'gray.700',
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '20px'
        },
        _disabled: {
          borderColor: 'gray.200',
          background: 'gray.200',
          opacity: 0.7
        },
        _pressed: {
          background: 'gray.50'
        },
        _hover: {
          background: 'gray.50'
        }
      }

    default:
      return {}
  }
}

export const Button = {
  defaultProps: {
    size: 'lg',
    variant: 'solid',
    borderRadius: 'full'
  },
  sizes: {
    lg: {
      h: '50px',
      _text: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '20px',
        fontFamily: 'body'
      }
    },
    md: {
      h: '24px',
      _text: {
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '16px',
        fontFamily: 'body'
      }
    }
  },
  variants: {
    solid: (props: Record<string, any>) => swithColorSchemeSolid(props.colorScheme),
    outline: (props: Record<string, any>) => swithColorSchemeOutline(props.colorScheme)
  }
}
