import { useCallback, useEffect } from 'react'

import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import {
  useConfirmAccountEmailMutation,
  useSendConfirmAccountEmailMutation
} from 'integration/resources/createAccount'
import { useBreakpointValue } from 'native-base'
import { formatEmail } from 'src/utils'

import { UseAccountConfirmEmailScreen } from './AccountConfirmEmailScreen.types'

export const useAccountConfirmEmailScreen: UseAccountConfirmEmailScreen = ({
  navigation,
  route
}) => {
  const {
    params: { id, email }
  } = route

  const resendConfirmEmailMutation = useSendConfirmAccountEmailMutation()

  const bottomSheetContext = useBottomSheetContext()

  const handleResendEmail = useCallback(
    () =>
      resendConfirmEmailMutation.mutate(
        {
          id
        },
        {
          onError: () => {
            bottomSheetContext.open({
              description: `Erro ao reenviar e-mail`,
              title: 'Não foi possível reenviar o e-mail, tente novamente mais tarde'
            })
          },
          onSuccess: () =>
            bottomSheetContext.open({
              description: `Reenviamos um E-MAIL para o endereço ${formatEmail(email)}`,
              title: 'Reenviamos o código de confirmação'
            })
        }
      ),
    [bottomSheetContext, id, email, resendConfirmEmailMutation]
  )

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const confirmAccountEmailMutation = useConfirmAccountEmailMutation()

  const goToEntryScreen = useCallback(
    () =>
      navigation.reset({
        index: 0,
        routes: [{ name: 'Entry' }]
      }),
    [navigation]
  )

  useEffect(() => {
    confirmAccountEmailMutation.mutate(route.params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isMobile,
    isError: confirmAccountEmailMutation.isError,
    isLoading: confirmAccountEmailMutation.isLoading,
    isSuccess: confirmAccountEmailMutation.isSuccess,
    goToEntryScreen,
    handleResendEmail
  }
}
