import { forwardRef, useImperativeHandle, useState } from 'react'

import { Cards, Illustrations, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { LinearGradient } from 'expo-linear-gradient'
import { Center, Box, VStack, useTheme, ZStack, Text, useBreakpointValue } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { formatDateStringToMask } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { useListActivitiesByDate } from './hook'
import { ListTypesRef, ListTypesView } from '../../OrganizationScreen.types'
import TitleByDay from '../TitleByDay'

export const ListActivitiesByDate = forwardRef<ListTypesRef, ListTypesView>(
  ({ navigation, isLoading, data }, ref) => {
    const [search, setSearch] = useState('')

    const {
      list,
      handleComplete,
      isFetchingNextPage,
      isLoading: localIsLoading,
      pagination,
      refreshing,
      fetchMore,
      refetch
    } = useListActivitiesByDate({
      date: data.date,
      search
    })

    const skeletonArray = new Array(6).fill(0)

    useImperativeHandle(ref, () => ({
      refreshing,
      fetchMore,
      refetch,
      search: (value: string) => {
        setSearch(value)
      },
      clearSearch: () => setSearch('')
    }))

    const { width, height } = useWindowDimensions()

    const theme = useTheme()

    const isMobile = useBreakpointValue({ base: true, lg: false })

    return (
      <Box {...(isMobile && { paddingX: 4 })} mt={isMobile ? 4 : 12} position="relative">
        {((localIsLoading && !list?.items.length) || isLoading) && (
          <VStack py={4}>
            {skeletonArray.map((_, i) => (
              <SkeletonCard key={`key-skeleton-${i}`} />
            ))}
          </VStack>
        )}

        {!list?.items.length && !localIsLoading && (
          <Box mt={-6} alignItems="center" justifyContent="center">
            <Illustrations.ActivitiesEmpty />
            <Text fontSize="md" lineHeight="24px" color="gray.500">
              Ainda não há atividades na sua organização.
            </Text>
          </Box>
        )}

        {!isLoading && !localIsLoading && !!list?.items.length && (
          <TitleByDay
            title={
              data.date
                ? formatDateStringToMask(data.date, isMobile ? 'dd MMM' : "d 'de' MMMM, yyyy")
                : ''
            }
            titleFormmated={!isMobile}
            subtitle={data.date ? formatDateStringToMask(data.date, 'iiii') : ''}
            size={Number(pagination?.total) ?? ''}
            type={`Atividade${Number(pagination?.total) > 1 ? 's' : ''}`}
          />
        )}

        {!isLoading &&
          list?.items.map((activity, i) => (
            <Box
              mb={isMobile ? 2 : 4}
              {...(!isMobile && i === 0 && { marginTop: 8 })}
              key={`key-${i}-${activity.title}`}>
              <Cards.OrganizationCard
                expandedDetail
                isLoadingFavorite={isLoading}
                cover={activity.store?.logo_image?.url}
                topText={activity?.title}
                organizationType={activity?.activity_type?.name}
                nameFantasy={activity?.store?.name_fantasy}
                tabNumber={activity?.store?.tab_number}
                midText={
                  activity?.store?.tab_number
                    ? `TAB ${activity?.store?.tab_number}`
                    : 'Minha atividade'
                }
                bottomText={
                  activity.schedule_date
                    ? formatDateStringToMask(activity?.schedule_date, 'dd/MM/yyyy')
                    : undefined
                }
                hour={formatDateStringToMask(activity?.schedule_date, 'HH:mm')}
                attachments={activity?.count_activity_attachments}
                planActions={activity?.activity_plan_action}
                userName={activity?.user_name}
                active={activity?.is_finished}
                icon="Check"
                onPress={() => {
                  LogEvent('atividade', {
                    item_id: activity?.id
                  })

                  navigation.navigate('OrganizationActivity', {
                    id: String(activity?.id)
                  })
                }}
                onCheckPress={(callback, currentCheck) =>
                  handleComplete(callback, currentCheck, activity.id)
                }
              />
            </Box>
          ))}

        {isFetchingNextPage && (
          <Center mt={6}>
            <LoadingTurbo size={48} />
          </Center>
        )}

        {refreshing && (
          <>
            <LinearGradient
              style={{
                position: 'absolute',
                height: 800,
                zIndex: 4,
                flex: 1,
                width
              }}
              colors={['#ffffff05', theme.colors.background.light, '#ffffff05']}
            />
            <ZStack
              position="absolute"
              w={width}
              h={0}
              alignItems="center"
              justifyContent="center"
              top={height / 5}
              zIndex={8}>
              <Center>
                <LoadingTurbo size={48} />
              </Center>
            </ZStack>
          </>
        )}
      </Box>
    )
  }
)
