import { FunctionComponent, useMemo, useEffect, useState } from 'react'

import { Icons, LoadingTurbo } from 'atoms'
import * as ScreenOrientation from 'expo-screen-orientation'
import LottieView from 'lottie-react-native'
import { UnconnectedMessage } from 'molecules'
import {
  Box,
  Center,
  Heading,
  HStack,
  Modal,
  Pressable,
  Text,
  useBreakpointValue,
  VStack
} from 'native-base'
import Chart from 'organisms/Chart'
import { Dimensions } from 'react-native'
import { HomeChartsTypes, homeResumedChartsProps } from 'src/screens/HomeScreen/constants'
import { formatDateStringToMask, verifyHasValue } from 'src/utils'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

type HomeFullChartLayoutProps = {
  title?: string
  last_updated?: string
  subtitles?: { title: string; color: string }[]
  handleGoBack?(): void
  handleGoToProductionScreen(yearMonth: string, type: HomeChartsTypes, total: string): void
  showModal: boolean
  setShowModal(value: boolean): void
  showSpinner: boolean
  type: HomeChartsTypes
  chartData: any
  chartsDataIsLoading: boolean
  colors: string[]
  tabNumber?: string
  tooltips: { values: any; titles: string[] }
  chartLabels?: string[]
  processed_at?: string
}

export const HomeFullChartLayout: FunctionComponent<HomeFullChartLayoutProps> = ({
  handleGoBack,
  handleGoToProductionScreen,
  setShowModal,
  showModal,
  showSpinner,
  type,
  chartData,
  chartsDataIsLoading,
  tooltips,
  colors,
  chartLabels,
  processed_at
}) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const [spinner, setSpinner] = useState(true)

  useEffect(() => {
    if (!spinner) return

    const timeoutSpinner = setTimeout(() => {
      setSpinner(!spinner)
    }, 3000)

    return () => {
      clearTimeout(timeoutSpinner)
    }
  }, [spinner])

  const chartLabelsIsQuantity = useMemo(
    () =>
      [
        HomeChartsTypes.AMOUNT_SIMULATIONS,
        HomeChartsTypes.AMOUNT_CONTRACTS,
        HomeChartsTypes.AMOUNT_APNEF,
        HomeChartsTypes.AMOUNT_ACTIVE_STORES,
        HomeChartsTypes.AMOUNT_INACTIVES,
        HomeChartsTypes.AMOUNT_STORES,
        HomeChartsTypes.AMOUNT_HALF_PRODUCTION,
        HomeChartsTypes.CONTRACTS_DELINQUENCY_QTY
      ].includes(type),
    [type]
  )

  const isStackChart = useMemo(
    () =>
      [
        HomeChartsTypes.SIMULATIONS_RATING,
        HomeChartsTypes.CONTRACTS_RATING,
        HomeChartsTypes.PRODUCTION_SUBSEGMENT,
        HomeChartsTypes.OVERDUE
      ].includes(type),
    [type]
  )

  const isMultipleChart = useMemo(
    () => [HomeChartsTypes.AMOUNT_ACTIVE_STORES, HomeChartsTypes.WALLET_SITUATION].includes(type),
    [type]
  )

  const phoneWidth = Dimensions.get('window').width

  const lowWidthPhone = phoneWidth < 700

  const hasDinamicLabels = !!chartLabels?.length

  const onGoBack = () => {
    setSpinner(true)

    !isDesktop && changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP)

    handleGoBack?.()
  }

  const [onReady, setOnReady] = useState(false)

  useEffect(() => {
    const timeoutOnReady = setTimeout(() => {
      setOnReady(true)
    }, 5000)

    return () => {
      clearTimeout(timeoutOnReady)
    }
  }, [onReady])

  return (
    <>
      <Box
        flex={1}
        safeAreaLeft={!isDesktop}
        safeAreaBottom={!isDesktop}
        onLayout={(e) => {
          if (e.nativeEvent.layout.width.toFixed() === phoneWidth.toFixed()) {
            setOnReady(true)
          }
        }}
        py={0}
        px={6}
        _ios={{ pt: lowWidthPhone ? 12 : 20 }}
        bg="white">
        {onReady || isDesktop ? (
          <>
            {(showSpinner || spinner) && (
              <Box position="absolute" bg="white" zIndex={9} left={0} right={0} top={0} bottom={0}>
                <LoadingTurbo />
              </Box>
            )}
            {!isDesktop && (
              <HStack
                justifyContent="space-between"
                alignItems="center"
                zIndex={2}
                _android={{ mt: 4 }}
                mb="17px">
                <VStack>
                  <Heading fontSize="14px">{homeResumedChartsProps[type].title}</Heading>
                  {!isStackChart && (
                    <Text fontSize="10px" fontWeight={600} color="gray.400">
                      Carteira
                    </Text>
                  )}
                </VStack>
                <Pressable onPress={onGoBack}>
                  <Center bg="background.base" borderRadius="full" size={10} p={3}>
                    <Icons.Close size={4} color="gray.700" />
                  </Center>
                </Pressable>
              </HStack>
            )}

            <Box>
              {chartsDataIsLoading ? (
                <>
                  <LoadingTurbo />
                </>
              ) : verifyHasValue(chartData) ? (
                isMultipleChart ? (
                  <Chart.LandscapeMultipleBars
                    colors={colors}
                    chartsData={chartData}
                    onClickChart={({ monthYear, total }) =>
                      handleGoToProductionScreen(monthYear, type, total)
                    }
                    type={homeResumedChartsProps[type].type ?? 'qtd'}
                  />
                ) : isStackChart ? (
                  <Chart.LandscapeStackBars
                    colors={colors}
                    chartsData={chartData}
                    chartType={type}
                    tooltips={tooltips}
                    type={homeResumedChartsProps[type].type ?? 'qtd'}
                  />
                ) : (
                  <Chart.LandscapeSingleBar
                    chartData={chartData}
                    color={undefined}
                    onClickChart={({ monthYear, total }) =>
                      handleGoToProductionScreen(monthYear, type, total)
                    }
                    type={chartLabelsIsQuantity ? 'quantity' : 'currency'}
                  />
                )
              ) : (
                <UnconnectedMessage />
              )}
            </Box>

            <HStack flex={{ base: 1, lg: 0 }} mt={{ base: 0, lg: 6 }}>
              <HStack alignItems="flex-start" flex={1} space={4}>
                {hasDinamicLabels
                  ? chartLabels &&
                    chartLabels.length > 0 &&
                    chartLabels.map((subtitle, index) => (
                      <HStack alignItems="center" maxW="50%">
                        <Center
                          bg={colors[index]}
                          borderRadius="full"
                          size={!isDesktop ? 3 : 4}
                          mr="6px"
                        />
                        <Text color="gray.500" fontSize={!isDesktop ? '12px' : '14px'} isTruncated>
                          {subtitle}
                        </Text>
                      </HStack>
                    ))
                  : homeResumedChartsProps[type].subtitles &&
                    homeResumedChartsProps[type].subtitles.length > 0 &&
                    homeResumedChartsProps[type].subtitles.map((subtitle) => (
                      <HStack alignItems="center" maxW="50%">
                        <Center
                          bg={subtitle.color}
                          borderRadius="full"
                          size={!isDesktop ? 3 : 4}
                          mr="6px"
                        />
                        <Text color="gray.500" fontSize={!isDesktop ? '12px' : '14px'} isTruncated>
                          {subtitle.title}
                        </Text>
                      </HStack>
                    ))}
              </HStack>
            </HStack>
            {processed_at && (
              <HStack justifyContent="flex-end">
                <Text color="gray.500" fontSize="10px" fontWeight="semibold">
                  Data de Ref. -{' '}
                  {processed_at && formatDateStringToMask(processed_at, 'dd/MM/yyyy')}
                </Text>
              </HStack>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
      {!isDesktop && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          _backdrop={{
            mb: -100,
            bg: 'black',
            opacity: '0.65'
          }}>
          <Center>
            <LottieView
              autoPlay
              loop
              style={{
                height: 280
              }}
              source={require('assets/change-orientation.json')}
            />
          </Center>
        </Modal>
      )}
    </>
  )
}
