import { FunctionComponent } from 'react'

import { Box, useTheme } from 'native-base'
import { Dimensions, Platform } from 'react-native'
import { getNumberYearAndMonth, percentageCalculate } from 'src/utils'
import { currencyFormatChart, toFixedNoRounding } from 'src/utils/currencyFormat'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory-native'

export type ChartData = {
  value: number
  year_month: string
}[]

export type SingleBarChartProps = {
  chartData: ChartData
  higherValue?: number
  tickValues?: string[]
  horizontalLabels?: boolean
  color?: string
  onClickChart?({ monthYear, total }: { monthYear: string; total: string }): void
  type?: 'percentage' | 'currency'
}

export const SingleBarChart: FunctionComponent<SingleBarChartProps> = ({
  chartData,
  horizontalLabels,
  higherValue = 0,
  tickValues,
  color,
  onClickChart,
  type
}) => {
  const theme = useTheme()

  const isMobile = Platform.OS !== 'web'

  // we use this constant to remedy the limitations of the charts library in relation to responsiveness
  const phoneWidth = Dimensions.get('window').width
  //

  const needsLabel = {
    yes: true,
    no: false
  }

  const needsShortcut = {
    yes: true,
    no: false
  }

  const tickValuesAxis =
    type === 'percentage'
      ? [0, 50, 100]
      : [0, Number(toFixedNoRounding(higherValue * 0.5, 1)), higherValue]

  return (
    <Box mt={isMobile ? -10 : 0} ml={horizontalLabels ? 0 : isMobile ? -8 : 0}>
      <VictoryChart
        theme={VictoryTheme.material}
        height={260}
        width={isMobile ? (horizontalLabels ? phoneWidth * 0.89 : phoneWidth) : 490}
        domain={{ x: [1, 6] }}
        domainPadding={{ x: 20 }}>
        <VictoryAxis
          tickValues={tickValuesAxis}
          tickFormat={(t) => String(t).split('-')[0]}
          orientation="bottom"
          style={{
            tickLabels: {
              fontSize: 14,
              padding: 10,
              fill: theme.colors.gray[500]
            },
            ticks: { size: 0 },
            axis: { stroke: 0 },
            grid: { stroke: 0 }
          }}
          standalone={false}
        />

        {horizontalLabels && (
          <VictoryAxis
            dependentAxis
            tickValues={tickValuesAxis}
            tickFormat={(t) =>
              t && type === 'currency'
                ? currencyFormatChart(String(t), needsLabel.yes, needsLabel.no, 0)
                : type === 'percentage'
                ? `${parseInt(t, 10)}%`
                : parseInt(t, 10)
            }
            style={{
              tickLabels: {
                fontSize: 12,
                fill: theme.colors.gray[500],
                padding: -4
              },
              axisLabel: {
                stroke: 0
              },
              ticks: { size: 8, stroke: 0 },
              axis: { stroke: 0 },
              grid: {
                //@ts-ignore
                strokeWidth: ({ tickValue }) => (tickValue === 0 ? 1 : 0),
                stroke: theme.colors.gray[100]
              }
            }}
            standalone={false}
          />
        )}
        <VictoryBar
          data={chartData}
          x="year_month"
          y="value"
          barWidth={24}
          domainPadding={{ x: horizontalLabels ? 34 : 0 }}
          padding={44}
          cornerRadius={{
            top: ({ datum }) => {
              const percentage = percentageCalculate(higherValue ?? 0, datum.value ?? 0)

              if (percentage > 13) return 12

              if (percentage > 8) return 8

              if (percentage > 5) return 6

              if (percentage > 3) return 4

              return 2
            },
            bottom: ({ datum }) => {
              const percentage = percentageCalculate(higherValue ?? 0, datum.value ?? 0)

              if (percentage > 13) return 12

              if (percentage > 8) return 8

              if (percentage > 5) return 6

              if (percentage > 3) return 4

              return 2
            }
          }}
          style={{
            data: { fill: color ? color : theme.colors.primary[500] },
            //@ts-ignore
            labels: {
              fontSize: 12,
              //@ts-ignore
              fill: ({ datum }) =>
                datum.value === 0 ? theme.colors.gray[500] : theme.colors.primary[500],
              fontWeight: 500
            }
          }}
          labels={({ datum }) =>
            type === 'currency'
              ? currencyFormatChart(datum.value, needsLabel.no, needsShortcut.no, 2)
              : type === 'percentage'
              ? `${datum.value.toFixed(2)}%`
              : datum.value
          }
          events={[
            {
              target: 'data',
              eventHandlers: {
                onPressOut: () => [
                  {
                    target: 'data',

                    mutation: (props) =>
                      onClickChart?.({
                        monthYear: getNumberYearAndMonth(props.datum.year_month).monthNumber,
                        total: props.datum.value
                      })
                  }
                ]
              }
            }
          ]}
        />
      </VictoryChart>
    </Box>
  )
}
