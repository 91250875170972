import { FunctionComponent } from 'react'

import { Icons, LoadingTurbo } from 'atoms'
import { Opportunity } from 'integration/resources/opportunities'
import {
  FlatList,
  Box,
  HStack,
  Pressable,
  Text,
  VStack,
  useDisclose,
  IconButton,
  Divider,
  Center
} from 'native-base'
import { useBottomSheetOpportunities } from 'organisms/BottomSheetOpportunities/hook'

type TSelectOpportunities = {
  value?: Opportunity
  handleSelect(opportunity?: Opportunity): void
  isDisabled?: boolean
  storeTabNumber: number
}

export const SelectOpportunities: FunctionComponent<TSelectOpportunities> = ({
  value,
  handleSelect,
  isDisabled,
  storeTabNumber
}) => {
  const { isOpen, onToggle } = useDisclose(false)

  const { opportunityResponse, isLoadingOpportunities, onScrollEndDragOpportunityList } =
    useBottomSheetOpportunities({ storeTabNumber, enabled: !!storeTabNumber })

  return (
    <Box position="relative">
      <Pressable
        onPress={onToggle}
        isDisabled={isDisabled}
        alignItems="center"
        w="full"
        h="50px"
        bg="gray.30"
        borderRadius="30px"
        _disabled={{
          opacity: 0.5
        }}
        flexDir="row"
        px={4}
        py="13px">
        <Icons.Cash color="gray.600" />

        {value ? (
          <Text
            ml={2}
            flex={1}
            color="gray.700"
            fontSize="16px"
            lineHeight="24px">{`${value.title}`}</Text>
        ) : (
          <Text flex={1} ml={2} color="gray.500" fontSize="16px" lineHeight="24px">
            Oportunidade vinculada
          </Text>
        )}

        <HStack alignItems="center" space={2}>
          {value && (
            <IconButton
              borderRadius="full"
              colorScheme="gray"
              onPress={() => handleSelect(undefined)}
              icon={<Icons.CloseTab color="gray.700" size={4} />}
            />
          )}
          {isLoadingOpportunities ? (
            <LoadingTurbo />
          ) : (
            <Icons.ArrowDropDown size={6} color="gray.700" />
          )}
        </HStack>
      </Pressable>

      {isOpen && (
        <VStack
          position="static"
          bg="white"
          maxH="500px"
          w="99%"
          mx="auto"
          style={{
            //@ts-ignore
            boxShadow: '0px 5px 15px rgba(68, 68, 79, 0.1)'
          }}
          top={10}
          _important={{
            zIndex: 999
          }}
          borderRadius="20px"
          py={8}>
          <FlatList
            data={opportunityResponse?.items}
            keyExtractor={(item) => `${item.id}`}
            ListEmptyComponent={
              <Center>
                <Text>Sem oportunidades</Text>
              </Center>
            }
            onEndReached={onScrollEndDragOpportunityList}
            renderItem={({ item, index }) => (
              <>
                <Pressable
                  onPress={() => {
                    onToggle()

                    handleSelect(item)
                  }}
                  flexDir="row"
                  alignItems="center"
                  _hover={{
                    bg: 'gray.30'
                  }}
                  h={10}
                  px={8}
                  {...(item.id === value?.id && { bg: 'gray.30' })}
                  py={4}
                  key={item.id}>
                  <Text bold>{item.title} </Text>
                </Pressable>
                {index + 1 < (opportunityResponse?.total_opportunities ?? 0) && index > 0 && (
                  <Divider bg="gray.30" my={2} />
                )}
              </>
            )}
          />
        </VStack>
      )}
    </Box>
  )
}
