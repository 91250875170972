import { useState } from 'react'

import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { useAuthSendCodeMutation } from 'integration/resources/auth'
import { useAuthCodeAtom } from 'src/store/authCode'
import { formatEmail, formatHidePhoneMask } from 'src/utils/formatters'

export const useSendAuthCode = () => {
  const [authCodeAtom, setAuthCodeAtom] = useAuthCodeAtom()

  const [method, setMethod] = useState<'email' | 'sms'>()

  const authSendCodeMutation = useAuthSendCodeMutation()

  const bottomSheetContext = useBottomSheetContext()

  const handleSendAuthCodeSms = async (params?: {
    user_id: string
    slt: string
    phone_number_cell: string
  }) => {
    setMethod('sms')

    try {
      await handleSendAuthCode('sms', params)

      bottomSheetContext.open({
        // testID: 'login-authenticate-resend-ok',
        illustrationName: 'ReSentCode',
        title: 'Reenviamos o código de confirmação',
        description: `Reenviamos um SMS para o número: \n ${
          formatHidePhoneMask(authCodeAtom?.phone_number_cell) ??
          formatHidePhoneMask(params?.phone_number_cell)
        }`
      })
    } catch (err) {
      bottomSheetContext.open({
        illustrationName: 'TimeInForWork',
        // testID: 'login-authenticate-exceeded-ok',
        title: 'Atenção',
        description:
          'Esta ação está indisponível por excesso de tentativas com falha. Tente novamente em algumas horas.'
      })
    }
  }

  const handleSendAuthCodeEmail = async () => {
    try {
      setMethod('email')

      await handleSendAuthCode('email')

      bottomSheetContext.open({
        illustrationName: 'ReSentCode',
        title: 'Reenviamos o código de confirmação',
        description: `Reenviamos um E-MAIL para o o endereço: \n ${formatEmail(
          authCodeAtom!.email
        )}`
      })
    } catch (err) {
      bottomSheetContext.open({
        illustrationName: 'TimeInForWork',
        title: 'Atenção',
        // testID: 'login-authenticate-exceeded-ok',
        description:
          'Esta ação está indisponível por excesso de tentativas com falha. Tente novamente em algumas horas.'
      })
    }
  }

  /**
   * Send code to authentication
   * @param type 'email' | 'sms' used to define which request called
   * @returns Promise
   */
  const handleSendAuthCode = (type: 'email' | 'sms', params?: { user_id: string; slt: string }) =>
    authSendCodeMutation.mutateAsync({
      user_id: params?.user_id ?? authCodeAtom!.user_id,
      slt: params?.slt ?? authCodeAtom!.slt,
      type
    })

  const clearAuthCode = () => setAuthCodeAtom(undefined)

  return {
    slt: authCodeAtom?.slt,
    clearAuthCode,
    handleSendAuthCode,
    handleSendAuthCodeSms,
    handleSendAuthCodeEmail,
    isLoadingAuthCode: authSendCodeMutation.isLoading,
    isLoadingAuthSendCodeSms: method === 'sms' && authSendCodeMutation.isLoading,
    isLoadingAuthSendCodeEmail: method === 'email' && authSendCodeMutation.isLoading,
    setAuthCodeAtom
  }
}
