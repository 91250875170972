import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { Box, Heading, HStack, Pressable, useBreakpointValue } from 'native-base'

type TButtonAttach = {
  title: string
  handlePickAttachment(): void
}

export const ButtonAttach: FunctionComponent<TButtonAttach> = ({ title, handlePickAttachment }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <Box w="full">
      <Pressable onPress={handlePickAttachment} justifyContent="space-between" borderRadius="30px">
        <HStack
          h="56px"
          bg={isDesktop ? 'gray.30' : 'white'}
          borderRadius="40px"
          justifyContent="space-between"
          alignItems="center">
          <Icons.Clip zIndex={1} color="gray.500" ml={4} size={6} />
          <Box ml={4} flex={1}>
            <Heading fontWeight={400} color="gray.400" fontSize="16px">
              {title}
            </Heading>
          </Box>
          <Icons.Add outilned color="gray.700" size={6} mr={4} />
        </HStack>
      </Pressable>
    </Box>
  )
}
