import { FunctionComponent } from 'react'

import Icons from 'atoms/Icons'
import StackedBarChart from 'atoms/StackedBarChart'
import { RvStatementData } from 'integration/resources/rv'
import { VStack, Heading, Text, HStack, Divider, useTheme } from 'native-base'
import { decimalToPercent, formatToReal } from 'src/utils'

type SummaryDetailCardProps = RvStatementData

export const SummaryDetailCard: FunctionComponent<SummaryDetailCardProps> = ({
  title,
  goal_value,
  real_value,
  percentage,
  weight_value,
  real_points_value,
  projection_percent
}) => {
  const theme = useTheme()

  return (
    <VStack bg="white" borderRadius={20} space={3}>
      <Heading fontSize="14px" mt={4} px={4}>
        {title}
      </Heading>

      <HStack space={2} px={4} alignItems="center">
        <Text fontSize="12px" color="gray.700">
          Realizado:
        </Text>
        <Text fontSize="12px" color="gray.700" fontWeight="bold">
          {formatToReal(real_value)}
        </Text>
        <Text fontSize="12px" color="gray.300">
          {Number(percentage)}% ({real_points_value}Pts)
        </Text>
      </HStack>

      <HStack space={2} px={4} alignItems="center">
        <Text fontSize="12px" color="gray.700">
          Meta:
        </Text>
        <Text fontSize="12px" color="gray.700" fontWeight="bold">
          {formatToReal(goal_value)}
        </Text>
      </HStack>

      <VStack flex={1} space={2} mx={4}>
        <StackedBarChart
          expectedValue={Number(projection_percent)}
          currentValue={Number(percentage)}
          expectedIsStriped
          currentIsStriped={false}
          maxValue={200}
        />
      </VStack>

      <Divider bg="gray.50" />

      <HStack space={2} px={4} pb={4} alignItems="center" justifyContent="space-between">
        <HStack space={4}>
          <HStack alignItems="center" space={1}>
            <Icons.SummaryStatus
              color={Number(percentage) >= 100 ? 'secondary.500' : 'error.500'}
            />
            <Text fontSize="10px" color="gray.400" fontWeight={600} h="14px" mt="-2px">
              Realizado (%)
            </Text>
          </HStack>

          <HStack alignItems="center" space={1}>
            <Icons.SummaryStatus
              color={
                Number(projection_percent) >= 100
                  ? theme.colors.secondary[300]
                  : theme.colors.error[500]
              }
              dashedCurrentColor={
                Number(projection_percent) >= 100
                  ? theme.colors.secondary[500]
                  : theme.colors.primary[800]
              }
              projected
            />
            <Text fontSize="10px" color="gray.400" fontWeight={600} h="14px" mt="-2px">
              Projetado (%)
            </Text>
          </HStack>
        </HStack>

        <Text fontSize="10px" color="gray.300" fontWeight={600}>
          peso ({decimalToPercent(weight_value)})
        </Text>
      </HStack>
    </VStack>
  )
}
