import { useCallback, useEffect } from 'react'

import { useLinkTo } from '@react-navigation/native'
import {
  KEY_NOT_READ_NOTIFICATIONS_QUERY,
  TNotificationType,
  useGetDetailNotificationQuery
} from 'integration/resources/notifications'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'

import { UseNotificationDetailScreen } from './NotificationDetailScreen.types'

export const useNotificationDetailScreen: UseNotificationDetailScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()

  const { id } = route?.params

  const linkTo = useLinkTo()

  const { data, isLoading } = useGetDetailNotificationQuery({ id })

  const notification = data?.data.data

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const hiddenButton =
    notification?.notification_type_enum === 'COMMUNICATION' && !notification?.extras?.contentId

  const selectTypeNotification = (type: TNotificationType = 'WALLET') => {
    const object = {
      WALLET: {
        title: 'Carteira',
        titleButton: 'Ver carteira',
        handleAction: () => navigation.navigate('Tab', { screen: 'Wallet', params: { tabId: 1 } })
      },
      CONTENT: {
        title: 'Conteúdos',
        titleButton: 'Ver conteúdo',
        handleAction: () => {
          if (notification?.extras?.contentId)
            navigation.navigate('Content', { id: notification?.extras?.contentId })
        }
      },
      ORGANIZATION: {
        title: 'Minha organização',
        titleButton: 'Ver atividades',
        handleAction: () =>
          navigation.navigate('Tab', { screen: 'Organization', params: { tabId: 1 } })
      },
      OPPORTUNITY: {
        title: 'Minha organização',
        titleButton: notification?.extras?.contentId ? 'Ver oportunidade' : 'Ver oportunidades',
        handleAction: () => {
          if (notification?.extras?.contentId) {
            navigation.navigate('OrganizationOpportunity', {
              id: notification.extras.contentId
            })

            return
          }

          return isMobile
            ? navigation.navigate('Tab', { screen: 'Organization', params: { tabId: 2 } })
            : linkTo('/minha-organizacao/oportunidades')
        }
      },
      ACTIVITY: {
        title: 'Minha organização',
        titleButton: notification?.extras?.contentId ? 'Ver atividade' : 'Ver atividades',
        handleAction: () => {
          if (notification?.extras?.contentId) {
            navigation.navigate('OrganizationActivity', {
              id: notification?.extras.contentId
            })

            return
          }

          return isMobile
            ? navigation.navigate('Tab', { screen: 'Organization', params: { tabId: 2 } })
            : linkTo('/minha-organizacao/atividades')
        }
      },
      COMMUNICATION: {
        title: 'Comunicado institucional',
        titleButton: 'Ver comunicado',
        handleAction: () => {
          if (notification?.extras?.contentId) {
            return navigation.navigate('Content', { id: notification?.extras?.contentId })
          }

          return navigation.navigate('HomeContent')
        }
      },
      BIRTHDATE: {
        title: 'Aniversários',
        titleButton: 'Ver contato',
        //@ts-ignore
        handleAction: () => {
          if (notification?.extras?.contactId) {
            navigation.navigate('WalletContact', { contactId: notification.extras?.contactId })
          }
        }
      },
      SURVEY: {
        title: 'Pesquisas',
        titleButton: 'Ver pesquisa',
        handleAction: () => {
          if (notification?.extras?.surveyId)
            navigation.navigate('SurveyDetail', { id: notification?.extras?.surveyId })
        }
      },
      DEFAULT: {
        title: 'Notificação não configurada',
        titleButton: 'Ver detalhes',
        handleAction: () => {}
      }
    }

    return type in object ? object[type] : object['DEFAULT']
  }

  useEffect(() => {
    queryClient.invalidateQueries(KEY_NOT_READ_NOTIFICATIONS_QUERY)
  }, [queryClient])

  return {
    isMobile,
    isLoading,
    handleGoBack,
    notification,
    selectTypeNotification,
    hiddenButton
  }
}
