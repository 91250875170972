import { useEffect, useMemo, useRef, useState } from 'react'

import { Column } from '@tanstack/react-table'
import { Icons } from 'atoms'
import { Dropdown, DropdownRefProps } from 'atoms/web/Dropdown'
import { Box, Button, HStack, IconButton, Input, Text } from 'native-base'
import { Dimensions } from 'react-native'
import useDebounce from 'src/hooks/useDebounce'

const screenHeight = Dimensions.get('window').height

function FilterTable({
  column,
  showUniqueValues = true
}: {
  column: Column<any, unknown>
  showUniqueValues?: boolean
}) {
  const isNumberFilter = typeof column.getFacetedUniqueValues().keys().next().value === 'number'

  const [filterValue, setFilterValue] = useState<string>('')

  const debouncedFilterValue = useDebounce(filterValue, 900)

  useEffect(() => {
    column.setFilterValue(debouncedFilterValue)
  }, [debouncedFilterValue, column])

  const sortedUniqueValues = useMemo(() => {
    return isNumberFilter
      ? (Array.from(column.getFacetedUniqueValues().keys()).sort((a, b) => a - b) as string[])
      : (Array.from(column.getFacetedUniqueValues().keys())
          .filter((value) => value !== '-')
          .sort() as string[])
  }, [column, isNumberFilter])

  const menuFilterRef = useRef<DropdownRefProps>(null)

  return (
    <>
      <HStack space={1} alignItems="center">
        <Input
          key="table-filter-search"
          onChange={(e: any) => {
            setFilterValue(e.target.value || undefined)
          }}
          value={filterValue}
          position="relative"
          w={150}
          pl={0}
          pr={0}
          h={screenHeight > 900 ? '40px' : '32px'}
          borderWidth={1}
          borderColor="gray.200"
          borderRadius={14}
          fontSize={screenHeight > 900 ? '14px' : '12px'}
          overflowY={screenHeight > 900 ? 'auto' : 'hidden'}
          placeholder="Pesquisar"
          autoCapitalize="none"
          bgColor="white"
          color="gray.400"
          rightElement={
            filterValue ? (
              <IconButton
                borderRadius="full"
                colorScheme="gray"
                onPress={() => {
                  setFilterValue('')
                }}
                icon={<Icons.Close size={3} color="gray.500" />}
              />
            ) : (
              <>
                {showUniqueValues && sortedUniqueValues.length > 0 && !filterValue ? (
                  <Dropdown
                    key="table-filter-dropdown"
                    ref={menuFilterRef}
                    label=""
                    active={false}
                    buttonProps={{
                      px: 1,
                      h: 'auto'
                    }}
                    renderItems={
                      <Box overflowY="auto" maxHeight={250} borderRadius={14} p={2}>
                        {sortedUniqueValues.map((value) => {
                          const count = column.getFacetedUniqueValues().get(value)

                          if (!count) return null

                          return (
                            <Button
                              key={value}
                              variant="link"
                              onPress={() => {
                                setFilterValue(value)

                                menuFilterRef.current?.close()
                              }}>
                              <Text color="gray.700" fontSize="14px">
                                {value} ({count})
                              </Text>
                            </Button>
                          )
                        })}
                      </Box>
                    }
                  />
                ) : undefined}
              </>
            )
          }
        />
      </HStack>
    </>
  )
}

export default FilterTable
