import { forwardRef, useImperativeHandle, useEffect, useState } from 'react'

import { Cards, Icons, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { LinearGradient } from 'expo-linear-gradient'
import { UnconnectedMessage } from 'molecules'
import { FavoritListCard } from 'molecules/FavoritListCard'
import {
  Center,
  Box,
  VStack,
  Text,
  Button,
  ZStack,
  useTheme,
  Heading,
  HStack,
  View,
  useBreakpointValue
} from 'native-base'
import { BottomSheetActionIllustration, BottomSheetEvaluationOpportunity } from 'organisms'
import { useWindowDimensions, Platform } from 'react-native'
import { LogEvent } from 'src/utils/logEvents'

import { useListOpportunities } from './hook'
import { ListTypesRef, ListTypesView } from '../../OrganizationScreen.types'
import { opportunityViewTypes } from '../../useOrganizationScreen'

export const ListOpportunities = forwardRef<ListTypesRef, ListTypesView>(
  ({ navigation, handleSelect, isLoading }, ref) => {
    const [search, setSearch] = useState('')

    const isMobile = useBreakpointValue({ base: true, lg: false })

    const {
      list,
      refetch,
      fetchMore,
      refreshing,
      isFetchingNextPage,
      isLoading: localIsLoading,
      isLoadingEvaluantion,
      isOpenEvaluation,
      setIsOpenEvaluantion,
      handleEvaluate,
      handleUpdateOpportunityHighlighted,
      evaluationSuccessSheetRef,
      closeEvaluationSuccessSheet,
      setOpportunityEvaluation
    } = useListOpportunities({
      search
    })

    const skeletonArray = new Array(6).fill(0)

    useImperativeHandle(ref, () => ({
      fetchMore,
      refetch,
      refreshing,
      search: (value: string) => {
        setSearch(value)
      },
      clearSearch: () => setSearch('')
    }))

    const { width, height } = useWindowDimensions()

    const theme = useTheme()

    useEffect(() => {
      navigation.addListener('focus', () => {
        refetch()
      })
    }, [navigation, refetch])

    return (
      <VStack mx={4} mt={10}>
        <Box zIndex={0}>
          {localIsLoading && (
            <VStack py={4} w={width - 32}>
              {skeletonArray.map((_, i) => (
                <SkeletonCard key={`key-skeleton-${i}`} />
              ))}
            </VStack>
          )}
          {!list?.items.length && !localIsLoading && <UnconnectedMessage />}

          {Platform.OS !== 'web' &&
            !!list?.highlights?.total_opportunities &&
            !!list?.highlights?.items?.length && (
              <FavoritListCard
                title="Destaques"
                type={`Oportunidade${
                  list?.highlights?.total_opportunities && list?.highlights?.total_opportunities > 1
                    ? 's'
                    : ''
                }`}
                quantity={list?.highlights?.total_opportunities ?? 0}>
                <>
                  {list?.highlights?.items?.map((opportunity) => (
                    <Cards.OrganizationCard
                      key={opportunity.id}
                      topText={opportunity.title}
                      midText={
                        opportunity.store?.name_fantasy
                          ? `TAB ${opportunity.store?.tab_number} • ${opportunity.dueDate}`
                          : undefined
                      }
                      bottomText={`R$ ${opportunity.earningPotential}`}
                      userName={opportunity.user_name}
                      active={opportunity.is_finished}
                      icon="Dollar"
                      onPress={() => {
                        LogEvent('oportunidade', {
                          item_id: opportunity?.id
                        })

                        navigation.navigate('OrganizationOpportunity', {
                          id: String(opportunity.id)
                        })
                      }}
                      onCheckPress={
                        !opportunity.isFinished
                          ? (callback, currentCheck) => {
                              setOpportunityEvaluation(opportunity)

                              setIsOpenEvaluantion(true)

                              callback(currentCheck)
                            }
                          : undefined
                      }
                    />
                  ))}

                  {list?.highlights?.total_opportunities > 3 && (
                    <Button
                      pb={0}
                      colorScheme="gray"
                      bgColor="transparent"
                      rightIcon={<Icons.ArrowRightGo />}
                      onPress={() => {
                        handleSelect(opportunityViewTypes[1])
                      }}>
                      <Text color="white">Ver todos os destaques</Text>
                    </Button>
                  )}
                </>
              </FavoritListCard>
            )}

          <HStack justifyContent="space-between" mb={4}>
            {!isLoading && !localIsLoading && !!list?.items.length && (
              <Heading fontWeight="bold" lineHeight={{ base: '28px', lg: '38px' }} fontSize="24px">
                {isMobile ? 'Todas' : 'Todas as oportunidades'}
              </Heading>
            )}

            {!isLoading && !localIsLoading && !!list?.items.length && (
              <View>
                <Box
                  flexDir="row"
                  bgColor={{ base: 'primary.800', lg: undefined }}
                  px={3}
                  py={1}
                  borderRadius={20}>
                  {isMobile ? (
                    <Heading color="white" fontSize={12} fontWeight="bold" fontFamily="heading">
                      {list?.total_opportunities}{' '}
                      {`Oportunidade${Number(list?.total_opportunities) > 1 ? 's' : ''}`}
                    </Heading>
                  ) : (
                    <Text color="gray.400" fontSize="16px" fontWeight="normal">
                      {list?.total_opportunities}{' '}
                      {`Oportunidade${Number(list?.total_opportunities) > 1 ? 's' : ''}`}
                    </Text>
                  )}
                </Box>
              </View>
            )}
          </HStack>

          {!isLoading &&
            list?.items.map((opportunity, i) => (
              <Box mb={2} key={`key-${i}-${opportunity.title}`}>
                <Cards.OrganizationCard
                  expandedDetail
                  isLoadingFavorite={isLoading}
                  disableHandleFavorite={
                    !opportunity.highlightedByUserStdCode && opportunity.is_highlighted
                  }
                  points={opportunity.rv_simulation_goal_points}
                  cover={opportunity.store?.logo_image?.url}
                  topText={opportunity.title}
                  nameFantasy={opportunity.store.name_fantasy}
                  tabNumber={opportunity.store?.tab_number}
                  favorite={opportunity.is_highlighted}
                  midText={
                    opportunity.store?.name_fantasy
                      ? `TAB ${opportunity.store?.tab_number} • ${opportunity.dueDate}`
                      : undefined
                  }
                  bottomText={`R$ ${opportunity.earningPotential}`}
                  userName={opportunity.user_name}
                  active={opportunity.is_finished}
                  icon="Dollar"
                  handleFavorite={() =>
                    handleUpdateOpportunityHighlighted(opportunity.id, opportunity.is_highlighted)
                  }
                  onPress={() => {
                    LogEvent('oportunidade', {
                      item_id: opportunity?.id
                    })

                    navigation.navigate('OrganizationOpportunity', {
                      id: String(opportunity.id)
                    })
                  }}
                  onCheckPress={
                    !opportunity.isFinished
                      ? (callback, currentCheck) => {
                          setOpportunityEvaluation(opportunity)

                          setIsOpenEvaluantion(true)

                          callback(currentCheck)
                        }
                      : undefined
                  }
                />
              </Box>
            ))}

          {isFetchingNextPage && (
            <Center mt={6}>
              <LoadingTurbo size={48} />
            </Center>
          )}

          {refreshing && !isLoading && (
            <>
              <LinearGradient
                style={{
                  position: 'absolute',
                  height: 800,
                  zIndex: 4,
                  flex: 1,
                  width
                }}
                colors={['#ffffff05', theme.colors.background.light, '#ffffff05']}
              />

              <ZStack
                position="absolute"
                w={width - 32}
                h={0}
                alignItems="center"
                justifyContent="center"
                top={height / 5}
                zIndex={8}>
                <Center>
                  <LoadingTurbo size={48} />
                </Center>
              </ZStack>
            </>
          )}
        </Box>
        <BottomSheetEvaluationOpportunity
          isLoading={isLoadingEvaluantion}
          isOpen={isOpenEvaluation}
          handleClose={() => setIsOpenEvaluantion(false)}
          onSubmit={handleEvaluate}
        />

        <BottomSheetActionIllustration
          illustration="EvaluationSuccess"
          ref={evaluationSuccessSheetRef}
          title="Obrigado por avaliar esta oportunidade"
          description="Desta forma nós podemos separar as oportunidades que melhor combinam com o seu perfil."
          handleConfirm={() => closeEvaluationSuccessSheet()}
          buttonTextConfirm={Platform.OS === 'web' ? 'Confirmar' : 'Ok'}
        />
      </VStack>
    )
  }
)
