import { FunctionComponent, memo } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Illustrations } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { OpportunityCard } from 'atoms/web/Card/OpportunityCard'
import { Center, ScrollView, Text, VStack } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { BottomSheetActionIllustration, BottomSheetEvaluationOpportunity } from 'organisms'
import { Platform } from 'react-native'
import { LogEvent } from 'src/utils/logEvents'

import { useOpportunityContainerList } from './useOpportunityContainerList'

type OpportunityContainerListProps = {
  tabNumber: string
}

export const OpportunityContainerListDesktop: FunctionComponent<OpportunityContainerListProps> =
  memo(({ tabNumber }) => {
    const {
      isLoading,
      isFetching,
      opportunities,
      onScrollEndDragOpportunityList,
      handleEvaluate,
      setOpportunityEvaluation,
      setIsOpenEvaluantion,
      closeEvaluationSuccessSheet,
      isOpenEvaluation,
      handleUpdateOpportunityHighlighted,
      isLoadingEvaluantion,
      evaluationSuccessSheetRef
    } = useOpportunityContainerList({
      store_tab_numbers: [Number(tabNumber)]
    })

    const activitySkeleton = isLoading && !opportunities.length

    const noResults = !opportunities.length && !isLoading

    const contentPreview = new Array<number>(5).fill(0)

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        onScrollEndDrag={onScrollEndDragOpportunityList}>
        {activitySkeleton &&
          contentPreview.map((_, i) => <SkeletonCard key={`key-skeleton-${i}`} />)}

        <VStack>
          {opportunities.map((opportunity) => (
            <OpportunityCard
              expandedDetail
              isLoadingFavorite={isLoading || isFetching}
              disableHandleFavorite={
                !opportunity.highlightedByUserStdCode && opportunity.is_highlighted
              }
              points={opportunity.rv_simulation_goal_points}
              cover={opportunity.store?.logo_image?.url}
              topText={opportunity.title}
              nameFantasy={opportunity.store.name_fantasy}
              tabNumber={opportunity.store?.tab_number}
              favorite={opportunity.is_highlighted}
              midText={
                opportunity.store?.name_fantasy
                  ? `TAB ${opportunity.store?.tab_number} • ${opportunity.dueDate}`
                  : undefined
              }
              bottomText={`R$ ${opportunity.earningPotential}`}
              userName={opportunity.user_name}
              active={opportunity.is_finished}
              icon="Dollar"
              handleFavorite={() =>
                handleUpdateOpportunityHighlighted(opportunity.id, opportunity.is_highlighted)
              }
              onPress={() => {
                LogEvent('oportunidade', {
                  item_id: opportunity?.id
                })

                navigation.navigate('OrganizationOpportunity', {
                  id: String(opportunity.id)
                })
              }}
              onCheckPress={
                !opportunity.isFinished
                  ? (callback, currentCheck) => {
                      setOpportunityEvaluation(opportunity)

                      setIsOpenEvaluantion(true)

                      callback(currentCheck)
                    }
                  : undefined
              }
            />
          ))}
        </VStack>

        {noResults && (
          <Center>
            <Illustrations.RelatedOpportunitiesEmpty />
            <Text fontSize="16px" lineHeight="24px" color="gray.500" textAlign="center" mt={6}>
              Ainda não há oportunidades relacionadas para este TAB.
            </Text>
          </Center>
        )}

        <BottomSheetEvaluationOpportunity
          isLoading={isLoadingEvaluantion}
          isOpen={isOpenEvaluation}
          handleClose={() => setIsOpenEvaluantion(false)}
          onSubmit={handleEvaluate}
        />

        <BottomSheetActionIllustration
          illustration="EvaluationSuccess"
          ref={evaluationSuccessSheetRef}
          title="Obrigado por avaliar esta oportunidade"
          description="Desta forma nós podemos separar as oportunidades que melhor combinam com o seu perfil."
          handleConfirm={() => closeEvaluationSuccessSheet()}
          buttonTextConfirm={Platform.OS === 'web' ? 'Confirmar' : 'Ok'}
        />
      </ScrollView>
    )
  })
