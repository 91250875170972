import { useCallback, useEffect, useRef, useState } from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { format } from 'date-fns'
import {
  KEY_ACTIVITY_ITEM_QUERY,
  useFinishedActivityMutation,
  useUnFinishedActivityMutation
} from 'integration/resources/activities'
import {
  EvaluationItem,
  useGetOpportunityItemQuery,
  useSetOpportunityHighlightedMutation,
  useSetOpportunityUnHighlightedMutation,
  useSetOpportunityRatesMutation,
  KEY_OPPORTUNITY_LIST_QUERY,
  KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY,
  KEY_OPPORTUNITY_ITEM_QUERY
} from 'integration/resources/opportunities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { INSIGHTS } from 'organisms/BottomSheetEvaluationOpportunity/insghts'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import { useAuthAtomValue } from 'src/store/auth'
import { LogEvent } from 'src/utils/logEvents'

import { UseOrganizationOpportunityDetails } from './types'

type TLogAnalitcsRating = {
  insight?: string
  oportunidade?: string
  matricula?: number
  data_hora: string
  texto?: string
  estrelas: string
}

export const useOrganizationOpportunityDetails: UseOrganizationOpportunityDetails = ({
  navigation,
  route
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const authAtom = useAuthAtomValue()

  const isOwner = authAtom?.user.access_level === 1

  const toast = useToast()

  const { data, isLoading, refetch, isRefetching, isError } = useGetOpportunityItemQuery(
    route.params?.id
  )

  // not found opportunity
  if (isError) {
    navigation.navigate('ErrorPageNotFound')
  }

  const opportunity = data?.data.data

  const [isOpenEvaluation, setIsOpenEvaluantion] = useState(false)

  const [highlighted, setHighlighted] = useState<boolean>(opportunity?.is_highlighted ?? false)

  useEffect(() => {
    setHighlighted(!!opportunity?.is_highlighted)
  }, [opportunity?.is_highlighted])

  const { mutateAsync: setOpportunityHighlighted, isLoading: highlightedIsLoading } =
    useSetOpportunityHighlightedMutation()

  const { mutateAsync: setOpportunityUnHighlighted, isLoading: unhighlightedIsLoading } =
    useSetOpportunityUnHighlightedMutation()

  const queryClient = useQueryClient()

  const handleUpdateOpportunityHighlighted = (toggle: boolean) => {
    setHighlighted(toggle)

    queryClient.invalidateQueries([
      KEY_OPPORTUNITY_LIST_QUERY,
      KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY
    ])

    route.params.id &&
      (highlighted ? setOpportunityUnHighlighted : setOpportunityHighlighted)(
        {
          id: route.params.id
        },
        {
          onError: () => {
            setHighlighted(!toggle)
          },
          onSuccess: () => {
            setHighlighted(toggle)
          }
        }
      )
  }

  const evaluationSuccessSheetRef = useRef<BottomSheetModal>(null)

  const openEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.present()
  }, [])

  const closeEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.close()

    evaluationSuccessSheetRef.current?.dismiss()
  }, [])

  const setOpportunityRatesMutation = useSetOpportunityRatesMutation()

  const handleEvaluate = useCallback(
    (evaluantion: EvaluationItem) => {
      route.params?.id &&
        setOpportunityRatesMutation
          .mutateAsync(
            {
              ...evaluantion,
              opportunity_id: route.params.id
            },
            {
              onError: () => {
                toast.show({
                  render: () => <Toast type="error" text="Não foi possível avaliar no momento." />
                })
              },
              onSuccess: async () => {
                openEvaluationSuccessSheet()

                refetch()

                if (Platform.OS !== 'web') {
                  const date = new Date()

                  const formated = format(date, 'dd-MM-yyyy HH:mm')

                  const log = {
                    oportunidade: route.params.id,
                    matricula: authAtom?.user?.std_code,
                    data_hora: formated,
                    estrelas: evaluantion.score
                  } as TLogAnalitcsRating

                  if (evaluantion?.insight) {
                    const insight = INSIGHTS.filter(
                      (item) => item.value === evaluantion.insight.toString()
                    )

                    log.insight = insight[0].title
                  }

                  if (evaluantion.comment) {
                    log.texto = evaluantion.comment
                  }

                  LogEvent('oportunidade_concluida', {
                    item_id: route?.params?.id
                  })
                }
              }
            }
          )
          .finally(() => setIsOpenEvaluantion(false))
    },
    [
      route.params.id,
      setOpportunityRatesMutation,
      toast,
      openEvaluationSuccessSheet,
      refetch,
      authAtom?.user?.std_code
    ]
  )

  const finishedActivityMutation = useFinishedActivityMutation()

  const unFinishedActivityMutation = useUnFinishedActivityMutation()

  const handleCompleteActivity = (
    callback: (isChecked: boolean) => void,
    currentCheck: boolean,
    id: string
  ) =>
    (currentCheck ? unFinishedActivityMutation : finishedActivityMutation).mutate(
      {
        id
      },
      {
        onError() {
          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação" />,
            placement: 'bottom',
            duration: 800
          })

          queryClient
            .invalidateQueries([
              `${KEY_ACTIVITY_ITEM_QUERY}.${id}`,
              `${KEY_OPPORTUNITY_ITEM_QUERY}.${route.params.id}`
            ])
            .then(() => toast.closeAll())

          callback(currentCheck)
        },
        onSuccess: async () => {
          if (!currentCheck) {
            toast.show({
              render: () => <Toast type="success" text="Atividade marcada como concluída 🎉" />,
              duration: 800
            })

            LogEvent('atividade_concluida', {
              item_id: route?.params?.id
            })
          }

          queryClient
            .invalidateQueries([
              `${KEY_ACTIVITY_ITEM_QUERY}.${id}`,
              `${KEY_OPPORTUNITY_ITEM_QUERY}.${route.params.id}`
            ])
            .then(() => toast.closeAll())

          callback(!currentCheck)
        }
      }
    )

  return {
    isMobile,
    isLoading,
    navigation,
    highlighted,
    opportunity,
    isRefetching: isRefetching || highlightedIsLoading || unhighlightedIsLoading,
    handleEvaluate,
    isOpenEvaluation,
    onRefresh: refetch,
    setIsOpenEvaluantion,
    handleUpdateOpportunityHighlighted,
    isLoadingEvaluantion: setOpportunityRatesMutation.isLoading,
    evaluationSuccessSheetRef,
    closeEvaluationSuccessSheet,
    handleCompleteActivity,
    isOwner
  }
}
