import {
  SendEmailResetPasswordData,
  SendEmailResetPasswordPayload,
  PreResetPasswordData,
  PreResetPasswordPayload,
  ResetPasswordData,
  ResetPasswordPayload,
  ResetPasswordPayloadV2
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const sendEmailResetPassword = (data: SendEmailResetPasswordPayload) =>
  client.post<ResponseSuccess<SendEmailResetPasswordData>>('v1/users/reset-password', data)

export const preResetPassword = async ({
  confirm_token,
  id,
  password,
  password_confirmation
}: PreResetPasswordPayload) => {
  const response = await client.patch<ResponseSuccess<PreResetPasswordData>>(
    `v1/users/${id}/pre-reset-password?confirm_token=${confirm_token}`,
    {
      password,
      password_confirmation
    }
  )

  return response
}

export const resetPassword = ({ id, ...data }: ResetPasswordPayload) =>
  client.patch<ResponseSuccess<ResetPasswordData>>(`v1/users/${id}/reset-password`, data)

export const resetPasswordV2 = ({ id, ...data }: ResetPasswordPayloadV2) =>
  client.patch<ResponseSuccess<ResetPasswordData>>(`v2/users/${id}/reset-password`, data)
