import { FunctionComponent, useState } from 'react'

import { Icons, LoadingTurbo } from 'atoms'
import { Dropdown } from 'atoms/web/Dropdown'
import { Store } from 'integration/resources/store'
import { GetListsParams } from 'integration/resources/wallet'
import { JobsDropdown } from 'molecules/web/Filters/JobsDropdown'
import { Button, Center, HStack, Menu, ScrollView, Text } from 'native-base'
import { useBottomSheetStores } from 'organisms/BottomSheetStores/hook'
import { Platform } from 'react-native'

const JOBS = ['PROPRIETÁRIO', 'DIRETOR', 'GERENTE', 'COMERCIAL', 'ADM', 'FINANCEIRO', 'OUTROS']

interface Filters {
  tabs: {
    label: string
    selected: string | undefined
  }
  jobs: {
    label: string
    selected: string | undefined
    items: string[]
  }
  favorites: {
    label: string
    selected: number | boolean | undefined
  }
}

interface WalletContactFiltersProps {
  initialValues: GetListsParams
  onChange(filters: Filters): void
}

const INITIAL_STATE = {
  tabs: {
    label: 'Loja (TAB)',
    selected: undefined
  },
  jobs: {
    label: 'Função',
    selected: undefined,
    items: JOBS
  },
  favorites: {
    label: 'Marcados como favorito',
    selected: undefined
  }
}

export const WalletContactFilters: FunctionComponent<WalletContactFiltersProps> = ({
  onChange,
  initialValues
}) => {
  const isWeb = Platform.OS === 'web'

  const { stores, isLoadingStores, onScrollEndDragStoreList } = useBottomSheetStores()

  const DIRTY_STATE = {
    tabs: {
      ...INITIAL_STATE.tabs,
      selected: initialValues?.store_tab_number
    },
    jobs: {
      ...INITIAL_STATE.jobs,
      selected: initialValues?.job_title
    },
    favorites: {
      ...INITIAL_STATE.favorites,
      selected: initialValues?.is_favorite
    }
  }

  const [filters, setFilters] = useState<Filters>(DIRTY_STATE)

  const isDisabled = !filters.favorites.selected && !filters.tabs.selected && !filters.jobs.selected

  const setNewFilterState = (filterName: string, state: object) => {
    setFilters((oldState) => {
      const newstate = {
        ...oldState,
        [filterName]: {
          // @ts-ignore
          ...oldState[filterName],
          ...state
        }
      }

      onChange(newstate)

      return newstate
    })
  }

  return (
    <HStack mt={6} mb={4} space={2}>
      <Dropdown
        label={filters.tabs.label}
        active={!!filters.tabs.selected}
        // w={400}
        renderItems={
          <>
            <ScrollView
              showsVerticalScrollIndicator={false}
              {...(isWeb
                ? {
                    onScroll: (e) => {
                      if (
                        e.nativeEvent.layoutMeasurement.height + e.nativeEvent.contentOffset.y >=
                          e.nativeEvent.contentSize.height - 10 &&
                        onScrollEndDragStoreList
                      ) {
                        onScrollEndDragStoreList()
                      }
                    },
                    scrollEventThrottle: 10
                  }
                : { onScrollEndDrag: onScrollEndDragStoreList })}
              style={{ maxHeight: 480 }}>
              {stores?.map((store: Store) => (
                <Menu.Item
                  key={store.id}
                  onPress={() => setNewFilterState('tabs', { selected: store.tab_number })}
                  bgColor={filters.tabs.selected === store.tab_number ? 'gray.50' : 'white'}>
                  <HStack py={2}>
                    <Text fontSize="16px" lineHeight="24px" color="gray.500" mr={4}>
                      TAB {store.tab_number}
                    </Text>
                    <Text
                      fontSize="16px"
                      lineHeight="24px"
                      color="gray.700"
                      maxW={300}
                      fontWeight="bold">
                      {store.name_fantasy}
                    </Text>
                  </HStack>
                </Menu.Item>
              ))}

              {isLoadingStores && (
                <Center mt={2}>
                  <LoadingTurbo size={48} />
                </Center>
              )}
            </ScrollView>
          </>
        }
      />

      <Dropdown
        label={filters.jobs.label}
        active={!!filters.jobs.selected}
        w={484}
        renderItems={
          <JobsDropdown
            onChange={(selected) => setNewFilterState('jobs', { selected })}
            value={filters.jobs.selected}
            items={JOBS}
          />
        }
      />

      <Button
        px={6}
        _text={{
          fontSize: '16px'
        }}
        h={10}
        colorScheme={filters.favorites.selected ? 'primary-light' : 'white'}
        onPress={() => setNewFilterState('favorites', { selected: !filters.favorites.selected })}
        size="sm">
        {filters.favorites.label}
      </Button>

      <Button
        px={6}
        h={10}
        _text={{
          fontSize: '14px',
          color: 'gray.700'
        }}
        isDisabled={isDisabled}
        _disabled={{
          _text: {
            color: 'gray.300'
          }
        }}
        onPress={() => [setFilters(INITIAL_STATE), onChange(INITIAL_STATE)]}
        bgColor="background.light"
        colorScheme="gray"
        rightIcon={<Icons.Trash color={isDisabled ? 'gray.300' : 'gray.700'} />}
        size="sm">
        Limpar
      </Button>
    </HStack>
  )
}
