import { FunctionComponent, useState } from 'react'

import { Menu } from 'native-base'

type TBottomSheetJob = {
  value: string | undefined
  onChange(value: string): void
  items: string[]
}

export const JobsDropdown: FunctionComponent<TBottomSheetJob> = ({ value, onChange, items }) => {
  const [jobSelected, setJobSelected] = useState(value)

  return (
    <>
      {items.map((_job) => {
        return (
          <Menu.Item
            key={_job}
            borderBottomColor="gray.50"
            borderBottomWidth={1}
            borderBottomStyle="solid"
            onPress={() => [setJobSelected(_job), onChange(_job)]}
            _pressed={{
              bg: 'gray.50'
            }}
            bg={jobSelected !== _job ? 'transparent' : 'gray.50'}
            _text={{
              color: 'gray.500',
              fontSize: '16px',
              lineHeight: '24px'
            }}>
            {_job}
          </Menu.Item>
        )
      })}
    </>
  )
}
