import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ActivitiesResponse,
  useGetActivityListInfiniteQuery,
  useFinishedActivityMutation,
  useUnFinishedActivityMutation,
  KEY_ACTIVITY_LIST_QUERY,
  KEY_ACTIVITY_ITEM_QUERY
} from 'integration/resources/activities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import { LogEvent } from 'src/utils/logEvents'

import { UseListActivities } from './types'
import { useSetOrganizationAtom } from '../../useOrganizationScreen'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0
}

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetActivityListInfiniteQuery>['data']
) =>
  data?.pages.reduce<ActivitiesResponse>(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...currentValue.data.data,
      items: [...previousValue.items, ...currentValue.data.data.items]
    }),
    defaultReturn
  )

export const useListActivities: UseListActivities = ({ search }) => {
  const setOrganizationAtom = useSetOrganizationAtom()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchHook
  } = useGetActivityListInfiniteQuery(
    {
      group_by: 2,
      per_page: 10,
      expand_details: isDesktop && Platform.OS === 'web',
      search
    },
    (data) => {
      setOrganizationAtom(data.pages[0].data.data)
    }
  )

  const fetchMore = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const [refetchLoading, setRefetchLoading] = useState(false)

  const queryClient = useQueryClient()

  const refetch = () => {
    queryClient.invalidateQueries(KEY_ACTIVITY_LIST_QUERY)

    setRefetchLoading(true)

    setTimeout(() => {
      setRefetchLoading(false)
    }, 500)
  }

  const totalItens =
    data && data?.pages.length > 0 ? data?.pages[0].data?.metadata?.pagination?.total : 0

  const list = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  const toast = useToast()

  const finishedActivityMutation = useFinishedActivityMutation()

  const unFinishedActivityMutation = useUnFinishedActivityMutation()

  const handleComplete = (callback: (a: boolean) => void, currentCheck: boolean, id: string) =>
    (currentCheck ? unFinishedActivityMutation : finishedActivityMutation).mutate(
      {
        id
      },
      {
        onError() {
          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação" />,
            placement: 'bottom',
            duration: 2000
          })

          queryClient
            .invalidateQueries([`${KEY_ACTIVITY_ITEM_QUERY}.${id}`])
            .then(() => toast.closeAll())

          callback(currentCheck)
        },
        onSuccess: async (response) => {
          if (!currentCheck) {
            toast.show({
              render: () => <Toast type="success" text="Atividade marcada como concluída 🎉" />,
              duration: 2000
            })

            LogEvent('atividade_concluida', {
              item_id: response.data.data.id
            })
          }

          queryClient
            .invalidateQueries([`${KEY_ACTIVITY_ITEM_QUERY}.${id}`])
            .then(() => toast.closeAll())

          callback(!currentCheck)
        }
      }
    )

  useEffect(() => {
    refetchHook()
  }, [search, refetchHook])

  return {
    list,
    isLoading,
    handleComplete,
    isFetchingNextPage,
    refreshing: refetchLoading,
    fetchMore,
    refetch,
    totalItens
  }
}
