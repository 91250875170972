import NetInfo from '@react-native-community/netinfo'
import * as Notifications from 'expo-notifications'
import { Platform } from 'react-native'
import { enableFreeze } from 'react-native-screens'
import { onlineManager } from 'react-query'
import * as Sentry from 'sentry-expo'

import { AppProviders } from './AppProviders'
import { RootStackNavigator } from './navigation'

enableFreeze(true)

Sentry.init({
  dsn: process.env.SENTRY_DNS,
  enableInExpoDevelopment: false,
  debug: process.env.ENV === 'dev'
})

// Handler to enable notications
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false
  })
})

/**
 * Reference: https://react-query.tanstack.com/react-native#online-status-management
 */
onlineManager.setEventListener((setOnline) => {
  return NetInfo.addEventListener((state) => {
    setOnline(!!state.isConnected)
  })
})

export const App =
  Platform.OS === 'web'
    ? () => (
        <AppProviders>
          <RootStackNavigator />
        </AppProviders>
      )
    : Sentry.Native.wrap(() => {
        return (
          <AppProviders>
            <RootStackNavigator />
          </AppProviders>
        )
      })
