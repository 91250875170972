import { memo } from 'react'

import { Box, Circle, Divider, HStack, Skeleton, VStack } from 'native-base'
import { useWindowDimensions } from 'react-native'

export const SkeletonWalletSummary = memo(() => {
  const { width: SCREEN_WIDTH } = useWindowDimensions()

  const widthCard = Math.floor((SCREEN_WIDTH - 48) / 2)

  return (
    <>
      <HStack flexWrap="wrap" ml={-4}>
        {new Array(6).fill(1).map((index) => (
          <Box
            key={Math.random()}
            style={{
              paddingLeft: index % 2 !== 0 ? 16 : 0
            }}>
            <Box w={`${widthCard}px`}>
              <VStack mb={4} py="14px" px={4} bg="white" borderRadius={20}>
                <VStack flex={1}>
                  <HStack justifyContent="space-between">
                    <Skeleton w="50%" h={6} rounded="sm" />

                    <Circle style={{ transform: [{ rotate: '0deg' }] }} size={6}>
                      <Skeleton w="100%" h={6} rounded="full" />
                    </Circle>
                  </HStack>

                  <Skeleton w="70%" h={4} mt={2} rounded="sm" />

                  <Divider bg="gray.50" my={3} />

                  {[1, 2, 3, 4].map((item) => (
                    <Box>
                      <HStack justifyContent="space-between">
                        <Skeleton w="50%" h={4} mb={1} rounded="sm" />
                        <Skeleton w="25%" h={4} mb={1} rounded="sm" />
                      </HStack>
                    </Box>
                  ))}

                  <Skeleton w="80%" mt={2} h={2} rounded="sm" />
                </VStack>
              </VStack>
            </Box>
          </Box>
        ))}
      </HStack>
    </>
  )
})
