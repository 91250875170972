import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { Platform } from 'react-native'
import client from 'src/integration/client'
import { Storage } from 'src/utils'

export const AuthTokenKey = 'consultormaisturbo_auth_token'

const tokenAtom = atomWithStorage<string | null | undefined>(AuthTokenKey, undefined, {
  removeItem: Storage.removeItem,
  getItem: async (key) => {
    const item = await Storage.getItem(key)

    const itemParsed = item ? JSON.parse(item) : null

    setHeaderAuthorization(itemParsed)

    return itemParsed
  },
  setItem: (key, value) => {
    setHeaderAuthorization(value)

    if (value) {
      const newValue = Platform.OS === 'web' ? value : JSON.stringify(value)

      return Storage.setItem(key, newValue)
    }

    return Storage.removeItem(key)
  }
})

const setHeaderAuthorization = (value: string | null | undefined) => {
  if (value) {
    client.defaults.headers.common.Authorization = `Bearer ${value}`

    return
  }

  delete client.defaults.headers.common['Authorization']
}

export const useTokenAtom = () => useAtom(tokenAtom)

export const useTokenAtomValue = () => useAtomValue(tokenAtom)

export const useSetTokenAtom = () => useSetAtom(tokenAtom)
