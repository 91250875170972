import { FunctionComponent } from 'react'

import { LoadingTurbo } from 'atoms'
import { Center, useBreakpointValue, useTheme, VStack } from 'native-base'
import Chart from 'organisms/Chart'
import Layouts from 'organisms/Layouts'
import { Platform } from 'react-native'

import { useHomeConcentration } from './hook'
import { HomeChartsTypes } from '../../constants'

type HomeConcentrationProps = {
  handleGoToFullChartScreen(type: HomeChartsTypes): void
  isGoodsAndServices: boolean
}

export const HomeConcentration: FunctionComponent<HomeConcentrationProps> = ({
  handleGoToFullChartScreen,
  isGoodsAndServices
}) => {
  const { productionSubsegmentData, productionSubsegmentDataIsLoading } = useHomeConcentration()

  const theme = useTheme()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return isGoodsAndServices ? (
    <VStack
      mt={Platform.OS === 'ios' ? 1 : 5}
      mr={4}
      mb={2}
      space={4}
      maxW={isDesktop ? '1122px' : 'auto'}>
      {productionSubsegmentDataIsLoading ? (
        <Center h={360} w="full" position="absolute" zIndex={9}>
          <LoadingTurbo size={100} />
        </Center>
      ) : (
        <>
          <Layouts.MultipleResumedChart
            labels={[productionSubsegmentData?.titles?.slice(2, 1) ?? []]}
            colors={[
              [theme.colors.primary[800], theme.colors.primary[500], theme.colors.primary[100]]
            ]}
            charts={[
              {
                isLoading: productionSubsegmentDataIsLoading,
                hasData: !!productionSubsegmentData.chartData?.length,
                type: HomeChartsTypes.PRODUCTION_SUBSEGMENT,
                updated_at: productionSubsegmentData.processed_at ?? '',
                handleGoToFullChartScreen: () =>
                  handleGoToFullChartScreen(HomeChartsTypes.PRODUCTION_SUBSEGMENT),
                noLabels: false,
                chart: (
                  <Chart.PortraitStackBar
                    colors={[
                      theme.colors.primary[800],
                      theme.colors.primary[500],
                      theme.colors.primary[100]
                    ]}
                    chartsData={productionSubsegmentData?.chartData ?? []}
                    chartType={HomeChartsTypes.PRODUCTION_SUBSEGMENT}
                    type="currency"
                    showTooltip
                    tooltips={{
                      titles: productionSubsegmentData.titles ?? [],
                      values: productionSubsegmentData.tooltipsValues ?? []
                    }}
                  />
                )
              }
            ]}
          />
        </>
      )}
    </VStack>
  ) : (
    <></>
  )
}
