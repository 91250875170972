import { forwardRef, useCallback, useRef, useState } from 'react'

import { Icons, Calendar } from 'atoms'
import { Dropdown, DropdownRefProps } from 'atoms/web/Dropdown'
import { CustomBottomSheet, BottomSheetModal } from 'molecules'
import { VStack, Text, HStack, useTheme, FormControl, Button } from 'native-base'
import { TouchableOpacity, Platform } from 'react-native'

import { useViewTypeSheet } from './hook'
import { BottomSheetOrganizationMenuProps } from './types'
import { ViewType } from '../../OrganizationScreen.types'

export const ViewTypeSheet = forwardRef<BottomSheetModal, BottomSheetOrganizationMenuProps>(
  ({ handleSelect, currentViewType, viewTypes }, ref) => {
    const theme = useTheme()

    const calendarRef = useRef<BottomSheetModal>(null)

    const menuCalendarRef = useRef<DropdownRefProps>(null)

    const closeMenu = () => {
      menuCalendarRef?.current?.close()
    }

    const handleOpenCalendar = useCallback(() => {
      calendarRef.current?.present()
    }, [])

    const handleCloseCalendar = useCallback(() => {
      calendarRef.current?.close()
    }, [])

    const [currentDate, setCurrentDate] = useState<string>()

    const handleAddedDate = (vision: ViewType, selectedDate?: Date) => {
      handleSelect(vision, selectedDate?.toISOString() ?? currentDate)

      handleCloseCalendar()
    }

    const { isMobile, getActivitiesByPeriod, datesWithActivities, isLoading } = useViewTypeSheet()

    return (
      <>
        {!isMobile ? (
          <HStack space={2}>
            {viewTypes.map((vision) => {
              return (
                <HStack>
                  <FormControl>
                    {vision.group === 3 ? (
                      Platform.OS === 'web' ? (
                        <Button
                          colorScheme={
                            currentViewType.group === vision.group ? 'primary-light' : 'white'
                          }
                          px={5}
                          h={10}
                          maxW="md"
                          justifyContent="center"
                          borderRadius="full"
                          onPress={() =>
                            vision.group === 3
                              ? [handleOpenCalendar(), getActivitiesByPeriod()]
                              : handleSelect(vision)
                          }
                          key={vision.title}>
                          <HStack alignItems="center">
                            <Text
                              fontSize="16px"
                              lineHeight="22px"
                              color={
                                currentViewType.group === vision.group ? 'primary.800' : 'black'
                              }
                              fontWeight="normal">
                              {vision.title}
                            </Text>
                          </HStack>
                        </Button>
                      ) : (
                        <Dropdown
                          ref={menuCalendarRef}
                          label={vision.title}
                          active={currentViewType.group === vision.group}
                          w={484}
                          renderItems={
                            <Calendar.Root
                              isLoading={isLoading || datesWithActivities === undefined}>
                              <Calendar.Title title="Definir uma data" />
                              <Calendar.Body
                                daySelected={currentDate}
                                onDaySelected={setCurrentDate}
                                markedDates={datesWithActivities}
                              />
                              <Calendar.Footer>
                                <Button
                                  ml={2}
                                  flex={1}
                                  style={{
                                    opacity: !currentDate ? 0.3 : 1
                                  }}
                                  colorScheme="gray"
                                  disabled={!currentDate}
                                  onPress={() => {
                                    handleAddedDate({
                                      group: 3,
                                      title: 'Visão por data',
                                      icon: 'CalendarPlus'
                                    })

                                    closeMenu()
                                  }}>
                                  Confirmar
                                </Button>
                              </Calendar.Footer>
                            </Calendar.Root>
                          }
                        />
                      )
                    ) : (
                      <Button
                        colorScheme={
                          currentViewType.group === vision.group ? 'primary-light' : 'white'
                        }
                        px={5}
                        h={10}
                        maxW="md"
                        justifyContent="center"
                        borderRadius="full"
                        onPress={() =>
                          vision.group === 3
                            ? [handleOpenCalendar(), getActivitiesByPeriod()]
                            : handleSelect(vision)
                        }
                        key={vision.title}>
                        <HStack alignItems="center">
                          <Text
                            fontSize="16px"
                            lineHeight="22px"
                            color={currentViewType.group === vision.group ? 'primary.800' : 'black'}
                            fontWeight="normal">
                            {vision.title}
                          </Text>
                        </HStack>
                      </Button>
                    )}
                  </FormControl>
                </HStack>
              )
            })}
          </HStack>
        ) : (
          <CustomBottomSheet
            ref={ref}
            snapPoints={[viewTypes.length < 4 ? viewTypes.length * 75 : viewTypes.length * 65]}>
            <VStack py={6} p={0} w="full">
              {viewTypes.map((vision, index) => {
                const IconComponent = Icons[vision.icon] as typeof Icons.Add

                return (
                  <>
                    <FormControl key={`key-vision-form-${index}`}>
                      <TouchableOpacity
                        onPress={() =>
                          vision.group === 3
                            ? [handleOpenCalendar(), getActivitiesByPeriod()]
                            : handleSelect(vision)
                        }
                        key={vision.title}>
                        <HStack px={6} pb={8}>
                          <IconComponent
                            color={
                              currentViewType.group === vision.group
                                ? theme.colors.primary[500]
                                : theme.colors.gray[700]
                            }
                            size={4}
                            m={0.5}
                            mr={4}
                          />
                          <Text
                            fontSize="14px"
                            lineHeight="20px"
                            color="black"
                            fontWeight={currentViewType.group === vision.group ? 'bold' : 'normal'}>
                            {vision.title}
                          </Text>
                        </HStack>
                      </TouchableOpacity>
                    </FormControl>
                  </>
                )
              })}
            </VStack>
          </CustomBottomSheet>
        )}

        <Calendar.Apresentation ref={calendarRef}>
          <Calendar.Title title="Definir uma data" />
          <Calendar.Body
            markedDates={datesWithActivities}
            daySelected={currentDate}
            onDaySelected={setCurrentDate}
          />
          <Calendar.Footer>
            <Button
              flex={1}
              colorScheme="gray"
              isDisabled={!currentDate}
              onPress={() => {
                handleAddedDate({
                  group: 3,
                  title: 'Visão por data',
                  icon: 'CalendarPlus'
                })
              }}>
              Confirmar
            </Button>
          </Calendar.Footer>
        </Calendar.Apresentation>
      </>
    )
  }
)
