import { FunctionComponent, PropsWithChildren } from 'react'

import { CustomStatusBar } from 'atoms/CustomStatusBar'
import { LinearGradient } from 'expo-linear-gradient'
import { Box, useTheme, VStack } from 'native-base'
import { Platform, StyleSheet } from 'react-native'
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated'

type InternalLayoutProps = {
  header: React.ReactNode
  subheaderItem1: React.ReactNode
  subheaderItem2?: React.ReactNode
  body: React.ReactNode
  scroll?: boolean
  onScrollEndDrag?: () => void
  headerPadding: number
  scrollPaddingLeft: number
  scrollPaddingRight: number
}

const Header_Max_Height = 375

const Header_Min_Height = 70

export const GradientBackgroundLayout: FunctionComponent<
  PropsWithChildren<InternalLayoutProps>
> = ({
  header,
  subheaderItem1,
  subheaderItem2,
  body,
  scroll = true,
  onScrollEndDrag,
  headerPadding,
  scrollPaddingLeft = 16,
  scrollPaddingRight = 16,
  children
}) => {
  const theme = useTheme()

  const heightSharedValue = useSharedValue(0)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    heightSharedValue.value = event.contentOffset.y
  })

  const hStyle = useAnimatedStyle(() => {
    const interpolateH = interpolate(
      heightSharedValue.value,
      [0, Header_Max_Height - Header_Min_Height],
      [0, Platform.OS === 'ios' ? 230 : 200],
      Extrapolate.CLAMP
    )

    return {
      transform: [
        {
          translateY: -interpolateH
        }
      ]
    }
  })

  const boxRatingStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      heightSharedValue.value,
      [0, Header_Max_Height - Header_Min_Height],
      [1, 0],
      Extrapolate.CLAMP
    )

    return {
      opacity
    }
  })

  return (
    <Box flex={1}>
      <CustomStatusBar barStyle="light-content" />
      <Animated.View style={[styles.header, hStyle]}>
        <LinearGradient
          start={{ x: 0, y: 0.1 }}
          end={{ x: 0.3, y: 1 }}
          locations={[0, 0.2, 0.9]}
          colors={[
            theme.colors['header-gradient']['50'],
            theme.colors['header-gradient']['100'],
            theme.colors['header-gradient']['200']
          ]}>
          {header}
          <VStack bg="background.base" borderTopRadius="32px" px={4} pr={0}>
            <Animated.View style={boxRatingStyle}>{subheaderItem1}</Animated.View>
            {subheaderItem2}
          </VStack>
        </LinearGradient>
      </Animated.View>

      <Animated.ScrollView
        contentContainerStyle={{
          paddingTop: headerPadding,
          paddingLeft: scrollPaddingLeft,
          paddingRight: scrollPaddingRight,
          backgroundColor: theme.colors.background.base,
          flexGrow: 1
        }}
        bounces={false}
        scrollEventThrottle={16}
        scrollEnabled={scroll}
        onScroll={scrollHandler}
        onScrollEndDrag={onScrollEndDrag}
        showsVerticalScrollIndicator={false}>
        {body}
      </Animated.ScrollView>

      {children}
    </Box>
  )
}

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    left: 0,
    right: 0,
    overflow: 'hidden',
    elevation: 998,
    zIndex: 998,
    justifyContent: 'space-between'
  }
})
