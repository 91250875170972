export enum HomeChartsTypes {
  SIMULATIONS_RATING = 'simulations_rating',
  CONTRACTS_RATING = 'contracts_rating',
  PRODUCTION_SUBSEGMENT = 'production_subsegment',
  WALLET_SITUATION = 'wallet_situation',
  OVERDUE = 'overdue',
  ACCUMULATED_PRODUCTION = 'accumulated_production',
  INSURANCE_PRESCRIPTION = 'insurance_prescription',
  VALUE_FRAUDS = 'value_frauds',
  VAR_OVER90 = 'var_over90',
  MBB_REVENUE = 'mbb_revenue',
  PROJECTED_PRODUCTION = 'projected_production',
  AMOUNT_SIMULATIONS = 'amount_simulations',
  AMOUNT_APNEF = 'amount_apnef',
  VALUE_APNEF = 'value_apnef',
  AMOUNT_CONTRACTS = 'amount_contracts',
  AMOUNT_STORES = 'amount_stores',
  AMOUNT_INACTIVES = 'amount_inactives',
  AMOUNT_HALF_PRODUCTION = 'amount_half_production',
  AMOUNT_ACTIVE_STORES = 'amount_active_stores',
  PRODUCTION = 'production',
  RESULT = 'result',
  RECEIVABLE = 'receivable',
  CAMPAIGNS = 'campaigns',
  FPD10 = 'fpd10',
  SAFRA_BALANCE = 'safra_balance',
  OVER60 = 'over60',
  WALLET_BALANCE = 'wallet_balance',
  FRAUDS = 'frauds',
  OVER90 = 'over90',
  OVER30 = 'over30',
  OVER30M3 = 'over30m3',
  CONTRACTS_DELINQUENCY_QTY = 'contract_delinquency_qty'
}

export enum HomeSelectWalletTypes {
  RESULT = 'Resultado',
  FUNNEL = 'Funil',
  CONCENTRATION = 'Concentração',
  COMMISSION = 'Comissão',
  CHARGE = 'Inadimplência'
}

export const homeResumedChartsProps = {
  simulations_rating: {
    title: 'Simulações/Rating(%)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'SimRT 9 e 10',
        color: 'primary.800'
      },
      {
        title: 'SimRT 7 e 8',
        color: 'primary.500'
      },
      {
        title: 'SimRT 5 e 6',
        color: 'primary.100'
      }
    ],
    tooltipsTitles: [
      'Simulações RT NT',
      'Simulações RT 1 e 2',
      'Simulações RT 3 e 4',
      'Simulações RT 5 e 6',
      'Simulações RT 7 e 8',
      'Simulações RT 9 e 10'
    ],
    type: 'percentage'
  },
  contracts_rating: {
    title: 'Contratos/Rating(%)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'ContRT 9 e 10',
        color: 'primary.500'
      },
      {
        title: 'ContRT 7 e 8',
        color: 'primary.300'
      },
      {
        title: 'ContRT 5 e 6',
        color: 'primary.100'
      }
    ],
    tooltipsTitles: [
      'Contratos RT NT',
      'Contratos RT 1 e 2',
      'Contratos RT 3 e 4',
      'Contratos RT 5 e 6',
      'Contratos RT 7 e 8',
      'Contratos RT 9 e 10'
    ],
    type: 'percentage'
  },
  production_subsegment: {
    title: 'Produção / Subsegmento',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Móveis',
        color: 'primary.800'
      },
      {
        title: 'Fotovoltaico',
        color: 'primary.500'
      },
      {
        title: 'Planejados',
        color: 'primary.100'
      }
    ],
    tooltipsTitles: [
      'Móveis',
      'Fotovoltaico',
      'Planejados',
      'Piscinas',
      'Equipamentos',
      'Saúde',
      'Construção',
      'OUTROS'
    ],
    type: 'currency'
  },
  wallet_situation: {
    title: 'Situação Carteira (%)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Em atraso',
        color: 'primary.500'
      },
      {
        title: 'Em dia',
        color: 'primary.100'
      }
    ],
    tooltipsTitles: ['Em dia', 'Em atraso'],
    type: 'percentage'
  },
  overdue: {
    title: 'Em atraso/Faixas (%)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: '61 a 90',
        color: 'primary.100'
      },
      {
        title: '91 a 120',
        color: 'primary.300'
      },
      {
        title: '> 120',
        color: 'primary.500'
      }
    ],
    tooltipsTitles: ['1 a 30', '31 a 60', '61 a 90', '91 a 120', '>120'],
    type: 'percentage'
  },
  accumulated_production: {
    title: 'Prod. Acumulada (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  insurance_prescription: {
    title: 'Receita seguros (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  value_frauds: {
    title: 'Valor Fraudes (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  var_over90: {
    title: 'Variável OVER90 (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  mbb_revenue: {
    title: 'Receita MBB (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  projected_production: {
    title: 'Produção Projetada (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  amount_simulations: {
    title: 'Simulações (Qtd)',
    subLevelTitle: 'Proposta',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'qtd'
  },
  amount_apnef: {
    title: 'APNEF (Qtd)',
    subLevelTitle: 'Proposta',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'qtd'
  },
  value_apnef: {
    title: 'Valor. APNEF (R$)',
    subLevelTitle: 'Proposta',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  amount_contracts: {
    title: 'Contratos (Qtd)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'qtd'
  },
  amount_stores: {
    title: 'Lojas (Qtd)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'qtd'
  },
  amount_inactives: {
    title: 'Inativas (Qtd)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'qtd'
  },
  amount_half_production: {
    title: '50% Produção (Qtd)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'qtd'
  },
  amount_active_stores: {
    title: 'Quantidade de lojas ativas',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [
      {
        title: 'Ativas',
        color: 'primary.800'
      },
      {
        title: 'Simulam',
        color: 'primary.500'
      },
      {
        title: 'Produzem',
        color: 'primary.300'
      },
      {
        title: 'Recorrentes',
        color: 'primary.100'
      }
    ],
    tooltipsTitles: [],
    type: 'qtd'
  },
  production: {
    title: 'Comissão Produção (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  result: {
    title: 'Comissão Resultado (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  receivable: {
    title: 'Comissão a Receber (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  campaigns: {
    title: 'Comissão Campanhas (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  fpd10: {
    title: 'FPD10 (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  safra_balance: {
    title: 'Saldo Safra (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  over60: {
    title: 'OVER60',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  wallet_balance: {
    title: 'Saldo Carteira',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  frauds: {
    title: 'Fraudes',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  over90: {
    title: 'OVER90 (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  over30: {
    title: 'OVER30 (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  over30m3: {
    title: 'OVER30M3 (R$)',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'currency'
  },
  contract_delinquency_qty: {
    title: 'Contratos Inadimplentes',
    subLevelTitle: 'Contratos da TAB',
    last_updated: String(new Date()),
    subtitles: [],
    tooltipsTitles: [],
    type: 'qtd'
  }
}

export const getDescriptionContract = (type: HomeChartsTypes, monthYear: string) => {
  switch (type) {
    // case HomeChartsTypes.PRODUCTION:
    //   return `Produção ${monthYear} (R$)`
    default:
      return ''
  }
}
