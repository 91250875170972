import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { Platform } from 'react-native'

import { Storage } from '../../utils'

type TScreen = {
  title: string
  description: string
}

// @ts-ignore
const contractListScreenAtom = atomWithStorage<TScreen | null | undefined>(
  'contract-list-screen',
  undefined,
  {
    removeItem: Storage.removeItem,
    getItem: async (key) => {
      const item = await Storage.getItem(key)

      return item ? JSON.parse(item) : null
    },
    // @ts-ignore
    setItem: (key, value) => {
      if (value) {
        const newValue = Platform.OS === 'web' ? value : JSON.stringify(value)

        return Storage.setItem(key, newValue as string)
      }
    }
  }
)

export const useContractListScreenAtom = () => useAtom(contractListScreenAtom)

export const useContractListScreenAtomValue = () => useAtomValue(contractListScreenAtom)

export const useSetContractListScreenAtom = () => useSetAtom(contractListScreenAtom)
