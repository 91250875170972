type Resize = (_: { base?: 'height' | 'width'; height: number; size: number; width: number }) => {
  height: number
  width: number
}

export const resize: Resize = ({ base, height, size, width }) => {
  let newHeight = size

  let newWidth = size

  if (height > width || base === 'height') {
    newHeight = size

    newWidth = (size * width) / height
  } else if (height < width || base === 'width') {
    newHeight = (size * height) / width

    newWidth = size
  }

  return {
    height: newHeight,
    width: newWidth
  }
}

export const getHighestValue = (data: { value: number; year_month: string }[]): number => {
  let highestValue = 0

  for (const item of data) {
    if (item.value > highestValue) {
      highestValue = item.value
    }
  }

  return highestValue
}

export const findMaxSumByYearMonth = (data: { value: number; year_month: string }[][]): number => {
  const yearMonthMap: { [key: string]: number } = {}

  // Iterates through each array in the data
  for (const arr of data) {
    // Iterates through each object in the array
    for (const obj of arr) {
      const { value, year_month } = obj

      // If year_month is already in the map, add value to the existing sum
      if (yearMonthMap[year_month]) {
        yearMonthMap[year_month] += value
      }
      // If year_month is not in the map, create a new entry with value as sum
      else {
        yearMonthMap[year_month] = value
      }
    }
  }

  // Finds the maximum sum value in the map
  let maxSum = 0

  for (const yearMonth in yearMonthMap) {
    if (yearMonthMap[yearMonth] > maxSum) {
      maxSum = yearMonthMap[yearMonth]
    }
  }

  return maxSum
}

export const calculateMonthValueStack = (
  dados: { value: number; year_month: string }[][]
): number[] => {
  const sum: { [key: string]: number } = {}

  for (const arr of dados) {
    for (const obj of arr) {
      const { value, year_month } = obj

      if (!sum[year_month]) {
        sum[year_month] = 0
      }

      sum[year_month] += value
    }
  }

  return Object.values(sum)
}
