import { FunctionComponent, useState } from 'react'

import { Icons, LoadingTurbo } from 'atoms'
import { Store } from 'integration/resources/store'
import {
  FlatList,
  Box,
  HStack,
  Pressable,
  Text,
  VStack,
  useDisclose,
  IconButton,
  Divider,
  Input,
  Center
} from 'native-base'
import { useBottomSheetStores } from 'organisms/BottomSheetStores/hook'
import useDebounce from 'src/hooks/useDebounce'

type TSelectStores = {
  value?: Store
  handleSelect(store?: Store): void
}

export const SelectStores: FunctionComponent<TSelectStores> = ({ value, handleSelect }) => {
  const { isOpen, onToggle } = useDisclose(false)

  const [search, setSearch] = useState('')

  const debounceFilter = useDebounce(search, 300)

  const { stores, isLoadingStores, onScrollEndDragStoreList, totalItens } = useBottomSheetStores(
    100,
    debounceFilter
  )

  return (
    <Box position="relative">
      <Pressable
        onPress={onToggle}
        alignItems="center"
        w="full"
        h="50px"
        bg="gray.30"
        borderRadius="30px"
        flexDir="row"
        px={4}
        py="13px">
        <Icons.Tab />
        {value ? (
          <Text ml={2} flex={1} color="gray.700" fontSize="16px" lineHeight="24px">{`TAB ${
            value.tab ?? value.tab_number
          } • ${value.name_fantasy}`}</Text>
        ) : (
          <Input
            flex={1}
            value={search}
            onChangeText={setSearch}
            placeholder="Selecione"
            _stack={{ pl: 2 }}
          />
        )}

        <HStack alignItems="center" space={2}>
          {value && (
            <IconButton
              borderRadius="full"
              colorScheme="gray"
              onPress={() => handleSelect(undefined)}
              icon={<Icons.CloseTab color="gray.700" size={4} />}
            />
          )}
          {isLoadingStores ? <LoadingTurbo /> : <Icons.ArrowDropDown size={6} color="gray.700" />}
        </HStack>
      </Pressable>

      {isOpen && (
        <VStack
          position="static"
          bg="white"
          maxH="500px"
          w="99%"
          mx="auto"
          style={{
            //@ts-ignore
            boxShadow: '0px 5px 15px rgba(68, 68, 79, 0.1)'
          }}
          top={10}
          _important={{
            zIndex: 999
          }}
          borderRadius="20px"
          py={8}>
          <FlatList
            data={stores}
            keyExtractor={(item) => `${item.id}`}
            ListEmptyComponent={
              <Center>
                <Text>Pesquisa não econtrada</Text>
              </Center>
            }
            onEndReached={onScrollEndDragStoreList}
            renderItem={({ item, index }) => (
              <>
                <Pressable
                  onPress={() => {
                    onToggle()

                    handleSelect(item)
                  }}
                  flexDir="row"
                  alignItems="center"
                  _hover={{
                    bg: 'gray.30'
                  }}
                  h={10}
                  px={8}
                  {...(item.id === value?.id && { bg: 'gray.30' })}
                  py={4}
                  key={item.id}>
                  <Text>{`TAB ${item.tab_number} `}</Text>
                  <Text bold>{item.name_fantasy} </Text>
                </Pressable>
                {index + 1 < totalItens && <Divider bg="gray.30" my={2} />}
              </>
            )}
          />
        </VStack>
      )}
    </Box>
  )
}
