import { createRef, useCallback } from 'react'

import { useBreakpointValue } from 'native-base'
import { TextInput } from 'react-native'
import { RootStackScreenComponentProps } from 'src/navigation'

import { UseLoginBlockedScreen } from './LoginBlockedScreen.types'

export const refInputPassword = createRef<TextInput>()

export const handleInputPasswordFocus = () => refInputPassword.current?.focus()

export const useLoginBlockedScreen: UseLoginBlockedScreen = ({
  navigation
}: Pick<RootStackScreenComponentProps<'LoginBlocked'>, 'navigation'>) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const goToRecovery = useCallback(() => navigation.replace('ResetPasswordSendEmail'), [navigation])

  return {
    isMobile,
    goToRecovery
  }
}
