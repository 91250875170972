export const Switch = {
  defaultProps: {
    onTrackColor: 'primary.500',
    onThumbColor: 'white'
  },
  sizes: {
    sm: {
      style: {
        transform: [{ scale: 0.75 }]
      }
    },
    // md: {},
    lg: {
      style: {
        transform: [{ scale: 1 }]
      }
    }
  }
}
