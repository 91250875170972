import { Icons } from 'atoms'
import { Box, Heading, Text, VStack } from 'native-base'

type FavoritListProps = {
  quantity: number
  type: string
  children: JSX.Element
  title: string
}

export const FavoritListCard = ({ quantity, type, children, title }: FavoritListProps) => {
  return (
    <Box bg="primary.800" borderRadius="12px" p={4} mb="32px" position="relative">
      <Box
        bg="red.500"
        px="12px"
        py="9px"
        position="absolute"
        top="0"
        right="0"
        borderTopRightRadius="12px"
        borderBottomLeftRadius="12px">
        <Icons.Star color="white" size={4} filled />
      </Box>
      <VStack space={1}>
        <Heading fontSize={18} color="white">
          {title}
        </Heading>
        <Text fontSize="12px" lineHeight="24px" fontWeight={400} color="white">
          {quantity} {type}
        </Text>
      </VStack>

      <VStack space={2} mt={4}>
        {children}
      </VStack>
    </Box>
  )
}
