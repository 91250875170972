import { memo } from 'react'

import Svg, { Path } from 'react-native-svg'

const ProfileLevelBronzeIcon = () => (
  <Svg width={48} height={48} fill="none" viewBox="0 0 48 48">
    <Path
      d="M24 2.76562e-06C20.8483 2.90339e-06 17.7274 0.620781 14.8156 1.82689C11.9038 3.03301 9.25804 4.80083 7.02943 7.02944C4.80083 9.25805 3.033 11.9038 1.82689 14.8156C0.620777 17.7274 -1.32461e-06 20.8483 -1.04907e-06 24C-7.73541e-07 27.1517 0.620778 30.2726 1.82689 33.1844C3.033 36.0962 4.80083 38.742 7.02944 40.9706C9.25804 43.1992 11.9038 44.967 14.8156 46.1731C17.7274 47.3792 20.8483 48 24 48L24 24L24 2.76562e-06Z"
      fill="#BC9057"
    />
    <Path
      d="M24 48C27.1517 48 30.2726 47.3792 33.1844 46.1731C36.0962 44.967 38.742 43.1992 40.9706 40.9706C43.1992 38.742 44.967 36.0962 46.1731 33.1844C47.3792 30.2726 48 27.1517 48 24C48 20.8483 47.3792 17.7274 46.1731 14.8156C44.967 11.9038 43.1992 9.25804 40.9706 7.02944C38.742 4.80083 36.0962 3.033 33.1844 1.82689C30.2726 0.620777 27.1517 -1.18684e-06 24 -1.04907e-06L24 24L24 48Z"
      fill="#A9814E"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.201 23.0457C29.1653 22.2264 28.9351 21.4137 28.5091 20.6791L24.7515 14.1987C24.465 13.7044 24.2671 13.175 24.157 12.6318L24.0002 12.9027C23.0699 14.5066 23.0699 16.4828 24 18.0867L27.0061 23.271C27.9362 24.8751 27.9362 26.8514 27.0061 28.4554L26.8491 28.7262C26.7392 28.1831 26.5411 27.6536 26.2546 27.1595L21.7454 19.3829C21.4591 18.8888 21.261 18.3591 21.1511 17.8162L20.9941 18.087C20.0672 19.6856 20.0643 21.6537 20.9848 23.2548L20.9847 23.255L24.0002 28.4554C24.9301 30.0593 24.9301 32.0356 24.0002 33.6397L23.843 33.9106C23.7331 33.3674 23.5352 32.838 23.2486 32.3437L19.4911 25.8633C18.9871 24.9941 18.7568 24.0157 18.799 23.0465C14.7765 24.0928 12 26.3148 12 28.8875C12 32.4664 17.3727 35.3677 24.0002 35.3677C30.6274 35.3677 36 32.4664 36 28.8875C36 26.3146 33.2238 24.0922 29.201 23.0457Z"
      fill="white"
    />
  </Svg>
)

export const ProfileLevelBronze = memo(ProfileLevelBronzeIcon)
