import * as yup from 'yup'

export const homeFilterWalletFormSchema = yup.object().shape({
  micro_region: yup.string().nullable(),
  segment: yup
    .object({
      name: yup.string(),
      code: yup.number()
    })
    .nullable(),
  sub_segment: yup
    .object({
      name: yup.string(),
      code: yup.string()
    })
    .nullable()
})
