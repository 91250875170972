import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const DropdownIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 11 17', ...props }}>
    <Path
      d="m.454 2.632 3.968 3.926a1.537 1.537 0 0 0 2.16 0l3.968-3.926c.965-.956.275-2.593-1.088-2.593H1.527C.163.04-.511 1.676.454 2.632Z"
      fill="currentColor"
    />
  </Icon>
)
