import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const GraphicIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fill="#A8A8A8"
      fillRule="evenodd"
      d="M1.4.375a.75.75 0 0 1 .75.75v15.45H17.6a.75.75 0 0 1 0 1.5H1.4a.75.75 0 0 1-.75-.75v-16.2a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
    <Path
      fill="#A8A8A8"
      fillRule="evenodd"
      d="M.65 2.896a.75.75 0 0 1 .75-.75h14.4a.75.75 0 0 1 .75.75v3.6a.75.75 0 0 1-.75.75H1.4a.75.75 0 0 1 0-1.5h13.65v-2.1H1.4a.75.75 0 0 1-.75-.75ZM.65 10.18a.75.75 0 0 1 .75-.75h7.2a.75.75 0 0 1 .75.75v3.6a.75.75 0 0 1-.75.75H1.4a.75.75 0 0 1 0-1.5h6.45v-2.1H1.4a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </Icon>
)

export default GraphicIcon
