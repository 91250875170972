import { useCallback, useEffect } from 'react'

import { format } from 'date-fns'
import {
  useUnfavoriteKnowledgeMutation,
  useFavoriteKnowledgeMutation,
  useGetKnowledgeQuery
} from 'integration/resources/knowledgeBase'
import { useBreakpointValue } from 'native-base'
import { LogEvent, LogEventScreen } from 'src/utils/logEvents'

import { ContentScreen, FAVORITE_TYPE } from './ContentScreen.types'

export const useContentScreen: ContentScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { id } = route?.params

  const { data, isLoading, isError } = useGetKnowledgeQuery(id)

  const contentData = data?.data.data

  const favoriteKnowledgeMutation = useFavoriteKnowledgeMutation(id)

  const unfavoriteKnowledgeMutation = useUnfavoriteKnowledgeMutation(id)

  const handleGoToHomeContent = useCallback(() => navigation.goBack(), [navigation])

  const handleFavoriteKnowledge = () =>
    favoriteKnowledgeMutation.mutateAsync({
      id
    })

  const handleUnfavoriteKnowledgeMutation = () =>
    unfavoriteKnowledgeMutation.mutateAsync({
      id
    })

  const handleTogglefavorite = (type: FAVORITE_TYPE) => {
    switch (type) {
      case FAVORITE_TYPE.FAVORITE:
        handleFavoriteKnowledge()

        break

      case FAVORITE_TYPE.UNFAVORITE:
        handleUnfavoriteKnowledgeMutation()

        break
    }
  }

  useEffect(() => {
    if (contentData?.category.name) {
      LogEvent(contentData?.category.name, {
        item_id: id
      })

      const date = new Date()

      const formated = format(date, 'dd-MM-yyyy HH:mm')

      LogEventScreen({
        screen_name: `Conteudo - ${contentData?.category.name}`,
        screen_class: 'Content',
        date: formated,
        hora: format(date, 'HH'),
        minutos: format(date, 'mm')
      })
    }
  }, [contentData, id])

  useEffect(() => {
    if (!contentData && isError) {
      return navigation.navigate('ErrorPageNotFound')
    }
  }, [contentData, isError, navigation])

  return {
    isMobile,
    isLoading,
    isLoadingFavorite: favoriteKnowledgeMutation.isLoading || unfavoriteKnowledgeMutation.isLoading,
    favorite: contentData?.is_favorite ?? false,
    contentData,
    handleGoToHomeContent,
    handleTogglefavorite
  }
}
