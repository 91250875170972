import { useCallback, useEffect, useMemo, useState } from 'react'

import * as ScreenOrientation from 'expo-screen-orientation'
import {
  ChargeDataSerialized,
  CommissionDataSerialized,
  ConcentrationDataSerialized,
  FunnelDataSerialized,
  ResultDataSerialized,
  SwitchHookReturnType,
  useGetChargeQuery,
  useGetCommissionQuery,
  useGetConcentrationQuery,
  useGetFunnelQuery,
  useGetResultQuery
} from 'integration/resources/walletResume'
import { useTheme, useBreakpointValue } from 'native-base'
import { isObjectVazio } from 'src/utils'

import { UseHomeChartScreen } from './HomeChartScreen.types'
import { HomeChartsTypes, homeResumedChartsProps } from '../constants'

const CONTRACT_LIST_NAVIGATION: string | HomeChartsTypes[] = [
  HomeChartsTypes.PRODUCTION,
  HomeChartsTypes.RESULT,
  HomeChartsTypes.RECEIVABLE,
  HomeChartsTypes.CAMPAIGNS,
  HomeChartsTypes.WALLET_SITUATION
]

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

export const useHomeChartScreen: UseHomeChartScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  useEffect(() => {
    if (!isMobile) return

    changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT).then(() => {
      const rotateTimeout = setTimeout(() => {}, 1500)

      clearTimeout(rotateTimeout)
    })
  }, [isMobile])

  const theme = useTheme()

  const tabNumber = route.params.tabNumber

  const type = route.params.type

  const [showModal, setShowModal] = useState(true)

  const timeoutModal = setTimeout(() => {
    setShowModal(false)
  }, 2500)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutModal)
    }
  }, [timeoutModal])

  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    if (!showSpinner) return

    const timeoutSpinner = setTimeout(() => {
      setShowSpinner(!showSpinner)
    }, 3000)

    return () => {
      clearTimeout(timeoutSpinner)
    }
  }, [showSpinner])

  const handleGoBack = useCallback(() => {
    if (isMobile) {
      changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP).then(() => {
        navigation.goBack()
      })
    } else {
      navigation.goBack()
    }
  }, [navigation, isMobile])

  const handleGoToProductionScreen = useCallback(
    (yearMonth: string, type: HomeChartsTypes, total: string) => {
      if (!CONTRACT_LIST_NAVIGATION.includes(type)) {
        if (isMobile) {
          changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP).then(() => {
            setShowSpinner(true)

            navigation.navigate('Production', { yearMonth, type, total })
          })
        } else {
          navigation.navigate('Production', { yearMonth, type, total })
        }
      }
    },
    [navigation, isMobile]
  )

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (e.data.action.type === 'GO_BACK' && isMobile) {
          // Prevent default behavior of leaving the screen
          e.preventDefault()

          setShowSpinner(true)

          changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP)

          setTimeout(() => {
            navigation.dispatch(e.data.action)
          }, 3000)
        }
      }),
    [navigation, isMobile]
  )

  const resultType = [
    HomeChartsTypes.ACCUMULATED_PRODUCTION,
    HomeChartsTypes.PROJECTED_PRODUCTION,
    HomeChartsTypes.INSURANCE_PRESCRIPTION,
    HomeChartsTypes.MBB_REVENUE,
    HomeChartsTypes.VALUE_FRAUDS,
    HomeChartsTypes.VAR_OVER90
  ]

  const funnelType = [
    HomeChartsTypes.AMOUNT_SIMULATIONS,
    HomeChartsTypes.AMOUNT_CONTRACTS,
    HomeChartsTypes.AMOUNT_APNEF,
    HomeChartsTypes.VALUE_APNEF,
    HomeChartsTypes.SIMULATIONS_RATING,
    HomeChartsTypes.CONTRACTS_RATING
  ]

  const concentrationType = [
    HomeChartsTypes.AMOUNT_STORES,
    HomeChartsTypes.AMOUNT_ACTIVE_STORES,
    HomeChartsTypes.AMOUNT_INACTIVES,
    HomeChartsTypes.AMOUNT_HALF_PRODUCTION,
    HomeChartsTypes.PRODUCTION_SUBSEGMENT
  ]

  const comissionType = [
    HomeChartsTypes.PRODUCTION,
    HomeChartsTypes.CAMPAIGNS,
    HomeChartsTypes.RESULT,
    HomeChartsTypes.RECEIVABLE
  ]

  const chargeType = [
    HomeChartsTypes.FPD10,
    HomeChartsTypes.OVER30M3,
    HomeChartsTypes.SAFRA_BALANCE,
    HomeChartsTypes.OVER30,
    HomeChartsTypes.OVER60,
    HomeChartsTypes.OVER90,
    HomeChartsTypes.WALLET_BALANCE,
    HomeChartsTypes.FRAUDS,
    HomeChartsTypes.OVERDUE,
    HomeChartsTypes.WALLET_SITUATION,
    HomeChartsTypes.CONTRACTS_DELINQUENCY_QTY
  ]

  const resumeType = () => {
    if (resultType.includes(type)) return 'result'

    if (funnelType.includes(type)) return 'funnel'

    if (concentrationType.includes(type)) return 'concentration'

    if (comissionType.includes(type)) return 'comission'

    if (chargeType.includes(type)) return 'charge'

    return ''
  }

  const switchHook = (
    type: 'result' | 'funnel' | 'concentration' | 'comission' | 'charge' | ''
  ) => {
    switch (type) {
      case 'charge':
        return useGetChargeQuery

      case 'comission':
        return useGetCommissionQuery

      case 'concentration':
        return useGetConcentrationQuery

      case 'funnel':
        return useGetFunnelQuery

      case 'result':
        return useGetResultQuery

      default:
        break
    }
  }

  const selectedType = resumeType()

  const hook = switchHook(resumeType())

  //@ts-ignore
  const data = hook()

  const switchChartData = useMemo(() => {
    const resumeData: SwitchHookReturnType = data?.data?.data.data

    let responseData

    if (resumeData)
      switch (selectedType) {
        case 'charge':
          responseData = resumeData as ChargeDataSerialized

          switch (type) {
            // TODO: change chart_data for screen_data?
            case HomeChartsTypes.FPD10:
              return responseData.chart_data.fpd10_serialized

            case HomeChartsTypes.OVER30M3:
              return responseData.chart_data.over30m3_serialized

            case HomeChartsTypes.SAFRA_BALANCE:
              return responseData.chart_data.saldo_safra_serialized

            case HomeChartsTypes.OVER30:
              return responseData.chart_data.over30_serialized

            case HomeChartsTypes.OVER60:
              return responseData.chart_data.over60_serialized

            case HomeChartsTypes.OVER90:
              return responseData.chart_data.over90_serialized

            case HomeChartsTypes.WALLET_BALANCE:
              return responseData.chart_data.wallet_balance_serialized

            case HomeChartsTypes.WALLET_SITUATION:
              return [
                responseData.chart_data.wallet_situation_serialized.overdue,
                responseData.chart_data.wallet_situation_serialized.in_day
              ]

            case HomeChartsTypes.OVERDUE:
              return responseData.chart_data.overdue_serialized

            case HomeChartsTypes.FRAUDS:
              return responseData.chart_data.fraud_serialized

            case HomeChartsTypes.CONTRACTS_DELINQUENCY_QTY:
              return responseData.chart_data.contract_delinquency_qty_serialized

            default:
              break
          }

          return []

        case 'comission':
          responseData = resumeData as CommissionDataSerialized

          switch (type) {
            case HomeChartsTypes.PRODUCTION:
              return responseData.chart_data.commission_production_serialized

            case HomeChartsTypes.CAMPAIGNS:
              return responseData.chart_data.commission_campaign_serialized

            case HomeChartsTypes.RESULT:
              return responseData.chart_data.commission_results_serialized

            case HomeChartsTypes.RECEIVABLE:
              return responseData.chart_data.commission_to_receive_serialized

            default:
              break
          }

          return []

        case 'concentration':
          responseData = resumeData as ConcentrationDataSerialized

          switch (type) {
            case HomeChartsTypes.AMOUNT_STORES:
              return responseData.chart_data.total_stores_serialized

            case HomeChartsTypes.AMOUNT_ACTIVE_STORES:
              return [
                responseData.chart_data.active_stores_serialized,
                responseData.chart_data.simulate_serialized,
                responseData.chart_data.produce_serialized,
                responseData.chart_data.recurrent_serialized
              ]

            case HomeChartsTypes.AMOUNT_INACTIVES:
              return responseData.chart_data.inactive_stores_serialized

            case HomeChartsTypes.AMOUNT_HALF_PRODUCTION:
              return responseData.chart_data.production_50_serialized

            case HomeChartsTypes.PRODUCTION_SUBSEGMENT:
              return responseData.chart_data.production_serialized.length > 3
                ? responseData.chart_data.production_serialized.slice(0, 3)
                : responseData.chart_data.production_serialized

            default:
              break
          }

          return []

        case 'funnel':
          responseData = resumeData as FunnelDataSerialized

          switch (type) {
            case HomeChartsTypes.AMOUNT_SIMULATIONS:
              return responseData.chart_data.simulations_quantity_serialized

            case HomeChartsTypes.AMOUNT_CONTRACTS:
              return responseData.chart_data.contracts_quantity_serialized

            case HomeChartsTypes.AMOUNT_APNEF:
              return responseData.chart_data.apnef_quantity_serialized

            case HomeChartsTypes.VALUE_APNEF:
              return responseData.chart_data.apnef_value_serialized

            case HomeChartsTypes.SIMULATIONS_RATING:
              return [
                responseData.chart_data.simulations_serialized.range9or10,
                responseData.chart_data.simulations_serialized.range7or8,
                responseData.chart_data.simulations_serialized.range5or6
              ]

            case HomeChartsTypes.CONTRACTS_RATING:
              return [
                responseData.chart_data.contracts_serialized.range9or10,
                responseData.chart_data.contracts_serialized.range7or8,
                responseData.chart_data.contracts_serialized.range5or6
              ]

            default:
              break
          }

          return []

        case 'result':
          responseData = resumeData as ResultDataSerialized

          switch (type) {
            case HomeChartsTypes.ACCUMULATED_PRODUCTION:
              return responseData.chart_data.production_accumulated_serialized

            case HomeChartsTypes.PROJECTED_PRODUCTION:
              return responseData.chart_data.production_projected_serialized

            case HomeChartsTypes.INSURANCE_PRESCRIPTION:
              return responseData.chart_data.insurances_serialized

            case HomeChartsTypes.MBB_REVENUE:
              return responseData.chart_data.mbb_serialized

            case HomeChartsTypes.VALUE_FRAUDS:
              return responseData.chart_data.frauds_serialized

            case HomeChartsTypes.VAR_OVER90:
              return responseData.chart_data.over90_serialized

            default:
              return []
          }
      }

    return []
  }, [type, selectedType, data])

  const chartsData = switchChartData

  const switchChartDataColors = useMemo(() => {
    switch (type) {
      case HomeChartsTypes.SIMULATIONS_RATING:
        return [theme.colors.primary[800], theme.colors.primary[500], theme.colors.primary[100]]

      case HomeChartsTypes.CONTRACTS_RATING:
        return [theme.colors.primary[500], theme.colors.primary[300], theme.colors.primary[100]]

      case HomeChartsTypes.PRODUCTION_SUBSEGMENT:
        return [theme.colors.primary[800], theme.colors.primary[500], theme.colors.primary[100]]

      case HomeChartsTypes.AMOUNT_ACTIVE_STORES:
        return [
          theme.colors.primary[800],
          theme.colors.primary[500],
          theme.colors.primary[300],
          theme.colors.primary[100]
        ]

      case HomeChartsTypes.WALLET_SITUATION:
        return [theme.colors.primary[500], theme.colors.primary[300], theme.colors.primary[100]]

      case HomeChartsTypes.OVERDUE:
        return [theme.colors.primary[500], theme.colors.primary[300], theme.colors.primary[100]]

      default:
        return []
    }
  }, [type, theme.colors])

  const switchChartDataTooltips = useMemo(() => {
    const resumeData: SwitchHookReturnType = data?.data?.data.data

    let responseData

    let tooltipsValues: { [key: string]: number }[] = []

    switch (type) {
      case HomeChartsTypes.SIMULATIONS_RATING:
        responseData = resumeData as FunnelDataSerialized

        tooltipsValues =
          responseData?.chart_data.simulations.months.map((item) => ({
            rangeNt: item.rangeNt,
            range1or2: item.range1or2,
            range3or4: item.range3or4,
            range5or6: item.range5or6,
            range7or8: item.range7or8,
            range9or10: item.range9or10
          })) ?? []

        return {
          values: tooltipsValues,
          titles: homeResumedChartsProps[HomeChartsTypes.SIMULATIONS_RATING].tooltipsTitles,
          showTooltip: true
        }

      case HomeChartsTypes.CONTRACTS_RATING:
        responseData = resumeData as FunnelDataSerialized

        tooltipsValues =
          responseData.chart_data.contracts.months.map((item) => ({
            rangeNt: item.rangeNt,
            range1or2: item.range1or2,
            range3or4: item.range3or4,
            range5or6: item.range5or6,
            range7or8: item.range7or8,
            range9or10: item.range9or10
          })) ?? []

        return {
          values: tooltipsValues,
          titles: homeResumedChartsProps[HomeChartsTypes.CONTRACTS_RATING].tooltipsTitles,
          showTooltip: true
        }

      case HomeChartsTypes.PRODUCTION_SUBSEGMENT:
        responseData = resumeData as ConcentrationDataSerialized

        responseData?.chart_data.productions.months.map((item) => {
          const obj: { [key: string]: number } = {}

          item.subsegments.length &&
            item.subsegments.forEach((subitem) => {
              obj[subitem.name] = subitem.production
            })

          if (!isObjectVazio(obj)) tooltipsValues.push(obj)
        })

        return {
          values: tooltipsValues,
          titles: responseData.chart_data.subsegment_names,
          showTooltip: true
        }

      case HomeChartsTypes.WALLET_SITUATION:
        responseData = resumeData as ChargeDataSerialized

        tooltipsValues = responseData?.chart_data.wallet_situation.months.map((item) => ({
          inDay: item.in_day,
          overdue: item.overdue
        }))

        return {
          values: tooltipsValues,
          titles: homeResumedChartsProps[HomeChartsTypes.WALLET_SITUATION].tooltipsTitles,
          showTooltip: true
        }

      case HomeChartsTypes.OVERDUE:
        responseData = resumeData as ChargeDataSerialized

        tooltipsValues = responseData?.chart_data.overdue.months.map((item) => {
          const obj: { [key: string]: number } = {}

          item.ranges.forEach((subitem) => {
            obj[subitem.label] = subitem.value
          })

          return obj
        })

        return {
          values: tooltipsValues,
          titles: homeResumedChartsProps[HomeChartsTypes.OVERDUE].tooltipsTitles,
          showTooltip: true
        }

      default:
        return {
          values: [],
          titles: []
        }
    }
  }, [type, data])

  const switchChartLabels = useMemo(() => {
    const resumeData: SwitchHookReturnType = data?.data?.data.data

    let responseData

    switch (type) {
      case HomeChartsTypes.PRODUCTION_SUBSEGMENT:
        responseData = resumeData as ConcentrationDataSerialized

        return responseData.chart_data?.subsegment_names?.length > 3
          ? responseData.chart_data.subsegment_names.slice(0, 3)
          : responseData.chart_data.subsegment_names ?? []

      case HomeChartsTypes.WALLET_SITUATION:
        responseData = resumeData as ConcentrationDataSerialized

        return responseData.chart_data?.subsegment_names?.length > 3
          ? responseData.chart_data.subsegment_names.slice(0, 3)
          : responseData.chart_data.subsegment_names ?? []

      case HomeChartsTypes.OVERDUE:
        return []

      default:
        return []
    }
  }, [type, data])

  const switchChartProcessed = useMemo(() => {
    const resumeData: SwitchHookReturnType = data?.data?.data.data

    let responseData

    if (resumeData)
      switch (selectedType) {
        case 'charge':
          responseData = resumeData as ChargeDataSerialized

          switch (type) {
            case HomeChartsTypes.FPD10:
              return responseData.screen_data.fpd10.processed_at

            case HomeChartsTypes.OVER30M3:
              return responseData.screen_data.over30m3.processed_at

            case HomeChartsTypes.SAFRA_BALANCE:
              return responseData.screen_data.saldo_safra.processed_at

            case HomeChartsTypes.OVER30:
              return responseData.screen_data.over30.processed_at

            case HomeChartsTypes.OVER60:
              return responseData.screen_data.over60.processed_at

            case HomeChartsTypes.OVER90:
              return responseData.screen_data.over90.processed_at

            case HomeChartsTypes.WALLET_BALANCE:
              return responseData.screen_data.wallet_balance.processed_at

            case HomeChartsTypes.WALLET_SITUATION:
              return responseData.screen_data.wallet_situation.processed_at

            case HomeChartsTypes.OVERDUE:
              return responseData.screen_data.overdue.processed_at

            case HomeChartsTypes.FRAUDS:
              return responseData.screen_data.fraud.processed_at

            default:
              break
          }

          return ''

        case 'comission':
          responseData = resumeData as CommissionDataSerialized

          switch (type) {
            case HomeChartsTypes.PRODUCTION:
              return responseData.screen_data.commission_production.processed_at

            case HomeChartsTypes.CAMPAIGNS:
              return responseData.screen_data.commission_campaign.processed_at

            case HomeChartsTypes.RESULT:
              return responseData.screen_data.commission_results.processed_at

            case HomeChartsTypes.RECEIVABLE:
              return responseData.screen_data.commission_to_receive.processed_at

            default:
              break
          }

          return ''

        case 'concentration':
          responseData = resumeData as ConcentrationDataSerialized

          switch (type) {
            case HomeChartsTypes.AMOUNT_STORES:
              return responseData.screen_data.total_stores.processed_at

            case HomeChartsTypes.AMOUNT_ACTIVE_STORES:
              return responseData.screen_data.active_stores.processed_at

            case HomeChartsTypes.AMOUNT_INACTIVES:
              return responseData.screen_data.inactive_stores.processed_at

            case HomeChartsTypes.AMOUNT_HALF_PRODUCTION:
              return responseData.screen_data.production_50.processed_at

            case HomeChartsTypes.PRODUCTION_SUBSEGMENT:
              return responseData.screen_data.productions.processed_at

            default:
              break
          }

          return ''

        case 'funnel':
          responseData = resumeData as FunnelDataSerialized

          switch (type) {
            case HomeChartsTypes.AMOUNT_SIMULATIONS:
              return responseData.screen_data.simulations_quantity.processed_at

            case HomeChartsTypes.AMOUNT_CONTRACTS:
              return responseData.screen_data.contracts_quantity.processed_at

            case HomeChartsTypes.AMOUNT_APNEF:
              return responseData.screen_data.apnef_quantity.processed_at

            case HomeChartsTypes.VALUE_APNEF:
              return responseData.screen_data.apnef_value.processed_at

            case HomeChartsTypes.SIMULATIONS_RATING:
              return responseData.screen_data.simulations.processed_at

            case HomeChartsTypes.CONTRACTS_RATING:
              return responseData.screen_data.contracts.processed_at

            default:
              break
          }

          return ''

        case 'result':
          responseData = resumeData as ResultDataSerialized

          switch (type) {
            case HomeChartsTypes.ACCUMULATED_PRODUCTION:
              return responseData.screen_data.production_accumulated.processed_at

            case HomeChartsTypes.PROJECTED_PRODUCTION:
              return responseData.screen_data.production_projected.processed_at

            case HomeChartsTypes.INSURANCE_PRESCRIPTION:
              return responseData.screen_data.insurances.processed_at

            case HomeChartsTypes.MBB_REVENUE:
              return responseData.screen_data.mbb.processed_at

            case HomeChartsTypes.VALUE_FRAUDS:
              return responseData.screen_data.frauds.processed_at

            case HomeChartsTypes.VAR_OVER90:
              return responseData.screen_data.over90.processed_at

            default:
              return ''
          }
      }

    return ''
  }, [type, selectedType, data])

  return {
    isMobile,
    handleGoBack,
    handleGoToProductionScreen,
    showModal,
    setShowModal,
    showSpinner,
    setShowSpinner,
    type,
    chartsData,
    chartsDataIsLoading: data.isLoading,
    chartsDataColors: switchChartDataColors,
    tooltips: switchChartDataTooltips,
    chartLabels: switchChartLabels,
    processed_at: switchChartProcessed,
    tabNumber
  }
}
