import { FunctionComponent } from 'react'

import { Box, HStack, Text, VStack } from 'native-base'
import { Platform, TouchableHighlight } from 'react-native'
import Tooltip from 'react-native-walkthrough-tooltip'
import { HomeChartsTypes } from 'src/screens/HomeScreen/constants'
import { currencyFormatChart } from 'src/utils/currencyFormat'

type StackBarChartTooltipProps = {
  values: number[]
  titles: string[]
  isVisible: boolean
  type: HomeChartsTypes
  handleOnClose: () => void
  marginTopTooltip?: number
}

export const StackBarChartTooltip: FunctionComponent<StackBarChartTooltipProps> = ({
  values,
  type,
  titles,
  marginTopTooltip,
  isVisible,
  handleOnClose
}) => {
  const isMobile = Platform.OS !== 'web'

  const desktopStyle = {
    backgroundColor: 'white',
    borderRadius: 15,
    shadowColor: 'rgba(68, 68, 79, 0.10)',
    shadowOffset: {
      width: 4,
      height: 4
    },
    shadowOpacity: 0.8,
    shadowRadius: 3.84
  }

  return (
    <>
      {values.length ? (
        <Tooltip
          isVisible={isVisible}
          backgroundColor="transparent"
          placement="top"
          contentStyle={{
            paddingHorizontal: 12,
            paddingVertical: !isMobile ? 8 : undefined,
            borderRadius: 15,
            elevation: isMobile ? 6 : 2
          }}
          tooltipStyle={{
            marginTop: marginTopTooltip,
            marginLeft: -8,

            ...(isMobile ? {} : desktopStyle)
          }}
          disableShadow={!isMobile}
          content={
            <VStack>
              {values.map((value, index) => (
                <HStack key={`key-${index}`} space="2px" alignItems="center">
                  <Text fontSize="10px" color="gray.700" fontWeight={400}>
                    {HomeChartsTypes.PRODUCTION_SUBSEGMENT === type
                      ? `${titles[index].slice(0, 21)}. `
                      : `${titles[index]} `}
                  </Text>
                  <Text fontSize="10px" color="gray.700" fontWeight={700}>
                    {HomeChartsTypes.PRODUCTION_SUBSEGMENT === type
                      ? `${currencyFormatChart(String(value), true)}`
                      : `${currencyFormatChart(String(value), true, false, 2)}%`}
                  </Text>
                </HStack>
              ))}
            </VStack>
          }
          onClose={handleOnClose}>
          <TouchableHighlight>
            <Text opacity={0}>Click</Text>
          </TouchableHighlight>
        </Tooltip>
      ) : (
        <Box />
      )}
    </>
  )
}
