import { ResponsePagination } from 'integration/types'

export const ACTUAL_PLUS_FIDELITY_CLASSIFICATION = {
  GOLD: 'Gold',
  BRONZE: 'Bronze',
  PLATINUM: 'Platinum',
  SILVER: 'Silver',
  0: 'Sem dados'
}

export const ACTUAL_PLUS_FIDELITY_CLASSIFICATION_COLOR = {
  GOLD: 'tabs-yellow.50',
  BRONZE: 'tabs-yellow.100',
  PLATINUM: 'gray.100',
  SILVER: 'gray.300',
  0: 'primary.500'
}

export type BirthDatePicker = {
  day?: string
  month?: string
  year?: number | string
}

export type Contact = {
  id: string
  identifier?: string
  name?: string
  phone_number?: string
  phone_number_ddd?: string
  email?: string
  profile_image_url?: string | null
  profile_image_file_name?: string | null
  cover_image_url?: string
  logo_image_url?: string
  store_name?: string
  tab_number: string
  jobTitle?: string
  job_title?: string
  is_favorite?: boolean
  birth_date?: string
  is_activate_reminder?: boolean
  is_programmed_message?: boolean
  birthday_message?: string
  birthday_message_title?: string
  message_send_date?: string
  address_street?: string
  address_number?: string
  address_postal_code?: string
  address_neighborhood?: string
  address_city?: string
  address_uf?: string
  address_complement?: string
  secondary_address_city?: string
  secondary_address_complement?: string
  secondary_address_name?: string
  secondary_address_neighborhood?: string
  secondary_address_number?: string
  secondary_address_postal_code?: string
  secondary_address_street?: string
  secondary_address_uf?: string
  birthDatePicker?: BirthDatePicker
  website?: string
  web_site?: string
  creator_name?: string
}

export type ContactSerialized = Contact & {
  birthDateFormatted?: string | number
}

export type Tab = {
  id: string
  name_fantasy?: string
  tab_number: string
  is_favorite?: boolean
  logo_image_url?: string
  potential?: string
}

export type WalletItem = ContactSerialized &
  Tab & {
    id: string
    name: string
    job: string
    store: string
    image: string
  }

export type TabDetail = {
  name?: string
  id?: string
  is_favorite?: boolean
  jobTitle?: string
  store_name?: string
  tab_number: string
  identifier?: string
  address_name?: string
  address_street?: string
  address_number?: string
  profile_image_url?: string
  logo_image_file_name?: string | null
  address_postal_code?: string
  address_neighborhood?: string
  address_city?: string
  address_uf?: string
  secondary_address_name?: string
  secondary_address_complement?: string
  secondary_address_street?: string
  secondary_address_number?: string
  secondary_address_postal_code?: string
  secondary_address_neighborhood?: string
  secondary_address_city?: string
  secondary_address_uf?: string
  cnpj?: string
  logo_image_url?: string | null
  network?: string
  subsidiary?: string
  segment?: string
  subsegment?: string
  website?: string
  email?: string
  phone_number_ddd?: string
  phone_number?: string
}

export type ContactListSerialized = ContactSerialized[]

export type ContactListResponse = {
  data: ContactListSerialized
  metadata: {
    pagination: ResponsePagination
  }
}

export type GetContactsParams = {
  search: string
  store_tab_number?: string
  job_title?: string
  page?: number
  per_page?: number
  is_creator?: 0 | 1
  is_favorite?: 0 | 1
  order_by?: string
  order_by_direction?: 'asc' | 'desc'
}

export type Note = {
  id: string
  title?: string
  detail?: string
  type?: string
  created_at: string
  tab_name?: string
  tab_number?: string
  contact_id?: string
}

export type GetNoteListParams = {
  id: string
  page?: number
  per_page?: number
}

export type GetListsParams = {
  page?: number
  per_page?: number
  search?: string
  is_favorite?: number
  expand_details?: boolean
  is_creator?: string | undefined
  order_by?: string
  store_tab_number?: string
  job_title?: string
  subsegment_code?: number
  subSegment_name?: string
  micro_region?: string
  status?: string
  segments?: string
}

export type GetTabListParams = {
  page?: number
  per_page?: number
  search?: string
  is_favorite?: number
  order_by?: string
}

export type ClassificationTAB = {
  segment: string
  segment_processed_at: string
  segment_is_short_format?: boolean
  subsegment: string
  subsegment_processed_at: string
  subsegment_is_short_format?: boolean
  rating: string
  rating_processed_at: string
  rating_is_short_format?: boolean
  accreditation_at: string
  accreditation_processed_at: string
  accreditation_at_is_short_format?: boolean
  points_left_for_next_rating: string
  fidelity: 0
  plus_fidelity_premium_target: number
  plus_fidelity_premium_target_processed_at: string
  plus_fidelity_premium_target_is_short_format?: boolean
  plus_fidelity_points_achieved: number
  plus_fidelity_points_achieved_processed_at: string
  plus_fidelity_points_achieved_is_short_format?: boolean
  chamas_quantity: 0
  chamas_quantity_processed_at: string
  chamas_quantity_is_short_format?: boolean
  has_benefits_rescued: number
  has_benefits_rescued_processed_at: string
  has_benefits_rescued_is_short_format?: boolean
  platinum_actual_month: number
  platinum_actual_month_processed_at: string
  platinum_actual_month_is_short_format?: boolean
  platinum_previous_month: number
  platinum_previous_month_processed_at: string
  platinum_previous_month_is_short_format?: boolean
  platinum_applicant: number
  platinum_applicant_processed_at: string
  platinum_applicant_is_short_format?: boolean
  actual_plus_fidelity_classification: keyof typeof ACTUAL_PLUS_FIDELITY_CLASSIFICATION
  actual_plus_fidelity_classification_processed_at: string
  actual_plus_fidelity_classification_is_short_format?: boolean
}

export type RegulatoryTAB = {
  has_corban: boolean
  has_corban_processed_at: string
  cpf_master_user: string
  cpf_master_user_processed_at: string
  kyc: boolean
  kyc_processed_at: string
  kyc_expiration: string
  kyc_expiration_processed_at: string
  certified_agent: boolean
  certified_agent_processed_at: string
  certified_agent_expiration: string
  certified_agent_expiration_processed_at: string
  certified_pld_ft: boolean
  certified_pld_ft_processed_at: string
  certified_pld_ft_expiration: string
  certified_pld_ft_expiration_processed_at: string
  certified_lgpd: boolean
  certified_lgpd_processed_at: string
  certified_lgpd_expiration: string
  certified_lgpd_expiration_processed_at: string
}

export type EcosystemTAB = {
  missing_products_qty: number
  products_percentage: number
  products: {
    name: string
    code: number
    has_product: boolean
  }[]
}

export type TSummaryItem = {
  production: number
  market_share: number
  last_updated_at: string
  year_month: number
}

export type SummaryChartData = {
  months: TSummaryItem[]
  processed_at: string
  is_short_format?: boolean
}

export type Attachment = {
  id?: string
  file_name: string
  name: string
  attachment_url: string
  url?: string
}

export type AttachmentList = Attachment & {
  created_at: string
}

export type GetAttachmentListParams = {
  type: 'contacts' | 'stores'
  id: string
  page?: number
  per_page?: number
}

export type DeleteAttachmentParams = {
  type: 'contacts' | 'stores' | 'activity'
  id: string
  attachmentId: string
}

export type CreateAttachmentParams = {
  type: 'contacts' | 'stores' | 'activity'
  id: string
  attachment: Pick<Attachment, 'file_name' | 'name'>
}

export type ProductToUpdate = {
  product_code: number
  has_product: boolean
}

export type WalletSummary = {
  production: {
    total: number
    rise_up: boolean
    tabs: [
      {
        store_tab_number: number
        production: string
      },
      {
        store_tab_number: number
        production: string
      },
      {
        store_tab_number: number
        production: string
      }
    ]
    processed_at?: string
    is_short_format?: boolean
  }
  fpd10: {
    total_value: number
    total_percentage: number
    rise_up: boolean
    processed_at?: string
    is_short_format?: boolean
  }
  stores_status: {
    total: number
    active: number
    inactive: number
    rise_up: boolean
    processed_at?: string
    is_short_format?: boolean
  }
  active: {
    total: number
    simulate: number
    produce: number
    applicant: number
    rise_up: boolean
    processed_at?: string
    is_short_format?: boolean
  }
  plus_fidelities: {
    total: number
    platinum: number
    gold: number
    fidelity: number
    silver: number
    bronze: number
    rise_up: boolean
    processed_at?: string
    is_short_format?: boolean
  }
  certificates: {
    expired: number
    kyc: number
    certificate_ag: number
    lgpd: number
    pld: number
    rise_up: boolean
    processed_at?: string
    is_short_format?: boolean
  }
}

export const WalletSummaryNames = {
  production: 'Produção (R$)',
  fpd10: 'FPD10 (R$)',
  stores_status: 'Qtde.Lojas',
  active: 'Qtde.Ativas',
  plus_fidelities: 'Mais Fidelidade',
  certificates: 'Cert.vencidas',
  'Qtde.Ativas': 'Ativas',
  inactive: 'Inativas',
  simulate: 'Simulam',
  produce: 'Produzem',
  applicant: 'Recorrentes',
  platinum: 'Platinum',
  gold: 'Gold',
  fidelity: 'Fidelizadas',
  kyC: 'KyC',
  certificate_ag: 'Ag.Certificado',
  lgpd: 'LGPD',
  pld: 'PLD-FT',
  total_percentage: '%'
}

export type TContract = {
  credit_insurance: string
  fpd10: number
  market_share: number
  store_financed_value: number
  name_fantasy: string
  over30_m3: number
  production: number
  santander_auto_insurance: number
  tab_number: string
  ip_insurance_spf_qty: number
  ip_insurance_spf_volume: number
  vl_over30_m3: number
  vl_fpd10: number
  total_value: number
  contracts: {
    code: string
    production: string
  }[]
}

export type TContractParams = {
  tabNumber: string
  yearMonth: string
  type?: string
}

export type SummaryChartDataSerialized = SummaryChartData & {
  monthsSerialized: {
    production: number
    market_share: number
    last_updated_at: string
    year_month: string
  }[]
  higherValue: number
}
