import { memo } from 'react'

import Svg, { Path, Circle } from 'react-native-svg'

const FallbackIllustration = () => (
  <Svg width={159} height={158} viewBox="0 0 159 158" fill="none">
    <Circle cx="79.5" cy="78.666" r="78" stroke="#DBDBDB" />
    <Circle
      opacity="0.5"
      cx="79.5"
      cy="78.5923"
      r="58.6978"
      fill="#F5F5F5"
      stroke="#DBDBDB"
      stroke-width="12"
    />
    <Circle opacity="0.5" cx="79.5" cy="78.666" r="45" fill="white" />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M85.1964 78.0248C85.1572 77.1275 84.9051 76.2374 84.4386 75.4328L80.3231 68.3353C80.0093 67.7939 79.7925 67.214 79.672 66.6191L79.5002 66.9158C78.4814 68.6725 78.4814 70.8368 79.5 72.5935L82.7924 78.2716C83.8111 80.0285 83.8111 82.193 82.7924 83.9497L82.6204 84.2463C82.5001 83.6515 82.2832 83.0716 81.9693 82.5304L77.0308 74.0132C76.7171 73.472 76.5002 72.8919 76.3798 72.2972L76.2078 72.5939C75.1927 74.3447 75.1895 76.5003 76.1977 78.2538L76.1975 78.254L79.5002 83.9497C80.5187 85.7064 80.5187 87.8709 79.5002 89.6278L79.3281 89.9244C79.2077 89.3295 78.991 88.7497 78.6771 88.2083L74.5617 81.1107C74.0097 80.1588 73.7575 79.0872 73.8037 78.0256C69.3982 79.1716 66.3572 81.6053 66.3572 84.423C66.3572 88.3428 72.2416 91.5203 79.5002 91.5203C86.7586 91.5203 92.6429 88.3428 92.6429 84.423C92.6429 81.6051 89.6023 79.1709 85.1964 78.0248Z"
      fill="#C2C2C2"
    />
    <Path
      d="M103.952 64.346C103.952 64.8021 103.648 65.1061 103.192 65.1061H96.7339C96.5059 65.1061 96.354 65.2582 96.354 65.4862V71.9476C96.354 72.4037 96.0501 72.7078 95.5942 72.7078H94.0746C93.6188 72.7078 93.3148 72.4037 93.3148 71.9476V65.4862C93.3148 65.2582 93.1629 65.1061 92.935 65.1061H86.4768C86.021 65.1061 85.717 64.8021 85.717 64.346V62.8257C85.717 62.3696 86.021 62.0655 86.4768 62.0655H92.935C93.1629 62.0655 93.3148 61.9135 93.3148 61.6854V55.224C93.3148 54.7679 93.6188 54.4639 94.0746 54.4639H95.5942C96.0501 54.4639 96.354 54.7679 96.354 55.224V61.6854C96.354 61.9135 96.5059 62.0655 96.7339 62.0655H103.192C103.648 62.0655 103.952 62.3696 103.952 62.8257V64.346Z"
      fill="#C2C2C2"
    />
  </Svg>
)

export const Fallback = memo(FallbackIllustration)
