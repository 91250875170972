import { ActivityForm } from 'src/components/templates/ActivityFormTemplate/types'

export const serializerActivity = (formData: ActivityForm, user_std_code: number) => {
  const store_wallet_identifier =
    formData?.store?.store_wallet_identifier || formData?.store?.identifier

  return {
    id: formData?.id ?? null,
    activity_type_identifier: formData?.activity_type?.identifier ?? '',
    description: formData.description ?? null,
    is_all_day_scheduled: formData.is_all_day_schedule ?? false,
    opportunity_external_id: formData?.opportunity?.external_id ?? null,
    schedule_date: formData?.schedule_date
      ? new Date(formData?.schedule_date).toISOString() ?? ''
      : '',
    ...(store_wallet_identifier && {
      ...{ store_wallet_identifier: Number(store_wallet_identifier) }
    }),
    title: formData.title ?? null,
    user_std_code,
    action_items:
      formData.activity_plan_action_items?.map((item) => ({
        name: item.value,
        is_finished: item.checked
      })) ?? null,
    attachments:
      formData.activity_attachments?.map((item) => ({
        name: item.name,
        file_name: item.file_name
      })) ?? []
  }
}
