import Svg, { Path, SvgProps } from 'react-native-svg'

export const NoWalletTabs = (props: SvgProps) => {
  return (
    <Svg width="201" height="200" viewBox="0 0 201 200" fill="none">
      <Path
        d="M60.2466 114.992L59.998 158.745L116.43 187.582L190.512 178.136L190.014 133.637L116.43 142.835L60.2466 114.992Z"
        fill="#FAFAFA"
      />
      <Path
        d="M92.8243 114.367L112.717 111.789V80.9844L51.8223 88.9644V93.3064L92.8243 114.367Z"
        fill="#DBDBDB"
      />
      <Path
        d="M92.8243 114.367L112.717 111.789V80.9844L51.8223 88.9644V93.3064L92.8243 114.367Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M92.8246 114.369L131.205 109.394V90.125L70.3096 98.105V102.804L92.8246 114.369Z"
        fill="#DBDBDB"
      />
      <Path
        d="M92.8246 114.369L131.205 109.394V90.125L70.3096 98.105V102.804L92.8246 114.369Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M92.8245 114.367L149.292 107.047V99.2617L88.3975 107.242V112.093L92.8245 114.367Z"
        fill="#DBDBDB"
      />
      <Path
        d="M92.8245 114.367L149.292 107.047V99.2617L88.3975 107.242V112.093L92.8245 114.367Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M112.717 76.0631V80.9631L51.8223 88.9431C56.2773 86.2821 56.7763 85.8281 58.0733 85.6751L87.7133 81.7231L93.2043 75.0731C93.8702 74.2718 94.8128 73.7497 95.8453 73.6101L108.005 71.9571C108.592 71.8759 109.189 71.9214 109.757 72.0906C110.324 72.2598 110.849 72.5486 111.295 72.9377C111.742 73.3268 112.1 73.8071 112.345 74.3463C112.59 74.8854 112.717 75.4708 112.717 76.0631Z"
        fill="#F3123C"
      />
      <Path
        d="M112.717 76.0631V80.9631L51.8223 88.9431C56.2773 86.2821 56.7763 85.8281 58.0733 85.6751L87.7133 81.7231L93.2043 75.0731C93.8702 74.2718 94.8128 73.7497 95.8453 73.6101L108.005 71.9571C108.592 71.8759 109.189 71.9214 109.757 72.0906C110.324 72.2598 110.849 72.5486 111.295 72.9377C111.742 73.3268 112.1 73.8071 112.345 74.3463C112.59 74.8854 112.717 75.4708 112.717 76.0631Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M131.2 85.2041V90.1241L70.2998 98.1041C74.7548 95.4431 75.2538 94.9891 76.5508 94.8361L106.2 90.8841L111.691 84.2341C112.357 83.4327 113.299 82.9106 114.332 82.7711L126.492 81.1181C127.077 81.0362 127.673 81.0806 128.239 81.2483C128.805 81.416 129.329 81.7031 129.775 82.0903C130.221 82.4774 130.579 82.9557 130.825 83.4928C131.071 84.0299 131.199 84.6134 131.2 85.2041Z"
        fill="#F3123C"
      />
      <Path
        d="M131.2 85.2041V90.1241L70.2998 98.1041C74.7548 95.4431 75.2538 94.9891 76.5508 94.8361L106.2 90.8841L111.691 84.2341C112.357 83.4327 113.299 82.9106 114.332 82.7711L126.492 81.1181C127.077 81.0362 127.673 81.0806 128.239 81.2483C128.805 81.416 129.329 81.7031 129.775 82.0903C130.221 82.4774 130.579 82.9557 130.825 83.4928C131.071 84.0299 131.199 84.6134 131.2 85.2041Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M149.292 94.3399V99.2399L88.4004 107.222C92.8554 104.561 93.3544 104.107 94.6514 103.954L124.291 100.021L129.782 93.3709C130.448 92.5696 131.39 92.0475 132.423 91.9079L144.583 90.2549C145.168 90.1729 145.764 90.2172 146.33 90.3848C146.897 90.5524 147.42 90.8393 147.867 91.2264C148.313 91.6134 148.671 92.0916 148.917 92.6287C149.163 93.1657 149.291 93.7493 149.292 94.3399Z"
        fill="#F3123C"
      />
      <Path
        d="M149.292 94.3399V99.2399L88.4004 107.222C92.8554 104.561 93.3544 104.107 94.6514 103.954L124.291 100.021L129.782 93.3709C130.448 92.5696 131.39 92.0475 132.423 91.9079L144.583 90.2549C145.168 90.1729 145.764 90.2172 146.33 90.3848C146.897 90.5524 147.42 90.8393 147.867 91.2264C148.313 91.6134 148.671 92.0916 148.917 92.6287C149.163 93.1657 149.291 93.7493 149.292 94.3399Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M92.8242 114.366V149.896L152.332 142.182V106.652L92.8242 114.366Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M48.3262 91.5117L92.8242 114.369V149.918L48.3262 127.061V91.5117Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M124.326 131.241C125.69 131.241 126.796 130.135 126.796 128.771C126.796 127.407 125.69 126.301 124.326 126.301C122.962 126.301 121.856 127.407 121.856 128.771C121.856 130.135 122.962 131.241 124.326 131.241Z"
        fill="#F3123C"
      />
      <Path
        d="M124.326 131.241C125.69 131.241 126.796 130.135 126.796 128.771C126.796 127.407 125.69 126.301 124.326 126.301C122.962 126.301 121.856 127.407 121.856 128.771C121.856 130.135 122.962 131.241 124.326 131.241Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M80.5123 52.7297C89.5471 52.7297 96.8713 45.4055 96.8713 36.3707C96.8713 27.3359 89.5471 20.0117 80.5123 20.0117C71.4775 20.0117 64.1533 27.3359 64.1533 36.3707C64.1533 45.4055 71.4775 52.7297 80.5123 52.7297Z"
        fill="#FAFAFA"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M73.7332 28.2148C73.568 28.2149 73.4066 28.2652 73.2706 28.359C73.1346 28.4528 73.0302 28.5857 72.9714 28.7401L71.6819 32.1233C71.5437 32.4865 71.5396 32.8871 71.6705 33.253C71.849 33.75 72.1972 34.1681 72.6536 34.4337C73.1101 34.6993 73.6456 34.7954 74.1659 34.7051C74.6862 34.6147 75.158 34.3438 75.4982 33.9399C75.8385 33.536 76.0253 33.025 76.0259 32.4969C76.0259 33.0917 76.2622 33.6623 76.6829 34.0829C77.1035 34.5035 77.674 34.7398 78.2689 34.7398C78.8638 34.7398 79.4343 34.5035 79.8549 34.0829C80.2755 33.6623 80.5119 33.0917 80.5119 32.4969C80.5119 33.0917 80.7482 33.6623 81.1688 34.0829C81.5894 34.5035 82.16 34.7398 82.7548 34.7398C83.3497 34.7398 83.9202 34.5035 84.3408 34.0829C84.7615 33.6623 84.9978 33.0917 84.9978 32.4969C84.9984 33.025 85.1852 33.536 85.5255 33.9399C85.8657 34.3438 86.3375 34.6147 86.8578 34.7051C87.3781 34.7954 87.9136 34.6993 88.3701 34.4337C88.8265 34.1681 89.1747 33.75 89.3532 33.253C89.4843 32.887 89.4799 32.4863 89.341 32.1233L88.0523 28.7401C87.9935 28.5857 87.8891 28.4528 87.7531 28.359C87.6171 28.2652 87.4558 28.2149 87.2905 28.2148H73.7332Z"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M74.7676 37.1875H80.477V42.0813H74.7676V37.1875Z"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M82.1084 44.5281V37.5953C82.1084 37.4872 82.1514 37.3834 82.2278 37.3069C82.3043 37.2305 82.4081 37.1875 82.5162 37.1875H85.7787C85.8869 37.1875 85.9906 37.2305 86.0671 37.3069C86.1436 37.3834 86.1865 37.4872 86.1865 37.5953V44.5281"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M87.817 34.6641V43.711C87.817 43.9273 87.7311 44.1348 87.5781 44.2878C87.4251 44.4407 87.2177 44.5266 87.0014 44.5266H73.9514C73.735 44.5266 73.5276 44.4407 73.3746 44.2878C73.2217 44.1348 73.1357 43.9273 73.1357 43.711V34.6445"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21.859 98.7297C30.8938 98.7297 38.218 91.4056 38.218 82.3707C38.218 73.3359 30.8938 66.0117 21.859 66.0117C12.8242 66.0117 5.5 73.3359 5.5 82.3707C5.5 91.4056 12.8242 98.7297 21.859 98.7297Z"
        fill="#FAFAFA"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15.0457 73.8086C14.8805 73.8087 14.7191 73.8589 14.5831 73.9527C14.4471 74.0465 14.3427 74.1794 14.2839 74.3339L12.9944 77.7171C12.8562 78.0802 12.8521 78.4808 12.983 78.8467C13.1615 79.3437 13.5097 79.7618 13.9661 80.0274C14.4226 80.2931 14.9581 80.3892 15.4784 80.2988C15.9987 80.2085 16.4705 79.9375 16.8107 79.5336C17.151 79.1297 17.3378 78.6187 17.3384 78.0906C17.3384 78.6855 17.5747 79.256 17.9954 79.6766C18.416 80.0973 18.9865 80.3336 19.5814 80.3336C20.1763 80.3336 20.7468 80.0973 21.1674 79.6766C21.588 79.256 21.8244 78.6855 21.8244 78.0906C21.8244 78.6855 22.0607 79.256 22.4813 79.6766C22.9019 80.0973 23.4725 80.3336 24.0673 80.3336C24.6622 80.3336 25.2327 80.0973 25.6533 79.6766C26.074 79.256 26.3103 78.6855 26.3103 78.0906C26.3109 78.6187 26.4977 79.1297 26.838 79.5336C27.1782 79.9375 27.65 80.2085 28.1703 80.2988C28.6906 80.3892 29.2261 80.2931 29.6826 80.0274C30.139 79.7618 30.4872 79.3437 30.6657 78.8467C30.7968 78.4808 30.7924 78.0801 30.6535 77.7171L29.3648 74.3339C29.306 74.1794 29.2016 74.0465 29.0656 73.9527C28.9296 73.8589 28.7683 73.8087 28.603 73.8086H15.0457Z"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16.0801 82.7812H21.7895V87.675H16.0801V82.7812Z"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M23.4209 90.1219V83.1891C23.4209 83.0809 23.4639 82.9772 23.5403 82.9007C23.6168 82.8242 23.7206 82.7812 23.8287 82.7812H27.0912C27.1994 82.7812 27.3031 82.8242 27.3796 82.9007C27.4561 82.9772 27.499 83.0809 27.499 83.1891V90.1219"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M29.1295 80.2579V89.3048C29.1295 89.5211 29.0436 89.7285 28.8906 89.8815C28.7376 90.0345 28.5302 90.1204 28.3139 90.1204H15.2639C15.0475 90.1204 14.8401 90.0345 14.6871 89.8815C14.5342 89.7285 14.4482 89.5211 14.4482 89.3048V80.2383"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21.859 177.769C30.8938 177.769 38.218 170.445 38.218 161.41C38.218 152.375 30.8938 145.051 21.859 145.051C12.8242 145.051 5.5 152.375 5.5 161.41C5.5 170.445 12.8242 177.769 21.859 177.769Z"
        fill="#FAFAFA"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M179.141 126.089C188.176 126.089 195.5 118.765 195.5 109.73C195.5 100.695 188.176 93.3711 179.141 93.3711C170.106 93.3711 162.782 100.695 162.782 109.73C162.782 118.765 170.106 126.089 179.141 126.089Z"
        fill="#FAFAFA"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M172.362 101.574C172.197 101.574 172.036 101.625 171.9 101.718C171.763 101.812 171.659 101.945 171.6 102.099L170.311 105.483C170.173 105.846 170.169 106.246 170.299 106.612C170.478 107.109 170.826 107.527 171.283 107.793C171.739 108.059 172.274 108.155 172.795 108.064C173.315 107.974 173.787 107.703 174.127 107.299C174.467 106.895 174.654 106.384 174.655 105.856C174.655 106.451 174.891 107.022 175.312 107.442C175.732 107.863 176.303 108.099 176.898 108.099C177.493 108.099 178.063 107.863 178.484 107.442C178.904 107.022 179.141 106.451 179.141 105.856C179.141 106.451 179.377 107.022 179.798 107.442C180.218 107.863 180.789 108.099 181.384 108.099C181.979 108.099 182.549 107.863 182.97 107.442C183.39 107.022 183.627 106.451 183.627 105.856C183.627 106.384 183.814 106.895 184.154 107.299C184.495 107.703 184.966 107.974 185.487 108.064C186.007 108.155 186.543 108.059 186.999 107.793C187.455 107.527 187.804 107.109 187.982 106.612C188.113 106.246 188.109 105.846 187.97 105.483L186.681 102.099C186.622 101.945 186.518 101.812 186.382 101.718C186.246 101.625 186.085 101.574 185.919 101.574H172.362Z"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M173.396 110.547H179.106V115.441H173.396V110.547Z"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M180.737 117.887V110.955C180.737 110.847 180.78 110.743 180.857 110.666C180.933 110.59 181.037 110.547 181.145 110.547H184.408C184.516 110.547 184.62 110.59 184.696 110.666C184.772 110.743 184.815 110.847 184.815 110.955V117.887"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M186.446 108.023V117.07C186.446 117.287 186.36 117.494 186.207 117.647C186.054 117.8 185.847 117.886 185.63 117.886H172.58C172.364 117.886 172.156 117.8 172.004 117.647C171.851 117.494 171.765 117.287 171.765 117.07V108.004"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.0127 65.9922C80.0127 65.716 80.2366 65.4922 80.5127 65.4922H179.142C179.418 65.4922 179.642 65.716 179.642 65.9922V93.3712C179.642 93.6473 179.418 93.8712 179.142 93.8712C178.866 93.8712 178.642 93.6473 178.642 93.3712V66.4922H80.5127C80.2366 66.4922 80.0127 66.2683 80.0127 65.9922Z"
        fill="#737373"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8594 98.2109C22.1355 98.2109 22.3594 98.4348 22.3594 98.7109V145.052C22.3594 145.328 22.1355 145.552 21.8594 145.552C21.5832 145.552 21.3594 145.328 21.3594 145.052V98.7109C21.3594 98.4348 21.5832 98.2109 21.8594 98.2109Z"
        fill="#737373"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.327 145.312C124.603 145.312 124.827 145.536 124.827 145.812V161.411C124.827 161.688 124.603 161.911 124.327 161.911H38.2188C37.9426 161.911 37.7188 161.688 37.7188 161.411C37.7188 161.135 37.9426 160.911 38.2188 160.911H123.827V145.812C123.827 145.536 124.051 145.312 124.327 145.312Z"
        fill="#737373"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.5127 52.2109C80.7888 52.2109 81.0127 52.4348 81.0127 52.7109V82.5979C81.0127 82.8741 80.7888 83.0979 80.5127 83.0979C80.2366 83.0979 80.0127 82.8741 80.0127 82.5979V52.7109C80.0127 52.4348 80.2366 52.2109 80.5127 52.2109Z"
        fill="#737373"
      />
      <Path
        d="M152.332 167.129L152.313 179.992"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M158.755 173.57L145.892 173.551"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M35.9004 38.707C37.2121 38.707 38.2754 37.6436 38.2754 36.332C38.2754 35.0203 37.2121 33.957 35.9004 33.957C34.5887 33.957 33.5254 35.0203 33.5254 36.332C33.5254 37.6436 34.5887 38.707 35.9004 38.707Z"
        stroke="#737373"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15.0799 153.25C14.9146 153.25 14.7533 153.3 14.6173 153.394C14.4812 153.488 14.3769 153.621 14.3181 153.775L13.0286 157.158C12.8903 157.522 12.8863 157.922 13.0172 158.288C13.1957 158.785 13.5438 159.203 14.0003 159.469C14.4567 159.734 14.9923 159.831 15.5126 159.74C16.0329 159.65 16.5047 159.379 16.8449 158.975C17.1852 158.571 17.372 158.06 17.3726 157.532C17.3726 158.127 17.6089 158.697 18.0295 159.118C18.4502 159.539 19.0207 159.775 19.6156 159.775C20.2104 159.775 20.7809 159.539 21.2016 159.118C21.6222 158.697 21.8585 158.127 21.8585 157.532C21.8585 158.127 22.0948 158.697 22.5155 159.118C22.9361 159.539 23.5066 159.775 24.1015 159.775C24.6964 159.775 25.2669 159.539 25.6875 159.118C26.1082 158.697 26.3445 158.127 26.3445 157.532C26.3451 158.06 26.5319 158.571 26.8721 158.975C27.2124 159.379 27.6842 159.65 28.2045 159.74C28.7248 159.831 29.2603 159.734 29.7168 159.469C30.1732 159.203 30.5214 158.785 30.6999 158.288C30.8309 157.922 30.8266 157.521 30.6877 157.158L29.399 153.775C29.3402 153.621 29.2358 153.488 29.0998 153.394C28.9638 153.3 28.8024 153.25 28.6372 153.25H15.0799Z"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16.1143 162.223H21.8236V167.116H16.1143V162.223Z"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M23.4551 169.563V162.63C23.4551 162.522 23.498 162.419 23.5745 162.342C23.651 162.266 23.7547 162.223 23.8629 162.223H27.1254C27.2336 162.223 27.3373 162.266 27.4138 162.342C27.4902 162.419 27.5332 162.522 27.5332 162.63V169.563"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M29.1637 159.699V168.746C29.1637 168.962 29.0777 169.17 28.9248 169.323C28.7718 169.476 28.5644 169.562 28.348 169.562H15.298C15.0817 169.562 14.8743 169.476 14.7213 169.323C14.5684 169.17 14.4824 168.962 14.4824 168.746V159.68"
        stroke="#F3123C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
