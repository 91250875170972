import { format, parseISO } from 'date-fns'
import { createNumberMask, formatWithMask, Masks } from 'react-native-mask-input'

export const formatEmail = (email: string) =>
  `${email.substr(0, 2)}*****@****${email.substr(email.indexOf('@') + 6, email.length)}`

export const formatHidePhoneMask = (phone: string | undefined) => {
  if (phone?.includes('*')) return phone

  return `(${phone?.replace(/-|\s/g, '')?.slice(2)?.substr(0, 2)})*****-${phone?.substr(-4)}`
}

export const formatNameAttachments = (name: string) =>
  name.charAt(0).toLocaleUpperCase() + name.split('_').join(' ').slice(1).split('.')[0]

export const formatNameExtensionAttachments = (path: string) => {
  const final = path.substring(path.lastIndexOf('/') + 1)

  const separador = final.lastIndexOf('.')

  const name = separador <= 0 ? '' : final.substring(separador + 1)

  return name.split('?')[0].toLowerCase()
}

export const formatSizeAttachments = (name: string) => name.slice(1).split(' &size: ')[1]

export const formatToReal = (value: number) => {
  if (!value || value === undefined) return 'R$ 0'

  const numberPrefix = ['R', '$', ' ']

  if (value < 0) {
    numberPrefix.unshift('- ')
  }

  const customMask = createNumberMask({
    prefix: numberPrefix,
    separator: ',',
    delimiter: '.',
    precision: 2
  })

  const { masked } = formatWithMask({
    text: String(value?.toFixed(2)),
    mask: customMask
  })

  return masked
}

export const decimalToPercent = (value: string | number) => {
  const formatValue = Number(value)

  return (Number(formatValue) * 100).toFixed(0) + '%'
}

export const sanitizeNameToFirstLetterUppercase = (name: string = '') => {
  if (name === undefined) return

  const names = name.toLowerCase().split(' ')

  if (names.length > 1 && names[1]) {
    return `${names[0].charAt(0).toUpperCase()}${names[0].substring(1)} ${names[
      names.length - 1
    ][0].toUpperCase()}${names[names.length - 1].substring(1)}`
  }

  return `${name.charAt(0).toUpperCase()}${name.substring(1).toLowerCase()}`
}

export const sanitizeObjectToNotNull = (obj: object) =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => v))

export const avatarName = (title: string) => {
  if (!title) return title

  const splitTitle = title.split(' ')

  if (splitTitle.length > 1 && splitTitle[1]) {
    return `${splitTitle[0][0]}${splitTitle[splitTitle.length - 1][0]}`.toUpperCase()
  }

  return `${title.slice(0, 2)}`.toUpperCase()
}

export const setCustomBottomHight = (elements: number) => {
  if (!elements) return '20%'

  if (elements >= 5) return '80%'

  return `${elements * 30 + 10}%`
}

export const formatPhoneNumberWithMask = (dddNumber: string, phoneNumber: string) => {
  if (!dddNumber || !phoneNumber) return ''

  const { masked: maskedPhone } = formatWithMask({
    text: `${dddNumber}${phoneNumber}`,
    mask: Masks.BRL_PHONE
  })

  return maskedPhone
}

export function sanitazeColumnValues(value: any, columnName: string) {
  if (value == null) {
    return '-'
  }

  if (typeof value === 'boolean') {
    return value ? 'Sim' : 'Não'
  }

  if (columnName.startsWith('vl_') || columnName.startsWith('value')) {
    return formatToReal(Number(value))
  }

  if (columnName.startsWith('dt_')) {
    return format(parseISO(String(value)), 'dd/MM/yyyy')
  }

  if (columnName === 'year_month' || columnName.startsWith('anomes')) {
    return format(parseISO(String(value)), 'MM/yyyy')
  }

  return String(value)
}
