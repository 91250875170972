import { Suspense, forwardRef } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'
import { CurrentViewTypesRef } from 'src/components/templates/ContactScreenTemplate/ContactScreenTemplate'

import { NotesDesktop } from './desktop'
import { NotesMobile } from './mobile'
import { TabCommonProps } from './types'

export const Notes = forwardRef<CurrentViewTypesRef, TabCommonProps>((props, ref) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Screen = isMobile ? NotesMobile : NotesDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} ref={ref} />
    </Suspense>
  )
})
