import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'

import BottomSheet, {
  BottomSheetBackgroundProps,
  useBottomSheetDynamicSnapPoints,
  useBottomSheetTimingConfigs
} from '@gorhom/bottom-sheet'
import { BottomSheetMethods } from '@gorhom/bottom-sheet/lib/typescript/types'
import { useNavigation } from '@react-navigation/native'
import { CustomStatusBar, FadeInView, Icons } from 'atoms'
import Constants from 'expo-constants'
import { ButtonZendesk } from 'molecules'
import { Heading, Box, VStack, Text, HStack, IconButton, ScrollView } from 'native-base'
import { Dimensions, ImageBackground, Keyboard, Platform, StyleSheet } from 'react-native'
import Animated, { Easing, interpolate, useAnimatedStyle } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

type CreateAccountLayoutProps = {
  children: React.ReactNode
  isOpen?: boolean
  hasError?: boolean
}

const BottomSheetCustomBackdrop: FunctionComponent<BottomSheetBackgroundProps> = ({
  style,
  animatedIndex
}) => {
  const containerAnimatedStyle = useAnimatedStyle(() => {
    const borderRadius = interpolate(animatedIndex.value, [0, 1], [30, 0])

    return {
      borderRadius
    }
  })

  return (
    <Animated.View
      pointerEvents="none"
      style={[
        {
          backgroundColor: 'white'
        },
        containerAnimatedStyle,
        style
      ]}
    />
  )
}

export const LoginLayout: FunctionComponent<CreateAccountLayoutProps> = ({
  children,
  hasError = false
}) => {
  const screenHeight = Dimensions.get('window').height

  const screenWidth = Dimensions.get('window').width

  const statusBarHeight = Constants.statusBarHeight

  const pathImage = require('assets/santander-cpf.png')

  const isIos = Platform.OS === 'ios'

  const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT', '100%'], [])

  const navigation = useNavigation()

  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  const bottomSheetRef = useRef<BottomSheetMethods>()

  const insets = useSafeAreaInsets()

  const normalizeMarginTop = () => {
    if (insets.top >= 59 && screenHeight > 844) {
      return {
        header: screenHeight > 844 ? 145 : 125,
        logo: '60%'
      }
    } else if (insets.top > 48) {
      return {
        header: 130,
        logo: '60%'
      }
    } else if (insets.top >= 44) {
      let header = 100

      if (statusBarHeight > 47) {
        header = 140
      } else {
        header = 95
      }

      return {
        header,
        logo: 230
      }
    } else {
      return {
        header: 90,
        logo: 130
      }
    }
  }

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardVisible(true)

      bottomSheetRef.current?.snapToIndex(1)
    })

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardVisible(false)

      bottomSheetRef.current?.snapToIndex(0)
    })

    return () => {
      keyboardDidHideListener.remove()

      keyboardDidShowListener.remove()
    }
  }, [bottomSheetRef])

  const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
    useBottomSheetDynamicSnapPoints(initialSnapPoints)

  const animationConfigs = useBottomSheetTimingConfigs({
    duration: 450,
    easing: Easing.ease
  })

  return (
    <>
      <Box position="relative" flex={1} bg="gray.800">
        <CustomStatusBar barStyle={isKeyboardVisible ? 'dark-content' : 'light-content'} />

        <ImageBackground
          source={pathImage}
          resizeMode="contain"
          style={{
            width: screenWidth,
            height: screenHeight,
            marginTop: isIos ? -normalizeMarginTop().header : -170
          }}>
          <VStack justifyContent="flex-start" ml={4} mt={isIos ? normalizeMarginTop().logo : 200}>
            <FadeInView delay={600}>
              <Icons.FlameSantander color="white" size={9} />
            </FadeInView>
          </VStack>
        </ImageBackground>

        <BottomSheet
          // @ts-ignore
          ref={bottomSheetRef}
          snapPoints={animatedSnapPoints}
          handleHeight={animatedHandleHeight}
          keyboardBehavior="extend"
          handleIndicatorStyle={{ display: 'none' }}
          animationConfigs={animationConfigs}
          backgroundComponent={(props) => <BottomSheetCustomBackdrop {...props} />}
          contentHeight={animatedContentHeight}>
          <ScrollView contentContainerStyle={styles.container} onLayout={handleContentLayout}>
            {navigation.canGoBack() && (
              <HStack
                {...(isKeyboardVisible && { safeAreaTop: true })}
                mb={!isKeyboardVisible ? '22px' : '46px'}>
                <IconButton
                  bgColor="background.base"
                  borderRadius="full"
                  mt={2}
                  padding={1}
                  icon={<Icons.Chevron direction="left" color="gray.700" size={9} />}
                  onPress={navigation.goBack}
                />
              </HStack>
            )}
            <Heading color="gray.700" mt={2} mb={2}>
              Bem vindo de volta ao turbo
            </Heading>
            <Text fontSize="16px" color="gray.750" lineHeight="24px">
              Entre com os seus dados.
            </Text>
            {children}
            <Box h={10} />
          </ScrollView>
        </BottomSheet>
      </Box>

      <ButtonZendesk direction="right" />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16
  }
})
