/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useRef, useState } from 'react'

import { Icons } from 'atoms'
import { HeadPage } from 'atoms/web/HeadPage'
import { Contact, TabDetail } from 'integration/resources/wallet'
import { Button, HStack, VStack, View } from 'native-base'
import { FlatList, ScrollView, Platform } from 'react-native'
import { useAuthAtomValue } from 'src/store/auth'
import { LogEvent } from 'src/utils/logEvents'

type TNavigation = {
  handleGoBack(): void
  handleGoToContactEdit(contact: Contact): void
}

type TTabs<T> = {
  currentTab: number
  setCurrentTab(index: number): void
  mainContent: {
    [k: number]: {
      component: React.FunctionComponent<Partial<T>>
      name: string
      show?: boolean
    }
  }
  mainContentCommonProps: T
}

type TFavorite = {
  contactIsFavorite: boolean
}

type ContactScreenTemplateProps<T> = {
  isTab: boolean
  contact?: TabDetail | Contact
  navigation: TNavigation
  tabs: TTabs<T>
  favorite?: TFavorite
  contactIsLoading?: boolean
  favoriteIsLoading?: boolean
}

export type CurrentViewTypesRef = {
  onScrollEndDragContent?: () => void
}

export const WebContactScreenTemplate = <T extends any>({
  isTab,
  tabs
}: ContactScreenTemplateProps<T>) => {
  const currentViewRef = useRef<CurrentViewTypesRef>(null)

  const scrollRef = useRef<ScrollView>(null)

  const authAtom = useAuthAtomValue()

  const [disableControl, setDisableControl] = useState<'left' | 'right'>('right')

  const keyExtractor = useCallback((item: { key: string }) => item.key, [])

  const CurrentView = tabs.mainContent[tabs.currentTab]?.component

  const data = [
    {
      key: '1'
    }
  ]

  const renderItem = useCallback(
    ({ item }: { item: { key: string } }, index: number) => {
      return (
        <View bg="background.base" flex={1} minH={400} maxWidth={1124} key={`${index}-${item.key}`}>
          {/* @ts-ignore */}
          <CurrentView ref={currentViewRef} {...tabs.mainContentCommonProps} />
        </View>
      )
    },
    [CurrentView, tabs.mainContentCommonProps]
  )

  return (
    <View bg="background.base" flex={1} px={8}>
      <FlatList
        data={data}
        keyExtractor={keyExtractor}
        ListHeaderComponent={
          <VStack>
            <HeadPage
              title="Carteira"
              pages={[
                {
                  id: 1,
                  title: 'Carteira',
                  link: '',
                  params: null
                },
                {
                  id: 2,
                  title: isTab ? 'TABs' : 'Contatos',
                  link: isTab ? 'WalletTabList' : 'WalletContactList',
                  params: undefined
                },
                {
                  id: 3,
                  title: isTab ? 'Detalhes da TAB' : 'Detalhes do contato',
                  link: '',
                  params: null
                }
              ]}
            />
            <HStack my={8} p={1}>
              {Platform.OS !== 'web' && (
                <Button
                  w={10}
                  h={10}
                  zIndex={2}
                  shadow={3}
                  bgColor="gray.50"
                  position="absolute"
                  opacity={disableControl === 'right' ? 1 : 0.6}
                  onPress={() => {
                    scrollRef?.current?.scrollTo?.(0, 0)

                    setDisableControl('left')
                  }}>
                  <Icons.AccordionArrow
                    direction="left"
                    color="gray.700"
                    size={5}
                    ml={-1}
                    mb={-1}
                  />
                </Button>
              )}

              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                ref={scrollRef}
                pagingEnabled>
                <View w={12} />
                {/* @ts-ignore */}
                <HStack flexWrap="wrap" w="full" style={{ gap: 10 }}>
                  {Object.keys(tabs.mainContent).map((tab: string, index) => {
                    if (tabs.mainContent[Number(tab)].show) {
                      return (
                        <Button
                          key={tab}
                          onPress={() => {
                            LogEvent(
                              `carteira_${isTab ? 'tabs_' : 'contato_'}${tabs.mainContent[
                                Number(tab)
                              ].name.replace(/[^\w\s]/gi, '')}`,
                              {
                                item_id: String(authAtom?.user.std_code)
                              }
                            )

                            tabs.setCurrentTab(Number(tab))
                          }}
                          colorScheme={tabs.currentTab === Number(tab) ? 'primary-light' : 'white'}
                          borderRadius="full"
                          px={6}
                          size="sm"
                          _text={{
                            fontSize: '16px'
                          }}
                          h={10}>
                          {tabs.mainContent[Number(tab)].name}
                        </Button>
                      )
                    }
                  })}
                </HStack>

                <View w={12} />
              </ScrollView>

              {Platform.OS !== 'web' && (
                <Button
                  w={10}
                  h={10}
                  position="absolute"
                  zIndex={2}
                  right={0}
                  bgColor="gray.50"
                  shadow={3}
                  opacity={disableControl === 'left' ? 1 : 0.6}
                  onPress={() => {
                    scrollRef?.current?.scrollTo?.(0, 240)

                    setDisableControl('right')
                  }}>
                  <Icons.AccordionArrow
                    direction="right"
                    color="gray.700"
                    size={5}
                    mr={-1}
                    mb={-1}
                  />
                </Button>
              )}
              {/* @ts-ignore */}
              <HStack flexWrap="wrap" w="full" style={{ gap: '4px' }}>
                {Object.keys(tabs.mainContent).map((tab: string, index) => {
                  if (tabs.mainContent[Number(tab)].show) {
                    return (
                      <Button
                        key={tab}
                        onPress={() => {
                          LogEvent(
                            `carteira_${isTab ? 'tabs_' : 'contato_'}${tabs.mainContent[
                              Number(tab)
                            ].name.replace(/[^\w\s]/gi, '')}`,
                            {
                              item_id: String(authAtom?.user.std_code)
                            }
                          )

                          tabs.setCurrentTab(Number(tab))
                        }}
                        colorScheme={tabs.currentTab === Number(tab) ? 'primary-light' : 'white'}
                        borderRadius="full"
                        px={6}
                        size="sm"
                        _text={{
                          fontSize: '16px'
                        }}
                        h={10}>
                        {tabs.mainContent[Number(tab)].name}
                      </Button>
                    )
                  }
                })}
              </HStack>
            </HStack>
          </VStack>
        }
        flexShrink={1}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
        showsHorizontalScrollIndicator={false}
        onScrollEndDrag={() => {
          if (currentViewRef.current) {
            currentViewRef.current?.onScrollEndDragContent?.()
          }
        }}
        // @ts-ignore
        renderItem={renderItem}
      />
    </View>
  )
}
