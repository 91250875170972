import { BUSINESS_CODE } from 'integration/resources/auth'

export const returnDescriptionSegment = (business_code: BUSINESS_CODE) => {
  switch (business_code) {
    case 5:
      return 'PESADOS'

    case 4:
      return 'LEVES'

    case 3:
      return 'SIM'

    case 2:
      return 'Veículos'

    default:
      return 'Ambos'
  }
}
