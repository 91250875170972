// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo } from 'react'

import { HStack, Text, VStack } from 'native-base'

export const TermsContent = memo(({ showQuestion = true }: { showQuestion?: boolean }) => {
  return (
    <>
      <Text fontSize="16px" color="gray.500">
        Declaro compromisso de manter confidencialidade e sigilo absolutos sobre todas as
        informações, anotações, especificações, documentos ou quaisquer outros dados de clientes,
        prospects, funcionários, prestadores de serviços, contratos e propostas de clientes e/ou
        quaisquer outras informações do Banco Santander (Brasil) S.A. e suas Coligadas, denominados
        simplesmente “SANTANDER”, cujos acessos tenham sido autorizados a mim para prestação de
        serviços para os quais fui contratado, doravante denominados genericamente “INFORMAÇÕES”.
        {'\n'}
        {'\n'}
        Das obrigações assumidas pelo declarante:
      </Text>
      <VStack w="95%">
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            1.
          </Text>
          <Text fontSize="16px" color="gray.500">
            Obrigo-me a utilizar as INFORMAÇÕES exclusivamente para os fins aos quais elas me foram
            divulgadas.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            2.
          </Text>
          <Text fontSize="16px" color="gray.500">
            Não divulgarei e/ou repassarei a pessoas não autorizadas e por qualquer meio, inclusive
            verbal, as INFORMAÇÕES a que tenho acesso por meio do Consultor Mais Turbo , sendo que a
            violação deste Termo restará configurada mesmo que não haja intuito de gerar benefício
            próprio ou de terceiros, presente ou futuro.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            3.
          </Text>
          <Text fontSize="16px" color="gray.500">
            Não compartilharei usuário e senha pois são pessoais e intransferíveis.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            4.
          </Text>
          <Text fontSize="16px" color="gray.500">
            Reconheço que a violação de quaisquer das obrigações mencionadas neste TERMO
            sujeitar-me-á ao ressarcimento de perdas e danos ao SANTANDER e/ou terceiros, incluindo
            danos à imagem, bem como outras penalidades cabíveis, cíveis e criminais, nos termos da
            lei.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            5.
          </Text>
          <Text fontSize="16px" color="gray.500">
            Caso eu seja obrigado a revelar qualquer Informação Confidencial por determinação legal
            de autoridade competente, deverei, previamente à divulgação de tal informação, notificar
            o SANTANDER com antecedência necessária para adoção das medidas cabíveis, em juízo ou
            fora dele, para evitar ou minimizar tal divulgação. Também me comprometo, caso obrigado
            por determinação legal, a não divulgar qualquer Informação Confidencial além daquelas
            estritamente solicitadas.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            6.
          </Text>
          <Text fontSize="16px" color="gray.500">
            Concordo que a demora, não exercício ou exercício isolado/parcial, pelo SANTANDER, de
            qualquer prerrogativa ou direito previsto neste Termo não representa renúncia ou
            limitação ao exercício integral dos seus direitos.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            7.
          </Text>
          <Text fontSize="16px" color="gray.500">
            O descumprimento do presente TERMO sujeitará às penalidades cabíveis (inclusive rescisão
            contratual), sem prejuízo das demais medidas legais, inclusive responsabilização civil e
            criminal, e indenização em razão de danos decorrentes de quebra se sigilo/uso indevido
            das INFORMAÇÕES.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            8.
          </Text>
          <Text fontSize="16px" color="gray.500">
            As obrigações previstas neste TERMO, em razão de sua natureza, permanecerão em vigor por
            prazo indeterminado, mesmo após o término ou rescisão do contrato.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            9.
          </Text>
          <Text fontSize="16px" color="gray.500">
            O presente Termo está constituído sob as leis da República Federativa do Brasil, e fica
            eleito o foro central de São Paulo - SP, com renúncia de qualquer outro, por mais
            privilegiado que seja.
          </Text>
        </HStack>
        {showQuestion && (
          <Text fontSize="16px" color="gray.500" fontWeight="semibold">
            {'\n'}E por estar de acordo com o presente, assino-o eletronicamente.
          </Text>
        )}
      </VStack>
    </>
  )
})
