import * as serializers from './serializers'
import {
  GetListParams,
  CreateActivityData,
  ActivitiesResponse,
  ActivitiesByWeekResponse,
  ActivitiesType,
  Activity,
  ActivityByPeriodParams,
  ActivityByPeriod
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getActivityList = async (params: GetListParams) =>
  serializers.getActivityList(
    await client.get<ResponseSuccess<ActivitiesResponse>>('v1/activities', {
      params: {
        ...params,
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1,
        order_by: params.order_by ?? 'created_at',
        order_by_direction: params.order_by_direction ?? 'desc'
      }
    })
  )

export const getActivityListByWeek = async (params: GetListParams) =>
  serializers.getActivityListByWeek(
    await client.get<ResponseSuccess<ActivitiesByWeekResponse>>('v1/activities', {
      params: {
        ...params,
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1
      }
    })
  )

export const getActivityTypes = async (params: GetListParams) =>
  serializers.getActivityTypes(
    await client.get<ResponseSuccess<ActivitiesType[]>>('v1/activity-types', {
      params: {
        ...params,
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1
      }
    })
  )

export const createActivity = async (data: Partial<CreateActivityData>) => {
  const response = await client.post<ResponseSuccess<CreateActivityData>>('v1/activities', data)

  return response
}

export const updateActivity = async (data: Partial<CreateActivityData>) => {
  const response = await client.patch<ResponseSuccess<CreateActivityData>>(
    `v1/activities/${data.id}`,
    data
  )

  return response
}

export const getActivityItem = async (id: string) =>
  serializers.getActivityItem(await client.get<ResponseSuccess<Activity>>(`v1/activities/${id}`))

export const finishedActivity = async ({ id }: Pick<Activity, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<Activity, 'id'>>>(`v1/activities/${id}/finished`)

export const unFinishedActivity = async ({ id }: Pick<Activity, 'id'>) =>
  await client.patch<ResponseSuccess<Pick<Activity, 'id'>>>(`v1/activities/${id}/unfinished`)

export const deleteActivity = async ({ id }: Pick<Activity, 'id'>) =>
  await client.delete<ResponseSuccess<Pick<Activity, 'id'>>>(`v1/activities/${id}`)

export const getActivitiesByPeriod = async (params: ActivityByPeriodParams) =>
  await client.get<ResponseSuccess<ActivityByPeriod[]>>('v1/activities/count-grouped-by-period', {
    params: {
      ...params,
      per_page: params?.per_page ?? 31,
      page: params?.page ?? 1
    }
  })
