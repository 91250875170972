import { Icons, Illustrations, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { AccordionCard } from 'atoms/web/Card/AccordionCard'
import { HeadPage } from 'atoms/web/HeadPage'
import { GetListsParams, WalletItem } from 'integration/resources/wallet'
import {
  Center,
  Circle,
  HStack,
  Heading,
  Text,
  VStack,
  View,
  useBreakpointValue
} from 'native-base'
import Layouts from 'organisms/Layouts'
import { FlatList, Linking, Platform, RefreshControl, TouchableOpacity } from 'react-native'
import { formatPhoneNumberWithMask, sanitizeNameToFirstLetterUppercase } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { WalletContactFilters } from './Contact/Filters'
import { useWalletScreen } from './useWalletScreen'
import { WalletContactScreenComponent } from './WalletScreen.types'

const WalletContactScreenDesktop: WalletContactScreenComponent = ({ navigation, route }) => {
  const {
    list,
    params,
    isLoading,
    onEndReached,
    onRefresh,
    filtersSize,
    isFetchingNextPage,
    handleChangeFilters,
    handleToggleFavorite,
    handleGoToDetailsScreen,
    totalItensList
    // @ts-ignore
  } = useWalletScreen({ navigation, route })

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const emptyCondition = !list.length && !isLoading

  const handleOpenGps = (contact: WalletItem) => {
    if (contact) {
      const fullAddress = contact.secondary_address_street
        ? `${contact.secondary_address_street}, ${contact.secondary_address_number} - ${contact.secondary_address_neighborhood} ${contact.secondary_address_city} - ${contact.secondary_address_uf}, ${contact.secondary_address_postal_code}`
        : `${contact.address_street}, ${contact.address_number} - ${contact.address_neighborhood} ${contact.address_city} - ${contact.address_uf}, ${contact.address_postal_code}`

      const url = Platform.select({
        ios: `maps:0,0?q=${fullAddress}`,
        android: `geo:0,0?q=${fullAddress}`,
        web: `https://www.google.com/maps/search/?api=1&query=${fullAddress}`
      }) as string

      Linking.openURL(url)
    }
  }

  const handleSendWhatsapp = (contact: WalletItem) => {
    const urlWhatsApp = isMobile
      ? `whatsapp://send?phone=+55${contact?.phone_number_ddd}${contact?.phone_number}&text=Olá ${contact?.name}`
      : `https://wa.me/55${contact?.phone_number_ddd}${contact?.phone_number}?text=Olá%20${contact?.name}`

    const urlSms = `sms:${contact?.phone_number_ddd}${contact?.phone_number}`

    Linking.canOpenURL(urlWhatsApp)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(urlWhatsApp)
        } else {
          Linking.openURL(urlSms)
        }
      })
      .catch(() => Linking.openURL(urlSms))
  }

  return (
    <>
      <Layouts.InternalDesktop
        search={params.search}
        onSearch={(search) => {
          handleChangeFilters({
            search
          })
        }}>
        <View h="full" flex={1} px={8}>
          <FlatList
            data={[
              {
                key: 1
              }
            ]}
            onEndReached={onEndReached}
            refreshControl={
              <RefreshControl
                refreshing={isLoading ?? false}
                onRefresh={onRefresh}
                tintColor="white"
              />
            }
            showsVerticalScrollIndicator
            ListHeaderComponent={
              <VStack mb={8}>
                <HeadPage
                  title="Carteira"
                  leftIcon={<Icons.Add size="20px" color="white" />}
                  actionButtonTitle="Novo contato"
                  actionButtonPress={() => {
                    navigation.navigate('ContactCreate')
                  }}
                  pages={[
                    {
                      id: 1,
                      title: 'Carteira',
                      link: 'carteira/',
                      params: null
                    },
                    {
                      id: 2,
                      title: 'Contatos',
                      link: 'carteira/contatos',
                      params: null
                    }
                  ]}
                />

                <WalletContactFilters
                  initialValues={params as GetListsParams}
                  onChange={(filters) => {
                    handleChangeFilters({
                      store_tab_number: filters.tabs.selected ?? undefined,
                      job_title: filters.jobs.selected,
                      is_favorite: filters.favorites.selected ? 1 : undefined
                    })
                  }}
                />
              </VStack>
            }
            renderItem={() => (
              <View flex={1} background="background.base">
                {!isLoading && list.length > 0 && (
                  <>
                    <Heading fontSize="18px" lineHeight="24px" fontWeight="400" color="gray.700">
                      Meus Contatos
                    </Heading>
                    <Text fontSize="14px" fontWeight="18px" color="gray.400" mb={6}>
                      {totalItensList} contatos
                    </Text>
                  </>
                )}

                {isLoading && (
                  <>
                    {new Array(6).fill(0).map((_, i) => (
                      <SkeletonCard key={_ + i} />
                    ))}
                  </>
                )}

                {emptyCondition && (
                  <Center pt={4}>
                    {!!filtersSize && (
                      <>
                        <Text fontSize="16px" lineHeight="24px" color="gray.500">
                          Nenhum resultado encontrado.
                        </Text>
                        <Text fontSize="16px" lineHeight="24px" color="gray.500">
                          Revise o filtro.
                        </Text>
                      </>
                    )}
                  </Center>
                )}

                {!isLoading &&
                  list.map((item, i) => {
                    return (
                      <AccordionCard
                        key={item.id}
                        id={item.id}
                        isFavorite={item.is_favorite}
                        onViewMoreClick={() => handleGoToDetailsScreen(item.id)}
                        onFavoriteClick={handleToggleFavorite}
                        coverImageURL={item.cover_image_url ?? ''}
                        title={item.name}
                        subtitle={
                          <HStack alignItems="center">
                            <Text>{sanitizeNameToFirstLetterUppercase(item.job_title)}</Text>

                            {item.job_title && item.store_name && (
                              <Circle size={1} backgroundColor="gray.400" mx={2} />
                            )}

                            {!!item.store_name && (
                              <Text>{sanitizeNameToFirstLetterUppercase(item.store_name)}</Text>
                            )}
                          </HStack>
                        }
                        actions={
                          <>
                            <TouchableOpacity
                              onPress={() => {
                                LogEvent(`carteira_contatos_email`, {
                                  item_id: item.id
                                })

                                Linking.openURL(`mailto:${item.email}`)
                              }}>
                              <Center
                                borderRadius="full"
                                borderColor="gray.500"
                                borderWidth={1}
                                w={10}
                                h={10}>
                                <Icons.Mail filled color="gray.500" size={5} />
                              </Center>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => {
                                LogEvent(`carteira_contatos_telefone`, {
                                  item_id: item.id
                                })

                                Linking.openURL(`tel:${item.phone_number_ddd}${item.phone_number}`)
                              }}>
                              <Center
                                borderRadius="full"
                                borderColor="gray.500"
                                borderWidth={1}
                                w={10}
                                h={10}>
                                <Icons.Phone filled color="gray.500" size={5} />
                              </Center>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => {
                                LogEvent(`carteira_contatos_gps`, {
                                  item_id: item.id
                                })

                                handleOpenGps(item)
                              }}>
                              <Center
                                borderRadius="full"
                                borderColor="gray.500"
                                borderWidth={1}
                                w={10}
                                h={10}>
                                <Icons.Location filled color="gray.500" size={5} />
                              </Center>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => {
                                LogEvent(`carteira_contatos_whatsapp`, {
                                  item_id: item.id
                                })

                                handleSendWhatsapp(item)
                              }}>
                              <Center
                                borderRadius="full"
                                borderColor="gray.500"
                                borderWidth={1}
                                w={10}
                                h={10}>
                                <Icons.Chat filled color="gray.500" size={5} />
                              </Center>
                            </TouchableOpacity>
                          </>
                        }
                        details={
                          <>
                            <VStack flex={1} py={6} space={6} w="1/2">
                              <HStack alignItems="center" space={4}>
                                <Icons.Phone color="gray.400" size={5} />

                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="normal"
                                  color="gray.700">
                                  {formatPhoneNumberWithMask(
                                    item?.phone_number_ddd ?? '',
                                    item?.phone_number ?? ''
                                  )}
                                </Text>
                              </HStack>

                              <HStack alignItems="center" space={4}>
                                <Icons.Mail color="gray.400" size={5} />

                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="normal"
                                  color="gray.700">
                                  {item.email}
                                </Text>
                              </HStack>
                            </VStack>

                            <VStack flex={1} py={6} space={6} w="1/2">
                              <HStack alignItems="center" space={4}>
                                <Icons.Tab color="gray.400" size={5} />

                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="normal"
                                  color="gray.700">
                                  TAB {item.tab_number} •{' '}
                                  {sanitizeNameToFirstLetterUppercase(item.store_name)}
                                </Text>
                              </HStack>

                              <HStack alignItems="center" space={4}>
                                <Icons.Location color="gray.400" size={5} />

                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="normal"
                                  color="gray.700">
                                  {sanitizeNameToFirstLetterUppercase(item.address_street)},{' '}
                                  {item.address_number} -{' '}
                                  {sanitizeNameToFirstLetterUppercase(item.address_neighborhood)} -{' '}
                                  {sanitizeNameToFirstLetterUppercase(item.address_city)} -{' '}
                                  {item.address_uf}
                                </Text>
                              </HStack>
                            </VStack>
                          </>
                        }
                      />
                    )
                  })}

                {!isLoading && !list.length && !filtersSize && (
                  <View>
                    <Center mb={10}>
                      <Illustrations.FavoriteContactsEmpty />
                    </Center>
                    <Center>
                      <Text color="gray.500" fontSize="21px" lineHeight="30px" fontWeight={400}>
                        Não há contatos cadastrados
                      </Text>
                    </Center>
                  </View>
                )}

                {isFetchingNextPage && (
                  <Center mt={2}>
                    <LoadingTurbo size={48} />
                  </Center>
                )}
              </View>
            )}
            keyExtractor={(item) => `${item.key}`}
          />
        </View>
      </Layouts.InternalDesktop>
    </>
  )
}

export default WalletContactScreenDesktop
