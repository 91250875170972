import { FunctionComponent } from 'react'

import { LoadingTurbo } from 'atoms'
import { UnconnectedMessage } from 'molecules'
import { Center, VStack } from 'native-base'
import { TabSummaryChart } from 'organisms'

import { useTabComponent } from './hook'
import { TabCommonProps } from './types'

export const Summaries: FunctionComponent<TabCommonProps> = ({ tabNumber }) => {
  const { summaryData, isLoading, isLeves } = useTabComponent({
    tabNumber: tabNumber ?? ''
  })

  return (
    <VStack mt={4} safeAreaBottom mb={8}>
      {isLoading ? (
        <Center h={360} w="full" position="absolute" zIndex={9}>
          <LoadingTurbo size={100} />
        </Center>
      ) : !!summaryData?.data.data.months.length &&
        summaryData?.data.data.months[0]?.production > 0 ? (
        <TabSummaryChart
          chartData={summaryData?.data.data.monthsSerialized ?? []}
          higherValue={summaryData?.data.data.higherValue ?? 0}
          processedAt={summaryData?.data.data.processed_at}
          isGoodsAndServices={isLeves}
        />
      ) : (
        <UnconnectedMessage />
      )}
    </VStack>
  )
}
