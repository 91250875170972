import { FunctionComponent, useEffect, useRef, useState } from 'react'

import { Icons } from 'atoms'
import { Button, Center, HStack, IconButton, Input } from 'native-base'
import { InterfaceInputProps } from 'native-base/lib/typescript/components/primitives/Input/types'
import { TextInput } from 'react-native'
import useDebounce from 'src/hooks/useDebounce'

type InputSearchProps = InterfaceInputProps & {
  applyFilter(_: string): void
  cancel?: boolean
  handleCancel?: () => void
  placeholder?: string
}

export const InputSearch: FunctionComponent<InputSearchProps> = ({
  handleCancel,
  applyFilter,
  cancel,
  placeholder,
  ...props
}) => {
  const [text, setText] = useState('')

  const debouncedValue = useDebounce<string>(text, 900)

  const handleChange = (textValue: string) => {
    const value = props.keyboardType === 'numeric' ? textValue.replace(/\D/g, '') : textValue

    setText(value)
  }

  const handleCleanText = () => {
    refSearchInput.current?.clear()

    handleChange('')
  }

  useEffect(() => {
    applyFilter(text)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const refSearchInput = useRef<TextInput>()

  return (
    <HStack alignItems="center" justifyContent="space-between">
      <Input
        {...props}
        flex={1}
        px="0"
        fontSize="16px"
        lineHeight="24px"
        InputLeftElement={<Icons.Search size={5} ml={4} mr={2} color="gray.700" />}
        InputRightElement={
          <Center mx={3}>
            {text && (
              <IconButton
                size={4}
                bg="gray.700"
                borderRadius="full"
                icon={<Icons.Close size={2} color="white" />}
                onPress={handleCleanText}
              />
            )}
          </Center>
        }
        key="input-search"
        ref={refSearchInput}
        onChangeText={handleChange}
        placeholder={placeholder ?? 'Pesquisar'}
        autoCapitalize="none"
        borderWidth={0}
        bgColor="white"
        color="gray.400"
        value={text}
        autoFocus
      />
      {cancel && (
        <Button
          size="md"
          p={0}
          ml={4}
          onPress={handleCancel}
          background="transparent"
          borderBottomWidth={0}
          _text={{
            color: 'gray.700',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px'
          }}
          _pressed={{
            opacity: 0.7,
            background: 'transparent'
          }}>
          cancelar
        </Button>
      )}
    </HStack>
  )
}
