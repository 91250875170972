import { useCallback } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { useBreakpointValue } from 'native-base'

import { UsePageNotFoundScreen } from './PageNotFoundScreen.types'

export const usePageNotFoundScreen: UsePageNotFoundScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  /**
   * @TODO change navigation navigate to useLinkTo
   * define custom names cause this name used in url in version web
   */

  const goToStart = useCallback(
    () =>
      isMobile
        ? navigation.navigate('Tab', { screen: 'Home', params: { refetch: true } })
        : linkTo('/conteudos'),
    [isMobile, linkTo, navigation]
  )

  return { goToStart, isMobile }
}
