import { Icons, Illustrations } from 'atoms'
import { Box, ScrollView, Text, VStack } from 'native-base'
import { StyleSheet } from 'react-native'
import { useQueryClient } from 'react-query'
import { ZendeskAPI } from 'react-zendesk'
import { useSetAuthAtom } from 'src/store/auth'
import { useSetTokenAtom } from 'src/store/token'

import { ItemMenu } from './ItemMenu'
import Appconfig from '../../../../../app.config'

export const Sidebar = () => {
  const queryClient = useQueryClient()

  const setAuthAtom = useSetAuthAtom()

  const setAuthToken = useSetTokenAtom()

  const handleLogout = () => {
    setAuthAtom(null)

    setAuthToken(null)

    queryClient.removeQueries()
  }

  const handleToggleZendesk = () => {
    ZendeskAPI('webWidget', 'open')

    ZendeskAPI('webWidget', 'setLocale', 'pt-BR')

    ZendeskAPI('webWidget', 'helpCenter:setSuggestions', { search: 'login' })
  }

  return (
    <VStack w="255px" position="relative" p={8} pr={0} pb={0} bg="background.base">
      <Box style={styles.background} />
      <VStack space={4}>
        <Icons.LogoTurbo size={130} />
        <Box opacity={1}>
          <Icons.PortalMaisComplete size={130} />
        </Box>
      </VStack>
      <ScrollView showsVerticalScrollIndicator={false} position="relative" zIndex={3}>
        <Box height="83vh" justifyContent="space-between" mt={10} position="relative">
          <Box>
            <VStack space={4}>
              <ItemMenu testID="start" icon="FlameSantander" title="Início" path="/home" />
              <ItemMenu testID="profile" icon="User" title="Perfil" path="/perfil" />
              <ItemMenu
                testID="wallet"
                icon="Suitcase"
                title="Carteira"
                submenus={[
                  {
                    id: 1,
                    title: 'Contatos',
                    path: '/carteira/contatos',
                    testID: 'contacts'
                  },
                  {
                    id: 2,
                    title: 'TABs',
                    path: '/carteira/tabs',
                    testID: 'tabs'
                  }
                ]}
              />
              <ItemMenu
                testID="organization"
                icon="Calendar"
                title="Organização"
                submenus={[
                  {
                    id: 1,
                    title: 'Atividades',
                    path: '/minha-organizacao/atividades',
                    testID: 'activities'
                  },
                  {
                    id: 2,
                    title: 'Oportunidades',
                    path: '/minha-organizacao/oportunidades',
                    testID: 'opportunities'
                  }
                ]}
              />
              <ItemMenu testID="contents" icon="BookOpen" title="Conteúdos" path="/conteudos" />
              <ItemMenu testID="analytics" icon="Graphic" title="Analítico" path="/analitico" />
              <ItemMenu testID="survey" icon="Survey" title="Pesquisas" path="/pesquisas" />
              {/*
              <ItemMenu
                testID="wallet"
                icon="Suitcase"
                title="Carteira"
                submenus={[
                  {
                    id: 1,
                    title: 'Contatos',
                    path: '/carteira/contatos',
                    testID: 'contacts'
                  },
                  {
                    id: 2,
                    title: 'TABs',
                    path: '/carteira/tabs',
                    testID: 'tabs'
                  }
                ]}
              />
              
               <ItemMenu icon="Union" title="Carteirização" path="/carteirizacao" />
              <ItemMenu icon="Panels" title="Painéis de gestão" path="/paineis" /> */}
            </VStack>
            <Box h="1px" bg="gray.600" opacity={0.6} my={10} w="full" maxW="193px" mr={6} />
            <VStack space={4}>
              <ItemMenu
                testID="global-support"
                icon="Support"
                title="Suporte"
                onPress={handleToggleZendesk}
              />
              <ItemMenu
                testID="global-logout"
                icon="GoOut"
                title="Sair do Turbo"
                onPress={handleLogout}
              />
            </VStack>
          </Box>
          <Box position="relative" left={4} mt={10}>
            <Text
              fontSize="14px"
              lineHeight="20px"
              color="white"
              fontWeight={600}>{`versão ${Appconfig.version}`}</Text>
          </Box>
        </Box>
      </ScrollView>

      <Box
        position="absolute"
        right={0}
        bottom={0}
        zIndex={1}
        borderBottomRightRadius="20px"
        overflow="hidden">
        <Illustrations.FlameSantanderTurbo />
      </Box>
    </VStack>
  )
}

const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    backgroundImage: 'linear-gradient(146.76deg, #494848 4.39%, #393939 42.03%, #070707 94.18%)'
  }
})
