import { useMemo } from 'react'

import { useGetConcentrationQuery } from 'integration/resources/walletResume'
import { getShortMonthAndYear, isObjectVazio, verifyHasData } from 'src/utils'

import { UseHomeConcentration } from './types'

export const useHomeConcentration: UseHomeConcentration = () => {
  const { data, isLoading: productionSubsegmentDataIsLoading } = useGetConcentrationQuery({})

  const concentrationData = useMemo(() => data?.data.data, [data])

  const productionSubsegmentData = useMemo(() => {
    const chartData = concentrationData?.screen_data.production_serialized.length
      ? concentrationData?.screen_data.production_serialized.length > 6
        ? concentrationData?.screen_data.production_serialized.slice(-6)
        : concentrationData?.screen_data.production_serialized
      : []

    const tooltipsValues: any = []

    concentrationData?.screen_data.productions.months.map((item) => {
      const objt: { [key: string]: number } = {}

      item.subsegments.length &&
        item.subsegments.forEach((subitem) => {
          objt[subitem.name] = subitem.production
        })

      if (!isObjectVazio(objt)) tooltipsValues.push(objt)
    })

    return {
      chartData,
      titles:
        concentrationData?.screen_data.subsegment_names.length &&
        concentrationData?.screen_data.subsegment_names.length > 3
          ? concentrationData?.screen_data.subsegment_names
          : concentrationData?.screen_data.subsegment_names,
      tickValues: concentrationData?.chart_data.productions.months.map(
        (item: { year_month: number }) => getShortMonthAndYear(String(item.year_month))
      ),
      processed_at: concentrationData?.screen_data.productions.processed_at ?? '',
      hasData: verifyHasData('value', chartData ?? []),
      tooltipsValues
    }
  }, [concentrationData])

  return {
    productionSubsegmentData,
    productionSubsegmentDataIsLoading
  }
}
