export type SurveyBase = {
  id: string
  title: string
  date_start: string
  date_end: string
  created_at: string
  updated_at: string
  answered: boolean
}

export const SURVEYS_QUESTIONS_TYPES_ENUM = {
  SINGLE_CHOICE: 1,
  MULTI_CHOICE: 2,
  OPEN_CHOICE: 3
} as const

export type SurveyQuestionType =
  (typeof SURVEYS_QUESTIONS_TYPES_ENUM)[keyof typeof SURVEYS_QUESTIONS_TYPES_ENUM]

export type Survey = SurveyBase

export type Question = {
  id: string
  title: string
  description: string
  type: SurveyQuestionType
  required: boolean
  items: { id: string; title: string }[]
}

export type SurveyDetail = SurveyBase & {
  questions: Question[]
  status: number
}

export type AnswerSurveyItem = {
  id: string
  questions: {
    id: string
    answers: { id: string; value?: string }[]
  }[]
}

export type TParams<T = Record<string, unknown>> = T & {
  authorSlugs?: string[]
  categorySlugs?: string[]
  mostRead?: boolean
  firstPage?: number
  lastPage?: number
  currentPage?: number
  perPage?: number
  search?: string
}
