import { useCallback, useRef } from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import {
  useDeleteNoteContact,
  useDeleteNoteStore,
  useGetNoteItemQuery
} from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { TOption } from 'organisms/Layouts/InternalLayout'
import { Dimensions } from 'react-native'
import { useAuthAtomValue } from 'src/store/auth'

import { UseNoteDetailsScreen } from './NoteDetailsScreen.types'

export const useNoteDetailsScreen: UseNoteDetailsScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const authAtom = useAuthAtomValue()

  const isOwner = authAtom?.user.access_level === 1

  const screenHeight = Dimensions.get('screen').height

  const refMessageActionSheetDeleteNote = useRef<BottomSheetModal>(null)

  const openDeleteSheet = useCallback(() => {
    refMessageActionSheetDeleteNote.current?.present()
  }, [])

  const closeDeleteSheet = useCallback(() => {
    refMessageActionSheetDeleteNote.current?.close()

    refMessageActionSheetDeleteNote.current?.dismiss()
  }, [])

  const { id, key, contactId, tabNumber } = route.params

  const { data, isLoading } = useGetNoteItemQuery(id, key)

  const currentDeleteMutation = contactId ? useDeleteNoteContact : useDeleteNoteStore

  const { mutate: deleteNoteMutation, isLoading: isLoadingDelete } = currentDeleteMutation()

  const note = data?.data?.data

  if (!note && !isLoading) {
    navigation.navigate('ErrorPageErrorOccurred', { callback: undefined })
  }

  const goToEditNote = useCallback(() => {
    if (note)
      navigation.navigate('EditNoteDetails', {
        note,
        key: contactId ? 'contacts' : 'stores',
        contactId,
        tabNumber
      })
  }, [contactId, navigation, note, tabNumber])

  const moreOptions: TOption[] = [
    {
      type: 'edit',
      title: 'Editar',
      onPress: goToEditNote
    },
    {
      type: 'delete',
      title: 'Excluir',
      color: 'red.500',
      onPress: () => {
        openDeleteSheet()
      }
    }
  ]

  const bottomSheetContext = useBottomSheetContext()

  const handleConfirmDelete = () => {
    deleteNoteMutation(
      { id: note!.id },
      {
        onSuccess: () => {
          closeDeleteSheet()

          navigation.goBack()
        },
        onError: () => {
          bottomSheetContext.open({
            title: 'Atenção',
            description: 'Não foi possível remover a nota, por favor tente mais tarde'
          })

          closeDeleteSheet()
        }
      }
    )
  }

  const setRenderDeleteModal = (value: boolean) => (value ? openDeleteSheet() : closeDeleteSheet())

  return {
    isMobile,
    goToEditNote,
    setRenderDeleteModal,
    isLoading: isLoading || isLoadingDelete,
    note,
    screenHeight,
    moreOptions,
    handleConfirmDelete,
    refMessageActionSheetDeleteNote,
    isOwner
  }
}
