import { FunctionComponent } from 'react'

import { Slider } from '@miblanchard/react-native-slider'
import { Box, Text, useBreakpointValue } from 'native-base'
import { colors } from 'src/theme/colors'

type TSliderRVProps = {
  sliderItem: {
    minSliderValue: number
    maxSliderValue: number
    step: number
    trackMarks: number[]
  }
  sliderValue: number
  sliderInitialValue: number
  handleChangeSliderValue(value: number): void
  handleSlideComplete(value: number): void
  setMoves(value: number): void
  moves: number
  showThumb?: boolean
  disabled?: boolean
}

export const SliderRV: FunctionComponent<TSliderRVProps> = ({
  sliderItem,
  sliderValue,
  sliderInitialValue,
  handleChangeSliderValue,
  handleSlideComplete,
  setMoves,
  moves,
  showThumb = true,
  disabled = false
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const renderAboveThumbComponent = () => {
    return !showThumb ? (
      <></>
    ) : (
      <Text
        ml={
          sliderValue > 100 ? (sliderValue > 140 ? (isMobile ? '-24px' : '-54px') : '-12px') : '0px'
        }
        mr={sliderValue < 110 ? '-6px' : '0px'}
        mb={{
          base: '-10px',
          lg: 2
        }}
        fontSize={{
          base: '12px',
          lg: '16px'
        }}
        bold
        color={sliderValue > sliderItem.minSliderValue ? 'secondary.500' : 'gray.500'}>
        {sliderValue.toFixed(0)}Pts
      </Text>
    )
  }

  const renderTrackMarkComponent = () => {
    return <Box w="2px" h="10px" bg="white" />
  }

  const thumbStyle = {
    backgroundColor:
      sliderValue > sliderItem.minSliderValue ? colors.secondary[500] : colors.gray[200],
    borderColor: 'white',
    borderRadius: 10,
    borderWidth: 2,
    height: isMobile ? 16 : 18,
    shadowColor: colors.gray[500],
    shadowOffset: {
      width: isMobile ? 0 : 1,
      height: isMobile ? 2 : 1
    },
    shadowOpacity: 0.35,
    shadowRadius: 2,
    width: isMobile ? 16 : 18
  }

  const trackStyle = {
    backgroundColor: colors.gray[100],
    borderRadius: 10,
    height: 8,
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.15,
    shadowRadius: 1
  }

  const handleOnValueChange = (value: number) => {
    handleChangeSliderValue(value)

    handleSlideComplete(value)
  }

  const handleDisable = () => {
    if (sliderValue < sliderInitialValue) {
      handleChangeSliderValue(sliderInitialValue)

      handleSlideComplete(sliderInitialValue)
    }

    return false
  }

  return (
    <Slider
      minimumValue={sliderItem.minSliderValue}
      maximumValue={sliderItem.maxSliderValue}
      minimumTrackTintColor={colors.secondary[500]}
      thumbStyle={disabled ? { display: 'none' } : thumbStyle}
      trackStyle={trackStyle}
      renderAboveThumbComponent={renderAboveThumbComponent}
      trackMarks={sliderItem.trackMarks}
      renderTrackMarkComponent={renderTrackMarkComponent}
      value={sliderValue}
      disabled={handleDisable()}
      onValueChange={(value: any) => {
        value.constructor === Array && handleOnValueChange(value[0])
      }}
      onSlidingStart={() => setMoves(moves + 1)}
      step={sliderItem.step}
      animateTransitions
    />
  )
}
