import { FunctionComponent } from 'react'

import { IIconProps } from 'native-base'
import Svg, { Path } from 'react-native-svg'

export interface Props {
  color: string
}

export const Striped: FunctionComponent<IIconProps & Props> = ({ color }) => (
  <Svg {...{ viewBox: '0 0 353 84', height: 84, width: 353 }}>
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.7091 82.3998L20.0438 0.781125L21.0203 1.76367L44.6856 83.3823L43.7091 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.0987 82.3978L23.4334 0.779172L24.4099 1.76172L48.0752 83.3804L47.0987 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.491 82.3998L26.8257 0.781125L27.8022 1.76367L51.4676 83.3823L50.491 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.8809 82.4017L30.2156 0.783078L31.1921 1.76562L54.8574 83.3843L53.8809 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M57.2689 82.3978L33.6036 0.779172L34.5801 1.76172L58.2454 83.3804L57.2689 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M60.6632 82.4017L36.9979 0.783078L37.9744 1.76562L61.6397 83.3843L60.6632 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M64.0513 82.3998L40.386 0.781125L41.3625 1.76367L65.0279 83.3823L64.0513 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.442 82.3978L43.7767 0.779172L44.7532 1.76172L68.4185 83.3804L67.442 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M70.8306 82.3978L47.1653 0.779172L48.1418 1.76172L71.8072 83.3804L70.8306 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M74.2259 82.4037L50.5606 0.785031L51.5371 1.76758L75.2024 83.3862L74.2259 82.4037Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M77.6131 82.3998L53.9478 0.781125L54.9243 1.76367L78.5896 83.3823L77.6131 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M81.0062 82.3998L57.3409 0.781125L58.3174 1.76367L81.9827 83.3823L81.0062 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M84.3949 82.3998L60.7295 0.781125L61.7061 1.76367L85.3714 83.3823L84.3949 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M87.7865 82.4017L64.1211 0.783078L65.0977 1.76562L88.763 83.3843L87.7865 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M91.1756 82.3998L67.5103 0.781125L68.4868 1.76367L92.1521 83.3823L91.1756 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M94.5655 82.3978L70.9002 0.779172L71.8767 1.76172L95.542 83.3804L94.5655 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M97.9578 82.4017L74.2925 0.783078L75.269 1.76562L98.9344 83.3843L97.9578 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M101.348 82.3998L77.6824 0.781125L78.6589 1.76367L102.324 83.3823L101.348 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M104.738 82.3978L81.0723 0.779172L82.0488 1.76172L105.714 83.3804L104.738 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M108.127 82.3978L84.462 0.779172L85.4385 1.76172L109.104 83.3804L108.127 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M111.519 82.3998L87.8533 0.781125L88.8298 1.76367L112.495 83.3823L111.519 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M114.909 82.3998L91.2437 0.781125L92.2202 1.76367L115.886 83.3823L114.909 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M118.299 82.3978L94.6341 0.779172L95.6106 1.76172L119.276 83.3804L118.299 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M121.693 82.4037L98.0276 0.785031L99.0042 1.76758L122.669 83.3862L121.693 82.4037Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M125.083 82.4017L101.418 0.783078L102.394 1.76562L126.059 83.3843L125.083 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M128.47 82.3978L104.805 0.779172L105.781 1.76172L129.447 83.3804L128.47 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.859 82.3978L108.194 0.779172L109.171 1.76172L132.836 83.3804L131.859 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M135.253 82.3998L111.587 0.781125L112.564 1.76367L136.229 83.3823L135.253 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M138.643 82.4017L114.978 0.783078L115.955 1.76562L139.62 83.3843L138.643 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M142.034 82.3998L118.368 0.781125L119.345 1.76367L143.01 83.3823L142.034 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M145.425 82.4017L121.76 0.783078L122.736 1.76562L146.402 83.3843L145.425 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M148.815 82.3998L125.149 0.781125L126.126 1.76367L149.791 83.3823L148.815 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M152.205 82.3998L128.54 0.781125L129.517 1.76367L153.182 83.3823L152.205 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M155.598 82.4017L131.933 0.783078L132.909 1.76562L156.574 83.3843L155.598 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M158.987 82.4017L135.322 0.783078L136.299 1.76562L159.964 83.3843L158.987 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M162.377 82.3998L138.711 0.781125L139.688 1.76367L163.353 83.3823L162.377 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M165.765 82.3978L142.1 0.779172L143.077 1.76172L166.742 83.3804L165.765 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M169.158 82.3998L145.492 0.781125L146.469 1.76367L170.134 83.3823L169.158 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M172.547 82.3978L148.882 0.779172L149.858 1.76172L173.523 83.3804L172.547 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M175.94 82.4017L152.274 0.783078L153.251 1.76562L176.916 83.3843L175.94 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M179.327 82.3978L155.662 0.779172L156.638 1.76172L180.303 83.3804L179.327 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M182.719 82.3998L159.054 0.781125L160.03 1.76367L183.696 83.3823L182.719 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M186.111 82.3998L162.446 0.781125L163.422 1.76367L187.088 83.3823L186.111 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M189.499 82.3978L165.833 0.779172L166.81 1.76172L190.475 83.3804L189.499 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M192.894 82.4017L169.228 0.783078L170.205 1.76562L193.87 83.3843L192.894 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M196.284 82.4017L172.618 0.783078L173.595 1.76562L197.26 83.3843L196.284 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M199.672 82.3998L176.007 0.781125L176.983 1.76367L200.648 83.3823L199.672 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M203.061 82.3998L179.396 0.781125L180.373 1.76367L204.038 83.3823L203.061 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M206.665 82.3998L183 0.781125L183.977 1.76367L207.642 83.3823L206.665 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M210.058 82.3998L186.393 0.781125L187.369 1.76367L211.035 83.3823L210.058 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M213.447 82.3998L189.782 0.781125L190.758 1.76367L214.423 83.3823L213.447 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M216.839 82.4017L193.173 0.783078L194.15 1.76562L217.815 83.3843L216.839 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M220.228 82.3998L196.562 0.781125L197.539 1.76367L221.204 83.3823L220.228 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M223.618 82.3978L199.952 0.779172L200.929 1.76172L224.594 83.3804L223.618 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M227.01 82.4017L203.345 0.783078L204.321 1.76562L227.986 83.3843L227.01 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M230.4 82.3998L206.735 0.781125L207.711 1.76367L231.376 83.3823L230.4 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M233.79 82.3978L210.125 0.779172L211.101 1.76172L234.766 83.3804L233.79 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M237.18 82.3978L213.514 0.779172L214.491 1.76172L238.156 83.3804L237.18 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M240.571 82.3998L216.906 0.781125L217.882 1.76367L241.547 83.3823L240.571 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M243.961 82.3998L220.296 0.781125L221.272 1.76367L244.938 83.3823L243.961 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M247.352 82.3978L223.686 0.779172L224.663 1.76172L248.328 83.3804L247.352 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M250.745 82.4037L227.08 0.785031L228.056 1.76758L251.721 83.3862L250.745 82.4037Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M254.135 82.4017L230.47 0.783078L231.446 1.76562L255.111 83.3843L254.135 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M257.522 82.3978L233.857 0.779172L234.833 1.76172L258.499 83.3804L257.522 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M260.911 82.3978L237.246 0.779172L238.223 1.76172L261.888 83.3804L260.911 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M264.305 82.3998L240.639 0.781125L241.616 1.76367L265.281 83.3823L264.305 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M267.696 82.4017L244.03 0.783078L245.007 1.76562L268.672 83.3843L267.696 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M271.086 82.3998L247.42 0.781125L248.397 1.76367L272.062 83.3823L271.086 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M274.477 82.4017L250.812 0.783078L251.788 1.76562L275.454 83.3843L274.477 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M277.867 82.3998L254.202 0.781125L255.178 1.76367L278.844 83.3823L277.867 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M281.257 82.3998L257.592 0.781125L258.569 1.76367L282.234 83.3823L281.257 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M284.65 82.4017L260.985 0.783078L261.961 1.76562L285.627 83.3843L284.65 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M288.04 82.4017L264.374 0.783078L265.351 1.76562L289.016 83.3843L288.04 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M291.429 82.3998L267.764 0.781125L268.74 1.76367L292.406 83.3823L291.429 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M294.818 82.3978L271.152 0.779172L272.129 1.76172L295.794 83.3804L294.818 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M298.21 82.3998L274.544 0.781125L275.521 1.76367L299.186 83.3823L298.21 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M301.599 82.3978L277.934 0.779172L278.91 1.76172L302.576 83.3804L301.599 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M304.992 82.4017L281.327 0.783078L282.303 1.76562L305.969 83.3843L304.992 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M308.379 82.3978L284.714 0.779172L285.69 1.76172L309.355 83.3804L308.379 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M311.771 82.3998L288.106 0.781125L289.082 1.76367L312.748 83.3823L311.771 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M315.163 82.3998L291.498 0.781125L292.474 1.76367L316.14 83.3823L315.163 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M318.551 82.3978L294.885 0.779172L295.862 1.76172L319.527 83.3804L318.551 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M321.946 82.4017L298.28 0.783078L299.257 1.76562L322.922 83.3843L321.946 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M325.336 82.4017L301.67 0.783078L302.647 1.76562L326.312 83.3843L325.336 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M328.724 82.3998L305.059 0.781125L306.035 1.76367L329.7 83.3823L328.724 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M332.114 82.3998L308.448 0.781125L309.425 1.76367L333.09 83.3823L332.114 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M335.665 82.3978L312 0.779172L312.977 1.76172L336.642 83.3804L335.665 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M339.059 82.4017L315.394 0.783078L316.37 1.76562L340.035 83.3843L339.059 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M342.449 82.3998L318.783 0.781125L319.76 1.76367L343.425 83.3823L342.449 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M345.836 82.3959L322.171 0.777219L323.147 1.75977L346.813 83.3784L345.836 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M349.225 82.3959L325.56 0.777219L326.537 1.75977L350.202 83.3784L349.225 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M352.618 82.3978L328.953 0.779172L329.93 1.76172L353.595 83.3804L352.618 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M356.009 82.3998L332.344 0.781125L333.321 1.76367L356.986 83.3823L356.009 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M359.4 82.3978L335.734 0.779172L336.711 1.76172L360.376 83.3804L359.4 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M362.791 82.4017L339.126 0.783078L340.102 1.76562L363.768 83.3843L362.791 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M366.181 82.3998L342.516 0.781125L343.492 1.76367L367.157 83.3823L366.181 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M369.571 82.3998L345.906 0.781125L346.883 1.76367L370.548 83.3823L369.571 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M372.964 82.4017L349.299 0.783078L350.275 1.76562L373.941 83.3843L372.964 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M376.353 82.3998L352.688 0.781125L353.665 1.76367L377.33 83.3823L376.353 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M379.743 82.3998L356.077 0.781125L357.054 1.76367L380.719 83.3823L379.743 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M383.132 82.3978L359.466 0.779172L360.443 1.76172L384.108 83.3804L383.132 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M386.524 82.3998L362.858 0.781125L363.835 1.76367L387.5 83.3823L386.524 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M389.913 82.3978L366.248 0.779172L367.224 1.76172L390.889 83.3804L389.913 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M393.306 82.4017L369.641 0.783078L370.617 1.76562L394.282 83.3843L393.306 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M396.693 82.3959L373.028 0.777219L374.004 1.75977L397.669 83.3784L396.693 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M400.085 82.3998L376.42 0.781125L377.396 1.76367L401.062 83.3823L400.085 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M403.477 82.3998L379.812 0.781125L380.788 1.76367L404.454 83.3823L403.477 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M406.865 82.3959L383.199 0.777219L384.176 1.75977L407.841 83.3784L406.865 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M410.26 82.4017L386.594 0.783078L387.571 1.76562L411.236 83.3843L410.26 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M413.649 82.4017L389.984 0.783078L390.961 1.76562L414.626 83.3843L413.649 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M417.038 82.3998L393.373 0.781125L394.349 1.76367L418.014 83.3823L417.038 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M420.427 82.3978L396.762 0.779172L397.739 1.76172L421.404 83.3804L420.427 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M424.031 82.3978L400.366 0.779172L401.343 1.76172L425.008 83.3804L424.031 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M427.424 82.3998L403.759 0.781125L404.735 1.76367L428.401 83.3823L427.424 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M430.813 82.3978L407.148 0.779172L408.124 1.76172L431.79 83.3804L430.813 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M434.205 82.3998L410.539 0.781125L411.516 1.76367L435.181 83.3823L434.205 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M437.594 82.3998L413.928 0.781125L414.905 1.76367L438.57 83.3823L437.594 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M440.984 82.3978L417.318 0.779172L418.295 1.76172L441.96 83.3804L440.984 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M444.376 82.4017L420.711 0.783078L421.687 1.76562L445.353 83.3843L444.376 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M447.766 82.3998L424.101 0.781125L425.077 1.76367L448.742 83.3823L447.766 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M451.156 82.3978L427.491 0.779172L428.467 1.76172L452.132 83.3804L451.156 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M454.545 82.3978L430.88 0.779172L431.857 1.76172L455.522 83.3804L454.545 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M457.937 82.3978L434.272 0.779172L435.248 1.76172L458.913 83.3804L457.937 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M461.327 82.3998L437.662 0.781125L438.638 1.76367L462.303 83.3823L461.327 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M464.718 82.3978L441.052 0.779172L442.029 1.76172L465.694 83.3804L464.718 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M468.111 82.4017L444.446 0.783078L445.422 1.76562L469.088 83.3843L468.111 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M471.501 82.4017L447.836 0.783078L448.812 1.76562L472.478 83.3843L471.501 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M474.888 82.3959L451.223 0.777219L452.199 1.75977L475.865 83.3784L474.888 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M478.278 82.3959L454.612 0.777219L455.589 1.75977L479.254 83.3784L478.278 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M481.671 82.3978L458.005 0.779172L458.982 1.76172L482.647 83.3804L481.671 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M485.062 82.4017L461.396 0.783078L462.373 1.76562L486.038 83.3843L485.062 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M488.452 82.3978L464.787 0.779172L465.763 1.76172L489.428 83.3804L488.452 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M491.843 82.4017L468.178 0.783078L469.154 1.76562L492.82 83.3843L491.843 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M495.233 82.3998L471.568 0.781125L472.544 1.76367L496.209 83.3823L495.233 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M498.624 82.3998L474.958 0.781125L475.935 1.76367L499.6 83.3823L498.624 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M502.016 82.4017L478.351 0.783078L479.327 1.76562L502.993 83.3843L502.016 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M505.406 82.4017L481.74 0.783078L482.717 1.76562L506.382 83.3843L505.406 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M508.795 82.3998L485.13 0.781125L486.106 1.76367L509.771 83.3823L508.795 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M512.184 82.3978L488.518 0.779172L489.495 1.76172L513.16 83.3804L512.184 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M515.576 82.3978L491.91 0.779172L492.887 1.76172L516.552 83.3804L515.576 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M518.965 82.3978L495.3 0.779172L496.276 1.76172L519.942 83.3804L518.965 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M522.358 82.4017L498.693 0.783078L499.669 1.76562L523.334 83.3843L522.358 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M525.745 82.3978L502.08 0.779172L503.056 1.76172L526.721 83.3804L525.745 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M529.137 82.3998L505.472 0.781125L506.448 1.76367L530.114 83.3823L529.137 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M532.529 82.3998L508.864 0.781125L509.841 1.76367L533.506 83.3823L532.529 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M535.917 82.3959L512.251 0.777219L513.228 1.75977L536.893 83.3784L535.917 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M539.312 82.4017L515.647 0.783078L516.623 1.76562L540.288 83.3843L539.312 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M542.701 82.4017L519.036 0.783078L520.013 1.76562L543.678 83.3843L542.701 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M546.09 82.3998L522.425 0.781125L523.401 1.76367L547.067 83.3823L546.09 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M549.48 82.3978L525.814 0.779172L526.791 1.76172L550.456 83.3804L549.48 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M552.665 82.3998L529 0.781125L529.977 1.76367L553.642 83.3823L552.665 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M556.055 82.3978L532.389 0.779172L533.366 1.76172L557.031 83.3804L556.055 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M559.448 82.4017L535.782 0.783078L536.759 1.76562L560.424 83.3843L559.448 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M562.835 82.3978L539.169 0.779172L540.146 1.76172L563.811 83.3804L562.835 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M566.227 82.3998L542.562 0.781125L543.538 1.76367L567.203 83.3823L566.227 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M569.619 82.3998L545.954 0.781125L546.93 1.76367L570.595 83.3823L569.619 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M573.006 82.3978L549.341 0.779172L550.318 1.76172L573.983 83.3804L573.006 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M576.401 82.4017L552.736 0.783078L553.713 1.76562L577.378 83.3843L576.401 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M579.791 82.4017L556.126 0.783078L557.103 1.76562L580.768 83.3843L579.791 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M583.18 82.3998L559.514 0.781125L560.491 1.76367L584.156 83.3823L583.18 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M586.569 82.3998L562.904 0.781125L563.88 1.76367L587.546 83.3823L586.569 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M590.173 82.3998L566.508 0.781125L567.484 1.76367L591.15 83.3823L590.173 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M593.566 82.3998L569.901 0.781125L570.877 1.76367L594.542 83.3823L593.566 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M596.955 82.3998L573.289 0.781125L574.266 1.76367L597.931 83.3823L596.955 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M600.346 82.4017L576.681 0.783078L577.658 1.76562L601.323 83.3843L600.346 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M603.735 82.3998L580.07 0.781125L581.047 1.76367L604.712 83.3823L603.735 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M607.126 82.3978L583.46 0.779172L584.437 1.76172L608.102 83.3804L607.126 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M610.518 82.4017L586.852 0.783078L587.829 1.76562L611.494 83.3843L610.518 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M613.908 82.3998L590.242 0.781125L591.219 1.76367L614.884 83.3823L613.908 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M617.298 82.3978L593.632 0.779172L594.609 1.76172L618.274 83.3804L617.298 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M620.687 82.3978L597.022 0.779172L597.999 1.76172L621.664 83.3804L620.687 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M624.079 82.3998L600.413 0.781125L601.39 1.76367L625.055 83.3823L624.079 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M627.469 82.3998L603.804 0.781125L604.78 1.76367L628.445 83.3823L627.469 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M630.859 82.3978L607.194 0.779172L608.171 1.76172L631.836 83.3804L630.859 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M634.253 82.4037L610.587 0.785031L611.564 1.76758L635.229 83.3862L634.253 82.4037Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M637.643 82.4017L613.977 0.783078L614.954 1.76562L638.619 83.3843L637.643 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M641.03 82.3978L617.365 0.779172L618.341 1.76172L642.006 83.3804L641.03 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M644.419 82.3978L620.754 0.779172L621.73 1.76172L645.396 83.3804L644.419 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M647.813 82.3998L624.147 0.781125L625.124 1.76367L648.789 83.3823L647.813 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M651.203 82.4017L627.538 0.783078L628.515 1.76562L652.18 83.3843L651.203 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M654.594 82.3998L630.928 0.781125L631.905 1.76367L655.57 83.3823L654.594 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M657.985 82.4017L634.32 0.783078L635.296 1.76562L658.961 83.3843L657.985 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M661.375 82.3998L637.71 0.781125L638.686 1.76367L662.351 83.3823L661.375 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M664.765 82.3998L641.1 0.781125L642.076 1.76367L665.742 83.3823L664.765 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M668.158 82.4017L644.493 0.783078L645.469 1.76562L669.135 83.3843L668.158 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M671.547 82.4017L647.882 0.783078L648.859 1.76562L672.524 83.3843L671.547 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M674.937 82.3998L651.272 0.781125L652.248 1.76367L675.913 83.3823L674.937 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M678.326 82.3978L654.66 0.779172L655.637 1.76172L679.302 83.3804L678.326 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M681.718 82.3998L658.052 0.781125L659.029 1.76367L682.694 83.3823L681.718 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M685.107 82.3978L661.442 0.779172L662.418 1.76172L686.084 83.3804L685.107 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M688.5 82.4017L664.835 0.783078L665.811 1.76562L689.476 83.3843L688.5 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M691.887 82.3978L668.221 0.779172L669.198 1.76172L692.863 83.3804L691.887 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M695.279 82.3998L671.614 0.781125L672.59 1.76367L696.255 83.3823L695.279 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M698.671 82.3998L675.006 0.781125L675.982 1.76367L699.647 83.3823L698.671 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M702.058 82.3978L678.393 0.779172L679.37 1.76172L703.035 83.3804L702.058 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M705.453 82.4017L681.788 0.783078L682.765 1.76562L706.43 83.3843L705.453 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M708.843 82.4017L685.178 0.783078L686.155 1.76562L709.82 83.3843L708.843 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M712.232 82.3998L688.566 0.781125L689.543 1.76367L713.208 83.3823L712.232 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M715.621 82.3998L691.956 0.781125L692.933 1.76367L716.598 83.3823L715.621 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M719.173 82.3978L695.508 0.779172L696.484 1.76172L720.15 83.3804L719.173 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M722.567 82.4017L698.901 0.783078L699.878 1.76562L723.543 83.3843L722.567 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M725.957 82.3998L702.291 0.781125L703.268 1.76367L726.933 83.3823L725.957 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M729.344 82.3959L705.679 0.777219L706.655 1.75977L730.32 83.3784L729.344 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M732.733 82.3959L709.068 0.777219L710.044 1.75977L733.71 83.3784L732.733 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M736.126 82.3978L712.461 0.779172L713.438 1.76172L737.103 83.3804L736.126 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M739.517 82.3998L715.852 0.781125L716.828 1.76367L740.494 83.3823L739.517 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M742.908 82.3978L719.242 0.779172L720.219 1.76172L743.884 83.3804L742.908 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M746.299 82.4017L722.634 0.783078L723.61 1.76562L747.275 83.3843L746.299 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M749.689 82.3998L726.023 0.781125L727 1.76367L750.665 83.3823L749.689 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M753.079 82.3998L729.414 0.781125L730.39 1.76367L754.056 83.3823L753.079 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M756.472 82.4017L732.807 0.783078L733.783 1.76562L757.449 83.3843L756.472 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M759.861 82.3998L736.196 0.781125L737.172 1.76367L760.838 83.3823L759.861 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M763.251 82.3998L739.585 0.781125L740.562 1.76367L764.227 83.3823L763.251 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M766.639 82.3978L742.974 0.779172L743.951 1.76172L767.616 83.3804L766.639 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M770.031 82.3998L746.366 0.781125L747.343 1.76367L771.008 83.3823L770.031 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M773.421 82.3978L749.755 0.779172L750.732 1.76172L774.397 83.3804L773.421 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M776.814 82.4017L753.148 0.783078L754.125 1.76562L777.79 83.3843L776.814 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M780.201 82.3959L756.535 0.777219L757.512 1.75977L781.177 83.3784L780.201 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M783.593 82.3998L759.928 0.781125L760.904 1.76367L784.569 83.3823L783.593 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M786.985 82.3998L763.32 0.781125L764.296 1.76367L787.961 83.3823L786.985 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M790.372 82.3959L766.707 0.777219L767.684 1.75977L791.349 83.3784L790.372 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M793.767 82.4017L770.102 0.783078L771.079 1.76562L794.744 83.3843L793.767 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M797.157 82.4017L773.492 0.783078L774.469 1.76562L798.134 83.3843L797.157 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M800.546 82.3998L776.88 0.781125L777.857 1.76367L801.522 83.3823L800.546 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M803.935 82.3978L780.27 0.779172L781.246 1.76172L804.912 83.3804L803.935 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M807.539 82.3978L783.874 0.779172L784.85 1.76172L808.516 83.3804L807.539 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M810.932 82.3998L787.267 0.781125L788.243 1.76367L811.908 83.3823L810.932 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M814.321 82.3978L790.656 0.779172L791.632 1.76172L815.297 83.3804L814.321 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M817.712 82.3998L794.047 0.781125L795.024 1.76367L818.689 83.3823L817.712 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M821.101 82.3998L797.436 0.781125L798.413 1.76367L822.078 83.3823L821.101 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M824.492 82.3978L800.826 0.779172L801.803 1.76172L825.468 83.3804L824.492 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M827.884 82.4017L804.219 0.783078L805.195 1.76562L828.86 83.3843L827.884 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M831.274 82.3998L807.608 0.781125L808.585 1.76367L832.25 83.3823L831.274 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M834.664 82.3978L810.998 0.779172L811.975 1.76172L835.64 83.3804L834.664 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M838.053 82.3978L814.388 0.779172L815.365 1.76172L839.03 83.3804L838.053 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M841.445 82.3978L817.779 0.779172L818.756 1.76172L842.421 83.3804L841.445 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M844.835 82.3998L821.169 0.781125L822.146 1.76367L845.811 83.3823L844.835 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M848.225 82.3978L824.56 0.779172L825.537 1.76172L849.202 83.3804L848.225 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M851.619 82.4017L827.954 0.783078L828.93 1.76562L852.595 83.3843L851.619 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M855.009 82.4017L831.344 0.783078L832.32 1.76562L855.985 83.3843L855.009 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M858.396 82.3959L834.731 0.777219L835.707 1.75977L859.373 83.3784L858.396 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M861.785 82.3959L838.12 0.777219L839.097 1.75977L862.762 83.3784L861.785 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M865.179 82.3978L841.513 0.779172L842.49 1.76172L866.155 83.3804L865.179 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M868.569 82.4017L844.904 0.783078L845.881 1.76562L869.546 83.3843L868.569 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M871.96 82.3978L848.294 0.779172L849.271 1.76172L872.936 83.3804L871.96 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M875.351 82.4017L851.686 0.783078L852.662 1.76562L876.327 83.3843L875.351 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M878.741 82.3998L855.075 0.781125L856.052 1.76367L879.717 83.3823L878.741 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M882.131 82.3998L858.466 0.781125L859.443 1.76367L883.108 83.3823L882.131 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M885.524 82.4017L861.859 0.783078L862.835 1.76562L886.5 83.3843L885.524 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M888.913 82.4017L865.248 0.783078L866.225 1.76562L889.89 83.3843L888.913 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M892.303 82.3998L868.638 0.781125L869.614 1.76367L893.279 83.3823L892.303 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M895.691 82.3978L872.026 0.779172L873.003 1.76172L896.668 83.3804L895.691 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M899.084 82.3978L875.418 0.779172L876.395 1.76172L900.06 83.3804L899.084 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M902.473 82.3978L878.808 0.779172L879.784 1.76172L903.449 83.3804L902.473 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M905.866 82.4017L882.2 0.783078L883.177 1.76562L906.842 83.3843L905.866 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M909.253 82.3978L885.587 0.779172L886.564 1.76172L910.229 83.3804L909.253 82.3978Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M912.645 82.3998L888.98 0.781125L889.956 1.76367L913.622 83.3823L912.645 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M916.037 82.3998L892.372 0.781125L893.348 1.76367L917.014 83.3823L916.037 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M919.424 82.3959L895.759 0.777219L896.736 1.75977L920.401 83.3784L919.424 82.3959Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M922.82 82.4017L899.154 0.783078L900.131 1.76562L923.796 83.3843L922.82 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M926.209 82.4017L902.544 0.783078L903.521 1.76562L927.186 83.3843L926.209 82.4017Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M929.598 82.3998L905.933 0.781125L906.909 1.76367L930.574 83.3823L929.598 82.3998Z"
      fill={color}
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M932.987 82.3978L909.322 0.779172L910.299 1.76172L933.964 83.3804L932.987 82.3978Z"
      fill={color}
    />
  </Svg>
)
