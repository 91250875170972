import { FunctionComponent } from 'react'

import { Calendar, Icons } from 'atoms'
import { Box, Pressable, Text, useDisclose } from 'native-base'
import { Keyboard } from 'react-native'
import { formatDateStringToMask } from 'src/utils'

type TDate = {
  value?: string
  onSelect(day: any): void
}

export const InputDate: FunctionComponent<TDate> = ({ value, onSelect }) => {
  const { isOpen, onToggle } = useDisclose()

  const handleSelect = (day: any) => {
    onSelect(day)

    onToggle()
  }

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return (
    <>
      <Pressable
        onPress={() => {
          Keyboard.dismiss()

          onToggle()
        }}
        flexDir="row"
        alignItems="center"
        h="50px"
        bg="gray.30"
        borderRadius="40px"
        p={4}
        borderColor="gray.30"
        borderWidth="1px">
        <Icons.Calendar />
        <Text ml={2} fontSize="16px" lineHeight="24px" color="gray.700">
          {value
            ? capitalizeFirstLetter(formatDateStringToMask(value, "EEEE, d 'de' MMM 'de' yyyy"))
            : 'Hoje'}
        </Text>
      </Pressable>

      {isOpen && (
        <Box maxW="100%">
          <Calendar.Root>
            <Calendar.Body
              daySelected={value}
              onDaySelected={handleSelect}
              markingType="dot"
              enableSelectYear
            />
          </Calendar.Root>
        </Box>
      )}
    </>
  )
}
