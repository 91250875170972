import { useCallback, useEffect, useState } from 'react'

import * as ScreenOrientation from 'expo-screen-orientation'
import { useGetProductionHistoryQuery } from 'integration/resources/production'
import { useBreakpointValue } from 'native-base'
import { Platform } from 'react-native'
import { HomeChartsTypes } from 'src/screens/HomeScreen/constants'
import { LogEvent } from 'src/utils/logEvents'

import { UseProductionChartScreen } from './ProductionChartScreen.types'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

export const useProductionChartScreen: UseProductionChartScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data: productionHistoryData, isFetching: dataIsFetching } = useGetProductionHistoryQuery()

  const chartData = productionHistoryData?.data.data

  const [showModal, setShowModal] = useState(true)

  const timeoutModal = setTimeout(() => {
    setShowModal(false)
  }, 2500)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutModal)
    }
  }, [timeoutModal])

  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    if (!showSpinner) return

    const timeoutSpinner = setTimeout(() => {
      setShowSpinner(!showSpinner)
    }, 3000)

    return () => {
      clearTimeout(timeoutSpinner)
    }
  }, [showSpinner])

  useEffect(() => {
    if (Platform.OS !== 'web') {
      changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT)
    }
  }, [])

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (e.data.action.type === 'GO_BACK' && isMobile) {
          // Prevent default behavior of leaving the screen
          e.preventDefault()

          setShowSpinner(true)

          changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP)

          setTimeout(() => {
            navigation.dispatch(e.data.action)
          }, 3000)
        }
      }),
    [navigation, isMobile]
  )

  const handleGoBack = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const handleGoToProductionScreen = useCallback(
    (yearMonth: string, type: HomeChartsTypes, total: string) => {
      LogEvent(`producao_grafico`, {
        item_id: yearMonth
      })

      setShowSpinner(true)

      if (Platform.OS !== 'web') {
        changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP).then(() => {
          navigation.replace('Production', { yearMonth, type, total })
        })
      } else {
        navigation.replace('Production', { yearMonth, type, total })
      }
    },
    [navigation]
  )

  return {
    isMobile,
    handleGoBack,
    handleGoToProductionScreen,
    chartData,
    showModal,
    setShowModal,
    dataIsFetching,
    showSpinner,
    setShowSpinner
  }
}
