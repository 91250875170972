import { useCallback, useEffect, useState } from 'react'

import * as ScreenOrientation from 'expo-screen-orientation'
import { useContractsByTabNumberQuery } from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { PanelsTypes, panelsFullChartsProps } from 'organisms/TabPanels/constants'
import { formatToReal, getMonthName } from 'src/utils'

import { UseContractListScreen } from './ContractListScreen.types'
import { homeResumedChartsProps } from '../HomeScreen/constants'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

export const useContractListScreen: UseContractListScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [showSpinner, setShowSpinner] = useState(false)

  const { tabNumber, yearMonth, title, type } = route.params

  const { data, isLoading, isFetching } = useContractsByTabNumberQuery({
    tabNumber,
    yearMonth,
    type
  })

  const contractList = data?.data.data

  const month = getMonthName(Number(yearMonth.slice(4)))

  const year = yearMonth.slice(0, 4)

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (e.data.action.type === 'GO_BACK' && isMobile) {
          // Prevent default behavior of leaving the screen
          e.preventDefault()

          setShowSpinner(true)

          if (
            navigation.getState().routes?.[navigation.getState().routes.length - 2]?.name ===
            'Chart'
          ) {
            changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT)
          }

          setTimeout(() => {
            navigation.dispatch(e.data.action)
          }, 3000)
        }
      }),
    [navigation, isMobile]
  )

  const types = {
    ...panelsFullChartsProps,
    ...homeResumedChartsProps
  }

  const typeFormat = (currentType: PanelsTypes) =>
    types[currentType] ? types[currentType]?.type : 'currency'

  const getTotal = (type: PanelsTypes, total: number) => {
    switch (typeFormat(type)) {
      case 'currency':
        return formatToReal(total)

      case 'percentage':
        return `${total}%`

      default:
        return total
    }
  }

  const handleGoBack = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  return {
    isMobile,
    isLoading: isLoading || isFetching,
    contractList,
    showSpinner,
    title,
    tabNumber,
    handleGoBack,
    description: `${month} ${year} ${typeFormat(type as PanelsTypes) === 'currency' ? '(R$)' : ''}`,
    total: getTotal(type as PanelsTypes, contractList?.total_value ?? 0)
  }
}
