import { FunctionComponent, useMemo } from 'react'

import { LoadingTurbo } from 'atoms'
import { UnconnectedMessage } from 'molecules'
import {
  Box,
  Center,
  Heading,
  HStack,
  useTheme,
  VStack,
  Text,
  useBreakpointValue,
  Skeleton
} from 'native-base'
import { Dimensions } from 'react-native'
import { formatDateStringToMask, percentageCalculate } from 'src/utils'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory-native'

import { useHomeProposalFunnel } from './hook'

export const HomeProposalFunnel: FunctionComponent = () => {
  const { proposalFunnelData, funnelDataIsLoading, changeColor } = useHomeProposalFunnel()

  const theme = useTheme()

  const phoneWidth = Dimensions.get('window').width

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const higherValue = useMemo(() => {
    return proposalFunnelData.dataSerialized.reduce((a, b) => (a.y > b.y ? a : b)).y
  }, [proposalFunnelData])

  return (
    <VStack
      mr={{ base: 4, lg: '30px' }}
      mb={{ base: 6, lg: 8 }}
      space={4}
      maxW={{ base: 'auto', lg: 738 }}
      flex={1}>
      {funnelDataIsLoading ? (
        <Center h={360} w="full" position="absolute" zIndex={9}>
          <LoadingTurbo size={100} />
        </Center>
      ) : (
        <>
          <VStack bg="white" borderRadius="20px" px={{ base: 4, lg: 6 }} py={6}>
            <Heading color="gray.700" fontSize="16px" mb={6}>
              Funil de Propostas (Qtd)
            </Heading>
            {funnelDataIsLoading ? (
              <VStack space={1} flex={1} mb={7}>
                <Skeleton borderRadius="full" h={6} w="70%" />
                <Skeleton borderRadius="full" h={6} w="50%" />
                <Skeleton borderRadius="full" h={6} w="90%" />
                <Skeleton borderRadius="full" h={6} w="70%" />
                <Skeleton borderRadius="full" h={6} w="40%" />
              </VStack>
            ) : (
              <Box h={{ base: '110px', lg: 268 }} mb={{ base: 4, lg: 0 }}>
                {proposalFunnelData?.dataSerialized ? (
                  <Box mt={{ base: -20, lg: -8 }} ml={{ base: -12, lg: '-116px' }} flex={1}>
                    <VictoryChart
                      theme={VictoryTheme.material}
                      height={isMobile ? 260 : 268}
                      width={isMobile ? phoneWidth * 1.08 : 600}
                      domainPadding={{ y: isMobile ? 30 : 0, x: isMobile ? 40 : 0 }}>
                      <VictoryAxis
                        dependentAxis
                        style={{
                          tickLabels: {
                            fontSize: 1
                          },
                          ticks: { size: 0 },
                          axis: { stroke: 0 },
                          grid: {
                            stroke: 0
                          }
                        }}
                      />

                      <VictoryBar
                        horizontal
                        data={proposalFunnelData?.dataSerialized}
                        barWidth={isMobile ? 24 : 30}
                        cornerRadius={{
                          top: ({ datum }) => {
                            const percentage = percentageCalculate(
                              higherValue ?? 0,
                              datum.value ?? 0
                            )

                            if (percentage > 13) return 9

                            if (percentage > 9) return 9

                            if (percentage > 5) return 7

                            if (percentage > 3) return 5

                            return 12
                          },
                          bottom: ({ datum }) => {
                            const percentage = percentageCalculate(
                              higherValue ?? 0,
                              datum.value ?? 0
                            )

                            if (percentage > 13) return 9

                            if (percentage > 9) return 9

                            if (percentage > 5) return 7

                            if (percentage > 3) return 5

                            return 12
                          }
                        }}
                        style={{
                          //@ts-ignore
                          data: {
                            fill: ({ datum }) => changeColor(datum.x)
                          },
                          //@ts-ignore
                          labels: {
                            fontSize: 14,
                            //@ts-ignore
                            fill: ({ datum }) =>
                              datum.y === 0 ? changeColor(datum.x) : theme.colors.gray[400]
                          }
                        }}
                        labels={({ datum }) => datum.y}
                      />
                    </VictoryChart>
                  </Box>
                ) : (
                  <UnconnectedMessage noAnimation />
                )}
              </Box>
            )}

            {proposalFunnelData?.dataSerialized ? (
              <VStack space={{ base: 2, lg: 4 }} mb={4}>
                <HStack flex={1} space={{ base: 1, lg: 6 }}>
                  <HStack alignItems="center" flex={{ base: 1, lg: undefined }}>
                    <Center
                      bg="secondary.800"
                      borderRadius="full"
                      size={{ base: 3, lg: 4 }}
                      mr="6px"
                    />
                    <Text color="gray.700" fontSize={{ base: 12, lg: 14 }} bold>
                      Qtd Simulação
                    </Text>
                  </HStack>
                  <HStack alignItems="center" flex={{ base: 1, lg: undefined }}>
                    <Center
                      bg="secondary.500"
                      borderRadius="full"
                      size={{ base: 3, lg: 4 }}
                      mr="6px"
                    />
                    <Text color="gray.700" fontSize={{ base: 12, lg: 14 }} bold>
                      Qtd Proposta
                    </Text>
                  </HStack>
                  <HStack alignItems="center" flex={{ base: 1, lg: undefined }}>
                    <Center
                      bg="secondary.300"
                      borderRadius="full"
                      size={{ base: 3, lg: 4 }}
                      mr="6px"
                    />
                    <Text color="gray.700" fontSize={{ base: 12, lg: 14 }} bold>
                      Qtd Aprovado
                    </Text>
                  </HStack>
                </HStack>

                <HStack flex={1} space={3}>
                  <HStack alignItems="center" flex={1}>
                    <Center
                      bg="secondary.100"
                      borderRadius="full"
                      size={{ base: 3, lg: 4 }}
                      mr="6px"
                    />
                    <Text color="gray.700" fontSize={{ base: 12, lg: 14 }} bold>
                      Qtd Efetivado
                    </Text>
                  </HStack>

                  <HStack flex={1} />
                </HStack>
              </VStack>
            ) : (
              <></>
            )}

            {proposalFunnelData?.processed_at && (
              <Text color="gray.200" fontSize={{ base: '10px', lg: '14px' }} fontWeight="semibold">
                Data de Ref.{' '}
                {formatDateStringToMask(
                  proposalFunnelData?.processed_at,
                  proposalFunnelData?.is_short_format ? 'MM/yyyy' : 'dd/MM/yyyy'
                )}
              </Text>
            )}
          </VStack>
        </>
      )}
    </VStack>
  )
}
