import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { G, Path, Defs, Rect, ClipPath } from 'react-native-svg'

export const CampaignIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 21 20', ...props }}>
    <Path
      d="M19.7425 1.18928C19.4658 1.00011 19.1465 0.882579 18.8131 0.847231C18.4798 0.811883 18.1429 0.859821 17.8327 0.986746L8.6519 5.30472H4.8038C3.66236 5.30472 2.56767 5.75815 1.76055 6.56527C0.953434 7.37239 0.5 8.46708 0.5 9.60852C0.5 10.75 0.953434 11.8446 1.76055 12.6518C2.56767 13.4589 3.66236 13.9123 4.8038 13.9123H7.18759C6.28025 15.8627 5.07721 19.0951 6.81899 19.5832C8.52025 20.049 10.6792 16.8774 11.7324 15.1093L18.0109 17.6267C18.3087 17.722 18.6246 17.7462 18.9334 17.6973C19.2423 17.6484 19.5353 17.5278 19.7891 17.3452C20.0075 17.1901 20.1859 16.9852 20.3095 16.7475C20.4331 16.5098 20.4984 16.2461 20.5 15.9781V2.58067C20.4973 2.30446 20.4268 2.03314 20.2947 1.79054C20.1627 1.54793 19.973 1.34147 19.7425 1.18928ZM7.07013 10.8743C7.07039 10.9409 7.05748 11.0069 7.03213 11.0684C7.00678 11.13 6.96949 11.1859 6.92242 11.233C6.87535 11.28 6.81943 11.3173 6.75788 11.3427C6.69633 11.368 6.63036 11.3809 6.5638 11.3807H4.79367C4.32367 11.3807 3.87291 11.194 3.54057 10.8616C3.20823 10.5293 3.02152 10.0785 3.02152 9.60852C3.02152 9.13851 3.20823 8.68776 3.54057 8.35542C3.87291 8.02307 4.32367 7.83637 4.79367 7.83637H6.5638C6.63036 7.8361 6.69633 7.84901 6.75788 7.87436C6.81943 7.89971 6.87535 7.937 6.92242 7.98407C6.96949 8.03114 7.00678 8.08706 7.03213 8.14861C7.05748 8.21017 7.07039 8.27613 7.07013 8.34269V10.8743Z"
      fill="#F3FAFB"
    />

    <G clipPath="url(#clip0_3568_5119)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4899 1.66668C18.487 1.66658 18.4841 1.66716 18.4815 1.66837C18.4665 1.67527 18.4513 1.68172 18.4359 1.68772L7.16667 6.07889V10.909L18.4413 14.6748C18.4568 14.68 18.4721 14.6856 18.4872 14.6917C18.4893 14.6925 18.4915 14.6928 18.4937 14.6927C18.4959 14.6925 18.4981 14.6919 18.5 14.6907V1.67005C18.4994 1.66966 18.4989 1.6693 18.4983 1.66897C18.4957 1.66756 18.4929 1.66678 18.4899 1.66668ZM17.8116 0.142254C18.0435 0.0404261 18.2954 -0.0078927 18.5487 0.00104904C18.8117 0.0103315 19.0689 0.0810628 19.2996 0.207579C19.5303 0.334096 19.7282 0.512883 19.8774 0.729618C20.0266 0.946352 20.123 1.19502 20.1589 1.45571C20.1641 1.49333 20.1667 1.53127 20.1667 1.56925V14.7892C20.1667 14.8258 20.1643 14.8623 20.1595 14.8985C20.1259 15.1525 20.0347 15.3954 19.8929 15.6087C19.7512 15.822 19.5625 16.0001 19.3414 16.1294C19.1203 16.2587 18.8725 16.3358 18.6171 16.3548C18.3711 16.373 18.1243 16.3369 17.8941 16.2492L6.06933 12.2997C5.72929 12.1861 5.5 11.8678 5.5 11.5092V5.50925C5.5 5.16577 5.71074 4.85749 6.03077 4.73278L17.8116 0.142254Z"
        fill="#5AB8CA"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.62276 5.79866C2.34165 5.07977 3.31667 4.6759 4.33333 4.6759H6.33333C6.79357 4.6759 7.16667 5.049 7.16667 5.50924V11.5092C7.16667 11.9695 6.79357 12.3426 6.33333 12.3426H4.33333C3.31667 12.3426 2.34165 11.9387 1.62276 11.2198C0.903868 10.5009 0.5 9.5259 0.5 8.50924C0.5 7.49257 0.903868 6.51755 1.62276 5.79866ZM4.33333 6.34257C3.7587 6.34257 3.2076 6.57084 2.80127 6.97717C2.39494 7.3835 2.16667 7.9346 2.16667 8.50924C2.16667 9.08387 2.39494 9.63497 2.80127 10.0413C3.2076 10.4476 3.7587 10.6759 4.33333 10.6759H5.5V6.34257H4.33333Z"
        fill="#5AB8CA"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33333 10.6759C6.79357 10.6759 7.16667 11.049 7.16667 11.5092V15.5092C7.16667 16.0839 7.39494 16.635 7.80127 17.0413C8.2076 17.4476 8.7587 17.6759 9.33333 17.6759C9.79357 17.6759 10.1667 18.049 10.1667 18.5092C10.1667 18.9695 9.79357 19.3426 9.33333 19.3426C8.31667 19.3426 7.34165 18.9387 6.62276 18.2198C5.90387 17.5009 5.5 16.5259 5.5 15.5092V11.5092C5.5 11.049 5.8731 10.6759 6.33333 10.6759Z"
        fill="#5AB8CA"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_3568_5119">
        <Rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </ClipPath>
    </Defs>
  </Icon>
)
