import { FunctionComponent, useMemo, useState } from 'react'

import { FunnelDataTypeMonths } from 'integration/resources/walletResume'
import { Box, Pressable, useBreakpointValue, useTheme } from 'native-base'
import { Dimensions } from 'react-native'
import { HomeChartsTypes } from 'src/screens/HomeScreen/constants'
import { getNumberYearAndMonth, calculateMonthValueStack } from 'src/utils'
import { currencyFormatChart } from 'src/utils/currencyFormat'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryStack, VictoryTheme } from 'victory-native'

import { StackBarChartTooltip } from './StackBarChartTooltip'

export type ChartData = {
  value: number
  year_month: string
}[]

export type StackBarChartProps = {
  colors: string[]
  chartsData: ChartData[]
  chartType: HomeChartsTypes
  tooltips: {
    titles: string[]
    values: FunnelDataTypeMonths[]
  }
  showTooltip?: boolean
  onClickChart?(monthYear: string): void
  type?: 'percentage' | 'currency' | 'qtd'
  horizontalLabels?: boolean
}

export const StackBarChart: FunctionComponent<StackBarChartProps> = ({
  chartsData,
  chartType,
  colors,
  onClickChart,
  type,
  tooltips,
  showTooltip,
  horizontalLabels = false
}) => {
  const maxIndex = tooltips.values.length

  const theme = useTheme()

  // we use this constant to remedy the limitations of the charts library about responsiveness
  const phoneWidth = Dimensions.get('window').width
  //

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const needsLabel = {
    yes: true,
    no: false
  }

  const tickValues = useMemo(
    () =>
      chartsData
        .map((chartData) => chartData.map((item) => item.year_month))
        .flat()
        .filter((item, index, total) => item !== total[index - 1]),
    [chartsData]
  )

  const tickValuesData = calculateMonthValueStack(chartsData)

  const dataLength = chartsData[0].length

  const [openTooltip, setOpenTooltip] = useState(0)

  const higherValue = useMemo(() => {
    return chartsData
      .map((chartData) => chartData.reduce((a, b) => (a.value > b.value ? a : b)).value)
      .reduce((a, b) => (a > b ? a : b))
  }, [chartsData])

  const lowerValue = useMemo(() => {
    return chartsData
      .map((chartData) => chartData.reduce((a, b) => (a.value < b.value ? a : b)).value)
      .reduce((a, b) => (a < b ? a : b))
  }, [chartsData])

  const tickValuesAxis =
    type === 'percentage'
      ? [0, 25, 50, 75, 100]
      : type === 'currency'
      ? lowerValue < 0
        ? [lowerValue, lowerValue * 0.5, 0, higherValue * 0.5, higherValue]
        : [0, higherValue * 0.25, higherValue * 0.5, higherValue * 0.75, higherValue]
      : [0, higherValue]

  return (
    <>
      <Box mt={isMobile ? -12 : 0} ml={isMobile ? -9 : 0} flex={1}>
        <VictoryChart
          theme={VictoryTheme.material}
          height={isMobile ? 205 : 330}
          width={isMobile ? phoneWidth : phoneWidth * 0.9}
          domain={{ x: [1, 6] }}
          domainPadding={{ x: isMobile ? 18 : 34 }}>
          <VictoryAxis
            tickValues={tickValues}
            tickFormat={(t) => String(t).split('-')[0]}
            orientation="bottom"
            style={{
              tickLabels: {
                fontSize: isMobile ? 14 : 18,
                padding: 10,
                fill: theme.colors.gray[500]
              },
              ticks: { size: 0 },
              axis: { stroke: 0 },
              grid: { stroke: 0 }
            }}
            standalone={false}
          />

          {horizontalLabels && (
            <VictoryAxis
              dependentAxis
              tickValues={tickValuesAxis}
              tickFormat={(t) =>
                t && type === 'currency'
                  ? currencyFormatChart(String(t), needsLabel.yes, needsLabel.no)
                  : type === 'percentage'
                  ? `${parseInt(t, 10)}%`
                  : parseInt(t, 10)
              }
              style={{
                tickLabels: {
                  fontSize: isMobile ? 14 : 16,
                  fill: theme.colors.gray[400],
                  fontWeight: 600
                },
                ticks: { size: 0 },
                axis: { stroke: 0 },
                grid: {
                  stroke: theme.colors.gray[100],
                  strokeWidth: 1
                }
              }}
              standalone={false}
            />
          )}

          <VictoryStack
            colorScale={colors}
            domain={{ x: [1, dataLength] }}
            labels={tickValuesData.map((d) => {
              return type === 'currency'
                ? currencyFormatChart(String(d), needsLabel.yes, needsLabel.no)
                : type === 'percentage'
                ? `${d.toFixed(2)}%`
                : `${d}`
            })}>
            {chartsData?.length > 0 &&
              chartsData.map((chart, index, size) => (
                <VictoryBar
                  key={String(Math.random())}
                  data={chart}
                  x="year_month"
                  y="value"
                  name={colors[index]}
                  barWidth={isMobile ? 28 : 38}
                  cornerRadius={{
                    top: ({ datum }) => {
                      return datum._stack === size.length ? 6 : 0
                    },
                    bottom: ({ datum }) => {
                      return datum._stack === 1 ? 6 : 0
                    }
                  }}
                  style={{
                    //@ts-ignore
                    labels: {
                      fontSize: isMobile ? 14 : 0,
                      fontWeight: 700,
                      lineHeight: 16,
                      //@ts-ignore
                      fill: theme.colors.gray[700]
                    }
                  }}
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onPressOut: () => [
                          {
                            target: 'data',

                            mutation: (props) =>
                              onClickChart?.(
                                getNumberYearAndMonth(props.datum.year_month).monthNumber
                              )
                          }
                        ]
                      }
                    }
                  ]}
                />
              ))}
          </VictoryStack>
        </VictoryChart>
      </Box>

      {showTooltip ? (
        new Array(6).fill(0).map((_, index) => {
          const fakeIndex = index > maxIndex ? 0 : index

          const widthPercentage = 100 / 6

          const ranges = Object.keys(tooltips.values?.[fakeIndex] ?? {}).map(
            (key) => tooltips.values[fakeIndex][key as keyof FunnelDataTypeMonths]
          )

          if (index <= maxIndex) {
            return (
              <Pressable
                position="absolute"
                top={0}
                left={index > 0 ? `${widthPercentage * index}%` : `0%`}
                width={`${widthPercentage}%`}
                height="100%"
                onPress={() => setOpenTooltip(index + 1)}>
                <Box flex={1} position="relative">
                  <Box
                    borderRadius={32}
                    position="absolute"
                    height={1}
                    bottom={isMobile ? `${90}%` : `${50}%`}
                    left={
                      dataLength < 6
                        ? dataLength < 4
                          ? dataLength < 3
                            ? dataLength < 2
                              ? '46%'
                              : '43%'
                            : '40%'
                          : '34%'
                        : '25%'
                    }>
                    <StackBarChartTooltip
                      isVisible={openTooltip === index + 1}
                      handleOnClose={() => setOpenTooltip(0)}
                      values={ranges}
                      titles={tooltips.titles}
                      type={chartType}
                    />
                  </Box>
                </Box>
              </Pressable>
            )
          }

          return <></>
        })
      ) : (
        <></>
      )}
    </>
  )
}
