import { useCallback } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { CreateAccountPayload } from 'integration/resources/createAccount'
import { useBreakpointValue } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import { Country, getCallingCode } from 'react-native-country-picker-modal'
import { validatePhone } from 'src/utils'
import * as Yup from 'yup'

import { UseCreateAccountPhoneEmailScreen } from './PhoneEmailScreen.types'

type FormData = Pick<CreateAccountPayload, 'phone_number_cell' | 'email' | 'cpf'> & {
  country?: Country
}

const schema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Digite seu e-mail'),
  phone_number_cell: Yup.string()
    .test('valid_phone', 'Telefone inválido', (value) => validatePhone(value ?? '', 'BR'))
    .required('Digite seu telefone')
})

export const useCreateAccountPhoneEmailScreen: UseCreateAccountPhoneEmailScreen = ({
  navigation,
  route
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  // const hasErrorInFormFields = 'email' in errors || 'phone_number_cell' in errors

  const onChangeCountry = (value: Country) => setValue('country', value)

  const onSubmit = handleSubmit(
    useCallback<SubmitHandler<FormData>>(
      async (data) => {
        Keyboard.dismiss()

        const codeCountry = await getCallingCode(!data.country ? 'BR' : data.country.cca2)

        const dataForm = {
          email: data.email,
          phone_number_cell: `${codeCountry}${data.phone_number_cell.replace(/-|\s/g, '')}`,
          cpf: route.params.cpf
        }

        navigation.navigate('CreateAccountCreatePassword', dataForm)
      },
      [navigation, route.params]
    )
  )

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  return { control, onSubmit, isMobile, onChangeCountry, handleGoBack, isValid }
}
