import { useCallback, useEffect, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { parseISO } from 'date-fns'
import client from 'integration/client'
import { Store } from 'integration/resources/store'
import { DIRECTORY_TYPE, EXTENSION_TYPE, useUploadMutation } from 'integration/resources/upload'
import {
  Contact,
  useDeleteContactMutation,
  useEditContactMutation
} from 'integration/resources/wallet'
import { BottomSheetModal } from 'molecules'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { TOption } from 'organisms/Layouts/InternalLayout'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard, Platform } from 'react-native'
import fieldsValidation from 'src/constants/fields-validation'
import { useCamera } from 'src/hooks/useCamera'
import { useAuthAtomValue } from 'src/store/auth'
import { isBase64File } from 'src/utils'
import * as Yup from 'yup'

import { UseContactEditScreen } from './ContactEditScreen.types'

const schema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z-ÖÙ-öù ]*$/, 'Por favor, digite um nome válido')
    .max(80, 'Nome deve ter no máximo 80 caracteres')
    .required(fieldsValidation.common.required),
  jobTitle: Yup.string().required(fieldsValidation.common.required),
  email: Yup.string()
    .required(fieldsValidation.common.required)
    .email('Informe um email válido')
    .max(80, 'Email deve ter no máximo 80 caracteres'),
  address_street: Yup.string(),
  birth_date: Yup.string().nullable(),
  tab_number: Yup.string().required(fieldsValidation.common.required),
  phone_number: Yup.string().required(fieldsValidation.common.required),
  store_name: Yup.string().required(fieldsValidation.common.required)
})

export const useContactEditScreen: UseContactEditScreen = ({
  navigation,
  route,
  onSuccessSubmit
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const authAtom = useAuthAtomValue()

  const deleteSheetRef = useRef<BottomSheetModal>(null)

  const isOwner = authAtom?.user.access_level === 1

  const openDeleteSheet = useCallback(() => {
    deleteSheetRef.current?.present()
  }, [])

  const closeDeleteSheet = useCallback(() => {
    deleteSheetRef.current?.close()

    deleteSheetRef.current?.dismiss()
  }, [])

  const closeRightSheet = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const { contact } = route?.params

  const deleteContactMutation = useDeleteContactMutation()

  const [openAddedJob, setOpenAddedJob] = useState(false)

  const bottomSheetContext = useBottomSheetContext()

  const { isLoading, mutate } = useEditContactMutation(contact.id)

  const handleDeleteContactMutation = () =>
    deleteContactMutation
      .mutateAsync(
        {
          id: contact.id
        },
        {
          onError: () => {
            bottomSheetContext.open({
              description: 'Não foi possível deletar o contato, tente novamente',
              title: 'Erro ao tentar deletar contato'
            })

            closeDeleteSheet()
          }
        }
      )
      .then(() => {
        closeDeleteSheet()

        if (isMobile) {
          navigation.navigate('Tab', {
            screen: 'Wallet',
            params: {
              tabId: 1
            }
          })

          return
        }

        toast.show({
          render: () => <Toast type="success" text="Contato excluído com sucesso " />,
          duration: 3000
        })

        navigation.navigate('WalletContactList', {})
      })
      .catch(() => {
        toast.show({
          render: () => <Toast type="error" text="Não foi possível completar a ação" />,
          duration: 3000
        })
      })

  const { control, handleSubmit, setValue, watch, getValues } = useForm<Contact>({
    resolver: yupResolver(schema),
    defaultValues: {
      is_favorite: false
    }
  })

  const nameContact = watch('name')

  const toast = useToast()

  const { mutateAsync: uploadMutationAsync, isLoading: isUploadingMutationLoading } =
    useUploadMutation()

  const handleGoToWalletContact = useCallback(() => {
    if (contact?.id) navigation.navigate('WalletContact', { contactId: contact.id })
  }, [navigation, contact?.id])

  const submit = handleSubmit(
    useCallback<SubmitHandler<Contact>>(
      async (formData) => {
        Keyboard.dismiss()

        const { birth_date, phone_number, jobTitle, tab_number = '0' } = formData

        const dateFormatted = birth_date ? new Date(birth_date) : ''

        const cleanPhone = phone_number?.replace(/\D/g, '')

        const formattedFields = {
          ...formData,
          birth_date: dateFormatted,
          phone_number_ddd: cleanPhone?.substring(0, 2),
          phone_number: cleanPhone?.substring(2),
          job_title: jobTitle?.toUpperCase(),
          store_tab_number: parseInt(tab_number, 10)
        } as Contact

        if (
          formData?.profile_image_url &&
          (formData?.profile_image_url.includes('file://') ||
            isBase64File(formData?.profile_image_url ?? ''))
        ) {
          const response = await fetch(formData.profile_image_url)

          const file = await response.blob()

          if (file.type && file.type.split('/').length > 0) {
            const type = file.type.split('/')[1]

            const files = await uploadMutationAsync({
              directory_type: DIRECTORY_TYPE.CONTACT_COVER_IMAGE,
              extension_type: type.toUpperCase() as keyof typeof EXTENSION_TYPE,
              files: [file]
            })

            if (files.length) {
              formattedFields.profile_image_file_name = files[0].name

              formattedFields.profile_image_url = undefined
            }
          }
        }

        if (formData?.profile_image_url === undefined) {
          formattedFields.profile_image_url = null

          formattedFields.profile_image_file_name = null
        }

        mutate(formattedFields, {
          onError: () => {
            bottomSheetContext.open({
              description: 'Não foi possível editar o contato, tente novamente',
              title: 'Erro ao tentar salvar dados do usuário'
            })
          },
          onSuccess: () => {
            if (onSuccessSubmit) return onSuccessSubmit()

            toast.show({
              render: () => <Toast type="success" text="Informações salvas com sucesso" />
            })

            handleGoToWalletContact()
          }
        })
      },
      [
        bottomSheetContext,
        handleGoToWalletContact,
        mutate,
        toast,
        uploadMutationAsync,
        onSuccessSubmit
      ]
    )
  )

  const handleSetOpenAddedJob = () => setOpenAddedJob(!openAddedJob)

  const handleAddedJob = () => handleSetOpenAddedJob()

  const handleAddedTabNumber = async ({ tab_number, name_fantasy }: Store) => {
    const { data } = await client.get(`v1/stores/${tab_number}`)

    // eslint-disable-next-line @babel/no-unused-expressions
    data?.data?.address_street
      ? setValue(
          'address_street',
          `${data?.data?.address_street} - ${data?.data?.address_neighborhood} - ${data?.data?.address_city} - ${data?.data?.address_uf}`
        )
      : setValue('address_street', '')

    setValue('store_name', name_fantasy)

    setValue('tab_number', tab_number ?? '')
  }

  const [showDate, setShowDate] = useState(Platform.OS !== 'android')

  const [date, setDate] = useState(
    contact?.birth_date ? parseISO(contact.birth_date.replace('Z', '')) : new Date()
  )

  const handleAddedBirthDate = (_: any, selectedDate: any) => {
    const currentDate = selectedDate

    if (Platform.OS !== 'android') setShowDate(true)

    setValue('birth_date', currentDate)

    setDate(currentDate)
  }

  const showMode = (currentMode: any) => {
    if (Platform.OS === 'android') {
      setShowDate(false)

      DateTimePickerAndroid.open({
        value: new Date(),
        onChange: handleAddedBirthDate,
        mode: currentMode || 'date',
        is24Hour: true
      })

      return
    }

    setShowDate(true)
  }

  const showDatePicker = () => {
    showMode('date')
  }

  useEffect(() => {
    if (contact) Object.entries(contact).forEach(([name, value]: any) => setValue(name, value))

    if (contact?.phone_number) {
      setValue('phone_number', `${contact?.phone_number_ddd}${contact?.phone_number}`)
    }

    if (contact?.address_street) {
      setValue(
        'address_street',
        `${contact?.address_street} - ${contact?.address_neighborhood} - ${contact?.address_city} - ${contact?.address_uf}`
      )
    }

    if (typeof contact === 'string') navigation.navigate('WalletContactList', {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setValue,
    contact.id,
    contact?.phone_number,
    contact?.address_street,
    contact?.address_neighborhood
  ])

  const { handleChooseImage, handleTakePicture } = useCamera()

  const [showOptions, setShowOptions] = useState(false)

  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)

  const handleShowOptionsCamera = () => setShowOptions(true)

  const handleCloseOptionsCamera = () => setShowOptions(false)

  const handleSelectPicture = useCallback(async () => {
    setShowOptions(false)

    await handleChooseImage((photoSelected) => {
      setValue('profile_image_url', photoSelected)
    })
  }, [handleChooseImage, setValue])

  const handelTakePicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    await handleTakePicture((photoSelected) => {
      setValue('profile_image_url', photoSelected)

      setTimeout(() => {
        setShowOptions(false)

        setIsLoadingPhoto(false)
      }, 300)
    })
  }, [handleTakePicture, setValue])

  const handleRemovePicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    setValue('profile_image_url', null)

    setValue('profile_image_file_name', null)

    setTimeout(() => {
      setShowOptions(false)

      setIsLoadingPhoto(false)
    }, 300)
  }, [setValue])

  const moreOptions: TOption[] = [
    {
      type: 'delete',
      title: 'Excluir',
      onPress: () => {
        openDeleteSheet()
      }
    }
  ]

  const setRenderDeleteModal = (value: boolean) => (value ? openDeleteSheet() : closeDeleteSheet())

  return {
    isMobile,
    isLoading: isLoading || isUploadingMutationLoading,
    control,
    submit,
    handleAddedJob,
    openAddedJob,
    handleSetOpenAddedJob,
    handleAddedBirthDate,
    showDatePicker,
    showDate,
    date,
    isLoadingPhoto,
    handleAddedTabNumber,
    getValues,
    nameContact,
    isOwner,
    handleShowOptionsCamera,
    handelTakePicture,
    handleRemovePicture,
    handleCloseOptionsCamera,
    handleSelectPicture,
    setRenderDeleteModal,
    deleteSheetRef,
    handleDeleteContactMutation,
    moreOptions,
    deleteContactLoading: deleteContactMutation.isLoading,
    //camera,
    closeRightSheet,
    showOptions
  }
}
