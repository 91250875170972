import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContactCreateScreenComponent } from './ContactCreateScreen.types'
import { useContactCreateScreen } from './useContactCreateScreen'

const ContactCreateScreenDesktop = lazy(() => import('./ContactCreateScreen.desktop'))

const ContactCreateScreenMobile = lazy(() => import('./ContactCreateScreen.mobile'))

export const ContactCreateScreen: ContactCreateScreenComponent = ({ navigation, route }) => {
  const contactCreateScreen = useContactCreateScreen({ navigation, route })

  const Screen = contactCreateScreen.isMobile
    ? ContactCreateScreenMobile
    : ContactCreateScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...contactCreateScreen} />
    </Suspense>
  )
}
