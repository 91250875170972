import { AxiosError, AxiosResponse } from 'axios'
import { ResponseError, ResponseSuccess } from 'integration/types'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { AnalyticsResponse, GetAnalyticsParams, TAvailableReport } from './types'

const KEY_GET_ANALYTICS = 'getAnalytics'

const KEY_GET_AVAILABLE_REPORTS = 'getAvailableReports'

export const useGetAnalyticsQuery = (params?: GetAnalyticsParams) =>
  useQuery<AxiosResponse<AnalyticsResponse>, AxiosError<ResponseError>>(
    `${KEY_GET_ANALYTICS}.${JSON.stringify(params)}`,
    () => requests.getAnalytics({ ...params }),
    {
      enabled: false,
      retry: false
    }
  )

export const useGetAvailableReportsQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<TAvailableReport[]>>, AxiosError<ResponseError>>(
    KEY_GET_AVAILABLE_REPORTS,
    requests.getAvailableReports
  )
