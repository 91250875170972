import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import {
  EvaluationItem,
  KEY_OPPORTUNITY_LIST_QUERY,
  OpportunitiesResponse,
  OpportunitySerialized,
  useGetOpportunityListInfiniteQuery,
  useSetOpportunityRatesMutation,
  useSetOpportunityHighlightedMutation,
  useSetOpportunityUnHighlightedMutation
} from 'integration/resources/opportunities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'

import { UseListOpportunities } from './types'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0,
  highlights: {
    total_opportunities: 0,
    items: []
  }
}

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetOpportunityListInfiniteQuery>['data']
) =>
  data?.pages.reduce<OpportunitiesResponse>(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...currentValue.data.data,
      items: [...previousValue.items, ...currentValue.data.data.items]
    }),
    defaultReturn
  )

export const useListOpportunities: UseListOpportunities = ({ search }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchHook
  } = useGetOpportunityListInfiniteQuery({
    per_page: 10,
    filter_by: 1,
    expand_details: isDesktop && Platform.OS === 'web',
    search
  })

  const { mutateAsync: setOpportunityHighlighted, isLoading: highlightedIsLoading } =
    useSetOpportunityHighlightedMutation()

  const { mutateAsync: setOpportunityUnHighlighted, isLoading: unhighlightedIsLoading } =
    useSetOpportunityUnHighlightedMutation()

  const fetchMore = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const list = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  const toast = useToast()

  const [refetchLoading, setRefetchLoading] = useState(false)

  const queryClient = useQueryClient()

  const refetch = useCallback(() => {
    setRefetchLoading(true)

    setTimeout(() => {
      setRefetchLoading(false)
    }, 300)

    queryClient.invalidateQueries(KEY_OPPORTUNITY_LIST_QUERY)
  }, [queryClient])

  const evaluationSuccessSheetRef = useRef<BottomSheetModal>(null)

  const openEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.present()
  }, [])

  const closeEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.close()

    evaluationSuccessSheetRef.current?.dismiss()
  }, [])

  const [isOpenEvaluation, setIsOpenEvaluantion] = useState(false)

  const [opportunityEvaluation, setOpportunityEvaluation] = useState<OpportunitySerialized>()

  const setOpportunityRatesMutation = useSetOpportunityRatesMutation()

  const handleEvaluate = useCallback(
    (evaluantion: EvaluationItem) => {
      opportunityEvaluation?.id &&
        setOpportunityRatesMutation
          .mutateAsync(
            {
              ...evaluantion,
              opportunity_id: opportunityEvaluation.id
            },
            {
              onError: () => {
                toast.show({
                  render: () => (
                    <Toast type="error" text="Não foi possível concluir oportunidade" />
                  ),
                  duration: 3000
                })
              },
              onSuccess: () => {
                openEvaluationSuccessSheet()

                refetch()
              }
            }
          )
          .finally(() => setIsOpenEvaluantion(false))
    },
    [
      openEvaluationSuccessSheet,
      opportunityEvaluation?.id,
      refetch,
      setOpportunityRatesMutation,
      toast
    ]
  )

  const handleUpdateOpportunityHighlighted = (id: string, highlighted: boolean) => {
    id &&
      (highlighted ? setOpportunityUnHighlighted : setOpportunityHighlighted)(
        {
          id
        },
        {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Tente novamente mais tarde" />,
              duration: 3000
            })
          },
          onSuccess: () => {
            refetch()
          }
        }
      )
  }

  useEffect(() => {
    refetchHook()
  }, [search, refetchHook])

  return {
    list,
    refetch,
    isLoading,
    isFetchingNextPage,
    refreshing: refetchLoading || highlightedIsLoading || unhighlightedIsLoading,
    fetchMore,
    closeEvaluationSuccessSheet,
    openEvaluationSuccessSheet,
    isOpenEvaluation,
    isLoadingEvaluantion: setOpportunityRatesMutation.isLoading,
    setIsOpenEvaluantion,
    evaluationSuccessSheetRef,
    handleEvaluate,
    setOpportunityEvaluation,
    handleUpdateOpportunityHighlighted
  }
}
