import { useCallback, useMemo, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { parseISO, differenceInDays } from 'date-fns'
import {
  KEY_NOTIFICATIONS_QUERY,
  useGetAllNotificationsInfinityQuery,
  usePathMarkUnreadNotificationMutation,
  usePathMarkReadNotificationMutation,
  useGetNotReadNotificationsQuery,
  TNotificationType
} from 'integration/resources/notifications'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { LogEvent } from 'src/utils/logEvents'

import {
  TNotification,
  TParamsNotificationDetail,
  UseNotificationScreen
} from './NotificationScreen.types'

export const useNotificationScreen: UseNotificationScreen = ({ navigation }) => {
  const linkTo = useLinkTo()

  const getAllNotifications = useGetAllNotificationsInfinityQuery({})

  const mutationMarkUnread = usePathMarkUnreadNotificationMutation()

  const mutationMarkRead = usePathMarkReadNotificationMutation()

  const getNotReadNotifications = useGetNotReadNotificationsQuery()

  const queryClient = useQueryClient()

  const data = useMemo(
    () =>
      getAllNotifications.data?.pages.reduce<TNotification[]>((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.data.data] as TNotification[]
      }, []),
    [getAllNotifications.data?.pages]
  )

  const notificationSections: { title: string; data: TNotification[] }[] = [
    {
      title: 'Hoje',
      data:
        data?.filter((item) => {
          const objectDate = new Date(item.created_at)

          const today = new Date()

          return differenceInDays(today, objectDate) === 0
        }) ?? []
    },
    {
      title: 'Anteriores',
      data:
        data?.filter((item) => {
          const objectDate = parseISO(item.created_at)

          const today = new Date()

          return differenceInDays(today, objectDate) > 0
        }) ?? []
    }
  ]

  const empty = !data?.length

  const onEndReached = useCallback(() => {
    if (!getAllNotifications.isFetchingNextPage && getAllNotifications.hasNextPage) {
      getAllNotifications.fetchNextPage()
    }
  }, [getAllNotifications])

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoToNotificationDetail = (notification: TNotification) => {
    let params: TParamsNotificationDetail = { id: notification.id }

    LogEvent(`notificacao`, {
      item_id: notification.id
    })

    if (notification.extras) {
      params = { ...params, extras: notification.extras }
    }

    navigation.navigate('NotificationDetail', params)
  }

  const onRefresh = () => queryClient.invalidateQueries(KEY_NOTIFICATIONS_QUERY)

  const handleMarkNotificationUnread = (id: string) => mutationMarkUnread.mutateAsync({ id })

  const [notificationLoading, setNotificationLoading] = useState<string>()

  const [renderListLoading, setRenderListLoading] = useState(false)

  const handleReadNotification = (id: string, isRead: boolean) => {
    setRenderListLoading(true)

    setNotificationLoading(id)

    if (isRead) mutationMarkUnread.mutateAsync({ id })
    else mutationMarkRead.mutateAsync({ id })

    const timeoutSpinner = setTimeout(() => {
      setRenderListLoading(false)
    }, 3000)

    return () => {
      clearTimeout(timeoutSpinner)
    }
  }

  const handleOpenRightSheet = (id: string) => {
    LogEvent(`notificacao`, {
      item_id: id
    })

    navigation.navigate('NotificationDetail', { id })
  }

  const notificationsCount = useMemo(
    () => getNotReadNotifications?.data?.data?.data,
    [getNotReadNotifications]
  )

  const notificationsTotal = useMemo(() => {
    const notificationsValue = Object.entries(notificationsCount ?? {})
      .filter(([key]) => key !== 'not_read_count')
      .reduce((total, [, valor]) => total + valor, 0)

    return notificationsValue
  }, [notificationsCount])

  const selectTypeNotification = (
    type: TNotificationType = 'WALLET',
    notificationItem: TNotification
  ) => {
    const object = {
      WALLET: {
        title: 'Carteira',
        titleButton: 'Ver carteira',
        handleAction: () => navigation.navigate('WalletTabList', {})
      },
      CONTENT: {
        title: 'Conteúdos',
        titleButton: 'Ver conteúdo',
        handleAction: () => {
          if (notificationItem?.extras?.contentId)
            navigation.navigate('Content', { id: notificationItem?.extras?.contentId })
        }
      },
      ORGANIZATION: {
        title: 'Minha organização',
        titleButton: 'Ver atividades',
        handleAction: () => linkTo('/minha-organizacao/atividades')
      },
      OPPORTUNITY: {
        title: 'Minha organização',
        titleButton: notificationItem?.extras?.contentId ? 'Ver oportunidade' : 'Ver oportunidades',
        handleAction: () => {
          if (notificationItem?.extras?.contentId) {
            navigation.navigate('OrganizationOpportunity', {
              id: notificationItem?.extras.contentId
            })

            return
          }

          linkTo('/minha-organizacao/oportunidades')
        }
      },
      ACTIVITY: {
        title: 'Minha organização',
        titleButton: notificationItem?.extras?.contentId ? 'Ver atividade' : 'Ver atividades',
        handleAction: () => {
          if (notificationItem?.extras?.contentId) {
            navigation.navigate('OrganizationActivity', {
              id: notificationItem?.extras.contentId
            })

            return
          }

          linkTo('/minha-organizacao/atividades')
        }
      },
      COMMUNICATION: {
        title: 'Comunicado institucional',
        titleButton: 'Ver comunicado',
        handleAction: () => {
          return navigation.navigate('NotificationDetail', { id: notificationItem.id })
        }
      },
      BIRTHDATE: {
        title: 'Aniversários',
        titleButton: 'Ver contato',
        //@ts-ignore
        handleAction: () => {
          if (notificationItem?.extras?.contactId) {
            navigation.navigate('WalletContact', { contactId: notificationItem?.extras?.contactId })
          }
        }
      },
      SURVEY: {
        title: 'Pesquisas',
        titleButton: 'Ver pesquisa',
        handleAction: () => {
          if (notificationItem?.extras?.surveyId)
            navigation.navigate('SurveyDetail', { id: notificationItem?.extras?.surveyId })
        }
      },
      DEFAULT: {
        title: 'Notificação não configurada',
        titleButton: 'Ver detalhes',
        handleAction: () => handleOpenRightSheet(notificationItem.id)
      }
    }

    return type in object ? object[type] : object['DEFAULT']
  }

  return {
    isMobile,
    handleGoToNotificationDetail,
    navigation,
    notificationSections,
    onEndReached,
    isLoading: getAllNotifications.isLoading,
    MarkReadUnreadIsLoading: mutationMarkUnread.isLoading || mutationMarkRead.isLoading,
    onRefresh,
    handleReadNotification,
    notificationLoading,
    empty,
    handleOpenRightSheet,
    handleMarkNotificationUnread,
    notificationsCount,
    notificationsTotal,
    renderListLoading,
    selectTypeNotification
  }
}
