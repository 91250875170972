import { LinkingOptions } from '@react-navigation/native'
import * as Linking from 'expo-linking'

import { RootStackParamList } from './types'

export const linking: LinkingOptions<RootStackParamList> = {
  config: {
    initialRouteName: 'Entry',
    screens: {
      Entry: {
        path: '/'
      },

      CreateAccountCPF: {
        path: '/cadastro/cpf'
      },
      CreateAccountPhoneEmail: {
        path: 'cadastro/email'
      },
      Login: {
        path: '/login'
      },
      LoginAuthCode: {
        path: '/autenticacao'
      },
      LoginAccountReview: {
        path: '/login/analise'
      },
      LoginBlocked: {
        path: '/login/conta-bloqueada'
      },
      AcceptTerms: {
        path: '/termos-de-aceite'
      },
      Onboarding: {
        path: '/bem-vindo'
      },
      CreateAccountConfirmEmail: {
        path: '/cadastro/email/confirmacao/processando'
      },
      CreateAccountCreatePassword: {
        path: '/cadastro/senha'
      },
      CreateAccountAuthCode: {
        path: '/cadastro/codigo'
      },
      CreateAccountChangeEmail: {
        path: '/cadastr/alterar-email'
      },
      CreateAccountSendEmailConfirmation: {
        path: '/cadastro/email/confirmacao/enviado'
      },
      ContentByCategory: {
        path: '/conteudos/categoria/:category_id'
      },
      ResetPasswordSendEmail: {
        path: '/redefinir-senha/email'
      },
      ResetPasswordCreatePassword: {
        path: '/redefinir-senha/senha'
      },
      ResetPasswordReSentEmail: {
        path: '/redefinir-senha/email/reenviado'
      },
      ResetPasswordEmailSentSuccessfully: {
        path: '/redefinir-senha/email/enviado'
      },
      ResetPasswordSuccessfully: {
        path: '/redefinir-senha/feedback'
      },
      ResetPasswordAuthCode: {
        path: '/redefinir-senha/codigo'
      },
      Simulator: {
        path: '/simulator'
      },
      Opportunity: {
        path: '/oportunidade/:title/:simulatedPoints/:total/:business_block_id'
      },
      NotificationDetail: {
        path: '/notificacao/:id/detalhes'
      },
      FirstLoginPassword: {
        path: '/primeiro-acesso'
      },
      Content: {
        path: '/conteudos/:id/detalhes'
      },
      TabEditNoteDetails: {
        path: '/carteira/tabs/:tabNumber/notas/editar'
      },
      ContactEditNoteDetails: {
        path: '/carteira/contatos/:contactId/notas/editar'
      },
      ContactCreateNoteDetails: {
        path: '/carteira/contatos/:contactId/notas/criar'
      },
      Chat: {
        path: '/chat'
      },
      ContractList: {
        path: '/contratos/:tabNumber/:yearMonth/:title/:total/:type'
      },
      WalletContact: {
        path: '/carteira/contatos/:contactId'
      },
      OrganizationActivity: {
        path: 'minha-organizacao/atividade/:id'
      },

      OrganizationActivityEdit: {
        path: 'minha-organizacao/atividade/:id/editar'
      },
      OrganizationActivityCreate: {
        path: 'minha-organizacao/atividade/criar'
      },
      OrganizationActivityList: {
        path: '/minha-organizacao/atividades'
      },
      OrganizationOpportunity: {
        path: 'oportunidade/:id'
      },
      OrganizationOpportunityList: {
        path: '/minha-organizacao/oportunidades'
      },

      WalletContactList: {
        path: '/carteira/contatos'
      },
      WalletTabList: {
        path: '/carteira/tabs'
      },
      WalletTab: {
        path: '/carteira/tabs/:tabNumber'
      },

      TabDetails: {
        path: '/carteira/tabs/:tabNumber/dados'
      },
      TabCreateNoteDetails: {
        path: '/carteira/tabs/:tabNumber/notas/criar'
      },
      TabContact: {
        path: '/carteira/tabs/:tabNumber/contatos'
      },
      TabFilter: {
        path: '/carteira/tabs/filtros'
      },
      TabLocation: {
        path: '/carteira/tabs/:tabNumber/localizacao'
      },
      TabRelated: {
        path: '/carteira/tabs/:tabNumber/relacionadas'
      },

      ContactFilter: {
        path: '/carteira/contatos/filtros'
      },
      ContactDetails: {
        path: '/carteira/contatos/:contactId/dados'
      },
      ContactBirthday: {
        path: '/carteira/contatos/:contactId/aniversario'
      },
      ContactEditBirthday: {
        path: '/carteira/contatos/:contactId/aniversario/editar'
      },

      ContactCreate: {
        path: '/carteira/contatos/criar'
      },

      ContactEdit: {
        path: '/carteira/contatos/:contactId/editar'
      },
      TabEdit: {
        path: '/carteira/tabs/:tabNumber/editar'
      },
      ProductionChart: {
        path: '/carteira/producao'
      },
      Production: {
        path: '/producao'
      },
      Chart: {
        path: '/carteira/resumo/:tabNumber/:type'
      },
      ErrorPageNotFound: {
        path: '/*'
      },
      ErrorPageErrorOccurred: {
        path: '/ocorreu-um-erro'
      },
      OptionsProfile: {
        path: 'perfil/opcoes'
      },
      PreferencesProfile: {
        path: '/perfil/preferencias'
      },
      SecurityProfile: {
        path: '/perfil/seguranca-e-privacidade'
      },
      RemoveAccountConfirm: {
        path: '/perfil/apagar-conta'
      },
      About: {
        path: '/perfil/sobre'
      },
      EditProfile: {
        path: '/perfil/editar'
      },
      HomeFilter: {
        path: '/home/filtros'
      },
      Analytics: {
        path: '/analitico'
      },
      Survey: {
        path: '/pesquisas'
      },
      SurveyDetail: {
        path: '/pesquisas/detalhes'
      },
      HomeChart: {
        path: '/home/grafico'
      },
      Home: {
        path: '/home'
      },
      Profile: {
        path: '/perfil'
      },
      Notification: {
        path: '/notificacoes'
      },
      HomeContent: {
        path: '/conteudos'
      },

      Tab: {
        screens: {
          Attendance: '/atendimento',
          Organization: '/minha-organizacao',
          Wallet: '/carteira'
        }
      }
    }
  },
  prefixes: [
    Linking.createURL('/'),
    'https://www.consultormaisturbo.com.br/',
    'https://hml.consultormaisturbo.com.br/',
    'consultormaisturbo://'
  ]
}
