import { FunctionComponent } from 'react'

import { Box, VStack, useBreakpointValue } from 'native-base'
import { HomeChartsTypes } from 'src/screens/HomeScreen/constants'

import { ChartInfos } from './ChartInfos/ChartInfos'

type ChartProps = {
  type: HomeChartsTypes
  chart?: React.ReactElement
  handleGoToFullChartScreen(type: HomeChartsTypes): void
  horizontalLabels?: boolean
  isLoading: boolean
  updated_at?: string
  noLabels?: boolean
  hasData?: boolean
}

type MultipleResumedCharttProps = {
  charts: ChartProps[]
  labels?: string[][]
  colors?: string[][]
}

export const MultipleResumedChartLayout: FunctionComponent<MultipleResumedCharttProps> = ({
  charts,
  labels,
  colors
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <>
      {isMobile ? (
        <VStack bg="white" borderRadius="20px" py="24px" px={4}>
          <VStack space={7}>
            {charts.map((chart, index, size) => (
              <>
                <ChartInfos
                  key={index}
                  handleGoToFullChartScreen={chart.handleGoToFullChartScreen}
                  isLoading={chart.isLoading}
                  type={chart.type}
                  chart={chart.chart}
                  noLabels={chart.noLabels}
                  updated_at={chart.updated_at}
                  labels={labels?.length ? labels[index] : []}
                  colors={colors?.length ? colors[index] : []}
                  hasData={chart.hasData}
                />

                {index !== size.length - 1 && (
                  <Box borderTopWidth={1} borderColor="gray.100" h={1} />
                )}
              </>
            ))}
          </VStack>
        </VStack>
      ) : (
        <VStack bg="white" borderRadius="20px" p={6} space={6}>
          {charts.map((chart, index) => (
            <VStack
              key={index}
              bg={charts.length > 1 ? 'gray.30' : 'white'}
              borderRadius="20px"
              p={6}>
              <VStack space={7}>
                <ChartInfos
                  handleGoToFullChartScreen={chart.handleGoToFullChartScreen}
                  isLoading={chart.isLoading}
                  type={chart.type}
                  chart={chart.chart}
                  noLabels={chart.noLabels}
                  updated_at={chart.updated_at}
                  labels={labels?.length ? labels[index] : []}
                  colors={colors?.length ? colors[index] : []}
                  hasData={chart.hasData}
                />
              </VStack>
            </VStack>
          ))}
        </VStack>
      )}
    </>
  )
}
