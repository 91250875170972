import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { DIRECTORY_TYPE, EXTENSION_TYPE, useUploadMutation } from 'integration/resources/upload'
import {
  useGetProfileQuery,
  UserProfile,
  useUpdateProfileMutation
} from 'integration/resources/user'
import { useBreakpointValue } from 'native-base'
import { useForm } from 'react-hook-form'
import { useCamera } from 'src/hooks/useCamera'
import { isBase64File, validatePhone } from 'src/utils'
import * as Yup from 'yup'

import { UseEditProfileScreen } from './EditProfileScreen.types'

const schema = Yup.object().shape({
  phone_number_cell: Yup.string()
    .test('valid_phone', 'Telefone inválido', (value) => validatePhone(value ?? '', 'BR'))
    .required('Digite seu telefone')
})

export const useEditProfileScreen: UseEditProfileScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data, isLoading: isLoadingProfile } = useGetProfileQuery()

  const { mutateAsync: uploadMutationAsync, isLoading: isUploadLoading } = useUploadMutation()

  const { mutate, isLoading: isLoadingUpdateProfile } = useUpdateProfileMutation()

  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)

  const bottomSheetContext = useBottomSheetContext()

  const [showOptions, setShowOptions] = useState(false)

  const { handleChooseImage, handleTakePicture } = useCamera()

  const profile = data?.data.data

  const { control, setValue, handleSubmit } = useForm<
    Pick<UserProfile, 'phone_number_cell' | 'profile_image_file_name' | 'profile_image_url'>
  >({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const onSubmit = handleSubmit(async (values) => {
    if (
      (values?.profile_image_file_name && values?.profile_image_file_name.includes('file://')) ||
      (values?.profile_image_file_name && isBase64File(values?.profile_image_file_name ?? ''))
    ) {
      const response = await fetch(values.profile_image_file_name)

      const file = await response.blob()

      if (file.type && file.type.split('/').length > 0) {
        const type = file.type.split('/')[1]

        const files = await uploadMutationAsync({
          directory_type: DIRECTORY_TYPE.PROFILE_IMAGES,
          extension_type: type.toUpperCase() as keyof typeof EXTENSION_TYPE,
          files: [file]
        })

        if (files.length) {
          if (files[0].status !== 200) {
            bottomSheetContext.open({
              description: 'Não foi possível fazer upload da imagem, tente novamente',
              title: 'Erro ao tentar submeter a imagem'
            })

            return
          }

          values.profile_image_file_name = files[0].name

          values.profile_image_url = undefined
        }
      }
    } else {
      if (values?.profile_image_file_name !== null && profile?.profile_image_url) {
        values.profile_image_file_name = profile?.profile_image_url?.slice(
          profile?.profile_image_url?.indexOf('profileImages/') + 'profileImages/'.length,
          profile?.profile_image_url?.indexOf('?')
        )
      }

      if (!profile?.profile_image_url) {
        values.profile_image_file_name = null
      }
    }

    if (values?.phone_number_cell) {
      values.phone_number_cell = values.phone_number_cell.replace(/-|\s/g, '')
    }

    mutate(values, {
      onSuccess: () => {
        bottomSheetContext.open({
          title: 'Sucesso',
          description: 'Dados atualizados com sucesso!'
        })
      },
      onError: ({ message }) => {
        if (message === 'This phone number cell is already in use') {
          bottomSheetContext.open({
            title: 'Atenção',
            description: 'Erro ao atualizar perfil, usuário com dados duplicados na base.'
          })
        } else {
          bottomSheetContext.open({
            title: 'Atenção',
            description: 'Os dados não foram atualizados, tente mais tarde!'
          })
        }
      }
    })
  })

  const goBack = useCallback(() => navigation.goBack(), [navigation])

  const handleShowOptionsCamera = () => setShowOptions(true)

  const handleCloseOptionsCamera = () => setShowOptions(false)

  const handleSelectPicture = useCallback(async () => {
    await handleChooseImage((photoSelected) => {
      setIsLoadingPhoto(true)

      if (photoSelected) {
        setValue('profile_image_file_name', photoSelected)
      }

      setTimeout(() => {
        setShowOptions(false)

        setIsLoadingPhoto(false)
      }, 300)
    })
  }, [handleChooseImage, setValue])

  const handelTakePicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    await handleTakePicture((photoSelected) => {
      if (photoSelected) {
        setValue('profile_image_file_name', photoSelected)
      }

      setTimeout(() => {
        setShowOptions(false)

        setIsLoadingPhoto(false)
      }, 300)
    })
  }, [handleTakePicture, setValue])

  const handleRemovePicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    setValue('profile_image_file_name', null)

    setTimeout(() => {
      setShowOptions(false)

      setIsLoadingPhoto(false)
    }, 300)
  }, [setValue])

  useEffect(() => {
    if (profile?.profile_image_url) {
      setValue('profile_image_file_name', profile.profile_image_url)
    }
  }, [profile?.profile_image_url, setValue])

  useEffect(() => {
    if (profile?.phone_number_cell) {
      setValue('phone_number_cell', profile.phone_number_cell)
    }
  }, [profile?.phone_number_cell, setValue])

  return {
    isMobile,
    goBack,
    control,
    profile,
    setValue,
    onSubmit,
    isLoading: isLoadingUpdateProfile || isUploadLoading,
    handleShowOptionsCamera,
    handleCloseOptionsCamera,
    handleSelectPicture,
    handelTakePicture,
    handleRemovePicture,
    showOptions,
    isLoadingPhoto: isLoadingPhoto || isLoadingProfile
  }
}
