import { FunctionComponent } from 'react'

import { LoadingTurbo } from 'atoms'
import { Center, useBreakpointValue, useTheme, VStack } from 'native-base'
import Chart from 'organisms/Chart'
import Layouts from 'organisms/Layouts'
import { Platform } from 'react-native'

import { useHomeCharge } from './hook'
import { HomeChartsTypes, homeResumedChartsProps } from '../../constants'

type HomeChargeProps = {
  handleGoToFullChartScreen(type: HomeChartsTypes): void
  isGoodsAndServices: boolean
}

export const HomeCharge: FunctionComponent<HomeChargeProps> = ({ handleGoToFullChartScreen }) => {
  const { chargeDataIsLoading, overdueData, walletData } = useHomeCharge()

  const theme = useTheme()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <VStack
      mt={isDesktop ? 0 : Platform.OS === 'ios' ? 1 : 5}
      mr={4}
      mb={2}
      space={4}
      maxW={isDesktop ? '1122px' : 'auto'}>
      {chargeDataIsLoading ? (
        <Center h={360} w="full" position="absolute" zIndex={9}>
          <LoadingTurbo size={100} />
        </Center>
      ) : (
        <>
          <Layouts.MultipleResumedChart
            labels={[[], overdueData?.titles ?? []]}
            colors={[
              [],
              [theme.colors.primary[100], theme.colors.primary[300], theme.colors.primary[500]]
            ]}
            charts={[
              {
                isLoading: chargeDataIsLoading,
                hasData: walletData.hasData,
                type: HomeChartsTypes.WALLET_SITUATION,
                updated_at: walletData?.processed_at ?? '',
                handleGoToFullChartScreen: () =>
                  handleGoToFullChartScreen(HomeChartsTypes.WALLET_SITUATION),
                noLabels: false,
                chart:
                  Platform.OS === 'web' ? (
                    <Chart.PortraitStackBar
                      horizontalLabels
                      colors={[theme.colors.primary[500], theme.colors.primary[300]]}
                      chartType={HomeChartsTypes.WALLET_SITUATION}
                      chartsData={[walletData?.overdue ?? [], walletData?.in_day ?? []]}
                      tooltips={{
                        titles:
                          homeResumedChartsProps[HomeChartsTypes.WALLET_SITUATION].tooltipsTitles,
                        values: walletData?.tooltipsValues ?? []
                      }}
                      showTooltip
                      type="percentage"
                    />
                  ) : (
                    <Chart.PortraitMultipleBars
                      colors={[theme.colors.primary[500], theme.colors.primary[300]]}
                      chartsData={[walletData?.overdue ?? [], walletData?.in_day ?? []]}
                      type="percentage"
                      small
                    />
                  )
              },
              {
                isLoading: chargeDataIsLoading,
                hasData: overdueData.hasData,
                type: HomeChartsTypes.OVERDUE,
                updated_at: overdueData?.processed_at ?? '',
                handleGoToFullChartScreen: () => handleGoToFullChartScreen(HomeChartsTypes.OVERDUE),
                noLabels: false,
                chart: (
                  <Chart.PortraitStackBar
                    horizontalLabels={isDesktop}
                    colors={[
                      theme.colors.primary[100],
                      theme.colors.primary[300],
                      theme.colors.primary[500]
                    ]}
                    chartType={HomeChartsTypes.OVERDUE}
                    chartsData={overdueData?.chartData ?? []}
                    tooltips={{
                      titles: homeResumedChartsProps[HomeChartsTypes.OVERDUE].tooltipsTitles,
                      values: overdueData?.tooltipsValues ?? []
                    }}
                    showTooltip
                    type="percentage"
                  />
                )
              }
            ]}
          />
        </>
      )}
    </VStack>
  )
}
