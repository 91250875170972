import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'

import { ListFiles, LoadingTurbo } from 'atoms'
import { PickAttachmentHandle } from 'atoms/ListFiles/ListFiles'
import { AttachmentList, useGetAttachmentListInfiniteQuery } from 'integration/resources/wallet'
import { ButtonAttach } from 'molecules'
import { Center, Text, VStack } from 'native-base'
import { CurrentViewTypesRef } from 'src/components/templates/ContactScreenTemplate/ContactScreenTemplate'

import { TabCommonProps } from './types'

const attachmentsMergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetAttachmentListInfiniteQuery>['data']
) =>
  data?.pages.reduce<AttachmentList[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const Documents = forwardRef<CurrentViewTypesRef, TabCommonProps>(({ contactId }, ref) => {
  const listFilesRef = useRef<PickAttachmentHandle>(null)

  const handlePickAttachment = () => listFilesRef.current?.handlePickAttachment()

  const {
    data: allAttachmentsData,
    isFetchingNextPage: allAttachmentsIsFetchingNextPage,
    hasNextPage: allAttachmentsHasNextPage,
    fetchNextPage: allAttachmentsFetchNextPage,
    isFetching: allAttachmentsisFetching,
    isLoading
  } = useGetAttachmentListInfiniteQuery({
    id: contactId ?? '',
    type: 'contacts',
    per_page: 40
  })

  const allAttachments = useMemo(
    () => attachmentsMergeDataInfiniteQuery(allAttachmentsData),
    [allAttachmentsData]
  )

  const onScrollEndDragAllAttachmentsList = useCallback(() => {
    if (!allAttachmentsIsFetchingNextPage && allAttachmentsHasNextPage) {
      allAttachmentsFetchNextPage()
    }
  }, [allAttachmentsIsFetchingNextPage, allAttachmentsHasNextPage, allAttachmentsFetchNextPage])

  useImperativeHandle(ref, () => ({
    onScrollEndDragContent: onScrollEndDragAllAttachmentsList
  }))

  return (
    <VStack flex={1}>
      {allAttachments.length > 0 && (
        <Text color="gray.700" fontSize="16px">
          Documentos
        </Text>
      )}
      {isLoading ? (
        <Center h={360} w="full" position="absolute" zIndex={9}>
          <LoadingTurbo size={100} />
        </Center>
      ) : (
        <>
          <VStack>
            <ListFiles
              attachment={{
                allAttachmentsIsFetchingNextPage,
                allAttachmentsIsLoading: allAttachmentsisFetching,
                allAttachments
              }}
              type="contacts"
              id={contactId ?? ''}
              ref={listFilesRef}
            />
          </VStack>

          <VStack
            alignSelf={allAttachments.length > 0 ? 'flex-end' : 'center'}
            w={350}
            paddingBottom={10}>
            <Center>
              <ButtonAttach title="Adicione um anexo" handlePickAttachment={handlePickAttachment} />
            </Center>
          </VStack>
        </>
      )}
    </VStack>
  )
})
