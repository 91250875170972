import { FunctionComponent } from 'react'

import Icons from 'atoms/Icons'
import {
  VStack,
  Heading,
  Text,
  Box,
  Divider,
  HStack,
  Circle,
  useBreakpointValue
} from 'native-base'
import { formatDateStringToMask } from 'src/utils'

type SummaryCardProps = {
  desktopCardHeight?: number
  title: string
  subtitle: string
  riseUp: boolean
  description: {
    title: string
    value: string
  }[]
  updatedAt: string
}

export const SummaryCard: FunctionComponent<SummaryCardProps> = ({
  desktopCardHeight,
  title,
  subtitle,
  description,
  updatedAt,
  riseUp
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <VStack
      key={Math.random()}
      mb={{
        base: 4,
        lg: 0
      }}
      py={{
        base: '14px',
        lg: 6
      }}
      px={{ base: 4, lg: 6 }}
      bg="white"
      borderRadius={20}
      minH={{
        base: 165,
        lg: desktopCardHeight
      }}>
      <VStack flex={1}>
        <HStack justifyContent="space-between">
          <Heading
            fontSize={{ base: 24, lg: 32 }}
            lineHeight={{ base: '28px', lg: '47px' }}
            fontWeight="bold"
            color="gray.700"
            numberOfLines={1}
            textTransform="uppercase">
            {title}
          </Heading>

          <Circle
            style={{ transform: [{ rotate: riseUp ? '0deg' : '180deg' }] }}
            w={{
              base: 6,
              lg: 10
            }}
            h={{
              base: 6,
              lg: 10
            }}
            bg={riseUp ? 'secondary.50' : 'error.50'}
            borderColor="background.base"
            borderWidth="1px">
            <Icons.ArrowUp color={riseUp ? 'secondary.500' : 'error.500'} size={isMobile ? 3 : 4} />
          </Circle>
        </HStack>

        <Text
          fontSize={{ base: '12px', lg: '18px' }}
          fontFamily={{ base: 'OpenSans_400Regular', lg: 'Assistant_400Regular' }}
          color="gray.500"
          lineHeight={{ base: '16px', lg: '22px' }}
          fontWeight="normal"
          mt={{ base: 1, lg: 2 }}
          numberOfLines={1}>
          {subtitle}
        </Text>

        <Divider bg="gray.50" my={{ base: 3, lg: 4 }} />
        <VStack space={1}>
          {description.map((item, index, size) => (
            <Box
              key={`SummaryCard-${item.value}-${Math.random()}`}
              mb={isMobile ? (size.length === index + 1 ? 3 : 0) : 2}
              minH={{ base: '16px', lg: 0 }}>
              <HStack justifyContent="space-between">
                <Text
                  fontSize={{ base: '12px', lg: '14px' }}
                  letterSpacing="0.2px"
                  lineHeight={{ base: '16px', lg: '20px' }}
                  color={{ base: 'gray.600', lg: 'gray.700' }}
                  fontWeight={{ base: 700, lg: 600 }}
                  w={{ base: '55%', lg: 'auto' }}
                  numberOfLines={1}>
                  {item.title}
                </Text>
                <Text
                  fontSize={{ base: '12px', lg: '14px' }}
                  color="gray.500"
                  lineHeight={{ base: '16px', lg: '20px' }}
                  fontWeight={{ base: 400, lg: 600 }}
                  numberOfLines={1}>
                  {item.value}
                </Text>
              </HStack>
            </Box>
          ))}
        </VStack>

        {updatedAt && (
          <Text
            fontSize={{ base: 10, lg: 14 }}
            fontWeight={{ base: 400, lg: 600 }}
            color="gray.200"
            mt="auto"
            letterSpacing={{ base: 0.3, lg: 0.1 }}
            numberOfLines={1}>
            {`Data de Ref. ${formatDateStringToMask(updatedAt, 'dd/MM/yyyy')}`}
          </Text>
        )}
      </VStack>
    </VStack>
  )
}
