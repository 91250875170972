import { FunctionComponent, memo } from 'react'

import Icons from 'atoms/Icons'
import { Button, Center, HStack, Heading, Pressable, VStack, Box, Text } from 'native-base'

import { Breadcrumb } from '../Breadcrumb'

type HeadPageProps = {
  title: string
  badge?: string
  pages: {
    id: number
    title: string
    link: any
    params: any
  }[]
  actionButtonTitle?: string
  leftIcon?: JSX.Element
  actionButtonPress?: () => void
  actionBackButton?: () => void
}

export const HeadPage: FunctionComponent<HeadPageProps> = memo(
  ({ pages, title, actionButtonPress, actionButtonTitle, leftIcon, actionBackButton, badge }) => {
    return (
      <HStack flex={1} justifyContent="space-between">
        <HStack>
          {actionBackButton && (
            <Pressable onPress={actionBackButton}>
              <Center bgColor="white" h={8} w={8} rounded="full" mr={4}>
                <Icons.AccordionArrow direction="left" size={6} />
              </Center>
            </Pressable>
          )}
          <VStack space={2}>
            <Box flexDir="row" alignItems="center">
              <Heading fontSize={24} fontWeight="bold" color="gray.700">
                {title}
              </Heading>
              {badge && (
                <Center
                  h={6}
                  py={1}
                  px={4}
                  ml="8px"
                  backgroundColor="primary.500"
                  borderRadius="full">
                  <Text
                    fontSize="12px"
                    color="white"
                    textTransform="uppercase"
                    lineHeight="16px"
                    letterSpacing={0.8}
                    fontWeight={700}>
                    {badge}
                  </Text>
                </Center>
              )}
            </Box>

            <Breadcrumb pages={pages} />
          </VStack>
        </HStack>

        {actionButtonTitle ? (
          <Center>
            <Button
              colorScheme="gray"
              width="162px"
              height="44px"
              onPress={actionButtonPress}
              px={6}
              leftIcon={leftIcon}>
              <Text fontSize="12px" fontWeight={700} lineHeight="16px" color="white">
                {actionButtonTitle}
              </Text>
            </Button>
          </Center>
        ) : null}
      </HStack>
    )
  }
)
