export type TNotReadResponse = {
  not_read_count: 10
}

export type TParams<T = Record<string, unknown>> = T & {
  authorSlugs?: string[]
  categorySlugs?: string[]
  mostRead?: boolean
  firstPage?: number
  lastPage?: number
  notificationType?: string
  currentPage?: number
  perPage?: number
}

export type TNotificationType =
  | 'WALLET'
  | 'CONTENT'
  | 'ORGANIZATION'
  | 'OPPORTUNITY'
  | 'COMMUNICATION'
  | 'BIRTHDATE'

export type TNotification = {
  id: string
  title: string
  description: string
  body: string
  extras: any
  notification_type_enum: TNotificationType
  is_read: boolean
  created_at: string
  updated_at: string
  sent_at: string
}

export enum NOTIFICATION_TYPES {
  WALLET = 'WALLET',
  CONTENT = 'CONTENT',
  ORGANIZATION = 'ORGANIZATION',
  OPPORTUNITY = 'OPPORTUNITY',
  COMMUNICATION = 'COMMUNICATION',
  BIRTHDAY = 'BIRTHDATE'
}
