import { useCallback, useMemo, useState } from 'react'

import {
  KEY_ALL_SURVEYS_QUERY,
  KEY_SURVEY_DETAIL_QUERY,
  SURVEYS_QUESTIONS_TYPES_ENUM,
  useAnswerSurveyMutation,
  useGetSurveyDetailQuery
} from 'integration/resources/surveys'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { useQueryClient } from 'react-query'

import { TAnswer, UseSurveyDetailScreen } from './SurveyDetailScreen.types'

export const useSurveyDetailScreen: UseSurveyDetailScreen = ({ navigation, route }) => {
  const { id, isAnswered } = route?.params

  const { data, isLoading } = useGetSurveyDetailQuery(id)

  const answerSurveyMutation = useAnswerSurveyMutation()

  const toast = useToast()

  const queryClient = useQueryClient()

  const surveyDetails = data?.data.data

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const [answersByQuestion, setAnswersByQuestion] = useState<{ [key: string]: TAnswer[] }>({})

  const totalQuestions = useMemo(() => surveyDetails?.questions.length ?? 1, [surveyDetails])

  const [page, setPage] = useState<number>(1)

  const [firstPage, setFirstPage] = useState(true)

  const [loading, setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, 1000)

  const onChangeAnswer = (answerId: string, answerValue: string) => {
    const currentQuestion = surveyDetails?.questions[page - 1]

    const currentQuestionId = currentQuestion?.id ?? ''

    setAnswersByQuestion((prevAnswers) => {
      if (currentQuestion?.type === SURVEYS_QUESTIONS_TYPES_ENUM.MULTI_CHOICE) {
        const updatedAnswers = { ...prevAnswers }

        if (updatedAnswers[currentQuestionId]) {
          if (updatedAnswers[currentQuestionId].map((item) => item.id).includes(answerId)) {
            updatedAnswers[currentQuestionId] = updatedAnswers[currentQuestionId].filter(
              (item) => item.id !== answerId
            )
          } else {
            updatedAnswers[currentQuestionId].push({ id: answerId, value: answerValue })
          }
        } else {
          updatedAnswers[currentQuestionId] = [{ id: answerId, value: answerValue }]
        }

        return updatedAnswers
      } else {
        return { ...prevAnswers, [currentQuestionId]: [{ id: answerId, value: answerValue }] }
      }
    })
  }

  const buttonIsContinue: boolean = useMemo(() => page !== totalQuestions, [page, totalQuestions])

  const handleContinueOrSubmit = () => {
    setLoading(true)

    if (buttonIsContinue) {
      if (page < totalQuestions) {
        setPage(page + 1)
      }
    } else {
      const submitItem = {
        id: surveyDetails?.id ?? '',
        questions:
          surveyDetails?.questions.map((question) => {
            const isOpenChoice = question.type === SURVEYS_QUESTIONS_TYPES_ENUM.OPEN_CHOICE

            return {
              id: question.id,
              answers: (answersByQuestion[question.id] || []).map((answer) => ({
                id: answer.id,
                value: isOpenChoice ? answer.value : undefined
              }))
            }
          }) ?? []
      }

      answerSurveyMutation.mutate(submitItem, {
        onSuccess: () => {
          setPage(page + 1)

          queryClient.invalidateQueries(KEY_ALL_SURVEYS_QUERY)

          queryClient.invalidateQueries(`${KEY_SURVEY_DETAIL_QUERY}.${surveyDetails?.id}`)
        },
        onError: () => {
          navigation.goBack()

          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação" />,
            duration: 3000
          })
        }
      })
    }

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const handleGoBackQuestion = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const questionRequired = useMemo(
    () =>
      !!(
        surveyDetails?.questions[page - 1]?.required &&
        !answersByQuestion[surveyDetails?.questions[page - 1]?.id]?.length
      ),
    [surveyDetails, answersByQuestion, page]
  )

  return {
    isMobile,
    isLoading: isLoading || loading,
    handleGoBack,
    surveyDetails,

    page,
    isAnswered: isAnswered ?? surveyDetails?.answered,
    buttonIsContinue,
    totalQuestions,
    handleGoBackQuestion,
    handleContinueOrSubmit,
    answersByQuestion,
    onChangeAnswer,
    questionRequired,
    firstPage,
    setFirstPage
  }
}
