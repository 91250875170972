export enum CategoryCode {
  ALL = 999,
  CAMPAIGN = 1,
  COMMUNICATION = 2,
  PROCESS = 3,
  PRODUCT = 4,
  COMMISSION = 5,
  TOOLS = 6,
  PRICING = 7,
  TRAINING = 8,
  ACADEMY = 9,
  REGULATORY = 10,
  PEOPLE = 11
}

export enum AttachmentExtension {
  PDF = 'pdf',
  PNG = 'png',
  MP3 = 'mp3',
  MP4 = 'mp4',
  ZIP = 'zip',
  JPEG = 'jpeg',
  JPG = 'jpg',
  CSV = 'csv',
  DOC = 'doc',
  DOCX = 'docx',
  PPT = 'ppt',
  PPTX = 'pptx',
  XLS = 'xls',
  XLSX = 'xlsx',
  XLSM = 'xlsm'
}

export type Category = {
  code: CategoryCode
  id: string
  name: string
  total: number
}

export type Attachment = {
  fileName: string
  url: string
  uploaded_at?: string
  created_at?: string
  file_size?: string
  name?: string
}

export type Content = {
  category: Category
  id: string
  is_favorite: boolean
  is_featured: boolean
  last_update: string
  updated_at: string
  estimated_reading_time?: number
  title: string
  subtitle?: string
  attachments?: Attachment[]
  cover_image_url?: string
  content_body?: string
}

export type ContentSerialized = Content & {
  lastUpdateFormatted: string | number
}

export type GetKnowledgeBasesParams = {
  category_id?: string
  order_by?: string
  order_by_secondary?: string
  page?: number
  per_page?: number
  title?: string
  is_featured?: 0 | 1
  is_favored?: 0 | 1
  is_read?: 0 | 1
  period_starting_at?: string
  period_ending_at?: string
}

export enum GetKnowledgeBasesStatus {
  ACTIVE = 1,
  INACTIVE = 0
}
