import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'
import { RootStackParamList } from 'navigation/types'

import { UseOptionsProfileScreen } from './OptionsProfileScreen.types'

type ScreenOptions =
  | Pick<RootStackParamList, 'EditProfile'>
  | Pick<RootStackParamList, 'PreferencesProfile'>
  | Pick<RootStackParamList, 'ManageNotifications'>
  | Pick<RootStackParamList, 'SecurityProfile'>
  | Pick<RootStackParamList, 'RemoveAccountConfirm'>
  | Pick<RootStackParamList, 'About'>

export const useOptionsProfileScreen: UseOptionsProfileScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoToScreen = useCallback(
    (screen: keyof ScreenOptions) => navigation.navigate(screen),
    [navigation]
  )

  const goBack = useCallback(() => navigation.goBack(), [navigation])

  return {
    isMobile,
    handleGoToScreen,
    goBack
  }
}
