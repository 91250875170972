import { useEffect, useState } from 'react'

import { Pressable, Text, useTheme } from 'native-base'
import { View, Animated, Dimensions, StyleSheet, Platform } from 'react-native'
import { LogEvent } from 'src/utils/logEvents'

import { TabSlidingProps } from './types'

const width = Platform.OS === 'web' ? 513 : Dimensions.get('window').width

const MARGIN = 16

const TAB_BAR_WIDTH = width - 2 * MARGIN

const TAB_WIDTH = TAB_BAR_WIDTH / 2 // 2 == tabs.length

export const TabSliding = ({
  index,
  setCurrentIndex,
  tabs,
  backgroundColor = '#fff'
}: TabSlidingProps) => {
  const [translateX] = useState(new Animated.Value(0))

  const [currentIndex, _setCurrentIndex] = useState<number>()

  const theme = useTheme()

  const translateTab = (index: number) => {
    Animated.spring(translateX, {
      toValue: index * TAB_WIDTH,
      useNativeDriver: Platform.OS !== 'web'
    }).start()
  }

  useEffect(() => {
    if (currentIndex !== index) {
      translateTab(index)

      _setCurrentIndex(index)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  return (
    <View
      style={[
        styles.tabBarContainer,
        {
          backgroundColor
        }
      ]}>
      <Animated.View style={[styles.slidingTabContainer, {}]}>
        <Animated.View
          style={[
            styles.slidingTab,
            { transform: [{ translateX }], backgroundColor: theme.colors.primary[500] }
          ]}
        />
      </Animated.View>

      {tabs
        .filter((tab) => tab.show)
        .map((route, i) => {
          const label = route.title

          const isFocused = index === i

          const onPress = () => {
            LogEvent(`perfil_${label}`, {
              item_id: ''
            })

            setCurrentIndex(route.id)
          }

          return (
            <Pressable
              key={route.title}
              onPress={onPress}
              style={{
                height: 44,
                width: TAB_WIDTH,
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <Text
                style={{ color: isFocused ? 'white' : theme.colors.gray[700] }}
                bold={isFocused}>
                {label}
              </Text>
            </Pressable>
          )
        })}
    </View>
  )
}

const styles = StyleSheet.create({
  tabBarContainer: {
    height: 48,
    flexDirection: 'row',
    width: TAB_BAR_WIDTH,
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 30,
    bottom: MARGIN
  },
  slidingTabContainer: {
    width: TAB_WIDTH,
    ...StyleSheet.absoluteFillObject
  },
  slidingTab: {
    top: 2,
    left: 2,
    height: 44,
    width: TAB_WIDTH - 4,
    borderRadius: 30
  }
})
