import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  KEY_OPPORTUNITY_LIST_QUERY,
  OpportunitiesResponse,
  useGetOpportunityListInfiniteQuery,
  useSetOpportunityHighlightedMutation,
  useSetOpportunityUnHighlightedMutation
} from 'integration/resources/opportunities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'

import { UseListOpportunities } from './types'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0,
  highlights: {
    total_opportunities: 0,
    items: []
  }
}

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetOpportunityListInfiniteQuery>['data']
) =>
  data?.pages.reduce<OpportunitiesResponse>(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...currentValue.data.data,
      items: [...previousValue.items, ...currentValue.data.data.items]
    }),
    defaultReturn
  )

export const useListOpportunities: UseListOpportunities = ({ search }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchHook
  } = useGetOpportunityListInfiniteQuery({
    per_page: 10,
    filter_by: 5,
    expand_details: isDesktop && Platform.OS === 'web',
    search
  })

  const { mutateAsync: setOpportunityHighlighted, isLoading: highlightedIsLoading } =
    useSetOpportunityHighlightedMutation()

  const { mutateAsync: setOpportunityUnHighlighted, isLoading: unhighlightedIsLoading } =
    useSetOpportunityUnHighlightedMutation()

  const fetchMore = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const list = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  const [refetchLoading, setRefetchLoading] = useState(false)

  const toast = useToast()

  const queryClient = useQueryClient()

  const refetch = () => {
    setRefetchLoading(true)

    setTimeout(() => {
      setRefetchLoading(false)
    }, 300)

    queryClient.invalidateQueries(KEY_OPPORTUNITY_LIST_QUERY)
  }

  const handleUpdateOpportunityHighlighted = (id: string, highlighted: boolean) => {
    id &&
      (highlighted ? setOpportunityUnHighlighted : setOpportunityHighlighted)(
        {
          id
        },
        {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Tente novamente mais tarde" />,
              duration: 3000
            })
          },
          onSuccess: () => {
            refetch()
          }
        }
      )
  }

  useEffect(() => {
    refetchHook()
  }, [search, refetchHook])

  return {
    list,
    refetch,
    isLoading,
    isFetchingNextPage,
    refreshing: refetchLoading || highlightedIsLoading || unhighlightedIsLoading,
    fetchMore,
    handleUpdateOpportunityHighlighted
  }
}
