import { forwardRef, useImperativeHandle, useEffect, useState } from 'react'

import { Cards, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { LinearGradient } from 'expo-linear-gradient'
import { UnconnectedMessage } from 'molecules'
import {
  Center,
  Box,
  VStack,
  ZStack,
  useTheme,
  HStack,
  Heading,
  View,
  Text,
  useBreakpointValue
} from 'native-base'
import { useWindowDimensions } from 'react-native'
import { LogEvent } from 'src/utils/logEvents'

import { useListOpportunities } from './hook'
import { ListTypesRef, ListTypesView } from '../../OrganizationScreen.types'

export const ListOpportunitiesConcluded = forwardRef<ListTypesRef, ListTypesView>(
  ({ navigation, isLoading }, ref) => {
    const [search, setSearch] = useState('')

    const isMobile = useBreakpointValue({ base: true, lg: false })

    const {
      list,
      refetch,
      fetchMore,
      refreshing,
      isFetchingNextPage,
      isLoading: localIsLoading,
      handleUpdateOpportunityHighlighted
    } = useListOpportunities({ search })

    const skeletonArray = new Array(6).fill(0)

    useImperativeHandle(ref, () => ({
      refreshing,
      fetchMore,
      refetch,
      search: (value: string) => setSearch(value),
      clearSearch: () => setSearch('')
    }))

    const { width, height } = useWindowDimensions()

    const theme = useTheme()

    useEffect(() => {
      navigation.addListener('focus', () => {
        refetch()
      })
    }, [navigation, refetch])

    return (
      <Box mt={10} px={4} position="relative">
        {(localIsLoading || isLoading) && (
          <VStack py={4} w={width - 32}>
            {skeletonArray.map((_, i) => (
              <SkeletonCard key={`key-skeleton-${i}`} />
            ))}
          </VStack>
        )}

        <HStack justifyContent="space-between" mb={4}>
          {!isLoading && !localIsLoading && !!list?.items.length && (
            <Heading fontWeight="bold" lineHeight={{ base: '28px', lg: '38px' }} fontSize="24px">
              {isMobile ? 'Concluídas' : 'Oportunidades Concluídas'}
            </Heading>
          )}

          {!isLoading && !localIsLoading && !!list?.items.length && (
            <View>
              <Box
                flexDir="row"
                bgColor={{ base: 'primary.800', lg: undefined }}
                px={3}
                py={1}
                borderRadius={20}>
                {isMobile ? (
                  <Heading color="white" fontSize={12} fontWeight="bold" fontFamily="heading">
                    {list?.total_opportunities}{' '}
                    {`Oportunidade${Number(list?.total_opportunities) > 1 ? 's' : ''}`}
                  </Heading>
                ) : (
                  <Text color="gray.400" fontSize="16px" fontWeight="normal">
                    {list?.total_opportunities}{' '}
                    {`Oportunidade${Number(list?.total_opportunities) > 1 ? 's' : ''}`}
                  </Text>
                )}
              </Box>
            </View>
          )}
        </HStack>

        {!list?.items.length && !localIsLoading && <UnconnectedMessage />}

        {!isLoading &&
          list?.items.map((opportunity, i) => (
            <Box mb={2} key={`key-${i}-${opportunity.title}`}>
              <Cards.OrganizationCard
                expandedDetail
                isLoadingFavorite={isLoading}
                disableHandleFavorite={!opportunity.highlightedByUserStdCode}
                points={opportunity.rv_simulation_goal_points}
                topText={opportunity.title}
                cover={opportunity.store?.logo_image?.url}
                nameFantasy={opportunity.store.name_fantasy}
                favorite={opportunity.is_highlighted}
                tabNumber={opportunity.store?.tab_number}
                midText={
                  opportunity.store?.name_fantasy
                    ? `TAB ${opportunity.store?.tab_number} • ${opportunity.dueDate}`
                    : undefined
                }
                bottomText={`R$ ${opportunity.earningPotential}`}
                active={opportunity.is_finished}
                userName={opportunity.user_name}
                icon="Dollar"
                handleFavorite={() =>
                  handleUpdateOpportunityHighlighted(opportunity.id, opportunity.is_highlighted)
                }
                onPress={() => {
                  LogEvent('oportunidade', {
                    item_id: opportunity?.id
                  })

                  navigation.navigate('OrganizationOpportunity', {
                    id: String(opportunity.id)
                  })
                }}
              />
            </Box>
          ))}

        {isFetchingNextPage && (
          <Center mt={6}>
            <LoadingTurbo size={48} />
          </Center>
        )}

        {refreshing && !isLoading && (
          <>
            <LinearGradient
              style={{
                position: 'absolute',
                height: 800,
                zIndex: 4,
                flex: 1,
                width
              }}
              colors={['#ffffff05', theme.colors.background.light, '#ffffff05']}
            />
            <ZStack
              position="absolute"
              w={width}
              h={0}
              alignItems="center"
              justifyContent="center"
              top={height / 5}
              zIndex={8}>
              <Center>
                <LoadingTurbo size={48} />
              </Center>
            </ZStack>
          </>
        )}
      </Box>
    )
  }
)
