import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { WalletCard } from 'atoms/Cards/WalletCard'
import { WalletItem } from 'integration/resources/wallet'
import { ResponsePagination } from 'integration/types'
import { FavoritListCard } from 'molecules/FavoritListCard'
import { Heading, Pressable, Text, VStack, FlatList, Spinner, Center } from 'native-base'
import { Platform } from 'react-native'
import Animated from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

type WalletContainerListProps<T> = {
  data: T[]
  favoritData: T[]
  isLoading?: boolean
  type: 'WalletContact' | 'WalletTab'
  pagination?: ResponsePagination
  onEndReached: () => void
  onRefresh?: () => void
  scrollHandler: (e: any) => void
  goToDetailsScreen?: (id: string) => void
  isGoodsAndServices?: boolean
}

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList)

export const WalletContainerList = <T extends WalletItem>({
  data,
  favoritData,
  type,
  goToDetailsScreen,
  onEndReached,
  onRefresh,
  pagination,
  scrollHandler,
  isLoading,
  isGoodsAndServices = false
}: WalletContainerListProps<T>) => {
  const insets = useSafeAreaInsets()

  const activeSkeleton = isLoading && !data.length

  return (
    <VStack justifyContent="flex-start">
      <AnimatedFlatList
        contentContainerStyle={{
          paddingTop: 320,
          paddingBottom: 100 + insets.bottom
        }}
        bounces={false}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
        scrollEventThrottle={16}
        onScroll={scrollHandler}
        data={activeSkeleton ? new Array(6) : data}
        onEndReached={onEndReached}
        // @ts-ignore
        keyExtractor={(item: any, index) => (activeSkeleton ? `${item}` : `${item.id}-${index}`)}
        onEndReachedThreshold={0.1}
        onRefresh={onRefresh}
        refreshing={isLoading}
        ListHeaderComponent={() =>
          !activeSkeleton && !!favoritData.length ? (
            <>
              <FavoritListCard
                title="Favoritos"
                type={`contato${favoritData.length > 1 ? 's' : ''}`}
                quantity={favoritData.length}>
                <>
                  {favoritData.map((item, index) => (
                    <Pressable
                      key={`${index}-${item.id}`}
                      onPress={() =>
                        goToDetailsScreen?.(type === 'WalletContact' ? item.id : item.tab_number)
                      }
                      bg="white"
                      borderRadius="20px">
                      <WalletCard {...item} isGoodsAndServices={isGoodsAndServices} type={type} />
                    </Pressable>
                  ))}
                </>
              </FavoritListCard>

              <VStack space={1} mb="24px">
                <Heading fontSize={18} color="gray.700">
                  Todos
                </Heading>
                {type && (
                  <Text fontSize="16px" lineHeight="24px" fontWeight={400} color="gray.400">
                    {pagination?.total} {type === 'WalletContact' ? 'contato' : 'TAB'}
                    {pagination && pagination?.total > 1 && 's'}
                  </Text>
                )}
              </VStack>
            </>
          ) : (
            <VStack space={1} mb="24px">
              <Heading fontSize={18} color="gray.700">
                Todos
              </Heading>
              {type && (
                <Text fontSize="16px" lineHeight="24px" fontWeight={400} color="gray.400">
                  {pagination?.total} {type === 'WalletContact' ? 'contato' : 'TAB'}
                  {pagination && pagination?.total > 1 && 's'}
                </Text>
              )}
            </VStack>
          )
        }
        // @ts-ignore
        renderItem={({ item }: { item: WalletItem }) =>
          activeSkeleton ? (
            <SkeletonCard />
          ) : (
            <Pressable
              mb={2}
              onPress={() =>
                goToDetailsScreen?.(type === 'WalletContact' ? item.id : item?.tab_number)
              }
              bg="white"
              borderRadius="20px"
              key={item.id}>
              <WalletCard {...item} isGoodsAndServices={isGoodsAndServices} type={type} />
            </Pressable>
          )
        }
        ListFooterComponent={() =>
          isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <></>
          )
        }
      />
    </VStack>
  )
}
