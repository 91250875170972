import { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import {
  EvaluationItem,
  KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY,
  KEY_OPPORTUNITY_LIST_QUERY,
  OpportunitiesResponse,
  OpportunitySerialized,
  useSetOpportunityRatesMutation,
  useGetOpportunityListInfiniteQuery,
  useSetOpportunityHighlightedMutation,
  useSetOpportunityUnHighlightedMutation
} from 'integration/resources/opportunities'
import { useToast } from 'native-base'
import { Toast } from 'organisms'
import { useQueryClient } from 'react-query'
import { FAVORITE_TYPE } from 'src/screens/ContentScreen/ContentScreen.types'

import { UseOpportunityContainerList } from './types'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0,
  highlights: {
    total_opportunities: 0,
    items: []
  }
}

const mergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetOpportunityListInfiniteQuery>['data']
) =>
  data?.pages.reduce<OpportunitiesResponse>(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...currentValue.data.data,
      items: [...previousValue.items, ...currentValue.data.data.items]
    }),
    defaultReturn
  )

export const useOpportunityContainerList: UseOpportunityContainerList = ({
  store_tab_numbers,
  business_block_id
}) => {
  const queryClient = useQueryClient()

  const evaluationSuccessSheetRef = useRef<BottomSheetModal>(null)

  const openEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.present()
  }, [])

  const closeEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.close()

    evaluationSuccessSheetRef.current?.dismiss()
  }, [])

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, refetch, isFetching } =
    useGetOpportunityListInfiniteQuery({
      store_tab_numbers,
      business_block_id,
      filter_by: 3,
      order_by: 'earning_potential',
      order_by_direction: 'desc'
    })

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(KEY_OPPORTUNITY_LIST_QUERY)
    }
  }, [queryClient])

  const onScrollEndDragOpportunityList = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const opportunities = useMemo(() => mergeDataInfiniteQuery(data), [data])

  const toast = useToast()

  const { mutateAsync: setOpportunityHighlighted } = useSetOpportunityHighlightedMutation()

  const { mutateAsync: setOpportunityUnHighlighted } = useSetOpportunityUnHighlightedMutation()

  const [opportunityEvaluation, setOpportunityEvaluation] = useState<OpportunitySerialized>()

  const [isOpenEvaluation, setIsOpenEvaluantion] = useState(false)

  const setOpportunityRatesMutation = useSetOpportunityRatesMutation()

  const handleEvaluate = useCallback(
    (evaluantion: EvaluationItem) => {
      opportunityEvaluation?.id &&
        setOpportunityRatesMutation
          .mutateAsync(
            {
              ...evaluantion,
              opportunity_id: opportunityEvaluation.id
            },
            {
              onError: () => {
                toast.show({
                  render: () => (
                    <Toast type="error" text="Não foi possível concluir oportunidade" />
                  ),
                  duration: 3000
                })
              },
              onSuccess: () => {
                openEvaluationSuccessSheet()

                refetch()
              }
            }
          )
          .finally(() => setIsOpenEvaluantion(false))
    },
    [
      openEvaluationSuccessSheet,
      opportunityEvaluation?.id,
      refetch,
      setOpportunityRatesMutation,
      toast
    ]
  )

  const handleUpdateOpportunityHighlighted = (id: string, highlighted: boolean) => {
    id &&
      (highlighted ? setOpportunityUnHighlighted : setOpportunityHighlighted)(
        {
          id
        },
        {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Tente novamente mais tarde" />,
              duration: 3000
            })
          },
          onSuccess: () => {
            queryClient.invalidateQueries([
              KEY_OPPORTUNITY_LIST_QUERY,
              KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY
            ])

            refetch()
          }
        }
      )
  }

  const handlePressFavorite = (isHighlighted: FAVORITE_TYPE, id: string) => {
    queryClient.invalidateQueries([
      KEY_OPPORTUNITY_LIST_QUERY,
      KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY
    ])

    return (
      isHighlighted === FAVORITE_TYPE.UNFAVORITE
        ? setOpportunityUnHighlighted
        : setOpportunityHighlighted
    )({
      id
    })
  }

  return {
    opportunities: opportunities?.items ?? [],
    isLoading,
    isFetching,
    onScrollEndDragOpportunityList,
    handlePressFavorite,
    setOpportunityEvaluation,
    isOpenEvaluation,
    isLoadingEvaluantion: setOpportunityRatesMutation.isLoading,
    refetch,
    closeEvaluationSuccessSheet,
    openEvaluationSuccessSheet,
    setIsOpenEvaluantion,
    evaluationSuccessSheetRef,
    handleEvaluate,
    handleUpdateOpportunityHighlighted
  }
}
