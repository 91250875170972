import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const KanbanIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.88247 4.49996C9.88247 2.84313 11.2256 1.5 12.8824 1.5H15C16.6569 1.5 18 2.84313 18 4.49996L17.9999 13.3233C17.9999 14.9802 16.6568 16.3233 15 16.3233H12.8823C11.2255 16.3233 9.88238 14.9802 9.88238 13.3233L9.88247 4.49996ZM12.8824 3.26468C12.2002 3.26468 11.6471 3.81773 11.6471 4.49996L11.6471 13.3233C11.6471 14.0056 12.2001 14.5586 12.8823 14.5586H15C15.6822 14.5586 16.2352 14.0056 16.2352 13.3233L16.2353 4.49996C16.2353 3.81773 15.6823 3.26468 15 3.26468H12.8824Z"
      fill="currentColor"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.62502e-05 4.49996C8.62502e-05 2.84313 1.34321 1.5 3.00004 1.5H5.11766C6.77449 1.5 8.11762 2.84313 8.11762 4.49996L8.11753 13.3233C8.11753 14.9802 6.7744 16.3233 5.11757 16.3233H2.99996C1.34313 16.3233 0 14.9802 0 13.3233L8.62502e-05 4.49996ZM3.00004 3.26468C2.31782 3.26468 1.76477 3.81773 1.76477 4.49996L1.76468 13.3233C1.76468 14.0056 2.31773 14.5586 2.99996 14.5586H5.11757C5.7998 14.5586 6.35285 14.0056 6.35285 13.3233L6.35294 4.49996C6.35294 3.81773 5.79988 3.26468 5.11766 3.26468H3.00004Z"
      fill="currentColor"
    />
  </Icon>
)
