import { useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  useGetActivityItemQuery,
  useUpdateActivityMutation
} from 'integration/resources/activities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { CheckItem } from 'organisms/Checklist/types'
import { useForm } from 'react-hook-form'
import {
  ActivityForm,
  activityFormSchema
} from 'src/components/templates/ActivityFormTemplate/types'
import { useAuthAtomValue } from 'src/store/auth'

import { UseEditActivity } from './types'
import { serializerActivity } from '../serializers'

export const useEditActivity: UseEditActivity = ({ route, navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { id } = route.params

  const { data: activityResponse } = useGetActivityItemQuery(id)

  const updateActivityMutation = useUpdateActivityMutation(id)

  const toast = useToast()

  const authAtom = useAuthAtomValue()

  const isPesados = useMemo(
    () => authAtom?.user.business_code === 5,
    [authAtom?.user.business_code]
  )

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit: onSubmit
  } = useForm<ActivityForm>({
    resolver: yupResolver(activityFormSchema),
    defaultValues: {
      activity_plan_action_items: [],
      activity_attachments: []
    }
  })

  const handleSubmit = (formData: ActivityForm) => {
    updateActivityMutation.mutate(
      serializerActivity(formData, authAtom?.user.std_code ?? formData.created_by_user_std_code),
      {
        onError: () => {
          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação" />
          })
        },
        onSuccess: () => {
          reset({})

          toast.show({
            render: () => <Toast type="success" text="Atividade atualizada com sucesso" />
          })

          navigation.navigate('OrganizationActivity', { id: formData.id })
        }
      }
    )
  }

  const handleAddItemActionPlan = (value: string) => {
    setValue('activity_plan_action_items', [
      ...getValues('activity_plan_action_items'),
      {
        value,
        id: value,
        checked: false
      }
    ])
  }

  const handleUpdateItemActionPlan = (item: CheckItem, checked: boolean) => {
    setValue(
      'activity_plan_action_items',
      getValues('activity_plan_action_items').map((obj) => {
        if (obj.id === item.id) {
          return { ...obj, checked }
        }

        return obj
      })
    )
  }

  const handleRemoveItemActionPlan = (item: CheckItem) => {
    setValue(
      'activity_plan_action_items',
      getValues('activity_plan_action_items').filter((obj) => obj.id !== item.id)
    )
  }

  useEffect(() => {
    if (activityResponse?.data.data.title) {
      reset(activityResponse?.data.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityResponse?.data.data.title])

  return {
    watch,
    control,
    setValue,
    isMobile,
    onSubmit,
    isLoading: updateActivityMutation.isLoading,
    handleSubmit,
    handleAddItemActionPlan,
    handleUpdateItemActionPlan,
    handleRemoveItemActionPlan,
    isPesados
  }
}
