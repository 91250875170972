import { FunctionComponent } from 'react'

import { IIconProps, Icon } from 'native-base'
import { Path } from 'react-native-svg'

type Direction = 'up' | 'down' | 'left' | 'right'

const getPath = (direction: Direction) => {
  switch (direction) {
    case 'up':
      return (
        <>
          <Path
            d="M17.0399 14.1984C16.8162 14.4184 16.4688 14.4357 16.2257 14.2521L16.1561 14.191L10.8098 8.75522L5.37368 14.1013C5.14995 14.3213 4.80257 14.3386 4.55942 14.155L4.48982 14.0939C4.26979 13.8702 4.25251 13.5228 4.43608 13.2796L4.49718 13.21L10.3789 7.42548C10.6026 7.20545 10.95 7.18817 11.1931 7.37174L11.2627 7.43284L17.0473 13.3145C17.2893 13.5606 17.286 13.9564 17.0399 14.1984Z"
            fill="currentColor"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9623 14.5754L15.8697 14.4941L10.8049 9.34444L5.66584 14.3983C5.2927 14.7653 4.71392 14.7937 4.30836 14.4875L4.29608 14.4783L4.20346 14.3969L4.19275 14.3861C3.82577 14.0129 3.79736 13.4341 4.10355 13.0286L4.11282 13.0163L4.19413 12.9237L10.0867 7.12841C10.4599 6.76143 11.0386 6.73302 11.4442 7.03921L11.4565 7.04847L11.5491 7.12979L17.3444 13.0224C17.7478 13.4325 17.7423 14.0921 17.3321 14.4955C16.959 14.8624 16.3802 14.8909 15.9746 14.5847L15.9623 14.5754ZM5.37368 14.1013L10.8098 8.75522L16.1561 14.191L16.2257 14.2521C16.4688 14.4357 16.8162 14.4184 17.0399 14.1984C17.286 13.9564 17.2893 13.5606 17.0473 13.3145L11.2627 7.43284L11.1931 7.37174C10.95 7.18817 10.6026 7.20545 10.3789 7.42548L4.49718 13.21L4.43608 13.2796C4.25251 13.5228 4.26979 13.8702 4.48982 14.0939L4.55942 14.155C4.80257 14.3386 5.14995 14.3213 5.37368 14.1013Z"
            fill="currentColor"
          />
        </>
      )

    case 'left':
      return (
        <>
          <Path
            d="M12.0227 3.35225C12.2224 3.55195 12.2406 3.86445 12.0772 4.08466L12.0227 4.14775L7.17073 9L12.0227 13.8523C12.2224 14.052 12.2406 14.3645 12.0772 14.5847L12.0227 14.6477C11.823 14.8474 11.5105 14.8656 11.2903 14.7022L11.2272 14.6477L5.97723 9.39775C5.77753 9.19805 5.75938 8.88555 5.92277 8.66534L5.97723 8.60225L11.2272 3.35225C11.4469 3.13258 11.8031 3.13258 12.0227 3.35225Z"
            fill="currentColor"
          />
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.3701 4.31923L12.2976 4.40319L7.70104 9L12.2879 13.5871C12.621 13.9202 12.6509 14.4408 12.3783 14.8081L12.3701 14.8192L12.2976 14.9032L12.2879 14.9129C11.9548 15.246 11.4342 15.2759 11.0669 15.0034L11.0557 14.9951L10.9718 14.9226L5.71207 9.66291C5.379 9.32984 5.34909 8.80918 5.62161 8.44189L5.62986 8.43077L5.70235 8.3468L10.9621 3.08709C11.3282 2.72097 11.9218 2.72097 12.2879 3.08709C12.621 3.42016 12.6509 3.94082 12.3783 4.30811L12.3701 4.31923ZM12.0227 13.8523L7.17073 9L12.0227 4.14775L12.0772 4.08466C12.2406 3.86445 12.2224 3.55195 12.0227 3.35225C11.8031 3.13258 11.4469 3.13258 11.2272 3.35225L5.97723 8.60225L5.92277 8.66534C5.75938 8.88555 5.77753 9.19805 5.97723 9.39775L11.2272 14.6477L11.2903 14.7022C11.5105 14.8656 11.823 14.8474 12.0227 14.6477L12.0772 14.5847C12.2406 14.3645 12.2224 14.052 12.0227 13.8523Z"
            fill="currentColor"
          />
        </>
      )

    case 'down':
      return (
        <>
          <Path
            d="M4.96006 7.80161C5.18379 7.58158 5.53116 7.5643 5.77432 7.74787L5.84391 7.80897L11.1902 13.2448L16.6263 7.89874C16.8501 7.67871 17.1974 7.66143 17.4406 7.84501L17.5102 7.9061C17.7302 8.12983 17.7475 8.47721 17.5639 8.72036L17.5028 8.78996L11.6211 14.5745C11.3974 14.7946 11.05 14.8118 10.8069 14.6283L10.7373 14.5672L4.9527 8.68546C4.71066 8.43936 4.71396 8.04365 4.96006 7.80161Z"
            fill="currentColor"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.03766 7.4246L6.13028 7.50592L11.1951 12.6556L16.3342 7.60167C16.7073 7.23469 17.2861 7.20628 17.6916 7.51247L17.7039 7.52174L17.7965 7.60305L17.8072 7.61394C18.1742 7.98708 18.2026 8.56585 17.8965 8.97142L17.8872 8.9837L17.8059 9.07632L11.9133 14.8716C11.5401 15.2386 10.9614 15.267 10.5558 14.9608L10.5435 14.9515L10.4509 14.8702L4.65563 8.97763C4.25224 8.56746 4.25773 7.90794 4.6679 7.50454C5.04104 7.13756 5.61981 7.10915 6.02538 7.41534L6.03766 7.4246ZM16.6263 7.89874L11.1902 13.2448L5.84391 7.80897L5.77432 7.74787C5.53116 7.5643 5.18379 7.58158 4.96006 7.80161C4.71396 8.04365 4.71066 8.43936 4.9527 8.68546L10.7373 14.5672L10.8069 14.6283C11.05 14.8118 11.3974 14.7946 11.6211 14.5745L17.5028 8.78996L17.5639 8.72036C17.7475 8.47721 17.7302 8.12983 17.5102 7.9061L17.4406 7.84501C17.1974 7.66143 16.8501 7.67871 16.6263 7.89874Z"
            fill="currentColor"
          />
        </>
      )

    case 'right':
      return (
        <>
          <Path
            d="M5.41478 3.53975C5.21508 3.73945 5.19692 4.05195 5.36031 4.27216L5.41478 4.33525L10.2668 9.1875L5.41478 14.0398C5.21508 14.2395 5.19692 14.552 5.36031 14.7722L5.41478 14.8352C5.61448 15.0349 5.92697 15.0531 6.14718 14.8897L6.21027 14.8352L11.4603 9.58525C11.66 9.38555 11.6781 9.07305 11.5147 8.85284L11.4603 8.78975L6.21027 3.53975C5.9906 3.32008 5.63445 3.32008 5.41478 3.53975Z"
            fill="currentColor"
          />
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.06741 4.50673L5.13989 4.59069L9.73646 9.1875L5.14961 13.7746C4.81654 14.1077 4.78663 14.6283 5.05916 14.9956L5.06741 15.0067L5.1399 15.0907L5.14961 15.1004C5.48268 15.4335 6.00334 15.4634 6.37063 15.1909L6.38175 15.1826L6.46572 15.1101L11.7254 9.85041C12.0585 9.51734 12.0884 8.99668 11.8159 8.62939L11.8076 8.61827L11.7351 8.5343L6.47544 3.27459C6.10932 2.90847 5.51573 2.90847 5.14961 3.27459C4.81654 3.60766 4.78663 4.12832 5.05916 4.49561L5.06741 4.50673ZM5.41478 14.0398L10.2668 9.1875L5.41478 4.33525L5.36031 4.27216C5.19692 4.05195 5.21508 3.73945 5.41478 3.53975C5.63445 3.32008 5.9906 3.32008 6.21027 3.53975L11.4603 8.78975L11.5147 8.85284C11.6781 9.07305 11.66 9.38555 11.4603 9.58525L6.21027 14.8352L6.14718 14.8897C5.92697 15.0531 5.61448 15.0349 5.41478 14.8352L5.36031 14.7722C5.19692 14.552 5.21508 14.2395 5.41478 14.0398Z"
            fill="currentColor"
          />
        </>
      )
  }
}

export const AccordionArrowIcon: FunctionComponent<
  IIconProps & { direction: 'up' | 'down' | 'left' | 'right' }
> = ({ direction, ...props }) => (
  <Icon {...{ viewBox: '0 0 20 20', ...props }}>{getPath(direction)}</Icon>
)
