import { Suspense, forwardRef } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'
import { CurrentViewTypesRef } from 'src/components/templates/ContactScreenTemplate/ContactScreenTemplate'

import { DocumentsDesktop } from './desktop'
import { DocumentsMobile } from './mobile'
import { TabCommonProps } from './types'

export const Documents = forwardRef<CurrentViewTypesRef, TabCommonProps>((props, ref) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Screen = isMobile ? DocumentsMobile : DocumentsDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} ref={ref} />
    </Suspense>
  )
})
