import { forwardRef } from 'react'

import { LoadingTurbo } from 'atoms'
import { WalletCard } from 'atoms/Cards/WalletCard'
import { Store } from 'integration/resources/store'
import { CustomBottomSheet, BottomSheetModal } from 'molecules'
import { VStack, Heading, Center, Pressable } from 'native-base'
import { Platform } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { setCustomBottomHight } from 'src/utils'

import { useBottomSheetStores } from './hook'

type BottomSheetStoresProps = {
  handleSelectStore(store: Store): void
}

export const BottomSheetStores = forwardRef<BottomSheetModal, BottomSheetStoresProps>(
  ({ handleSelectStore }, ref) => {
    const { stores, isLoadingStores, onScrollEndDragStoreList } = useBottomSheetStores()

    const isMobile = Platform.OS !== 'web'

    return (
      <CustomBottomSheet ref={ref} snapPoints={[`${setCustomBottomHight(stores?.length ?? 4)}`]}>
        <VStack
          p={isMobile ? 4 : 0}
          space={6}
          w="full"
          h="full"
          maxH={!isMobile ? 580 : undefined}
          pt={isMobile ? 6 : 0}>
          <Heading fontSize="18px" lineHeight="24px" textAlign="center">
            Lojas
          </Heading>

          <ScrollView
            showsVerticalScrollIndicator={false}
            onScrollEndDrag={onScrollEndDragStoreList}>
            {stores?.map((store: Store, index) => (
              <Pressable
                key={`${index}-${store?.tab_number}`}
                onPress={() => handleSelectStore(store)}
                borderRadius="20px"
                mb={2}>
                <WalletCard color="gray.50" type="WalletTab" typeFormat="currency" {...store} />
              </Pressable>
            ))}

            {isLoadingStores && (
              <Center mt={2}>
                <LoadingTurbo size={48} />
              </Center>
            )}
          </ScrollView>
        </VStack>
      </CustomBottomSheet>
    )
  }
)
