//import { format } from 'date-fns'
import { format } from 'date-fns'
import client from 'integration/client'
import { ResponseSuccess } from 'integration/types'

import { AnalyticsResponse, GetAnalyticsParams, TAvailableReport } from './types'

export const getAnalytics = async (params: GetAnalyticsParams) =>
  await client.get<AnalyticsResponse>(`/v1/reports/analytics/${params?.id}`, {
    params: {
      year_month: params?.year_month ?? format(new Date(), 'yyyyMM'),
      per_page: params?.per_page ?? 10,
      page: (params?.page && params?.page + 1) || 1,
      std_code: params?.std_code ?? undefined
    }
  })

export const getAvailableReports = () =>
  client.get<ResponseSuccess<TAvailableReport[]>>(`/v1/reports/analytics`)
