import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const BackIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 20 20', ...props }}>
    <Path
      fill="currentColor"
      d="M13.359 3.725c.222.222.242.569.06.814l-.06.07L7.967 10l5.392 5.391c.222.222.242.57.06.814l-.06.07a.625.625 0 0 1-.814.06l-.07-.06-5.834-5.833a.625.625 0 0 1-.06-.814l.06-.07 5.834-5.833a.625.625 0 0 1 .884 0Z"
    />
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.745 4.8-.081.092L8.557 10l5.096 5.097c.37.37.403.948.1 1.356l-.008.013-.081.093-.01.01c-.37.371-.95.404-1.357.101l-.013-.009-.093-.08-5.844-5.844a1.042 1.042 0 0 1-.1-1.357l.008-.012.08-.094L12.18 3.43a1.042 1.042 0 0 1 1.574 1.357l-.01.012Zm-.386 10.591L7.967 10l5.392-5.391.06-.07a.625.625 0 0 0-.944-.814L6.64 9.558l-.06.07a.625.625 0 0 0 .06.814l5.834 5.833.07.06a.625.625 0 0 0 .814-.06l.06-.07a.625.625 0 0 0-.06-.814Z"
      clipRule="evenodd"
    />
  </Icon>
)
