import { memo } from 'react'

import { Skeleton } from 'native-base'

export const SkeletonTable = memo(() => {
  const numRows = 10

  const numColumns = 8

  return (
    <table style={{ borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          {Array.from({ length: numColumns }, (_, index) => (
            <th key={index}>
              <Skeleton width="100%" height={12} startColor="gray.200" />
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {Array.from({ length: numRows }, (_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: numColumns }, (_, columnIndex) => (
              <td key={columnIndex}>
                <Skeleton width="100%" height={12} startColor="gray.100" />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
})
