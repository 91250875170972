import { Suspense, forwardRef, lazy } from 'react'

import { Illustrations, LoadingTurbo } from 'atoms'
import { BottomSheetModal } from 'molecules'
import { useBreakpointValue } from 'native-base'

type BottomSheetActionIllustrationProps = {
  illustration: keyof typeof Illustrations
  title: string
  description: string
  lastDescription?: string
  handleClose?(toogle: boolean): void
  handleConfirm(): void
  buttonTextClose?: string
  buttonTextConfirm?: string
  isLoading?: boolean
}

const ComponentMobile = lazy(() => import('./BottomSheetActionIllustration.mobile'))

const ComponentDesktop = lazy(() => import('./BottomSheetActionIllustration.desktop'))

export const BottomSheetActionIllustration = forwardRef<
  BottomSheetModal,
  BottomSheetActionIllustrationProps
>((props, ref) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? ComponentMobile : ComponentDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...{ ...props, ref }} />
    </Suspense>
  )
})
