import {
  eachMonthOfInterval,
  format,
  formatRelative,
  parseISO,
  set,
  subDays,
  subMonths
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ChartData } from 'integration/resources/panels'

export const getRecentKnowledgeData = () => {
  const previousData = subDays(new Date(), 7)

  return format(previousData, 'yyyy-MM-dd', {
    locale: ptBR
  })
}

export const getMonthName = (monthNumber: number) => {
  const date = set(new Date(), { month: monthNumber - 1 })

  const month = format(date, 'MMMM', {
    locale: ptBR
  })

  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1)

  return capitalizedMonth
}

export const getShortMonthName = (yearMonth: string) => {
  const monthNumber = yearMonth.slice(1)

  const monthName = getMonthName(Number(monthNumber)).substring(0, 3)

  return monthName
}

export const getShortMonthAndYear = (yearMonth: string, isMobile = true) => {
  // example: 202212
  const yearNumber = yearMonth.slice(0, 4)

  const monthNumber = yearMonth.slice(4)

  const monthName = isMobile
    ? getMonthName(Number(monthNumber)).substring(0, 3)
    : getMonthName(Number(monthNumber))

  // example: Dez-2022
  return isMobile ? `${monthName}-${yearNumber}` : `${monthName}`
}

export const getNumberYearAndMonth = (yearMonth: string) => {
  // example: Dez-2022
  const yearNumber = yearMonth.slice(4)

  const monthShortName = yearMonth.slice(0, 3)

  let monthNumber = ''

  let monthFullName = ''

  switch (monthShortName) {
    case 'Jan':
      monthNumber = '01'

      monthFullName = 'Janeiro'

      break

    case 'Fev':
      monthNumber = '02'

      monthFullName = 'Fevereiro'

      break

    case 'Mar':
      monthNumber = '03'

      monthFullName = 'Março'

      break

    case 'Abr':
      monthNumber = '04'

      monthFullName = 'Abril'

      break

    case 'Mai':
      monthNumber = '05'

      monthFullName = 'Maio'

      break

    case 'Jun':
      monthNumber = '06'

      monthFullName = 'Junho'

      break

    case 'Jul':
      monthNumber = '07'

      monthFullName = 'Julho'

      break

    case 'Ago':
      monthNumber = '08'

      monthFullName = 'Agosto'

      break

    case 'Set':
      monthNumber = '09'

      monthFullName = 'Setembro'

      break

    case 'Out':
      monthNumber = '10'

      monthFullName = 'Outubro'

      break

    case 'Nov':
      monthNumber = '11'

      monthFullName = 'Novembro'

      break

    case 'Dez':
      monthNumber = '12'

      monthFullName = 'Dezembro'

      break
  }

  return {
    monthFullName,
    monthNumber: `${yearNumber}${monthNumber}`
  }
}

export const formatDateStringToMask = (
  date: string | null,
  mask: string = 'yyyy-MM-dd HH:mm:ss'
) => {
  let parsed = new Date()

  if (date) {
    parsed = parseISO(date?.replace('Z', ''))
  }

  return format(parsed, mask, {
    locale: ptBR
  })
}

export const simpleCapitalizeText = (str: string = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const formatRelativeLocale = {
  yesterday: "'Ontem'",
  today: "'Hoje'",
  tomorrow: "'Amanhã'",
  nextWeek: 'dd MMM',
  other: 'dd MMM'
}

const locale = {
  ...ptBR,
  formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token]
}

export const getRelativeDay = (day: string) =>
  formatRelative(parseISO(day?.replace('Z', '')), new Date(), {
    locale,
    weekStartsOn: 0
  })

export const lastSixMonthsFromCurrentDate = (monthsData: ChartData[]): ChartData[] => {
  const currentDate = new Date()

  const lastSixMonths = []

  for (let i = 6; i >= 0; i--) {
    const monthDate = subMonths(currentDate, i)

    const month = format(monthDate, 'MMM-yyyy', {
      locale: ptBR
    })

    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1)

    const monthData = monthsData.find((data) => capitalizedMonth === data.year_month)

    if (monthData) {
      lastSixMonths.push(monthData)
    }
  }

  return lastSixMonths
}

export const lastTwelveMonthsFromCurrentDate = () => {
  const end = new Date()

  const start = subMonths(end, 12)

  const months = eachMonthOfInterval({ start, end })

  months.sort((a, b) => b.getTime() - a.getTime())

  return months.map((month) => {
    const label = format(month, 'MMM/yyyy', { locale: ptBR })

    const value = format(month, 'yyyyMM')

    return {
      label: label.charAt(0).toUpperCase() + label.slice(1),
      value
    }
  })
}
