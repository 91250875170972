import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ListIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.4 3.45C5.4 3.03579 5.73579 2.7 6.15 2.7H16.95C17.3642 2.7 17.7 3.03579 17.7 3.45C17.7 3.86421 17.3642 4.2 16.95 4.2H6.15C5.73579 4.2 5.4 3.86421 5.4 3.45Z"
      fill="#404040"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.4 8.85C5.4 8.43579 5.73579 8.1 6.15 8.1H16.95C17.3642 8.1 17.7 8.43579 17.7 8.85C17.7 9.26421 17.3642 9.6 16.95 9.6H6.15C5.73579 9.6 5.4 9.26421 5.4 8.85Z"
      fill="#404040"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.4 14.25C5.4 13.8358 5.73579 13.5 6.15 13.5H16.95C17.3642 13.5 17.7 13.8358 17.7 14.25C17.7 14.6642 17.3642 15 16.95 15H6.15C5.73579 15 5.4 14.6642 5.4 14.25Z"
      fill="#404040"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.2 3.75C1.36569 3.75 1.5 3.61569 1.5 3.45C1.5 3.28431 1.36569 3.15 1.2 3.15V2.25C0.537257 2.25 0 2.78726 0 3.45C0 4.11274 0.537257 4.65 1.2 4.65V3.75Z"
      fill="#404040"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.2 2.25C1.86274 2.25 2.4 2.78726 2.4 3.45C2.4 4.11274 1.86274 4.65 1.2 4.65V3.75C1.03431 3.75 0.9 3.61569 0.9 3.45C0.9 3.28431 1.03431 3.15 1.2 3.15V2.25Z"
      fill="#404040"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.2 14.55C1.36569 14.55 1.5 14.4157 1.5 14.25C1.5 14.0843 1.36569 13.95 1.2 13.95V13.05C0.537257 13.05 0 13.5873 0 14.25C0 14.9127 0.537257 15.45 1.2 15.45V14.55Z"
      fill="#404040"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.2 13.05C1.86274 13.05 2.4 13.5873 2.4 14.25C2.4 14.9127 1.86274 15.45 1.2 15.45V14.55C1.03431 14.55 0.9 14.4157 0.9 14.25C0.9 14.0843 1.03431 13.95 1.2 13.95V13.05Z"
      fill="#404040"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.2 9.15C1.36569 9.15 1.5 9.01569 1.5 8.85C1.5 8.68431 1.36569 8.55 1.2 8.55V7.65C0.537257 7.65 0 8.18726 0 8.85C0 9.51274 0.537257 10.05 1.2 10.05V9.15Z"
      fill="#404040"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.2 7.65C1.86274 7.65 2.4 8.18726 2.4 8.85C2.4 9.51274 1.86274 10.05 1.2 10.05V9.15C1.03431 9.15 0.9 9.01569 0.9 8.85C0.9 8.68431 1.03431 8.55 1.2 8.55V7.65Z"
      fill="#404040"
    />
  </Icon>
)
