import { AxiosResponse } from 'axios'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ResponseSuccess } from 'src/integration/types'
import { currencyFormat } from 'src/utils/currencyFormat'
import { formatDateStringToMask, getShortMonthAndYear } from 'src/utils/date'

import {
  ContactListSerialized,
  ContactSerialized,
  Note,
  Tab,
  WalletItem,
  TabDetail,
  SummaryChartDataSerialized,
  Contact
} from './types'
import { ProductionStoresList } from '../production'
import { Store } from '../store'

const walletItemSerializer = (item: ContactSerialized & Tab): WalletItem => ({
  ...item,
  name: item.name ?? item.name_fantasy ?? '',
  job: item.jobTitle ?? item.job_title ?? item.tab_number ?? '',
  store: item.store_name ?? (`${item.potential}` || ''),
  image: item.logo_image_url ?? item.profile_image_url ?? item.cover_image_url ?? ''
})

const contactItemSerialize = (contact: ContactSerialized): ContactSerialized => ({
  ...contact,
  birthDateFormatted:
    contact?.birth_date &&
    format(parseISO(contact.birth_date), "dd 'de' MMMM 'de' yyyy", {
      locale: ptBR
    }),
  birthDatePicker: {
    day:
      contact?.birth_date &&
      `${format(parseISO(contact.birth_date), 'dd', {
        locale: ptBR
      })}`,
    month:
      contact?.birth_date &&
      `${format(parseISO(contact.birth_date), 'MM', {
        locale: ptBR
      })}`,
    year: contact?.birth_date && parseISO(contact.birth_date).getFullYear()
  }
})

export const getContactItem: (
  response: AxiosResponse<ResponseSuccess<ContactSerialized>>
) => AxiosResponse<ResponseSuccess<WalletItem>> = (response) => {
  return {
    ...response,
    data: {
      ...response.data,
      data: walletItemSerializer(contactItemSerialize(response.data.data))
    }
  }
}

export const getTabItem: (
  response: AxiosResponse<ResponseSuccess<TabDetail>>
) => AxiosResponse<ResponseSuccess<TabDetail>> = (response) => ({ ...response })

export const getContactList: (
  response: AxiosResponse<ResponseSuccess<ContactListSerialized>>
) => AxiosResponse<ResponseSuccess<WalletItem[]>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: response.data.data.map((contact) => walletItemSerializer(contactItemSerialize(contact)))
  }
})

export const getTabList: (
  response: AxiosResponse<ResponseSuccess<ProductionStoresList>>
) => AxiosResponse<ResponseSuccess<WalletItem[]>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: response.data.data.items.map((item) => walletItemSerializer(item))
  }
})

export const getNoteList: (
  response: AxiosResponse<ResponseSuccess<Note[]>>
) => AxiosResponse<ResponseSuccess<Note[]>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: response.data.data.map((item) => ({
      ...item,
      created_at: formatDateStringToMask(item.created_at, "dd 'de' MMMM") ?? ''
    }))
  }
})

export const getNoteItem: (
  response: AxiosResponse<ResponseSuccess<Note>>
) => AxiosResponse<ResponseSuccess<Note>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: (response.data.data = {
      ...response.data.data,
      created_at: formatDateStringToMask(response.data.data.created_at, "dd 'de' MMMM ") ?? ''
    })
  }
})

export const getContactBirthdayItem: (
  response: AxiosResponse<ResponseSuccess<Contact>>
) => AxiosResponse<ResponseSuccess<Contact>> = (response) => ({ ...response })

export const getStoreList: (
  response: AxiosResponse<ResponseSuccess<Store[]>>
) => AxiosResponse<ResponseSuccess<Store[]>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: response.data.data.map((item) => ({
      ...item,
      potential: currencyFormat(`${item.potential}`)
    }))
  }
})

export const getSummaryChart: (
  response: AxiosResponse<ResponseSuccess<SummaryChartDataSerialized>>
) => AxiosResponse<ResponseSuccess<SummaryChartDataSerialized>> = (response) => {
  let higherValue = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        monthsSerialized: response.data.data.months.map((monthItem) => {
          if (higherValue < Number(monthItem.production)) higherValue = Number(monthItem.production)

          return {
            production: Number(monthItem.production) ?? 0,
            market_share: Number(monthItem.market_share) ?? 0,
            last_updated_at: monthItem.last_updated_at,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        higherValue
      })
    }
  }
}
