import { FunctionComponent, memo, useState } from 'react'

import { Cards } from 'atoms'
import { Opportunity } from 'integration/resources/opportunities'
import { Actionsheet, Box, Center, Heading, HStack, Pressable, Text, VStack } from 'native-base'
import { FlatList, Platform } from 'react-native'
import { OrganizationActivityDetailsProps } from 'src/screens/OrganizationScreen/Activity/Details/types'
import { formatDateStringToMask, formatToReal } from 'src/utils'

type OpportunitiesRelatedByStoreProps = {
  navigation: OrganizationActivityDetailsProps['navigation']
  opportunities: Opportunity[]
}

export const OpportunitiesRelatedByStore: FunctionComponent<OpportunitiesRelatedByStoreProps> =
  memo(({ navigation, opportunities }) => {
    const [isOpen, setisOpen] = useState(false)

    const opportunitiesInitial = opportunities?.slice(0, 2)

    const opportunitiesAll = opportunities?.slice(3)

    return (
      <VStack space={2}>
        {opportunitiesInitial?.map((oppotunity, i) => (
          <Box mb={2} key={`key-${i}-${oppotunity.title}${oppotunity.created_at}`}>
            <Cards.OrganizationCard
              topText={oppotunity.title}
              midText={
                oppotunity.store?.tab_number
                  ? `${oppotunity.store?.tab_number} • TAB ${formatDateStringToMask(
                      oppotunity.due_date,
                      'dd/MM/yyyy'
                    )}`
                  : undefined
              }
              bottomText={formatToReal(oppotunity?.earning_potential)}
              userName={oppotunity.user_name}
              icon="Dollar"
              active={oppotunity.is_finished}
              onPress={() =>
                navigation.navigate('OrganizationOpportunity', {
                  id: String(oppotunity.id)
                })
              }
            />
          </Box>
        ))}

        {!opportunitiesInitial?.length && (
          <Center flex={1} mx={8}>
            <Text fontSize="12px" lineHeight="18px" color="gray.300" textAlign="center">
              Não há opotunidades relacionadas ao TAB
            </Text>
          </Center>
        )}

        {!!opportunitiesAll?.length && (
          <Center>
            <Pressable
              bgColor="white"
              rounded="full"
              justifyContent="center"
              alignItems="center"
              px={8}
              h={12}
              onPress={() => setisOpen(true)}>
              <Text fontSize="14px" color="gray.700">
                Ver mais
              </Text>
            </Pressable>
          </Center>
        )}
        <Actionsheet isOpen={isOpen} onClose={() => setisOpen(false)}>
          <Actionsheet.Content p={4} bg="background.base">
            <Heading
              pt={4}
              pb={6}
              fontSize="18px"
              lineHeight="24px"
              textAlign="center"
              fontWeight={700}>
              Oportunindades do TAB
            </Heading>
            <HStack maxH="375px" justifyContent="space-between">
              <FlatList
                // showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={Platform.OS === 'web'}
                decelerationRate="normal"
                scrollEventThrottle={16}
                contentContainerStyle={{ paddingTop: 16 }}
                data={opportunitiesAll}
                keyExtractor={(item) => `key-actionsheet-${item.title}`}
                renderItem={({ item }) => (
                  <Box mb={2} bg="background.base">
                    <Cards.OrganizationCard
                      topText={item.title}
                      midText={
                        item.store?.tab_number
                          ? `${item.store?.tab_number} • TAB ${formatDateStringToMask(
                              item.due_date,
                              'dd/MM/yyyy'
                            )}`
                          : undefined
                      }
                      bottomText={`R$ ${item?.earning_potential}`}
                      userName={item.user_name}
                      icon="Dollar"
                      active={item.is_finished}
                      onPress={() =>
                        navigation.navigate('OrganizationActivity', {
                          id: String(item.id)
                        })
                      }
                    />
                  </Box>
                )}
              />
            </HStack>
          </Actionsheet.Content>
        </Actionsheet>
      </VStack>
    )
  })
