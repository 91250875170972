import { useCallback, useMemo } from 'react'

import { WalletItem, useGetContactListInfiniteQuery } from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'

import { UseTabContactScreen } from './TabContactScreen.types'

const mergeDataInfiniteQuery = (data: ReturnType<typeof useGetContactListInfiniteQuery>['data']) =>
  data?.pages.reduce<WalletItem[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useTabContactScreen: UseTabContactScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { tabNumber } = route.params

  const { data: favoritContactListData, isLoading: favoritContactListLoading } =
    useGetContactListInfiniteQuery({
      is_favorite: 1,
      store_tab_number: tabNumber,
      per_page: 100
    })

  const favoritContactList = useMemo(
    () => mergeDataInfiniteQuery(favoritContactListData),
    [favoritContactListData]
  )

  const {
    data: contactListData,
    isLoading: contactListIsLoading,
    isFetchingNextPage: contactListIsFetchingNextPage,
    hasNextPage: contactListHasNextPage,
    fetchNextPage: contactListFetchNextPage
  } = useGetContactListInfiniteQuery({
    store_tab_number: tabNumber
  })

  const allTabList = useMemo(() => mergeDataInfiniteQuery(contactListData), [contactListData])

  const handleGoToContactDetailsScreen = useCallback(
    (contactId: string) => {
      navigation.navigate('WalletContact', { contactId })
    },
    [navigation]
  )

  const onEndDragContactList = useCallback(() => {
    if (!contactListIsFetchingNextPage && contactListHasNextPage) {
      contactListFetchNextPage()
    }
  }, [contactListIsFetchingNextPage, contactListHasNextPage, contactListFetchNextPage])

  return {
    isMobile,
    favoritContactList,
    allTabList,
    isLoading: contactListIsLoading,
    favoritContactListLoading,
    handleGoToContactDetailsScreen,
    onEndDragContactList
  }
}
