import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  KEY_ACTIVITY_ITEM_QUERY,
  useDeleteActivityMutation,
  useFinishedActivityMutation,
  useGetActivityItemQuery,
  useUnFinishedActivityMutation,
  useUpdateActivityMutation
} from 'integration/resources/activities'
import { KEY_OPPORTUNITY_ITEM_QUERY } from 'integration/resources/opportunities'
import { BottomSheetModal } from 'molecules'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { CheckItem } from 'organisms/Checklist/types'
import { useQueryClient } from 'react-query'
import { useAuthAtomValue } from 'src/store/auth'
import { LogEvent } from 'src/utils/logEvents'

import { UseOrganizationActivityDetails } from './types'
import { serializerActivity } from '../serializers'

export const useOrganizationActivityDetails: UseOrganizationActivityDetails = ({
  navigation,
  route
}) => {
  const { id } = route.params

  const deleteSheetRef = useRef<BottomSheetModal>(null)

  const checkSheetRef = useRef<BottomSheetModal>(null)

  const queryClient = useQueryClient()

  const authAtom = useAuthAtomValue()

  const isOwner = authAtom?.user.access_level === 1

  const [checklist, setEmployees] = useState<CheckItem[]>([])

  const toast = useToast()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const finishedActivityMutation = useFinishedActivityMutation()

  const unFinishedActivityMutation = useUnFinishedActivityMutation()

  const deleteActivityMutation = useDeleteActivityMutation()

  const { mutate } = useUpdateActivityMutation(id)

  const {
    data: activityResponse,
    isLoading,
    isError,
    refetch,
    isRefetching
  } = useGetActivityItemQuery(id)

  const activity = useMemo(() => activityResponse?.data?.data, [activityResponse?.data.data])

  const handleGoBack = () => navigation.goBack()

  const openCheckSheet = useCallback(() => {
    checkSheetRef.current?.present()
  }, [])

  const closeCheckSheet = useCallback(() => {
    checkSheetRef.current?.close()

    checkSheetRef.current?.dismiss()
  }, [])

  const openDeleteSheet = useCallback(() => {
    deleteSheetRef.current?.present()
  }, [])

  const closeDeleteSheet = useCallback(() => {
    deleteSheetRef.current?.close()

    deleteSheetRef.current?.dismiss()
  }, [])

  const setOpenDeleteActivity = (value: boolean) => (value ? openDeleteSheet() : closeDeleteSheet())

  const setOpenCheckActivity = (value: boolean) => (value ? openCheckSheet() : closeCheckSheet())

  const handleFinishedActivity = () =>
    finishedActivityMutation.mutateAsync(
      {
        id
      },
      {
        onError: () => {
          toast.show({
            render: () => <Toast type="error" text="Não foi possível concluir atividade" />
          })
        },
        onSuccess: async () => {
          LogEvent('atividade_concluida', {
            item_id: id
          })

          queryClient.invalidateQueries([`${KEY_ACTIVITY_ITEM_QUERY}.${id}`])
        }
      }
    )

  const handleDeleteActivity = () =>
    deleteActivityMutation.mutateAsync(
      {
        id
      },
      {
        onError: () => {
          toast.show({
            render: () => (
              <Toast type="error" text="Não foi possível excluir a atividade, tente novamente." />
            )
          })
        },
        onSuccess: async () => {
          LogEvent('atividade_removida', {
            item_id: id
          })

          toast.show({
            render: () => <Toast type="success" text="Atividade excluída com sucesso" />
          })

          LogEvent('atividade_removida', {
            item_id: id
          })

          navigation.goBack()
        }
      }
    )

  const handleUnFinishedActivity = () =>
    unFinishedActivityMutation.mutateAsync(
      {
        id
      },
      {
        onError: () => {
          toast.show({
            render: () => <Toast type="error" text="Não foi possível alterar atividade" />
          })
        },
        onSuccess: () => {
          LogEvent('atividade_desfeita_concluida', {
            item_id: id
          })

          queryClient.invalidateQueries([`${KEY_ACTIVITY_ITEM_QUERY}.${id}`])
        }
      }
    )

  const handleToggleCheckActivity = (type: 'finished' | 'unfinished') => {
    switch (type) {
      case 'finished':
        setOpenCheckActivity(false)

        handleFinishedActivity()

        break

      case 'unfinished':
        setOpenCheckActivity(false)

        handleUnFinishedActivity()

        break
    }
  }

  const handleEditActivity = useCallback(
    (checklist: CheckItem[]) => {
      if (activity) {
        const activityData = { ...activity, activity_plan_action_items: checklist }

        mutate(
          serializerActivity(
            activityData,
            authAtom?.user.std_code ?? activityData.created_by_user_std_code
          )
        )
      }
    },
    [activity, authAtom?.user.std_code, mutate]
  )

  const handleAddNewItemInChecklist = (value: string) => {
    setEmployees((current) => {
      const newEmployees = [
        ...current,
        {
          value,
          id: value,
          checked: false,
          name: value,
          is_finished: false,
          created_at: new Date().toISOString()
        }
      ]

      handleEditActivity(newEmployees)

      return newEmployees
    })
  }

  const handleUpdateItemStatusInChecklist = (item: CheckItem, checked: boolean) => {
    setEmployees((current) => {
      const newEmployees = current.map((obj) => {
        if (obj.id === item.id) {
          return { ...obj, is_finished: checked, checked }
        }

        return obj
      })

      handleEditActivity(newEmployees)

      return newEmployees
    })
  }

  const handleRemoveItemActionPlan = (item: CheckItem) => {
    setEmployees((current) => {
      const newEmployees = current.filter((obj) => obj.id !== item.id)

      handleEditActivity(newEmployees)

      return newEmployees
    })
  }

  const handleGoToEditActivity = useCallback(
    (id: string) => navigation.navigate('OrganizationActivityEdit', { id }),
    [navigation]
  )

  if (isError && !isLoading) {
    navigation.navigate('ErrorPageErrorOccurred', {
      callback: () => {
        navigation.navigate('Tab', { screen: 'Organization', params: { tabId: 1 } })
      }
    })
  }

  useEffect(() => {
    const registerAnalitcs = async () =>
      LogEvent('atividade_detalhes_criada_pelo_usuario', {
        item_id: id
      })

    if (activity?.created_by_user_std_code) {
      registerAnalitcs()
    }

    if (activity?.activity_plan_action_items) {
      setEmployees(activity?.activity_plan_action_items)
    }
  }, [activity, id])

  const handleCompleteActivity = (
    callback: (isChecked: boolean) => void,
    currentCheck: boolean,
    id: string
  ) =>
    (currentCheck ? unFinishedActivityMutation : finishedActivityMutation).mutate(
      {
        id
      },
      {
        onError() {
          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação" />,
            placement: 'bottom',
            duration: 800
          })

          queryClient
            .invalidateQueries([
              `${KEY_ACTIVITY_ITEM_QUERY}.${id}`,
              `${KEY_OPPORTUNITY_ITEM_QUERY}.${route.params.id}`
            ])
            .then(() => toast.closeAll())

          callback(currentCheck)
        },
        onSuccess: async () => {
          if (!currentCheck) {
            toast.show({
              render: () => <Toast type="success" text="Atividade marcada como concluída 🎉" />,
              duration: 800
            })

            LogEvent('atividade_concluida', {
              item_id: id
            })
          }

          queryClient
            .invalidateQueries([
              `${KEY_ACTIVITY_ITEM_QUERY}.${id}`,
              `${KEY_OPPORTUNITY_ITEM_QUERY}.${route.params.id}`
            ])
            .then(() => toast.closeAll())

          callback(!currentCheck)
        }
      }
    )

  return {
    navigation,
    isMobile,
    checklist,
    activity,
    isLoading,
    handleAddNewItemInChecklist,
    handleUpdateItemStatusInChecklist,
    handleRemoveItemActionPlan,
    isLoadingCheckActivity:
      finishedActivityMutation.isLoading || unFinishedActivityMutation.isLoading,
    handleToggleCheckActivity,
    finishedActivity: activity?.is_finished ?? false,
    setOpenCheckActivity,
    setOpenDeleteActivity,
    checkSheetRef,
    deleteSheetRef,
    handleDeleteActivity,
    isLoadingDeleteActivity: deleteActivityMutation.isLoading,
    handleGoToEditActivity,
    onRefresh: refetch,
    isRefetching,
    handleGoBack,
    handleCompleteActivity,
    isOwner
  }
}
